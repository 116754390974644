import React from 'react'
import { FlatList, ListRenderItemInfo, TouchableOpacity } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import p from 'x/config/platform-specific'

import {
  IMkpProductDetailVariant,
  IXSellyVariantMappingSelectorViewProps,
  IXSellyVariantMappingSelectorViewState,
  IProductDetailVariant,
  IStagingPariedItem,
} from 'x/index'

import XText from 'xui/components/XText'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import XSellyLogo from 'xui/components/XSellyLogo'
import Box from 'xui/components/Box'
import XImage from 'xui/components/XImage'
import MkpLogo from 'xui/components/MkpLogo'
import XIconButton from 'xui/components/XIconButton'

import XSegment from 'xui/components/XSegment'
import XSpinner from 'xui/components/XSpinner'
import XCard from 'xui/components/XCard'
import XSwitch from 'xui/components/XSwitch'
import ForwardIcon from 'xui/components/ForwardIcon'
import XInput from 'xui/components/XInput'

const PAIR_BY_FIELD_OPTIONS = [
  { label: 'ชื่อตัวเลือกสินค้า', value: 'name' },
  { label: 'SKU', value: 'sku' },
]

const SIMILARITY_PERCENTAGE_OPTIONS = [
  { label: '100%', value: 100 },
  { label: '> 90%', value: 90 },
  { label: '> 75%', value: 75 },
  { label: '> 50%', value: 50 },
  { label: '> 25%', value: 25 },
]

const IS_SKIP_PAIRED_CHECK_OPTIONS = [
  { label: 'ผูกใหม่ทั้งหมด', value: true },
  { label: 'เฉพาะที่ยังไม่ได้ผูก', value: false },
]

export default class XSellyVariantMappingSelectorView extends React.Component<
  IXSellyVariantMappingSelectorViewProps,
  IXSellyVariantMappingSelectorViewState
> {
  static displayName = 'XSellyVariantMappingSelectorView'

  isPairing?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,

      focusedMkpVariantIndex: 0,
      searchText: '',
      isPairedXSellyVariantVisible: false,
      usedXSellyProductVariantPpIds: [],
    }
  }

  async componentDidMount() {
    await this._initialize()
  }

  // componentWillUnmount() {
  // }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _initialize = async () => {
    const editingMkpProduct = util.getNavParam(this.props, 'editingMkpProduct')
    const xSellyProduct = util.getNavParam(this.props, 'xSellyProduct')
    const newPairedMap = util.getNavParam(this.props, 'newPairedMap', {})
    const focusedMkpVariantIndex = util.getNavParam(this.props, 'focusedMkpVariantIndex')

    if (editingMkpProduct && xSellyProduct && _.isObject(newPairedMap) && _.isNumber(focusedMkpVariantIndex)) {
      await util.setStatePromise(this, { editingMkpProduct, xSellyProduct, newPairedMap, focusedMkpVariantIndex })
    } else {
      this._goBack()
    }

    const usedXSellyProductVariantPpIds = this._getUsedXSellyProductVariantPpIds()
    await util.setStatePromise(this, { isInitialized: true, usedXSellyProductVariantPpIds })
  }

  _getUsedXSellyProductVariantPpIds = () => {
    const { newPairedMap = {} } = this.state
    const keys = Object.keys(newPairedMap)
    const usedXSellyProductVariantPpIds = []
    for (let i = 0; i < keys.length; i++) {
      const ppId = newPairedMap[keys[i]]
      usedXSellyProductVariantPpIds.push(ppId)
    }
    return usedXSellyProductVariantPpIds
  }

  renderCustomHeader = () => {
    const { navigation } = this.props

    return <XCustomHeader title={'ผูกตัวเลือกสินค้า'} headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} />
  }

  renderMkpProductInfo = () => {
    const { editingMkpProduct: mkpProduct, focusedMkpVariantIndex } = this.state

    if (!mkpProduct || !mkpProduct.name) {
      return null
    }

    const { name, thumbnail_uris = [], variants = [], mkp_id } = mkpProduct

    const variant = variants[focusedMkpVariantIndex]

    let thumbnailImgUri = thumbnail_uris[0] || null
    if (variant.img_uri) {
      thumbnailImgUri = variant.img_uri
    }

    const variantName = variant.name

    return (
      <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
        {thumbnailImgUri && thumbnailImgUri !== '' && (
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailImgUri }} />

            <Box position='absolute' top='1' left='1'>
              <MkpLogo mkpId={mkp_id} height={24} width={24} />
            </Box>
          </VStack>
        )}

        <VStack flex={1} space='1'>
          <XText variant='inactive' numberOfLines={2}>
            {name}
          </XText>
          <XText bold>{variantName}</XText>
        </VStack>
      </HStack>
    )
  }

  _onPairedXSellyVariantVisibleChange = (newValue: boolean) => {
    this.setState({ isPairedXSellyVariantVisible: newValue })
  }

  _onPickXSellyProductVariantItem = (pickedXSellyVariantIndex: number) => {
    const { focusedMkpVariantIndex } = this.state
    const onVariantMappingPicked = util.getNavParam(this.props, 'onVariantMappingPicked')

    if (_.isFunction(onVariantMappingPicked)) {
      onVariantMappingPicked(focusedMkpVariantIndex, pickedXSellyVariantIndex)
    }

    this._goBack()
  }

  _isXSellyVariantItemVisible = (variant: IProductDetailVariant) => {
    const { isPairedXSellyVariantVisible, usedXSellyProductVariantPpIds, searchText } = this.state
    const { pp_id } = variant

    if (!isPairedXSellyVariantVisible && _.includes(usedXSellyProductVariantPpIds, pp_id)) {
      return false
    }

    if (this._isSearching()) {
      const { name } = variant
      if (!_.isString(name) || name.length === 0) {
        return false
      }

      if (!_.includes(name, searchText)) {
        return false
      }
    }

    return true
  }

  _renderXSellyProductVariantItem = (info: ListRenderItemInfo<IProductDetailVariant>) => {
    const { xSellyProduct } = this.state
    const { item: variant, index } = info

    const { name, thumbnail_url } = variant
    let thumbnailUri = xSellyProduct.thumbnail_uris[0] || null
    if (thumbnail_url) {
      thumbnailUri = thumbnail_url
    }

    const isVisible = this._isXSellyVariantItemVisible(variant)

    if (!isVisible) {
      return null
    }

    return (
      <HStack w='full' px='2' pt='1.5'>
        <TouchableOpacity style={{ width: '100%' }} onPress={() => this._onPickXSellyProductVariantItem(index)}>
          <HStack w='full' px='3' py='2' space='1' bg='white' borderRadius='lg' borderWidth='1' borderColor='gray.100'>
            <HStack flex={1} space='1.5'>
              <VStack>
                <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />
                <Box position='absolute' top='1' left='1'>
                  <XSellyLogo width={24} height={24} />
                </Box>
              </VStack>

              <VStack flex={1} pt='1' space='1'>
                <XText w='full' variant='primary' bold numberOfLines={2}>
                  {name}
                </XText>
              </VStack>
            </HStack>

            <HStack w='34px' alignItems='center' justifyContent='center'>
              <ForwardIcon />
            </HStack>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _keyExtractor = (item: IProductDetailVariant, index: number) => `${index}-${item.pp_id}`

  _renderListEmptyComponent = () => {
    const { isPairedXSellyVariantVisible, searchText } = this.state

    let text = 'ไม่พบตัวเลือกสินค้า'

    if (!isPairedXSellyVariantVisible) {
      text = `${text}ที่ยังไม่ได้ผูก`
    }

    if (this._isSearching()) {
      text = `${text} ตามการค้นหา "${searchText}"`
    }

    if (!isPairedXSellyVariantVisible) {
      text = `${text}\n\nหากต้องการดูตัวเลือกทั้งหมด กรุณากดเปิดสวิตช์ด้านบน`
    }

    return (
      <HStack w='full' px='4' py='20px' alignItems='center' justifyContent='center'>
        <XText variant='inactive' textAlign='center'>
          {text}
        </XText>
      </HStack>
    )
  }

  _isEmptyResult = () => {
    const { xSellyProduct } = this.state
    const { variants } = xSellyProduct
    if (!variants || variants.length === 0) {
      return true
    }

    const result = xSellyProduct.variants.filter((variant) => this._isXSellyVariantItemVisible(variant))
    return result.length === 0
  }

  renderXSellyVariantList = () => {
    const { xSellyProduct, searchText } = this.state

    if (this._isEmptyResult()) {
      return this._renderListEmptyComponent()
    }

    return (
      <FlatList
        style={{ flex: 1, width: '100%' }}
        extraData={searchText}
        data={xSellyProduct.variants}
        renderItem={this._renderXSellyProductVariantItem}
        keyExtractor={this._keyExtractor}
      />
    )
  }

  _onSearchTextChange = (text: string) => {
    this.setState({ searchText: text })
  }

  _isSearching = () => {
    const { searchText } = this.state
    return searchText && searchText.length > 0
  }

  _closeSearch = () => {
    this.setState({ searchText: '' })
  }

  renderHeaderOptions = () => {
    const { editingMkpProduct, xSellyProduct, isPairedXSellyVariantVisible, searchText, focusedMkpVariantIndex } = this.state

    if (!editingMkpProduct || !xSellyProduct) {
      return null
    }

    return (
      <VStack w='full' space='1' borderBottomWidth='1' borderBottomColor='gray.300'>
        {/* <XText>{JSON.stringify(editingMkpProduct)}</XText> */}
        <VStack w='full' px='4' py='2.5' space='3' bg='white'>
          <VStack w='full'>
            <XText variant='inactive'>{`สำหรับตัวเลือกที่ ${focusedMkpVariantIndex + 1}:`}</XText>
            <XCard>
              <VStack w='full' p='1'>
                {this.renderMkpProductInfo()}
                {/* <Box w='full' h='1px' bg='gray.300' /> */}
                {/* {this.renderXSellyProductInfo()} */}
              </VStack>
            </XCard>
          </VStack>

          <HStack w='full' space='2'>
            <XText flex={1} variant='inactive'>
              แสดงตัวเลือกสินค้า XSelly ที่เลือกผูกไปแล้ว
            </XText>
            <XSwitch value={isPairedXSellyVariantVisible} onValueChange={this._onPairedXSellyVariantVisibleChange} />
          </HStack>

          <HStack w='full' space='2'>
            <XInput
              w='full'
              value={searchText}
              placeholder='ค้นหาด้วยชื่อตัวเลือกสินค้า...'
              onChangeText={this._onSearchTextChange}
              InputLeftElement={
                <Box pl='1.5'>
                  <XIcon name='search' variant='inactive' />
                </Box>
              }
              InputRightElement={this._isSearching() ? <XIconButton name='close-circle' onPress={this._closeSearch} /> : undefined}
            />
          </HStack>
        </VStack>
      </VStack>
    )
  }

  renderMain = () => {
    const { isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    return (
      <XContainer>
        {this.renderCustomHeader()}
        {this.renderHeaderOptions()}
        {/* <XContent> */}
        <VStack w='full' flex={1} alignItems='center' bg='coolGray.200'>
          {this.renderXSellyVariantList()}
          {/* </XContent> */}
        </VStack>
      </XContainer>
    )
  }

  render() {
    return this.renderMain()
  }
}
