import BaseUIERPShippingSetting from 'xui/modules/erp/BaseUIERPShippingSetting'
// import { getConfig } from 'x/config/mode'
// import { WebView } from 'react-native-webview'
// import OrdersSummaryDonut from '../../components/OrdersSummaryDonut'

export default class ERPShippingSetting extends BaseUIERPShippingSetting {
  static displayName = 'ERPShippingSetting'

  static navigationOptions = {
    header: null,
  }
}
