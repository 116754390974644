import React from 'react'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { IPackageDetails, ISubscriptionPackageList, IXScreenProps, SubscriptionPackageListItems } from 'x/types'
import api from 'x/utils/api'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'

interface IBaseSubscriptionViewProps extends IXScreenProps {
  profile: any
  subscription: any
  fetchSubscription: any
}

interface IBaseSubscriptionViewState {
  refreshing: boolean
  subscriptionPackageList: ISubscriptionPackageList
  packageDetails: IPackageDetails
}

export default abstract class BaseSubscriptionView extends React.Component<IBaseSubscriptionViewProps, IBaseSubscriptionViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  mainPackageItems: any

  constructor(props) {
    super(props)
    // @ts-ignore
    this.state = {
      refreshing: false,
      packageDetails: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
      ...item,
      label: p.op.t(`Subscription.main.${item.key}`),
    }))
  }

  async componentDidMount() {
    await this._fetchSubscriptionPackageList()
    // console.log('componentDidMount 02 => ')
    // await this._getPackageDetails()
    // console.log('componentDidMount 01 => ')
    const { profile, subscription, fetchSubscription } = this.props
    // console.log(';subscription => ', subscription.toJS())
    if (!profile || !profile.get('user_id')) {
      this._onRefresh()
    } else if (profile && profile.get('user_id') && (!subscription || !subscription.get('user_id'))) {
      fetchSubscription()
    }
  }

  // _getPackageDetails = async () => {
  //   await util.setStatePromise(this, {
  //     packageDetails: JSON_PACKAGE_DETAIL_DATA,
  //   })
  // }

  shouldComponentUpdate(nextProps, nextState) {
    const isStateDiff = util.isDiff(this.props, nextProps, ['profile', 'subscription'])
    const isPropsDiff = util.isDiff(this.state, nextState, ['refreshing'])
    return isStateDiff || isPropsDiff
  }

  _fetchSubscriptionPackageList = async () => {
    const apiOptions = {
      showSpinner: true,
    }
    let PURCHASABLES_ID = xCONS.PURCHASABLES_ID.SCB
    if (p.op.isIOS()) {
      PURCHASABLES_ID = xCONS.PURCHASABLES_ID.IOS
    }
    const body = {
      lang: 'th',
      purchase_channel_id: PURCHASABLES_ID,
    }
    try {
      const res = await api.postV2(api.POST_SUBSCRIPTION_PACKAGE_LIST, body, apiOptions)
      // console.log('_fetchSubscriptionPackageList res => ', res)
      await util.setStatePromise(this, {
        subscriptionPackageList: res,
      })
      // if (!res || _.isEmpty(res)) {
      // }
    } catch (err) {}
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _hideObject = () => {
    const { subscription } = this.props
    const orderCount = subscription.get('order_count_curr') || 0
    const isIOS = p.op.isIOS()
    // console.log('/// => ', orderCount < 11 && subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE)
    return isIOS && orderCount < 3 && subscription.get('type') === xCONS.SUBSCRIPTION_PACKAGE.FREE
  }

  _onRefresh = () => {
    const { fetchSubscription } = this.props
    // this.setState({ refreshing: true }, () => {
    //   const successCallback = res => {
    //     // console.log('successCallback res => ', res)
    //     if (res && res.profile && res.profile.user_id) {
    //       // this._fetchSubscription(res.profile.user_id)
    //       fetchSubscription()
    //     }
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //
    //   const failedCallback = err => {
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //   fetchUserProfile(successCallback, failedCallback)
    // })
    this.setState({ refreshing: true }, () => {
      const callback = (err) => {
        // if (this && !this.isUnmounting) {
        this.setState({ refreshing: false })
        // }
      }
      fetchSubscription({
        successCallback: callback,
        failedCallback: callback,
      })
    })
  }

  _callbackFormSlectedMainPackage = (itam: SubscriptionPackageListItems) => {
    console.log('_callbackFormSlectedMainPackage => ', itam)
  }

  _navToSelectMainPackageView = (mode: 'NEW_PACKAGE' | 'UPGRADE_PACKAGE' | 'PER_PACKAGE') => {
    const { subscriptionPackageList } = this.state
    // @ts-ignore
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSelectMainPackageView({
        subscriptionPackageList,
        mode,
        callback: (itam: SubscriptionPackageListItems) => this._callbackFormSlectedMainPackage(itam),
      })
    )
  }

  _navToPaymentSubscriptionPackageView = () => {
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToPaymentSubscriptionPackageView({}))
  }

  _navToInAppPurchaseView = () => {
    const { subscriptionPackageList } = this.state
    const { navigation } = this.props
    navigation.dispatch(NavActions.navToInAppPurchaseView({ subscriptionPackageList }))
  }

  _navToInAppPurchasePerPackageView = () => {
    const { subscriptionPackageList } = this.state
    const { navigation, subscription } = this.props
    const subscriptionJS = subscription.toJS()
    // console.log('subscriptionPackageList => ', subscriptionPackageList)
    const { type } = subscriptionJS
    if (_.isNil(subscriptionPackageList)) {
      return
    }
    const warningPackage = () => p.op.showConfirmationOkOnly('', 'ไม่สามารถต่อแพ็กเกจได้ กรุณาติดต่อทีมงาน (have_package_upgrade)')
    const itams = _.cloneDeep(subscriptionPackageList.items)
    const PACKAGE_SKU = ['PACKAGE-BRONZE-CYCLE-12', 'PACKAGE-SILVER-CYCLE-12', 'PACKAGE-GOLD-CYCLE-12', 'PACKAGE-PLATINUM-CYCLE-12']
    const sku = PACKAGE_SKU[type - 2]
    // console.log('sku => ', sku)
    // console.log('itams => ', itams)
    let jsonData = null
    itams.forEach((itam) => {
      if (itam.sku === sku) {
        jsonData = itam.data_json
      }
    })
    if (_.isNil(jsonData)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาติดต่อทีมงาน (subscription_package is null)')
      return
    }
    // console.log('jsonData => ', jsonData)
    // console.log('subscriptionJS => ', subscriptionJS)
    if (jsonData.helper_count !== subscription.helper_count) {
      warningPackage()
      return
    }
    if (jsonData.m_o_count !== subscription.m_o_count) {
      warningPackage()
      return
    }
    if (jsonData.mkp_quota !== subscription.mkp_quota) {
      warningPackage()
      return
    }
    if (jsonData.pg_quota !== subscription.pg_quota) {
      warningPackage()
      return
    }
    if (jsonData.store_count !== subscription.store_count) {
      warningPackage()
      return
    }
    if (jsonData.ug_quota !== subscription.ug_quota) {
      warningPackage()
      return
    }
    if (jsonData.vd_quota !== subscription.vd_quota) {
      warningPackage()
      return
    }
    if (jsonData.wh_quota !== subscription.wh_quota) {
      warningPackage()
      return
    }
    navigation.dispatch(NavActions.navToInAppPurchasePerPackageView({ subscriptionPackageList }))
  }

  _getCountPackageByKey = (key: string) => {
    const { subscription } = this.props
    if (_.isNil(subscription)) {
      return '-'
    }
    const subscriptionJS = subscription.toJS()
    return subscriptionJS[key]
  }
}
