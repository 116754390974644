import React from 'react'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseResellerManageView from 'x/modules/reseller/BaseResellerManageView'
import XCustomHeader from 'xui/components/XCustomHeader'
import { COLORS } from 'x/config/styles'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XMenuListView from 'xui/components/XMenuListView'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'
import XOverlay from 'xui/components/XOverlay'
import XInput from 'xui/components/XInput'
import { TouchableOpacity, Keyboard } from 'react-native'

export default abstract class BaseUIResellerManageView extends BaseResellerManageView {
  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: true,
      onPressItem: () => util.navGoBack(this.props),
    }
    return <XCustomHeader title='สมาชิก' headerLeftProps={leftBtn} />
  }

  _renderResellerInfo = () => {
    const { reseller, userGroup } = this.state
    if (_.isNil(reseller)) {
      return null
    }
    const userGroupName = userGroup.name ? userGroup.name : ''
    return (
      <VStack w='full' bgColor={COLORS.FORM_PRIMARY_BG}>
        <HStack p='2'>
          <XImage
            // resizeMode='cover'
            style={{
              borderRadius: 90,
              width: 50,
              height: 50,
              justifyContent: 'center',
            }}
            source={{ uri: reseller.t }}
          />
          <VStack ml='8'>
            <XText>{reseller.d}</XText>
            {userGroupName === '' ? (
              <XText>{userGroupName}</XText>
            ) : (
              <HStack>
                <XText variant='inactive'>กลุ่มสมาชิก:</XText>
                <XText ml='2'>{userGroupName}</XText>
              </HStack>
            )}
          </VStack>
        </HStack>
      </VStack>
    )
  }

  _onPressSubmit = async () => {
    Keyboard.dismiss()
    await util.delay(100)
    this._onRequestCloseEditResellerName()
    await this._onChangeResellerName()
  }

  _renderEditResellerName = () => {
    const { resellerName, isResellerEditOpenModal } = this.state
    return (
      <XOverlay
        contentStyle={{ minHeight: 120, width: 300 }}
        visible={isResellerEditOpenModal}
        onRequestClose={this._onRequestCloseEditResellerName}>
        <VStack p='2'>
          <HStack w='full' alignItems='center' justifyContent='center'>
            <XText>เปลี่ยนชื่อสมาชิก</XText>
          </HStack>
          <HStack mt='2' w='full'>
            <XInput
              style={{ width: '100%' }}
              value={resellerName}
              onBlur={() => {
                Keyboard.dismiss()
              }}
              onChangeText={(newText) => this.setState({ resellerName: newText })}
            />
          </HStack>
          <HStack mt='2'>
            <TouchableOpacity
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onPress={() => this._onPressSubmit()}
              style={{
                borderRadius: 90,
                paddingTop: 4,
                paddingBottom: 4,
                flex: 1,
                height: 30,
                backgroundColor: COLORS.APP_MAIN,
                alignItems: 'center',
                justifyContent: 'center',
                marginRight: 4,
              }}>
              <XText color={COLORS.WHITE} bold>
                เปลี่ยนชื่อสมาชิก
              </XText>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={this._onRequestCloseEditResellerName}
              style={{
                borderRadius: 90,
                paddingTop: 4,
                paddingBottom: 4,
                width: 60,
                height: 30,
                borderColor: COLORS.APP_MAIN,
                borderWidth: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <XText color={COLORS.APP_MAIN} bold>
                ปิด
              </XText>
            </TouchableOpacity>
          </HStack>
        </VStack>
      </XOverlay>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent
          w='full'
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          }}>
          {this._renderResellerInfo()}
          {this._renderEditResellerName()}
          <VStack w='full' bgColor='white'>
            <XMenuListView menuItems={this.MENU_ITRMS} />
          </VStack>
        </XContent>
      </XContainer>
    )
  }
}
