import BackgroundJobListView from 'xui/modules/bgjob/BackgroundJobListViewContainer'
import BackgroundTaskListView from 'xui/modules/bgjob/BackgroundTaskListViewContainer'
import BackgroundJobInProcessByTypeView from 'xui/modules/bgjob/BackgroundJobInProcessByTypeViewContainer'
import AutoCompleteOrderTabItemsView from 'xui/modules/orderlist/AutoCompleteOrderTabItemsViewContainer'
import AutoCompletePaymentBySegmentViewContainer from 'xui/modules/orderlist/AutoCompletePaymentBySegmentViewContainer'
import AutoCompleteShipmentBySegmentViewContainer from 'xui/modules/orderlist/AutoCompleteShipmentBySegmentViewContainer'
import ProductVariantEditorView from 'xui/modules/product/ProductVariantEditorViewContainer'
import ProductDetailMkpInfoDetailView from 'xui/modules/product/ProductDetailMkpInfoDetailView'
import OrderListView from 'xui/modules/orders/OrderListView'
import PaymentOrderListView from 'xui/modules/orders/PaymentOrderListView'
import PrintDocumentOrderListView from 'xui/modules/orders/PrintDocumentOrderListView'
import AllPrintDocumentOrderListView from 'xui/modules/orders/AllPrintDocumentOrderListView'
import ERPSubmitDocumentOrderListView from 'xui/modules/orders/ERPSubmitDocumentOrderListView'
import ReservedProductsOrderListView from 'xui/modules/orders/ReservedProductsOrderListView'
import AutoShipmentOrderListView from 'xui/modules/orders/AutoShipmentOrderListView'
import AutoPaymentOrderListView from 'xui/modules/orders/AutoPaymentOrderListView'
import MkpConfirmOrderListView from 'xui/modules/orders/MkpConfirmOrderListView'
import SearchOrderListView from 'xui/modules/orders/SearchOrderListView'
import ProductListViewMyStore from 'xui/modules/productlist/ProductListViewMyStoreContainer'
import ProductListViewMyStoreWithVolumeDiscount from 'xui/modules/productlist/ProductListViewMyStoreWithVolumeDiscountContainer'
import ProductListViewSellersStore from 'xui/modules/productlist/ProductListViewSellersStoreContainer'
import ProductListViewPullMyProductsToOrder from 'xui/modules/productlist/ProductListViewPullMyProductsToOrderContainer'
import ProductListViewPullSellersProductsToOrder from 'xui/modules/productlist/ProductListViewPullSellersProductsToOrderContainer'
import ProductListViewPullMyProductsToSalePage from 'xui/modules/productlist/ProductListViewPullMyProductsToSalePageContainer'
import ProductListViewPullSellersProductsToSalePage from 'xui/modules/productlist/ProductListViewPullSellersProductsToSalePageContainer'
import ProductListViewPullSellersProductsToMyStore from 'xui/modules/productlist/ProductListViewPullSellersProductsToMyStoreContainer'
import ProductListViewMkpMapping from 'xui/modules/productlist/ProductListViewMkpMappingContainer'
import ProductListViewMkpView from 'xui/modules/productlist/ProductListViewMkpViewContainer'
import ProductListViewXSellyToMkpMapping from 'xui/modules/productlist/ProductListViewXSellyToMkpMappingContainer'
import ProductListViewMkpToXSellyMapping from 'xui/modules/productlist/ProductListViewMkpToXSellyMappingContainer'
import MkpProductDetailMapping from 'xui/modules/marketplace/MkpProductDetailMappingContainer'
import ProductVariantXSellyToMkpMappingView from 'xui/modules/marketplace/ProductVariantXSellyToMkpMappingView'
import ProductVariantMkpToXSellyMappingView from 'xui/modules/marketplace/ProductVariantMkpToXSellyMappingView'
import MkpProductDetailView from 'xui/modules/marketplace/MkpProductDetailViewContainer'
import SearchOrderView from 'xui/modules/orders/SearchOrderViewContainer'
import MultipleOrdersOperationMenuView from 'xui/modules/orderlist/MultipleOrdersOperationMenuViewContainer'
import MkpProductLinkManagerView from 'xui/modules/product/MkpProductLinkManagerViewContainer'
import TimeSlotsViewContainer from 'xui/modules/channels/TimeSlotsViewContainer'
import ReauthLazadaViewContainer from 'xui/modules/channels/ReauthLazadaViewContainer'
import VolumeDiscountListView from 'xui/modules/store/VolumeDiscountListViewContainer'
import VolumeDiscountView from 'xui/modules/store/VolumeDiscountViewContainer'
import StoreSettingShippingTypeViewContainer from 'xui/modules/shipment/StoreSettingShippingTypeViewContainer'
import StorePaymentAccountListViewContainer from 'xui/modules/payment/StorePaymentAccountListViewContainer'

import StoreSettingOrderPolicyContainer from 'xui/modules/store/StoreSettingOrderPolicyContainer'
import MenuOrderBillOnlineShowAmountContainer from 'xui/modules/store/MenuOrderBillOnlineShowAmountContainer'
import StoreSettingOrderAutomationContainer from 'xui/modules/store/StoreSettingOrderAutomationContainer'
import StoreSettingShipBeforePayAndCodContainer from 'xui/modules/store/StoreSettingShipBeforePayAndCodContainer'
import StoreSettingOrderNoteContainer from 'xui/modules/store/StoreSettingOrderNoteContainer'
import StoreSettingVolumeDiscount from 'xui/modules/store/StoreSettingVolumeDiscount'
import StoreSettingStoreProductSNContainer from 'xui/modules/store/StoreSettingStoreProductSNContainer'
import StoreSettingLockOrderAutomationContainer from 'xui/modules/store/StoreSettingLockOrderAutomationContainer'
import StoreSettingStoreCancelOrderAutomationContainer from 'xui/modules/store/StoreSettingStoreCancelOrderAutomationContainer'
import StoreSettingVerifySlip from 'xui/modules/store/StoreSettingVerifySlip'

import ShippingView from 'xui/modules/shipment/ShippingViewContainer'
import CardInfoViewContainer from 'xui/modules/setting/CardInfoViewContainer'
import StoreStoreSettingMenuShipping from 'xui/modules/store/StoreStoreSettingMenuShipping'
import ResellerChangeGroupViewContainer from 'xui/modules/userGroup/ResellerChangeGroupViewContainer'
import DevMenuSubscriptionViewContainer from 'xui/modules/dev/DevMenuSubscriptionViewContainer'
import SearchableProductListView from 'xui/modules/orders/SearchableProductListViewContainer'
import MkpMappingChannelListViewPicker from 'xui/modules/marketplace/MkpMappingChannelListViewPicker'
import MkpAutoMappingVariantsView from 'xui/modules/marketplace/MkpAutoMappingVariantsView'
import XSellyVariantMappingSelectorView from 'xui/modules/marketplace/XSellyVariantMappingSelectorView'

import DashboardView from 'xui/modules/dashboard/DashboardView'
import DailySaleChartView from 'xui/modules/dashboard/DailySaleChartView'

import VerifySlipCreditHistoryView from 'xui/modules/verifySlip/VerifySlipCreditHistoryView'
import VerifySlipCreditSummaryView from 'xui/modules/verifySlip/VerifySlipCreditSummaryView'
import VerifySlipCreditSummaryUnusableView from 'xui/modules/verifySlip/VerifySlipCreditSummaryUnusableView'
import VerifySlipDetailView from 'xui/modules/verifySlip/VerifySlipDetailView'

import ERPSubmitDocumentView from 'xui/modules/erp/ERPSubmitDocumentView'
import AddressFormView from 'xui/modules/address/AddressFormView'
import OrderListFilterView from 'xui/modules/orders/OrderListFilterView'
import OrderListFilterEditorView from 'xui/modules/orders/OrderListFilterEditorView'
import OrderListFilterManageView from 'xui/modules/orders/OrderListFilterManageView'

import ReauthShopifyViewContainer from 'xui/modules/channels/ReauthShopifyViewContainer'
import StoreContactListView from 'xui/modules/contact/StoreContactListView'
import StoreContactDetailView from 'xui/modules/contact/StoreContactDetailView'

export const SHARED_AUTH_SCREENS = {
  OrderListView: {
    screen: OrderListView,
    path: 'ol',
    // // ที่แสดงผลตลอดไม่ได้เพราะว่าเมื่อโดน push view เพิ่มเติมแล้ว tab จะไม่สามารถเลื่อนได้อีก
    // navigationOptions: {
    //   isAlwaysActive: true,
    // },
  },
  PrintDocumentOrderListView: {
    screen: PrintDocumentOrderListView,
    path: 'pdol',
  },
  AllPrintDocumentOrderListView: {
    screen: AllPrintDocumentOrderListView,
    path: 'apdol',
  },
  ERPSubmitDocumentOrderListView: {
    screen: ERPSubmitDocumentOrderListView,
    path: 'erppdol',
  },
  ReservedProductsOrderListView: {
    screen: ReservedProductsOrderListView,
    path: 'rpol',
  },
  AutoShipmentOrderListView: {
    screen: AutoShipmentOrderListView,
    path: 'asol',
  },
  AutoPaymentOrderListView: {
    screen: AutoPaymentOrderListView,
    path: 'apol',
  },
  MkpConfirmOrderListView: {
    screen: MkpConfirmOrderListView,
    path: 'apol',
  },
  SearchOrderListView: {
    screen: SearchOrderListView,
    path: 'sol',
  },
  PaymentOrderListView: {
    screen: PaymentOrderListView,
    path: 'p',
  },
  ProductListViewMyStore,
  ProductListViewMyStoreWithVolumeDiscount,
  ProductListViewSellersStore,
  ProductListViewPullMyProductsToOrder,
  ProductListViewPullSellersProductsToOrder,
  ProductListViewPullMyProductsToSalePage,
  ProductListViewPullSellersProductsToSalePage,
  ProductListViewPullSellersProductsToMyStore,
  ProductListViewMkpMapping,
  ProductListViewMkpView,
  ProductListViewXSellyToMkpMapping,
  ProductListViewMkpToXSellyMapping,
  MkpProductDetailMapping,
  ProductVariantXSellyToMkpMappingView,
  ProductVariantMkpToXSellyMappingView,
  MkpProductDetailView,
  ProductVariantEditorView,
  ProductDetailMkpInfoDetailView,
  VolumeDiscountListView,
  VolumeDiscountView,
  BackgroundJobListView,
  BackgroundTaskListView,
  BackgroundJobInProcessByTypeView,
  AutoCompleteOrderTabItemsView,
  AutoCompletePaymentBySegmentViewContainer,
  AutoCompleteShipmentBySegmentViewContainer,
  ShippingView,
  SearchOrderView,
  MultipleOrdersOperationMenuView,
  MkpProductLinkManagerView,
  TimeSlotsView: {
    screen: TimeSlotsViewContainer,
    path: 'time_slots',
  },
  StorePaymentAccountListView: {
    screen: StorePaymentAccountListViewContainer,
    path: 'payment_accounts',
  },
  StoreSettingShippingTypeView: {
    screen: StoreSettingShippingTypeViewContainer,
    path: 'StoreSettingShippingTypeView',
  },

  StoreSettingOrderPolicy: {
    screen: StoreSettingOrderPolicyContainer,
    path: 'StoreSettingOrderPolicy',
  },
  StoreSettingOrderBillOnlineShowAmount: {
    screen: MenuOrderBillOnlineShowAmountContainer,
    path: 'StoreSettingOrderBillOnlineShowAmount',
  },
  StoreSettingOrderAutomation: {
    screen: StoreSettingOrderAutomationContainer,
    path: 'StoreSettingOrderAutomation',
  },
  StoreSettingShipBeforePayAndCod: {
    screen: StoreSettingShipBeforePayAndCodContainer,
    path: 'StoreSettingShipBeforePayAndCod',
  },
  StoreSettingOrderNote: {
    screen: StoreSettingOrderNoteContainer,
    path: 'StoreSettingOrderNote',
  },
  StoreSettingVolumeDiscount: {
    screen: StoreSettingVolumeDiscount,
    path: 'StoreSettingVolumeDiscount',
  },
  StoreSettingStoreProductSN: {
    screen: StoreSettingStoreProductSNContainer,
    path: 'StoreSettingStoreProductSN',
  },
  StoreSettingLockOrderAutomation: {
    screen: StoreSettingLockOrderAutomationContainer,
    path: 'StoreSettingLockOrderAutomation',
  },
  StoreSettingStoreCancelOrderAutomation: {
    screen: StoreSettingStoreCancelOrderAutomationContainer,
    path: 'StoreSettingStoreCancelOrderAutomation',
  },
  StoreSettingVerifySlip,
  ResellerChangeGroupView: {
    screen: ResellerChangeGroupViewContainer,
  },
  CardInfoViewContainer,
  StoreStoreSettingMenuShipping,
  DevMenuSubscriptionView: {
    screen: DevMenuSubscriptionViewContainer,
  },
  SearchableProductListView,
  MkpMappingChannelListViewPicker,
  MkpAutoMappingVariantsView,
  XSellyVariantMappingSelectorView,
  ReauthLazadaView: {
    screen: ReauthLazadaViewContainer,
  },
  ReauthShopifyView: {
    screen: ReauthShopifyViewContainer,
  },

  DashboardView,
  DailySaleChartView,

  VerifySlipCreditHistoryView,
  VerifySlipCreditSummaryView,
  VerifySlipCreditSummaryUnusableView,
  VerifySlipDetailView,

  ERPSubmitDocumentView,
  AddressFormView,
  OrderListFilterView,
  OrderListFilterEditorView,
  OrderListFilterManageView,
  StoreContactListView,
  StoreContactDetailView,
}
