import React from 'react'
import { Image, View, ImageSourcePropType } from 'react-native'

import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import BaseStoreSettingXShippingEcoPostView from 'x/modules/xshipping/BaseStoreSettingXShippingEcoPostView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import FAQ from 'x/config/FAQ'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import { IQuestionsAndAnswers } from 'x/index'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import XFaqModal from 'xui/components/XFaqModal'

const IMG_ECOPOST: ImageSourcePropType = require('../../images/courier/logo_ecopost.png')

export default abstract class BaseUIStoreSettingXShippingEcoPostView extends BaseStoreSettingXShippingEcoPostView {
  static displayName = 'BaseUIStoreSettingXShippingEcoPostView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.ecopost')}
    />
  )

  _renderUseEcoPost = () => {
    const { useEcoPost, useAutoShippingIsEcoPostReadyToShip, isEcoPostLoading, loadingEcoPostWidth, loadingEcoPostCodHeight } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingEcoPostWidth: width, loadingEcoPostCodHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useEcoPost}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useEcoPost'
          helper={{
            title: 'eCo-Post ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useEcoPost ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoShippingIsEcoPostReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoShippingIsEcoPostReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isEcoPostLoading ? VIEW_LOADING(loadingEcoPostWidth, loadingEcoPostCodHeight) : null}
      </View>
    )
  }

  _renderUseEcoPostCod = () => {
    const {
      useEcoPostCod,
      useAutoShippingIsEcoPostCodReadyToShip,
      isEcoPostCodLoading,
      loadingEcoPostCodWidth,
      loadingEcoPostCodHeight,
      useAutoCreateCODPaymentWhenDelivered,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    // console.log('useAutoXShippingIsJAndTReadyToShip => ', useAutoXShippingIsJAndTReadyToShip)
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingEcoPostCodWidth: width, loadingEcoPostCodHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useEcoPostCod}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useEcoPostCod'
          helper={{
            title: 'eCo-Post ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
          // disabled={useJAndT}
        />

        {useEcoPostCod ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoShippingIsEcoPostCodReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoShippingIsEcoPostCodReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useEcoPostCod ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isEcoPostCodLoading ? VIEW_LOADING(loadingEcoPostCodWidth, loadingEcoPostCodHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' variant='outline' alignSelf='center'>
      <XText variant='primary'>ดูข้อตกลงและวิธีการใช้งาน</XText>
    </XButton>
  )

  _renderHowtoFaq = (onPressOpenOverlay: () => void) => (
    <HStack flex={1} alignItems='center' justifyContent='center' mt='2'>
      <XText textDecorationLine='underline' color={COLORS.BRAND_Info} onPress={() => onPressOpenOverlay()}>
        ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง eCo post
      </XText>
    </HStack>
  )

  _getFAQAgreementForEcoPost = (): IQuestionsAndAnswers[] => {
    const newFQA = []
    FAQ.XSHIPPING_AGREEMENT_ECOPOST_AGREEMENT.map((faq: IQuestionsAndAnswers) => {
      newFQA.push(faq)
    })
    return newFQA
  }

  _renderEcoPost = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box mt='3' style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
        <Image
          source={IMG_ECOPOST}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 103,
            height: 30,
            backgroundColor: 'transparent',
            zIndex: 70,
            marginLeft: 0,
          }}
        />
      </Box>
      <Box mt='2' style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก eCo-Post</XText>
      </Box>

      <XHelpModal
        key='XHelpModalEMS'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={this._getFAQAgreementForEcoPost()}
        initiateOpenIndex={3}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <XFaqModal
        key='XHelpModalEMS_HowTo'
        headerTitle='ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง eCo post'
        FAQ={FAQ_CONSTANTS.FAQ_EMS_AND_ECOPOST_XSHIPPING}
        initiateOpenIndex={0}
        renderButton={(onPressOpenOverlay: () => void) => this._renderHowtoFaq(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ecopost')}`}</XText>
      </Box>
      {this._renderUseEcoPost()}
      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.ecopost_cod')}`}</XText>
      </Box>
      {this._renderUseEcoPostCod()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent>
          <Box w='full'>{this._renderEcoPost()}</Box>
        </XContent>
      </XContainer>
    )
  }
}
