import React from 'react'
import { Dimensions, TouchableOpacity } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import { COLORS, S } from 'x/config/styles'
import p from 'x/config/platform-specific'

import {
  IMkpProductDetailProps,
  IMkpProductDetailState,
  IProductDetailVariant,
  IApiOptions,
  IMkpProductDetailVariant,
  IMkpProductDetailMappingNavParams,
  INavHeaderButtonProps,
  IProductDetailItem,
  IMkpProductDetail,
} from 'x/index'
import CONS from 'x/config/constants'
// import { getXsellyLogo, getShopeeLogo, getLazadaLogo } from 'xui/utils/BaseShareUI'

import * as NavActions from 'x/utils/navigation'
import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import api from 'x/utils/api'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'
import Box from 'xui/components/Box'
import XCard from 'xui/components/XCard'
import XIconButton from 'xui/components/XIconButton'
import XContainer from 'xui/components/XContainer'
import XScrollView from 'xui/components/XScrollView'
import XIcon from 'xui/components/XIcon'
import XSellyLogo from '../../components/XSellyLogo'
import ForwardIcon from '../../components/ForwardIcon'
import XOverlay from '../../components/XOverlay'

// import MkpProductVariantPicker from './MkpProductVariantPicker'

import BaseUIMkpProductDetail, { IMkpProductVariantItemInfo } from './BaseUIMkpProductDetail'
import XButton from 'xui/components/XButton'

type IProps = IMkpProductDetailProps<IMkpProductDetailMappingNavParams> & IMkpProductContextProps
interface IState extends IMkpProductDetailState {
  variantPickerVisibleAtIndex?: number
  isMappingSummaryVisible?: boolean

  /**
   * isOneToOneVariantMapping flag is set to TRUE when there is only one variant on both XSelly's pt and MKP' pt.
   * This flag is used to simplify UI as there is no need for users to choose mappings b/w variant anymore;
   * In other word, we can do auto variant mapping for users and allow users to immediately confirm the new
   * mapping without changing any data in the view.
   */
  isOneToOneVariantMapping: boolean

  // oldPairedMap?: {
  //   [ppv_uuid: string]: number // ppv_uuid to pp_id
  // }
  newPairedMap?: {
    // [ppv_uuid: string]: number // ppv_uuid to pp_id
    [itemId_itemVariantId: string]: number // item_id + item_variant_id to pp_id
  }
  hilightPickerMap?: {
    [variantIndex: number]: boolean // true for highlight
  }
  isFetchMapping?: boolean

  xSellyProduct?: IProductDetailItem

  // xSellyVariantPickerItemsContainerHeight?: number
  webWindowHeight?: number
}

const BASE_SUBMIT_BTN_STYLE = [S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 34 }]
const BASE_CLOSE_OVERLAY_BTN_STYLE = [S.ROW_CENTER, S.NO_FLEX, { width: 54, marginRight: 4, minHeight: 44 }]
export default class MkpProductDetailMapping extends BaseUIMkpProductDetail<IProps, IState> {
  isFetchMapping?: boolean

  mkpVariantButtonPositionYMap?: { [btnIndex: number]: number }

  // async componentDidMount() {
  //   await super.componentDidMount.apply(this)

  //   // Check if it's one-to-one variant mapping b/w XSelly's and MKP's product template.
  //   // If so, set the isOneToOneVariantMapping flag and populate the newPairedMap
  //   const { editingMkpProduct, coreProduct } = this.state
  //   if (
  //     util.getLengthIfNotNullOrGetZero(editingMkpProduct.variants) === 1 &&
  //     util.getLengthIfNotNullOrGetZero(coreProduct.variants) === 1
  //   ) {
  //     // Do auto mapping if there is only 1 variant on each side.
  //     const { ppv_uuid } = editingMkpProduct.variants[0]
  //     const { pp_id } = coreProduct.variants[0]
  //     if (ppv_uuid && pp_id) {
  //       const new_pp_mappings = {}
  //       new_pp_mappings[ppv_uuid] = pp_id
  //       this.setState({
  //         isOneToOneVariantMapping: true, // set state 1-1-flag
  //         newPairedMap: new_pp_mappings,
  //       })
  //     }
  //   }
  // }

  async componentDidMount() {
    await super.componentDidMount.apply(this)

    const { navigation } = this.props
    const { editingMkpProduct } = this.state
    const xSellyProduct = util.getNavParam(this.props, 'targetXSellyProduct')

    const newState: Partial<IState> = { xSellyProduct }

    if (xSellyProduct && xSellyProduct.variants && xSellyProduct.variants.length === 1 && editingMkpProduct.variants.length === 1) {
      // const ppvUuid = editingMkpProduct.variants[0].ppv_uuid
      const { item_id, item_variant_id = null } = editingMkpProduct.variants[0]
      const ppId = xSellyProduct.variants[0].pp_id
      newState.newPairedMap = {}
      // newState.newPairedMap[ppvUuid] = ppId
      newState.newPairedMap[`${item_id}_${item_variant_id}`] = ppId
    }

    // @ts-ignore
    await util.setStatePromise(this, newState)

    await util.delay(50)
    await this.initPairedMap()
    await util.delay(50)
    await this.computePairedPtMap()
    await util.delay(50)
    await this.initPairedPtVisibleMap()
    await util.delay(50)
    await this.computeVisibleVariantMap()
  }

  computePairedPtMap = async () => {
    const { xSellyProduct } = this.state

    if (!xSellyProduct) {
      return
    }

    const pairedPtMap = this.getPairedPtMap()
    pairedPtMap[xSellyProduct.id] = {
      name: xSellyProduct.name,
      thumbnail_uri: xSellyProduct.thumbnail_uris[0],
      pt_id: xSellyProduct.id,
      pp_ids: xSellyProduct.variants.map((v) => v.pp_id),
    }

    await util.setStatePromise(this, { pairedPtMap })
  }

  getPairedMap = () => {
    const { pairedMap = {}, newPairedMap = {} } = this.state
    const computedPairedMap = { ...pairedMap, ...newPairedMap }
    return computedPairedMap
  }

  // // Override
  // afterReloadEditingMkpProduct = async () => {
  //   // const { selectedProduct } = this.props
  //   const { editingMkpProduct, coreProduct } = this.state

  //   // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
  //   const currentPairedProductTemplateId = editingMkpProduct.pt_id
  //   const selectedProductId = coreProduct.id

  //   const oldPairedMap = {}

  //   // ถ้าสินค้าที่เลือกอยู่เป็นคนละตัวกับ PT ที่กำลังถูกผู้อยู่ แสดงว่ากำลังจะเปลี่ยน PT ใหม่เข้าไปหา MKP
  //   if (selectedProductId === currentPairedProductTemplateId) {
  //     // RE_MAPPING mode
  //     for (const mkpV of editingMkpProduct.variants) {
  //       const variantUUID = mkpV.ppv_uuid

  //       // // FIXME: THIS IS IS MOCK PPV_UUID
  //       // const variantUUID = mkpV.ppv_id.toString()

  //       const variantPairedToPPID = mkpV.paired_pp_id
  //       oldPairedMap[variantUUID] = variantPairedToPPID
  //     }
  //   } // else NEW_MAPPING mode

  //   // // FIXME: REMOVE ME THIS IS MOCK PPV_UUID
  //   // const mockedEditingMkpProduct = _.cloneDeep(editingMkpProduct)
  //   // mockedEditingMkpProduct.variants.forEach((mkpV, idx) => {
  //   //   mockedEditingMkpProduct.variants[idx].ppv_uuid = mkpV.ppv_id.toString()
  //   // })

  //   await util.setStatePromise(this, {
  //     // // FIXME: REMOVE ME THIS IS MOCK PPV_UUID
  //     // editingMkpProduct: mockedEditingMkpProduct,

  //     oldPairedMap,
  //     newPairedMap: oldPairedMap, // Just cloned from old state
  //     hilightPickerMap: {},
  //     // coreProduct: selectedProduct.toJS()
  //   })
  // }

  // Override
  // renderContent = () => {
  //   // const { editingMkpProduct, coreProduct } = this.state
  //   const { editingMkpProduct } = this.state
  //   if (!editingMkpProduct) {
  //     return null
  //   }
  //   return (
  //     <>
  //       {this.renderHeaderStoreName()}
  //       <View style={[S.PADDING_HORIZONTAL_4]}>
  //         <View style={{ height: 4 }} />
  //         {/* <CoreProductInfo product={coreProduct} /> */}
  //         {this.renderProductImages()}
  //         {this.renderProductName()}
  //         {/* {this.renderPairedCoreProductItem()} */}
  //         {this.renderProductDescription()}
  //         {this.renderProductVariants()}
  //         {/* {this._renderMkpProductVariantPickerOverlay()} */}
  //         {/* {this._renderMappingSummaryOverlay()} */}
  //       </View>
  //     </>
  //   )
  // }

  _onAutoMappingConfirm = async (newPairedMap: { [itemId_itemVariantId: string]: number }) => {
    // console.log('_onAutoMappingConfirm newPairedMap => ', newPairedMap)

    const { editingMkpProduct, xSellyProduct } = this.state
    const dedupNewPairedMap = {}

    for (const [itemId_itemVariantId, ppId] of Object.entries(newPairedMap)) {
      const [itemId, itemVariantId] = itemId_itemVariantId.split('_')

      const focusMkpVariant = editingMkpProduct.variants.find((mkpV) => mkpV.item_id === itemId && mkpV.item_variant_id === itemVariantId)
      const isAlreadyMapped = focusMkpVariant && focusMkpVariant.paired_pp_id === ppId

      if (isAlreadyMapped) {
        continue
      }

      dedupNewPairedMap[`${itemId}_${itemVariantId}`] = ppId
    }

    // console.log('_onAutoMappingConfirm dedupNewPairedMap => ', dedupNewPairedMap)

    if (_.isEmpty(dedupNewPairedMap)) {
      p.op.showToast('ไม่มีการเปลี่ยนแปลงการผูกตัวเลือกสินค้าใหม่จากของเดิม', 'warning', 8000)
    } else {
      p.op.showToast('ผูกตัวเลือกสินค้าเรียบร้อยแล้ว', 'success')
    }

    await util.setStatePromise(this, { newPairedMap: dedupNewPairedMap })
    await util.delay(50)
    await this.computeVisibleVariantMap()
  }

  _onAutoMappingPress = () => {
    const { navigation } = this.props
    const { editingMkpProduct, xSellyProduct, newPairedMap } = this.state
    navigation.dispatch(
      NavActions.navToMkpAutoMappingVariantsView({
        editingMkpProduct,
        xSellyProduct,
        newPairedMap,
        onConfirm: this._onAutoMappingConfirm,
      })
    )
  }

  _renderAutoMappingBar = () => {
    const { editingMkpProduct } = this.state

    if (!editingMkpProduct) {
      return null
    }

    return (
      <HStack w='full' alignItems='center' justifyContent='flex-end'>
        <TouchableOpacity onPress={this._onAutoMappingPress}>
          <HStack h='34px' w='200px' p='1' bg='gray.100' borderRadius='lg' justifyContent='center' alignItems='center' space='1.5'>
            <XIcon name='auto-fix' family='MaterialCommunityIcons' />
            <XText variant='primary'>ผูกตัวเลือกสินค้าอัตโนมัติ</XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  renderContent = () => {
    const { editingMkpProduct, isInitialized, coreProduct } = this.state
    if (!isInitialized || !editingMkpProduct) {
      return null
    }
    return (
      <XCard w='full'>
        <XCard.Body>
          <VStack w='full'>
            {this.renderHeaderStoreName()}
            <VStack w='full' space='2'>
              {this.renderProductImages()}
              {this.renderProductName()}
              {this.renderProductItemId()}
              {this.renderProductDescription()}
              {this._renderAutoMappingBar()}
              {this.renderProductPairedProductTemplates()}
              {this.renderProductVariants()}
            </VStack>
          </VStack>
        </XCard.Body>
      </XCard>
    )
  }

  // Override
  renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      {this.renderContentContainer()}
      {this.renderFooter()}
      {this._renderMkpProductVariantPickerOverlay()}
    </XContainer>
  )

  submitNewMapping = async () => {
    if (this.isFetchMapping) {
      return
    }
    this.isFetchMapping = true

    await util.setStatePromise(this, { isFetchMapping: true })

    const { navigation, mkpProductContext } = this.props
    const { editingMkpProduct, newPairedMap = {}, xSellyProduct: targetXSellyProduct } = this.state
    // const targetXSellyProduct = this.getXSellyProduct()
    const store_id = util.getNavParam(this.props, 'store_id')
    const mkp_ch_id = util.getNavParam(this.props, 'mkp_ch_id')
    // const mkp_id = util.getNavParam(this.props, 'mkp_id')
    // console.log('submitNewMapping editingMkpProduct => ', editingMkpProduct)
    // console.log('submitNewMapping targetXSellyProduct => ', targetXSellyProduct)

    // // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
    // const { uuid } = editingMkpProduct
    // // const pt_id = coreProduct.id
    // const pt_id = targetXSellyProduct.id

    try {
      const apiOptions: IApiOptions = {
        messages: {
          successMsg: 'ดำเนินการเสร็จสิ้น',
          errorMsg: 'ดำเนินการล้มเหลว',
        },
        showSpinner: true,
        axiosOptions: {
          retry: 0,
          timeout: 60000,
        },
        isApiV2: true,
      }

      const new_pp_mappings = []

      // const ppvUUIDs = Object.keys(newPairedMap)
      const itemId_ItemVariantIds = Object.keys(newPairedMap)
      for (const itemId_ItemVariantId of itemId_ItemVariantIds) {
        const ppid = newPairedMap[itemId_ItemVariantId]
        // const item_id = parseInt(itemId_ItemVariantId.split('_')[0])
        const item_id = itemId_ItemVariantId.split('_')[0]

        // let item_variant_id: string | number | null = itemId_ItemVariantId.split('_')[1]
        let item_variant_id: string | null = itemId_ItemVariantId.split('_')[1]

        if (item_variant_id === 'null') {
          item_variant_id = null
        }

        if (_.isString(item_variant_id)) {
          // item_variant_id = parseInt(item_variant_id)
          item_variant_id = item_variant_id
        }

        new_pp_mappings.push({
          pp_id: ppid,
          // ppv_uuid: ppvUUID,
          item_id,
          item_variant_id,
        })
      }

      const reqBody = {
        store_id,
        mkp_ch_id,
        // mkp_id,
        // item_uuid: uuid,
        // pt_id,
        new_pp_mappings,
        // new_pp_mappings: [
        //   {
        //     pp_id: targetXSellyProduct.variants[0].pp_id,
        //     ppv_uuid: editingMkpProduct.variants[0].ppv_uuid,
        //   },
        // ],
      }

      // console.log('submitNewMapping reqBody => ', reqBody)

      const res = await api.post('mkp/v2/product_mapping/update', reqBody, apiOptions)

      // console.log('submitNewMapping res => ', res)

      if (res && res.status === 'ok') {
        const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')
        if (_.isFunction(onMappingSuccess)) {
          await onMappingSuccess()
        }

        // await this._closeMappingSummary()
        // const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
        // if (isPreventDestroyWhenNavBack) {
        //   await this.doRefresh()
        // }
        // const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
        // if (_.isFunction(mkpState.onSuccesssMapping)) {
        //   mkpState.onSuccesssMapping()
        //   await util.delay(500)
        // }
        // this._doGoBackAfterSuccessMapping()
      }

      // console.log('_doFetchApiMkpProductMapping res => ', res)
    } catch (err) {
      // console.log('_doFetchApiMkpProductMapping err => ', err)
      console.log('submitNewMapping err => ', err)
    }

    await util.setStatePromise(this, { isFetchMapping: false })
    this.isFetchMapping = false
  }

  // Override
  renderFooter = () => null
  // renderFooter = () => (
  //   <HStack w='full' p='1'>
  //     <XButton flex={1} onPress={this.submitNewMapping}>
  //       Test Api
  //     </XButton>
  //   </HStack>
  // )

  // Override
  getPairedCoreProductLabelText = () => (this._isNewMappingCoreProduct() ? 'สลับไปผูกกับ' : 'ผูกอยู่กับ')

  // Override
  // // renderProductVariantItemContent = (variant: IMkpProductDetailVariantItem, index: number) => {
  // renderProductVariantItemContent = (variant: IMkpProductDetailVariant, index: number) => {
  //   const mkpId = this.state.editingMkpProduct.mkp_id
  //   return (
  //     <>
  //       {this.state.isOneToOneVariantMapping ? null : this._renderVariantMappingButton({ index, variant, mkpId })}
  //       <View style={{ height: 4 }} />
  //       {/* {this.renderVariantName({ index, variant, mkpId })} */}
  //       {this.renderVariantSalePrice({ index, variant, mkpId })}
  //       {this.renderVariantPromotionPrice({ index, variant, mkpId })}
  //     </>
  //   )
  // }
  renderProductVariantItemContent = (variant: IMkpProductDetailVariant, index: number) => {
    const mkpId = this.state.editingMkpProduct.mkp_id
    return (
      <VStack w='full' space='1.5'>
        {this.renderVariantName({ index, variant, mkpId })}
        {this.renderVariantSalePrice({ index, variant, mkpId })}
        {this.renderVariantPromotionPrice({ index, variant, mkpId })}

        {this.renderPairedXSellyProductVariantInfo({ index, variant, mkpId })}
        {this.renderNewPairXSellyProductVariantInfo({ index, variant, mkpId })}
      </VStack>
    )
  }

  // override
  renderPairedXSellyProductVariantInfo = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    const { newPairedMap = {} } = this.state
    // const isPickedNewPair = _.has(newPairedMap, variant.ppv_uuid)
    const isPickedNewPair = _.has(newPairedMap, `${variant.item_id}_${variant.item_variant_id}`)
    const isUnpaired = _.isNil(variant.paired_pp_id)
    return (
      <VStack w='full' key={`PairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          {isUnpaired ? null : <XIcon name={isPickedNewPair ? 'unlink' : 'link'} family='FontAwesome' inactive minW='28px' />}
          {isUnpaired ? null : <XText variant='inactive'>{isPickedNewPair ? 'เลิกผูกกับ:' : 'ผูกอยู่กับ:'}</XText>}
          {/* {isUnpaired ? (
            <XText flex={1} textAlign='center' variant='inactive'>
              ยังไม่ได้ผูกกับสินค้าใดๆ
            </XText>
          ) : null} */}
        </HStack>
        {this.renderPairedXSellyProductVariantInfoCardButton(info)}
      </VStack>
    )
  }

  renderPairedXSellyProductVariantInfoCardButton = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info

    if (!variant) {
      return null
    }

    const { paired_pt_name, paired_pp_name, img_uri, paired_pp_id, paired_pt_thumbnail_uri, paired_pp_thumbnail_uri } = variant

    const isUnpaired = _.isNil(paired_pp_id)

    const handlePressToPickNewPair = () => this._onOpenVariantPicker(index)

    const pairedPtName = paired_pt_name
    // const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '(ไม่มีชื่อ)' : paired_pp_name
    const pairedPpName = !paired_pp_name || paired_pp_name === '' ? '' : paired_pp_name

    const { newPairedMap = {} } = this.state
    // const isPickedNewPair = _.has(newPairedMap, variant.ppv_uuid)
    const isPickedNewPair = _.has(newPairedMap, `${variant.item_id}_${variant.item_variant_id}`)

    let pairedImgUri = paired_pt_thumbnail_uri && paired_pt_thumbnail_uri !== '' ? paired_pt_thumbnail_uri : CONS.IMG_PLACEHOLDER_URL
    if (img_uri && img_uri !== '') {
      pairedImgUri = img_uri
    }
    if (paired_pp_thumbnail_uri && paired_pp_thumbnail_uri !== '') {
      pairedImgUri = paired_pp_thumbnail_uri
    }

    return (
      <XCard
        key={`PairedInfoCardButton_${index}`}
        disabled={isPickedNewPair}
        bg={isPickedNewPair ? 'muted.400' : 'primary.200'}
        onPress={handlePressToPickNewPair}>
        <XCard.Body>
          {isUnpaired ? (
            <VStack w='full' alignItems='center' justifyContent='center'>
              <XText variant={isPickedNewPair ? 'active' : 'primary'} bold={!isPickedNewPair}>
                {isPickedNewPair ? 'เดิมไม่ได้ผูก' : 'กดที่นี่เพื่อเริ่มผูกสินค้า'}
              </XText>
            </VStack>
          ) : (
            <HStack w='full' space='1'>
              <VStack w='16' h='16'>
                {/* <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: img_uri }} /> */}
                <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: pairedImgUri }} />
                <Box position='absolute' top='1' left='1'>
                  <XSellyLogo width={24} height={24} />
                </Box>
              </VStack>
              <VStack flex={1}>
                <XText w='full' numberOfLines={2}>
                  {pairedPtName}
                </XText>
              </VStack>
              <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
                <XText w='full' textAlign='right' numberOfLines={3}>
                  {pairedPpName}
                </XText>
              </VStack>
            </HStack>
          )}
        </XCard.Body>
      </XCard>
    )
  }

  renderNewPairXSellyProductVariantInfo = (info: IMkpProductVariantItemInfo) => {
    const { newPairedMap = {}, xSellyProduct } = this.state
    const { index, variant } = info

    // if (!newPairedMap || !newPairedMap[variant.ppv_uuid] || !xSellyProduct) {
    if (!newPairedMap || !newPairedMap[`${variant.item_id}_${variant.item_variant_id}`] || !xSellyProduct) {
      return null
    }

    return (
      <VStack w='full' key={`NewPairedInfo_${index}`}>
        <HStack w='full' px='3' pb='1' alignItems='center' space='1'>
          <XIcon name='link' family='FontAwesome' inactive minW='28px' />
          <XText variant='inactive'>เปลี่ยนมาผูกกับสินค้า:</XText>
        </HStack>
        {this.renderNewPairdXSellyProductVariantInfoCard(info)}
      </VStack>
    )
  }

  _removeNewPairedAtMkpVariantIndex = (mkpVariantIndex: number) => {
    // console.log('_removeNewPairedAtMkpVariantIndex mkpVariantIndex => ', mkpVariantIndex)
    const { editingMkpProduct, newPairedMap = {} } = this.state

    // const removePpvUuid = editingMkpProduct.variants[mkpVariantIndex].ppv_uuid
    const { item_id, item_variant_id = null } = editingMkpProduct.variants[mkpVariantIndex]
    const removeItemId_ItemVariantId = `${item_id}_${item_variant_id}`
    const computeNewPairedMap = _.cloneDeep(newPairedMap)
    delete computeNewPairedMap[removeItemId_ItemVariantId]

    this.setState({ newPairedMap: computeNewPairedMap })
  }

  renderNewPairdXSellyProductVariantInfoCard = (info: IMkpProductVariantItemInfo) => {
    const { index, variant } = info
    // const { editingMkpProduct } = this.state
    // const { variants } = editingMkpProduct
    // const variant = variants[index]

    if (!variant) {
      return null
    }

    const { newPairedMap = {}, xSellyProduct: xProduct } = this.state
    // const newMapPpId = newPairedMap[variant.ppv_uuid]
    const { item_id, item_variant_id = null } = variant
    const newMapPpId = newPairedMap[`${item_id}_${item_variant_id}`]

    if (!xProduct || !xProduct.variants || !newMapPpId) {
      return null
    }

    const xVariant = xProduct.variants.find((xv) => xv.pp_id === newMapPpId)

    if (!xVariant || !xVariant.pp_id) {
      return null
    }

    // const xVariantName = _.isNil(xVariant.name) || xVariant.name === '' ? '(ไม่มีชื่อ)' : xVariant.name
    const xVariantName = _.isNil(xVariant.name) || xVariant.name === '' ? '' : xVariant.name
    // const { paired_pt_name, paired_pp_name, thumbnail_uri } = variant
    const { thumbnail_url } = xVariant
    let thumbnailUri = xProduct.thumbnail_uris[0] || CONS.IMG_PLACEHOLDER_URL
    if (thumbnail_url) {
      thumbnailUri = thumbnail_url
    }

    return (
      <XCard key={`NewPairedInfoCard_${index}`} overflow='visible'>
        <XCard.Body>
          <HStack w='full' space='1'>
            {/* <HStack w='11' h='16' alignItems='center'>
              <XIconButton name='trash' variant='outline' onPress={() => this._removeNewPairedAtMkpVariantIndex(index)} />
            </HStack> */}

            <VStack w='16' h='16'>
              <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailUri }} />
              <Box position='absolute' top='1' left='1'>
                <XSellyLogo width={24} height={24} />
              </Box>
            </VStack>
            <VStack flex={1}>
              <XText w='full' numberOfLines={2}>
                {xProduct.name}
              </XText>
            </VStack>
            <VStack _web={{ w: '70px' }} w='90px' alignItems='flex-end'>
              <XText variant='primary' w='full' textAlign='right' bold numberOfLines={3}>
                {xVariantName}
              </XText>
            </VStack>
          </HStack>
        </XCard.Body>
        <Box position='absolute' top='-12px' right='-12px'>
          <XIconButton name='close-circle' colorScheme='danger' onPress={() => this._removeNewPairedAtMkpVariantIndex(index)} />
        </Box>
      </XCard>
    )
  }

  // // Override
  // getContextViewKey = () => {
  //   const { item_uuid, pt_id } = this.props.navigation.state.params
  //   return item_uuid ? `${item_uuid.toString()}-mapping` : `${pt_id.toString()}-mapping`
  // }

  getHeaderLeftProps = (): INavHeaderButtonProps => {
    const { isFetchMapping } = this.state
    const isDirtyMapping = this._computeIsDirtyMapping()

    if (isDirtyMapping) {
      return {
        submitting: isFetchMapping,
        label: 'ตกลง',
        onPressItem: this.submitNewMapping,
      }
    }

    return {
      backIcon: true,
      onPressItem: this.goBack,
    }
  }

  getHeaderRightProps = (): INavHeaderButtonProps => {
    const { navigation } = this.props
    const { isFetchMapping } = this.state
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    // const targetXSellyProduct = util.getNavParam(this.props, 'targetXSellyProduct')
    // const mkpName = util.getMKPName(mkpId)
    const isDirtyMapping = this._computeIsDirtyMapping()

    if (isDirtyMapping) {
      return {
        label: 'ยกเลิก',
        submitting: isFetchMapping,
        onPressItem: this._handlePressCancelMapping,
        iconButtonProps: {
          _text: {
            color: COLORS.TEXT_INACTIVE,
          },
        },
      }
    }

    return null
  }

  getHeaderTitle = () => {
    const { navigation } = this.props
    const mkpId = util.getNavParam(this.props, 'mkp_id')
    if (!mkpId) {
      return 'จัดการผูกตัวเลือกสินค้า'
    }
    const mkpName = util.getMKPName(mkpId)
    return `จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`
  }

  // Override
  renderCustomHeader = () => (
    // const { navigation } = this.props
    // const { isFetchMapping } = this.state
    // const mkpId = util.getNavParam(this.props, 'mkp_id')
    // const targetXSellyProduct = util.getNavParam(this.props, 'targetXSellyProduct')
    // const mkpName = util.getMKPName(mkpId)
    // const isDirtyMapping = this._computeIsDirtyMapping()

    <VStack w='full'>
      <XCustomHeader
        // title={`จัดการผูกตัวเลือกสินค้า\nXSelly กับระบบ ${mkpName}`}
        title={this.getHeaderTitle()}
        headerLeftProps={this.getHeaderLeftProps()}
        // headerLeftProps={{
        //   label: 'ตกลง',
        //   // FIXME: จริงๆ ควร refactor เป็น props disabled แทน
        //   // submitting: !isDirtyMapping || isFetchMapping,
        //   // onPressItem: this._openMappingSummary,
        //   onPressItem: this.submitNewMapping,
        // }}
        headerRightProps={this.getHeaderRightProps()}
        // headerRightProps={{
        //   label: 'ยกเลิก',
        //   submitting: isFetchMapping,
        //   onPressItem: this._handlePressCancelMapping,
        // }}
      />
      {/* <XScrollView h='100px'>
          <XText>{JSON.stringify(targetXSellyProduct)}</XText>
        </XScrollView> */}
      {this.renderXSellyProductInfo()}
      <HStack w='full' px='3' py='1' space='1' alignItems='center' justifyContent='center'>
        <XText textAlign='center'>
          กดที่ "ข้อมูลสินค้าใน XSelly" ที่เชื่อมต่ออยู่กับแต่ละตัวเลือกสินค้าด้านล่าง เพื่อสลับมาผูกกับ สินค้าใน XSelly ด้านบนนี้แทน
        </XText>
      </HStack>
    </VStack>
  )

  // getXSellyProduct = () => {
  //   const { navigation } = this.props
  //   const xProduct = util.getNavParam(this.props, 'targetXSellyProduct')
  //   return xProduct
  // }

  renderXSellyProductInfo = () => {
    const { xSellyProduct: xProduct } = this.state

    if (!xProduct || !xProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [] } = xProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    return (
      <HStack w='full' px='3' py='2' space='1'>
        <VStack>
          <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnail_uris[0] }} />
          <Box position='absolute' top='1' left='1'>
            <XSellyLogo width={24} height={24} />
          </Box>
        </VStack>
        <VStack flex={1} space='1'>
          <XText w='full' numberOfLines={2}>
            {name}
          </XText>
          {/* <XText variant='inactive' fontSize='xs' w='full' numberOfLines={1}>
            {description}
          </XText> */}
          {variantCount > 1 ? (
            <XText fontSize='xs' w='full' numberOfLines={2}>
              <XText variant='inactive' fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
              {variantNames}
            </XText>
          ) : null}
        </VStack>
      </HStack>
    )
  }

  // _onPickVariant = (mkpVariantIdx, coreVariantIdx) => {
  //   this.setState({})
  // }

  _onRequestCloseProductVariantPicker = () => {
    this.setState({ variantPickerVisibleAtIndex: -1 })
  }

  _onVariantMappingPicked = async (focusedMkpVariantIndex: number, pickedXSellyProductVariantIndex: number) => {
    const { editingMkpProduct, newPairedMap = {}, xSellyProduct: xProduct } = this.state
    const mkpVariantIndex = focusedMkpVariantIndex
    const focusMkpVariant = editingMkpProduct.variants[mkpVariantIndex]
    const pickedPpId = xProduct.variants[pickedXSellyProductVariantIndex].pp_id
    const computeNewPairedMap = _.cloneDeep(newPairedMap)

    const pairedKeys = Object.keys(computeNewPairedMap)

    for (let i = 0; i < pairedKeys.length; i++) {
      const pairedPpvUuid = pairedKeys[i]
      const pairedPpId = computeNewPairedMap[pairedPpvUuid]

      if (pairedPpId === pickedPpId) {
        delete computeNewPairedMap[pairedPpvUuid]
      }
    }

    const { item_id, item_variant_id = null } = focusMkpVariant
    computeNewPairedMap[`${item_id}_${item_variant_id}`] = pickedPpId

    await util.setStatePromise(this, { newPairedMap: computeNewPairedMap })
    await util.delay(50)
    await this.computeVisibleVariantMap()
    this._scrollToPickerButtonIndex(focusedMkpVariantIndex)
  }

  _onOpenVariantPicker = (pickedMkpIdx: number) => {
    // this.setState({ variantPickerVisibleAtIndex: pickedMkpIdx })

    const { navigation } = this.props
    const { editingMkpProduct, xSellyProduct, newPairedMap } = this.state

    navigation.dispatch(
      NavActions.navToXSellyVariantMappingSelectorView({
        editingMkpProduct,
        xSellyProduct,
        newPairedMap,
        focusedMkpVariantIndex: pickedMkpIdx,
        onVariantMappingPicked: this._onVariantMappingPicked,
      })
    )
  }

  _closeMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: false })
  }

  _openMappingSummary = async () => {
    await util.setStatePromise(this, { isMappingSummaryVisible: true })
  }

  _handlePressCancelMapping = async () => {
    // const { oldPairedMap, newPairedMap = {} } = this.state
    // const objDiff = DeepObjectDiff.detailedDiff(oldPairedMap, newPairedMap)
    // console.log('oldPairedMap => ', oldPairedMap)
    // console.log('newPairedMap => ', newPairedMap)

    // console.log('objDiff => ', objDiff)
    const isDirtyMapping = this._computeIsDirtyMapping()
    if (isDirtyMapping) {
      const isUserConfirmBack = await p.op.isUserConfirm(
        'มีการเปลี่ยนแปลงการผูกตัวเลือก',
        'กรุณายืนยันว่าจะออกจากหน้านี้โดยที่ไม่บันทึกการเปลี่ยนแปลง'
      )
      if (!isUserConfirmBack) {
        return
      }
    }
    // User confirms to go back and discard all changes. Therefore we set the flag to prevent destroying
    // the mkpProductContext, so user can pick other product to map
    this.props.navigation.setParams({ isPreventDestroyWhenNavBack: true })
    // set delay a bit here to ensure param settling
    await util.delay(200)

    this.goBack()
  }

  // _doFetchApiMkpProductMapping = async () => {
  //   if (this.isFetchMapping) {
  //     return
  //   }
  //   this.isFetchMapping = true
  //   await util.setStatePromise(this, { isFetchMapping: true })
  //   try {
  //     const { navigation, mkpProductContext } = this.props
  //     const { editingMkpProduct, newPairedMap, coreProduct } = this.state
  //     const store_id = util.getNavParam(this.props, 'store_id')
  //     // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
  //     const { mkp_channel_id } = editingMkpProduct
  //     const { mkp_id } = editingMkpProduct
  //     const item_uuid = editingMkpProduct.uuid
  //     const pt_id = coreProduct.id

  //     const new_pp_mappings = []
  //     // const ppvUUIDs = Object.keys(newPairedMap)
  //     // for (const ppvUUID of ppvUUIDs) {
  //     //   const ppid = newPairedMap[ppvUUID]
  //     //   new_pp_mappings.push({
  //     //     pp_id: ppid,
  //     //     ppv_uuid: ppvUUID,
  //     //   })
  //     // }

  //     // ส่งเฉพาะ variant ทีเกิด diff เท่านั้น !!!
  //     const updatedDiff = this._computeUpdatedMappingVariants()
  //     const ppvUUIDs = Object.keys(updatedDiff)
  //     for (const ppvUUID of ppvUUIDs) {
  //       const ppid = newPairedMap[ppvUUID]
  //       new_pp_mappings.push({
  //         pp_id: ppid,
  //         ppv_uuid: ppvUUID,
  //       })
  //     }

  //     // console.log('updatedDiff ==> ', this._computeUpdatedMappingVariants())

  //     // test bypass success
  //     // const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
  //     // if (_.isFunction(mkpState.onSuccesssMapping)) {
  //     //   mkpState.onSuccesssMapping()
  //     //   await util.delay(200)
  //     // }
  //     // this.goBack()
  //     // return

  //     const apiOptions: IApiOptions = {
  //       messages: {
  //         successMsg: 'ดำเนินการเสร็จสิ้น',
  //         errorMsg: 'ดำเนินการล้มเหลว',
  //       },
  //       showSpinner: true,
  //       axiosOptions: {
  //         retry: 0,
  //         timeout: 60000,
  //       },
  //       isApiV2: true,
  //     }

  //     const reqBody = {
  //       store_id,
  //       mkp_channel_id,
  //       mkp_id,
  //       item_uuid,
  //       pt_id,
  //       new_pp_mappings,
  //     }

  //     const res = await api.post('mkp/product_mapping', reqBody, apiOptions)
  //     if (res && res.status === 'ok') {
  //       await this._closeMappingSummary()
  //       const isPreventDestroyWhenNavBack = util.getNavParam(this.props, 'isPreventDestroyWhenNavBack', false)
  //       if (isPreventDestroyWhenNavBack) {
  //         await this.doRefresh()
  //       }
  //       const mkpState = mkpProductContext.getMkpState(this.getContextViewKey())
  //       if (_.isFunction(mkpState.onSuccesssMapping)) {
  //         mkpState.onSuccesssMapping()
  //         await util.delay(500)
  //       }
  //       this._doGoBackAfterSuccessMapping()
  //     }
  //     // console.log('_doFetchApiMkpProductMapping res => ', res)
  //   } catch (err) {
  //     // console.log('_doFetchApiMkpProductMapping err => ', err)
  //   }
  //   await this._closeMappingSummary()
  //   await util.setStatePromise(this, { isFetchMapping: false })
  //   this.isFetchMapping = false
  // }

  _doGoBackAfterSuccessMapping = () => {
    if (this._isNewMappingCoreProduct()) {
      this._goBackToProductView()
      return
    }
    this.goBack()
  }

  _isNewMappingCoreProduct = () => {
    const { editingMkpProduct, coreProduct } = this.state
    // @ts-ignore :: FIXME: แก้ mapping ให้ใช้งานได้
    const currentPairedProductTemplateId = editingMkpProduct.pt_id
    const selectedProductId = coreProduct.id
    return currentPairedProductTemplateId !== selectedProductId
  }

  _goBackToProductView = () => {
    // const { navigation } = this.props
    // const { state, goBack } = navigation
    // goBack('MkpProductLinkManagerView')
    util.navGoBack(this.props)
  }

  // _onXSellyVariantPickerItemsContainerLayout = (evt: LayoutChangeEvent) => {
  //   try {
  //     // console.log('_onXSellyVariantPickerItemsContainerLayout Dimensions.get(window) => ', Dimensions.get('window'))
  //     const { height } = evt.nativeEvent.layout
  //     if (height && this.state.xSellyVariantPickerItemsContainerHeight !== height) {
  //       this.setState({ xSellyVariantPickerItemsContainerHeight: height })
  //     }
  //   } catch (error) {
  //     //
  //   }
  // }

  // _onMkpProductVariantPickerOverlayLayout = (evt: LayoutChangeEvent) => {
  _onMkpProductVariantPickerOverlayLayout = () => {
    try {
      // const { height } = evt.nativeEvent.layout
      const webWindowHeight = Dimensions.get('window').height
      // console.log('_onMkpProductVariantPickerOverlayLayout webWindowHeight => ', webWindowHeight)
      if (p.op.isWeb() && webWindowHeight !== this.state.webWindowHeight) {
        this.setState({ webWindowHeight })
      }
      // }
    } catch (error) {
      //
    }
  }

  _renderMkpProductVariantPickerOverlay = () => {
    const {
      editingMkpProduct,
      variantPickerVisibleAtIndex = -1,
      isFetchMapping,
      // xSellyVariantPickerItemsContainerHeight = 300,
      webWindowHeight = 300,
    } = this.state

    if (!editingMkpProduct || _.isNil(variantPickerVisibleAtIndex) || variantPickerVisibleAtIndex < 0) {
      return null
    }

    const mkpProduct = editingMkpProduct
    const isVisible = variantPickerVisibleAtIndex >= 0
    const currentMkpVariant = mkpProduct.variants[variantPickerVisibleAtIndex]
    if (!currentMkpVariant) {
      return null
    }

    const currentMkpVariantName = currentMkpVariant.name
    // const currentMkpVariantPpvUUID = currentMkpVariant.ppv_uuid
    // const currentMappedCoreProduct = this._findMappeCoreProductFromMkpPpvUUID(currentMkpVariantPpvUUID)
    // const currentMappedCoreProductVariantName = currentMappedCoreProduct ? currentMappedCoreProduct.name : null
    // // if (currentMkpVariantName.length > 40) {
    // //   currentMkpVariantName = currentMkpVariantName.substring(0, 40)
    // // }

    // // const handlePickMapping = (idx: number) => this._onPickVariant(variantPickerVisibleAtIndex, idx)
    const headerTitle = 'ผูกตัวเลือกสินค้า'
    const pickDescText = 'เลือกเพื่อผูกกับ...'
    // if (currentMappedCoreProductVariantName) {
    //   headerTitle = 'เปลี่ยนการผูกตัวเลือกสินค้า'
    //   pickDescText = 'เลือกเพื่อเปลี่ยนไปผูกกับ...'
    // }

    // const complexRenderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
    //   const isCurrentMappedCoreVariantItem = currentMappedCoreProduct && currentMappedCoreProduct.pp_id === coreV.pp_id
    //   if (isCurrentMappedCoreVariantItem) {
    //     return null
    //   }
    //   return this._renderVariantMappingPickerItem(coreV, idx)
    // }

    return (
      <XOverlay visible={isVisible} onRequestClose={this._onRequestCloseProductVariantPicker}>
        <VStack w='full' flexWrap='wrap' onLayout={this._onMkpProductVariantPickerOverlayLayout}>
          <XCustomHeader
            headerRightProps={{
              closeIcon: true,
              submitting: isFetchMapping,
              onPressItem: this._onRequestCloseProductVariantPicker,
            }}
            title={headerTitle}
          />

          <VStack w='full' p='2' space='1'>
            <HStack w='full' pt='2' space='1'>
              {/* <XText variant='inactive'>สำหรับ</XText> */}
              <XText variant='inactive'>{`สำหรับตัวเลือกที่ ${variantPickerVisibleAtIndex + 1}:`}</XText>
              <XText flex={1} textAlign='right' color={COLORS.SECONDARY_MAIN} bold>
                {currentMkpVariantName}
              </XText>
            </HStack>
            {/* {currentMappedCoreProductVariantName ? (
              <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
                <XText variant='inactive'>เดิมผูกกับ</XText>
                <XText variant='primary' bold>
                  {currentMappedCoreProductVariantName}
                </XText>
              </View>
            ) : null} */}

            <VStack w='full'>
              <XText flex={1} variant='inactive'>
                {pickDescText}
              </XText>
            </VStack>

            {this.renderXSellyProductInfo()}

            {/* <View style={{ height: 4 }} /> */}

            {/* <VStack w='full' minH='250px' flex={1} onLayout={this._onXSellyVariantPickerItemsContainerLayout}> */}
            <XScrollView
              // maxHeight={p.op.isWeb() && webWindowHeight < 700 ? '300px' : '500px'}
              maxHeight={p.op.isWeb() && webWindowHeight < 700 ? '300px' : '300px'}
              // style={p.op.isWeb() ? { maxHeight: xSellyVariantPickerItemsContainerHeight } : undefined}
              // style={{ maxHeight: Dimensions.get('window').height - 200 }}
            >
              <VStack w='full' p='1' space='1' borderRadius='lg' _light={{ bg: 'muted.100' }}>
                {this.renderXSellyVariantPickerItems()}
              </VStack>
            </XScrollView>
            {/* </VStack> */}
            {/* <ScrollView style={{ maxHeight: 450 }}>{coreProduct.variants.map(complexRenderVariantMappingPickerItem)}</ScrollView> */}

            {/* <View style={[S.MARGIN_VERTICAL_8]}>
            <XText>{'ยังไม่ถูกเลือก'}</XText>
          </View>
          <View>{coreVariants.map(_renderCoreVariantItem)}</View>
          <View style={[S.MARGIN_VERTICAL_8]}>
            <XText>{'ถูกเลือกแล้ว'}</XText>
          </View>
          <View>{coreVariants.map(_renderCoreVariantItem)}</View> */}
          </VStack>
        </VStack>
      </XOverlay>
    )
  }

  renderXSellyVariantPickerItems = () => {
    const { xSellyProduct: xProduct } = this.state
    if (!xProduct || !xProduct.variants) {
      return null
    }
    return xProduct.variants.map(this.renderXSellyVariantPickerItem)
  }

  onXSellyVariantPickerItem = (pickedXSellyIndex: number) => {
    const { editingMkpProduct, variantPickerVisibleAtIndex, newPairedMap = {}, xSellyProduct: xProduct } = this.state
    const mkpVariantIndex = variantPickerVisibleAtIndex
    // console.log('onXSellyVariantPickerItem mkpVariantIndex => ', mkpVariantIndex)
    // console.log('onXSellyVariantPickerItem pickedXSellyIndex => ', pickedXSellyIndex)

    const focusMkpVariant = editingMkpProduct.variants[mkpVariantIndex]
    const pickedPpId = xProduct.variants[pickedXSellyIndex].pp_id

    const computeNewPairedMap = _.cloneDeep(newPairedMap)

    const pairedKeys = Object.keys(computeNewPairedMap)

    for (let i = 0; i < pairedKeys.length; i++) {
      const pairedPpvUuid = pairedKeys[i]
      const pairedPpId = computeNewPairedMap[pairedPpvUuid]

      if (pairedPpId === pickedPpId) {
        delete computeNewPairedMap[pairedPpvUuid]
      }
    }

    // computeNewPairedMap[focusMkpVariant.ppv_uuid] = pickedPpId
    const { item_id, item_variant_id = null } = focusMkpVariant
    computeNewPairedMap[`${item_id}_${item_variant_id}`] = pickedPpId

    // console.log('onXSellyVariantPickerItem computeNewPairedMap => ', computeNewPairedMap)

    this.setState({ variantPickerVisibleAtIndex: -1, newPairedMap: computeNewPairedMap })
  }

  renderXSellyVariantPickerItem = (variant: IProductDetailVariant, index: number) => {
    const handlePressPickerItem = () => this.onXSellyVariantPickerItem(index)

    // console.log('renderXSellyVariantPickerItem variant => ', variant)
    const { xSellyProduct: xProduct } = this.state
    const hasOnlyOneVariant = xProduct.variants.length === 1

    const isEmptyVariantName = _.isNil(variant.name) || variant.name === ''
    // let variantName = isEmptyVariantName ? '(ไม่มีชื่อ)' : variant.name
    let variantName = isEmptyVariantName ? '' : variant.name

    if (hasOnlyOneVariant) {
      variantName = 'ยืนยัน'
    }

    return (
      <XCard key={`XSellyVariantPickerItem_${index}`} mt='1' onPress={handlePressPickerItem}>
        <XCard.Body>
          <HStack w='full' alignItems='center'>
            <XSellyLogo />
            <VStack flex={1} px='1' alignItems='flex-end'>
              <XText variant='primary' bold>
                {variantName}
              </XText>
            </VStack>
            <ForwardIcon color={COLORS.SECONDARY_MAIN} />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  // _renderMkpProductVariantPickerOverlay = () => {
  //   const { editingMkpProduct, coreProduct, variantPickerVisibleAtIndex = -1, isFetchMapping } = this.state

  //   if (!editingMkpProduct || !coreProduct || !coreProduct.id) {
  //     return null
  //   }

  //   // return (
  //   //   <MkpProductVariantPicker
  //   //     visibleAtIndex={variantPickerVisibleAtIndex}
  //   //     coreProduct={selectedProduct.toJS()}
  //   //     mkpProduct={editingMkpProduct}
  //   //     onPickVariant={this._onPickVariant}
  //   //     onRequestClose={this._onRequestCloseProductVariantPicker}
  //   //   />
  //   // )

  //   const mkpProduct = editingMkpProduct
  //   const isVisible = variantPickerVisibleAtIndex >= 0
  //   const currentMkpVariant = mkpProduct.variants[variantPickerVisibleAtIndex]
  //   if (!currentMkpVariant) {
  //     return null
  //   }

  //   const currentMkpVariantName = currentMkpVariant.name
  //   const currentMkpVariantPpvUUID = currentMkpVariant.ppv_uuid
  //   const currentMappedCoreProduct = this._findMappeCoreProductFromMkpPpvUUID(currentMkpVariantPpvUUID)
  //   const currentMappedCoreProductVariantName = currentMappedCoreProduct ? currentMappedCoreProduct.name : null
  //   // if (currentMkpVariantName.length > 40) {
  //   //   currentMkpVariantName = currentMkpVariantName.substring(0, 40)
  //   // }

  //   // const handlePickMapping = (idx: number) => this._onPickVariant(variantPickerVisibleAtIndex, idx)
  //   let headerTitle = 'ผูกตัวเลือกสินค้า'
  //   let pickDescText = 'เลือกเพื่อผูกกับ...'
  //   if (currentMappedCoreProductVariantName) {
  //     headerTitle = 'เปลี่ยนการผูกตัวเลือกสินค้า'
  //     pickDescText = 'เลือกเพื่อเปลี่ยนไปผูกกับ...'
  //   }

  //   const complexRenderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
  //     const isCurrentMappedCoreVariantItem = currentMappedCoreProduct && currentMappedCoreProduct.pp_id === coreV.pp_id
  //     if (isCurrentMappedCoreVariantItem) {
  //       return null
  //     }
  //     return this._renderVariantMappingPickerItem(coreV, idx)
  //   }
  //   return (
  //     <XOverlay contentStyle={{ padding: 8 }} visible={isVisible} onRequestClose={this._onRequestCloseProductVariantPicker}>
  //       <View style={{ flex: 1, backgroundColor: 'white' }}>
  //         <XCustomHeader
  //           headerRightProps={{
  //             closeIcon: true,
  //             submitting: isFetchMapping,
  //             onPressItem: this._onRequestCloseProductVariantPicker,
  //           }}
  //           title={headerTitle}
  //         />
  //         <View
  //           style={{
  //             height: 1,
  //             width: '100%',
  //             borderTopWidth: 0.5,
  //             borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
  //           }}
  //         />
  //         <View style={[S.WIDTH_FULL]}>
  //           <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
  //             <XText variant='inactive'>สำหรับ</XText>
  //             <XText style={[S.TEXT_BLUE, S.TEXT_BOLD]}>{currentMkpVariantName}</XText>
  //           </View>
  //           {currentMappedCoreProductVariantName ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4, { paddingRight: 48 }]}>
  //               <XText variant='inactive'>เดิมผูกกับ</XText>
  //               <XText variant='primary' bold>
  //                 {currentMappedCoreProductVariantName}
  //               </XText>
  //             </View>
  //           ) : null}

  //           <View style={[S.MARGIN_VERTICAL_4]}>
  //             <XText variant='inactive'>{pickDescText}</XText>
  //           </View>
  //           <CoreProductInfo product={coreProduct} />
  //           <View style={{ height: 4 }} />
  //           {/* <CurrentMkpVariant variant={currentMkpVariant} /> */}
  //           {/* <CoreProductDisplay product={coreProduct} /> */}
  //           {/* <CoreVariantItem variant={coreProduct.variants[0]} idx={0} onPress={handlePickMapping} /> */}
  //           <ScrollView style={{ maxHeight: 450 }}>{coreProduct.variants.map(complexRenderVariantMappingPickerItem)}</ScrollView>
  //           {/* <View style={[S.MARGIN_VERTICAL_8]}>
  //           <XText>{'ยังไม่ถูกเลือก'}</XText>
  //         </View>
  //         <View>{coreVariants.map(_renderCoreVariantItem)}</View>
  //         <View style={[S.MARGIN_VERTICAL_8]}>
  //           <XText>{'ถูกเลือกแล้ว'}</XText>
  //         </View>
  //         <View>{coreVariants.map(_renderCoreVariantItem)}</View> */}
  //         </View>
  //       </View>
  //     </XOverlay>
  //   )
  // }

  _computeIsDirtyMapping = () => {
    const { newPairedMap = {} } = this.state
    return newPairedMap && !_.isEmpty(newPairedMap)
    // const { oldPairedMap = {}, newPairedMap } = this.state
    // const objDiff = DeepObjectDiff.diff(oldPairedMap, newPairedMap)
    // return !_.isEmpty(objDiff)
  }

  // _computeIsMappingValid = () => {
  //   const { editingMkpProduct, newPairedMap } = this.state
  //   const allMappingPpvUUIDs = editingMkpProduct.variants.map((mkpV) => mkpV.ppv_uuid)
  //   let isMappingValid = true
  //   for (const ppvUUID of allMappingPpvUUIDs) {
  //     if (!newPairedMap[ppvUUID]) {
  //       isMappingValid = false
  //     }
  //   }
  //   return isMappingValid
  // }

  // _computeHasMissingVariants = () => {
  //   const deletedDiff = this._computeMissingMappingVariants()
  //   const hasMissingVariants = !_.isEmpty(deletedDiff)
  //   return hasMissingVariants
  // }

  // _computeMissingMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap, editingMkpProduct } = this.state
  //   const idealPairedMap = oldPairedMap
  //   if (_.isEmpty(idealPairedMap)) {
  //     editingMkpProduct.variants.forEach((mkpV) => {
  //       idealPairedMap[mkpV.ppv_uuid] = 1 // 1 คือค่าสำหรับแทน pp_id เพื่อบ่งบอกว่ามีค่าเฉยๆ ไม่ได้นำไปใช้จริง
  //     })
  //   }
  //   const deletedDiff = DeepObjectDiff.deletedDiff(idealPairedMap, newPairedMap)
  //   return deletedDiff
  // }

  // _computeUpdatedMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap } = this.state
  //   let updatedDiff = DeepObjectDiff.updatedDiff(oldPairedMap, newPairedMap)
  //   if (_.isEmpty(oldPairedMap)) {
  //     updatedDiff = DeepObjectDiff.addedDiff(oldPairedMap, newPairedMap)
  //   }
  //   return updatedDiff
  // }

  // _renderSummaryUpdatedMappingVariants = () => {
  //   const updatedDiff = this._computeUpdatedMappingVariants()
  //   const updatedPpvUUIDs = Object.keys(updatedDiff)
  //   return updatedPpvUUIDs.map(this._renderSummaryUpdatedMappingVariantItem)
  // }

  // _renderSummaryUpdatedMappingVariantItem = (focusPPvUUID: string, idx: number) => {
  //   const { editingMkpProduct, coreProduct, oldPairedMap, newPairedMap } = this.state

  //   const oldPPID = oldPairedMap[focusPPvUUID] || null
  //   const newPPID = newPairedMap[focusPPvUUID]

  //   const mkpId = editingMkpProduct.mkp_id
  //   const mkpVariantItem = editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === focusPPvUUID)
  //   const mkpVariantName = mkpVariantItem ? mkpVariantItem.name : null

  //   const oldCoreVariant = oldPPID ? coreProduct.variants.find((coreV) => coreV.pp_id === oldPPID) : null
  //   const oldCoreVariantName = oldCoreVariant ? oldCoreVariant.name : null
  //   const newCoreVariant = coreProduct.variants.find((coreV) => coreV.pp_id === newPPID)
  //   const newCoreVariantName = newCoreVariant.name
  //   // return (
  //   //   <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //   //       <XText variant='inactive'>{` ${mkpVariantName}`}</XText>
  //   //     </View>
  //   //     <XText variant='inactive'>{' ผูกกับ '}</XText>
  //   //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //   //       <XSellyLogo width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //   //       {oldCoreVariantName ? (
  //   //         <XText style={[S.TEXT_INACTIVE, { textDecorationLine: 'line-through', textDecorationStyle: 'solid' }]}>
  //   //           {oldCoreVariantName}
  //   //         </XText>
  //   //       ) : null}
  //   //       <XText style={[S.TEXT_BLUE]}>{newCoreVariantName}</XText>
  //   //     </View>
  //   //   </View>
  //   // )
  //   return (
  //     <View key={idx} style={[S.BUTTON_OUTLINE_BLUE, S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //       <View style={[S.FLEX]}>
  //         {/* LINE 1 - MKP Product Variant Name */}
  //         <MappingMkpVariantNameItem mkpId={mkpId} label='ชื่อ' value={mkpVariantName} />

  //         <PairText label='จะผูกกับ' />

  //         {/* LINE 2 - Core Product Variant Name */}
  //         <LineThroughMappingCoreVariantNameItem label='' oldValue={oldCoreVariantName} newValue={newCoreVariantName} />

  //         {/* --- */}
  //       </View>
  //     </View>
  //   )
  // }

  // _renderSummaryMissingMappingVariants = () => {
  //   const { oldPairedMap, newPairedMap } = this.state
  //   const deletedDiff = DeepObjectDiff.deletedDiff(oldPairedMap, newPairedMap)
  //   const missingPpvUUIDs = Object.keys(deletedDiff)
  //   return missingPpvUUIDs.map(this._renderSummaryMissingMappingVariantItem)
  // }

  // _renderSummaryMissingMappingVariantItem = (missingPPvUUID: string, idx: number) => {
  //   const { editingMkpProduct, coreProduct } = this.state
  //   const mkpId = editingMkpProduct.mkp_id
  //   const mkpVariantItem = editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === missingPPvUUID)
  //   const mkpVariantName = mkpVariantItem.name
  //   return (
  //     <View key={idx} style={[S.ROW_MIDDLE_START, { marginTop: 4 }]}>
  //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
  //       <XText style={[S.TEXT_BLUE]}>{` ${mkpVariantName}`}</XText>
  //     </View>
  //   )
  // }

  // _renderMappingSummaryOverlay = () => {
  //   const {
  //     editingMkpProduct,
  //     coreProduct,
  //     isMappingSummaryVisible = false,
  //     oldPairedMap,
  //     newPairedMap,
  //     isFetchMapping,
  //     isOneToOneVariantMapping,
  //   } = this.state

  //   if (!isMappingSummaryVisible) {
  //     return null
  //   }

  //   const isNewMapping = _.isEmpty(oldPairedMap)
  //   const isMappingValid = this._computeIsMappingValid()
  //   const hasMissingVariants = this._computeHasMissingVariants()
  //   // const hasUpdatedVariants = !_.isEmpty(this._computeUpdatedMappingVariants())

  //   const titleText = hasMissingVariants ? 'ผูกตัวเลือกยังไม่ครบ' : `ยืนยัน${isNewMapping ? '' : 'สลับ'}การผูกสินค้า`
  //   const descText = isNewMapping ? 'กรุณายืนยันการผูกตัวเลือกสินค้า ดังต่อไปนี้' : 'กรุณายืนยันสลับการผูกตัวเลือก ดังต่อไปนี้'

  //   const submitBtnStyle = [...BASE_SUBMIT_BTN_STYLE, isFetchMapping ? S.BUTTON_INACTIVE : S.BUTTON_PRIMARY]
  //   const closeOverlayBtnStyle = [...BASE_CLOSE_OVERLAY_BTN_STYLE, isFetchMapping ? S.BUTTON_OUTLINE : S.BUTTON_OUTLINE_PRIMARY]
  //   const confirmButtonLabel = isOneToOneVariantMapping ? 'ยืนยัน' : 'ตรวจสอบและยืนยัน'
  //   return (
  //     <XOverlay
  //       contentStyle={{ padding: 8 }}
  //       visible={isMappingSummaryVisible}
  //       onRequestClose={isFetchMapping ? null : this._closeMappingSummary}>
  //       <View style={{ flex: 1, backgroundColor: 'white' }}>
  //         <XCustomHeader
  //           headerRightProps={{ closeIcon: true, submitting: isFetchMapping, onPressItem: this._closeMappingSummary }}
  //           title={titleText}
  //         />

  //         {/* line */}
  //         <View
  //           style={{
  //             height: 1,
  //             width: '100%',
  //             borderTopWidth: 0.5,
  //             borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
  //           }}
  //         />
  //         <View style={[S.WIDTH_FULL]}>
  //           {/* desctiption word */}
  //           {!isOneToOneVariantMapping && isMappingValid ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //               <XText variant='inactive'>{descText}</XText>
  //             </View>
  //           ) : null}
  //           {hasMissingVariants ? (
  //             <View style={[S.ROW_MIDDLE_BETWEEN, S.MARGIN_VERTICAL_4]}>
  //               <XText variant='inactive'>ตัวเลือกเหล่านี้ยังไม่ได้รับการผูก</XText>
  //             </View>
  //           ) : null}

  //           {/* diff content */}
  //           <View style={{ height: 4 }} />

  //           {/* Hide variant mapping info if it's only one variant on both XSelly and MKP */}
  //           {isOneToOneVariantMapping ? null : (
  //             <ScrollView style={{ maxHeight: 450 }}>
  //               {hasMissingVariants ? this._renderSummaryMissingMappingVariants() : this._renderSummaryUpdatedMappingVariants()}
  //             </ScrollView>
  //           )}

  //           <View style={{ height: 8 }} />
  //         </View>
  //         <View style={{ height: 4 }} />
  //         {/* buttons */}
  //         {isMappingValid ? (
  //           <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_BETWEEN]}>
  //             <TouchableOpacity
  //               disabled={isFetchMapping}
  //               onPress={this._closeMappingSummary}
  //               style={closeOverlayBtnStyle}
  //               // style={[S.BUTTON_OUTLINE_PRIMARY, S.ROW_CENTER, S.NO_FLEX, { width: 54, marginRight: 4, minHeight: 44 }]}
  //             >
  //               <XText style={[isFetchMapping ? S.TEXT_INACTIVE : S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_LARGER]}>ปิด</XText>
  //             </TouchableOpacity>
  //             <View style={S.FLEX}>
  //               <TouchableOpacity
  //                 disabled={isFetchMapping}
  //                 onPress={this._doFetchApiMkpProductMapping}
  //                 style={submitBtnStyle}
  //                 // style={[S.BUTTON_PRIMARY, S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 34 }]}
  //               >
  //                 {isFetchMapping ? (
  //                   <Spinner size='sm' color={COLORS.TEXT_ACTIVE} />
  //                 ) : (
  //                   <XText style={[S.TEXT_ACTIVE_DARK, S.TEXT_BOLD, S.TEXT_LARGER]}>{confirmButtonLabel}</XText>
  //                 )}
  //               </TouchableOpacity>
  //             </View>
  //           </View>
  //         ) : (
  //           <TouchableOpacity
  //             onPress={this._onPressContinueDoMappinng}
  //             style={[S.BUTTON_OUTLINE_PRIMARY, S.ROW_CENTER, S.WIDTH_FULL, { minHeight: 44 }]}>
  //             <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_LARGER]}>ดำเนินการผูกตัวเลือกต่อ</XText>
  //           </TouchableOpacity>
  //         )}
  //       </View>
  //     </XOverlay>
  //   )
  // }

  // _onPressContinueDoMappinng = async () => {
  //   await this._closeMappingSummary()
  //   const missingVariantMap = this._computeMissingMappingVariants()
  //   const missingPpvUUIDS = Object.keys(missingVariantMap)
  //   if (missingPpvUUIDS.length > 0) {
  //     const missingPpvUUID = missingPpvUUIDS[0]
  //     const foundIndex = this.state.editingMkpProduct.variants.findIndex((mkpV) => mkpV.ppv_uuid === missingPpvUUID)
  //     if (foundIndex >= 0) {
  //       await this._scrollToPickerButtonIndex(foundIndex)
  //     }
  //   }
  // }

  _scrollToPickerButtonIndex = async (idx: number) => {
    try {
      const cScrollRef = this.contentScrollViewRef.current

      // @ts-ignore
      if (cScrollRef && _.isFunction(cScrollRef.scrollTo) && this.mkpVariantButtonPositionYMap[idx]) {
        await util.delay(500)
        // @ts-ignore
        cScrollRef.scrollTo({ y: this.mkpVariantButtonPositionYMap[idx], animated: true })
        const hilightPickerMap = {}
        hilightPickerMap[idx] = true
        await util.setStatePromise(this, { hilightPickerMap })
        await util.delay(850)
        await util.setStatePromise(this, { hilightPickerMap: {} })
      }
    } catch (error) {
      //
    }
  }

  // _findMappedMkpProductFromCorePPID = (ppid: number) => {
  //   const { newPairedMap = {}, editingMkpProduct } = this.state
  //   const ppvUUIDs = Object.keys(newPairedMap)
  //   for (const ppvUUID of ppvUUIDs) {
  //     const mappedPPID = newPairedMap[ppvUUID]
  //     if (mappedPPID === ppid) {
  //       return editingMkpProduct.variants.find((mkpV) => mkpV.ppv_uuid === ppvUUID)
  //     }
  //   }
  //   return null
  // }

  // _onPickNewMappingVariant = (newCoreIdx: number) => {
  //   const { newPairedMap, editingMkpProduct, coreProduct, variantPickerVisibleAtIndex } = this.state
  //   const focusMkpIdx = variantPickerVisibleAtIndex
  //   const updatedNewPairedMap = _.cloneDeep(newPairedMap)
  //   const focusMkpPpvUUID = editingMkpProduct.variants[focusMkpIdx].ppv_uuid
  //   const pickedCoreVariantPPID = coreProduct.variants[newCoreIdx].pp_id

  //   // เช็ค pp_id ซ้ำ
  //   const ppvUUIDs = Object.keys(updatedNewPairedMap)
  //   for (const currentPpvUUID of ppvUUIDs) {
  //     const oldMappedPPID = updatedNewPairedMap[currentPpvUUID]
  //     if (oldMappedPPID === pickedCoreVariantPPID) {
  //       delete updatedNewPairedMap[currentPpvUUID]
  //     }
  //   }

  //   // ใส่ pair ตัวใหม่เข้าไป
  //   updatedNewPairedMap[focusMkpPpvUUID] = pickedCoreVariantPPID

  //   this.setState({ newPairedMap: updatedNewPairedMap, variantPickerVisibleAtIndex: -1 })
  // }

  // _renderVariantMappingPickerItem = (coreV: IProductDetailVariant, idx: number) => {
  //   const { editingMkpProduct } = this.state
  //   const currentPPID = coreV.pp_id
  //   const mappedMkpVariant = this._findMappedMkpProductFromCorePPID(currentPPID)
  //   return (
  //     <>
  //       <VariantMappingPicker
  //         key={idx}
  //         index={idx}
  //         coreVariant={coreV}
  //         mkpId={editingMkpProduct.mkp_id}
  //         mkpVariant={mappedMkpVariant}
  //         onPress={this._onPickNewMappingVariant}
  //       />
  //       <View style={{ height: 6 }} />
  //     </>
  //   )
  // }

  _findMappeCoreProductFromMkpPpvUUID = (ppvUUID: string) => {
    const { newPairedMap = {}, coreProduct } = this.state
    if (!coreProduct || !coreProduct.id) {
      return null
    }

    const mappedPPID = newPairedMap[ppvUUID] || null
    if (!mappedPPID) {
      return null
    }

    return coreProduct.variants.find((coreV) => coreV.pp_id === mappedPPID)
  }

  _onVariantMappingButtonMeasurePositionY = (posY: number, idx: number) => {
    if (!this.mkpVariantButtonPositionYMap) {
      this.mkpVariantButtonPositionYMap = {}
    }
    this.mkpVariantButtonPositionYMap[idx] = posY
    // console.log('this.mkpVariantButtonPositionYMap => ', this.mkpVariantButtonPositionYMap)
  }

  // _renderVariantMappingButton = (mkpV: IMkpProductVariantItemInfo) => {
  //   const currentPpvUUID = mkpV.variant.ppv_uuid
  //   const mappedCoreProduct = this._findMappeCoreProductFromMkpPpvUUID(currentPpvUUID)

  //   const { hilightPickerMap = {} } = this.state
  //   const highlighted = hilightPickerMap[mkpV.index] || false

  //   return (
  //     <VariantMappingButton
  //       key={mkpV.index}
  //       index={mkpV.index}
  //       mkpId={mkpV.mkpId}
  //       mkpVariant={mkpV.variant}
  //       coreVariant={mappedCoreProduct}
  //       onPress={this._onOpenVariantPicker}
  //       onMeasurePositionY={this._onVariantMappingButtonMeasurePositionY}
  //       highlightedBg={highlighted}
  //     />
  //   )
  // }
}

// Local components
// interface IMkpProductVariantPickerProps {
//   visibleAtIndex: number
//   coreProduct: IProductDetailItem
//   mkpProduct: IMkpProductDetail
//   onPickVariant: (mkpVariantIdx: number, coreVariantIdx: number) => void
//   onRequestClose: () => void
// }

// const CoreProductDisplay = (props: { product: IProductDetailItem }) => {
//   const { product } = props

//   if (!product || !product.id) {
//     return null
//   }

//   const name = product.name
//   const desc = product.description
//   const previewImg = product.thumbnail_uris[0]

//   return (
//     <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_START]}>
//       <View style={[S.ROW_CENTER, S.PADDING_4]}>
//         <FastImage style={{ width: 48, height: 48 }} resizeMode={FastImage.resizeMode.cover} source={{ uri: previewImg }} />
//       </View>
//       <View style={[S.FLEX, S.COLUMN_LEFT_MIDDLE, S.PADDING_4]}>
//         <XText style={[S.TEXT_INACTIVE, S.TEXT_BOLD]}>{name}</XText>
//         <XText variant='inactive' numberOfLines={1}>
//           {desc}
//         </XText>
//       </View>
//     </View>
//   )
// }

// interface IVariantMappingButtonProps {
//   index: number
//   coreVariant?: IProductDetailVariant
//   mkpId: number
//   // mkpVariant: IMkpProductDetailVariantItem
//   mkpVariant: IMkpProductDetailVariant
//   disabled?: boolean
//   onPress: (mkpIdx: number) => void

//   highlightedBg?: boolean
//   onMeasurePositionY?: (positionY: number, idx: number) => void
// }

// const VariantMappingButton = (props: IVariantMappingButtonProps) => {
//   const { index, coreVariant = null, mkpId, mkpVariant, disabled = false, onPress, highlightedBg = false, onMeasurePositionY } = props
//   // const coreVariantName = coreVariant ? coreVariant.name : '<<ยังไม่ได้เลือก>>'
//   const coreVariantName = coreVariant ? coreVariant.name : null
//   const mkpVariantName = mkpVariant.name
//   const handlePress = () => onPress(index)

//   const _onLayout = (evt) => {
//     // console.log(`onLayout index=${index} evt => `, evt)
//     // const layout = evt.nativeEvent.layout
//     // try-catch เนื่องจาก web จะมีช่วง undefined อยู่
//     try {
//       evt.target.measure((x, y, width, height, pageX, pageY) => {
//         // console.log(`index=${index} height:`, height)
//         // console.log(`index=${index} width:`, width)
//         // console.log(`index=${index} x:`, x)
//         // console.log(`index=${index} y:`, y)
//         // console.log(`index=${index} pageX:`, pageX)
//         // console.log(`index=${index} pageY:`, pageY)
//         if (_.isFunction(onMeasurePositionY)) {
//           onMeasurePositionY(pageY, index)
//         }
//       })
//     } catch (err) {
//       console.log(`onLayout index=${index} error => `, err)
//     }
//   }
//   const isPaired = coreVariant && mkpVariant
//   const hlBgStyle = highlightedBg ? { backgroundColor: COLORS.APP_SECONDARY } : {}
//   return (
//     <TouchableOpacity onLayout={_onLayout} onPress={handlePress} style={[S.BUTTON_OUTLINE_BLUE, S.ROW_MIDDLE_BETWEEN, hlBgStyle]}>
//       <View style={[S.FLEX]}>
//         {/* LINE 1 - MKP Product Variant Name */}
//         <MappingMkpVariantNameItem mkpId={mkpId} label='ชื่อ' value={mkpVariantName} />

//         {isPaired ? <PairText /> : null}

//         {/* LINE 2 - Core Product Variant Name */}
//         <MappingCoreVariantNameItem label='' value={coreVariantName} />

//         {/* --- */}
//       </View>
//       {disabled ? null : (
//         <View style={[S.ROW_CENTER, { width: 34 }]}>
//           <ForwardIcon style={{ color: COLORS.SECONDARY_MAIN }} />
//         </View>
//       )}
//     </TouchableOpacity>
//   )
// }

// interface IVariantMappingPickerProps {
//   index: number
//   coreVariant: IProductDetailVariant
//   mkpId: number
//   // mkpVariant?: IMkpProductDetailVariantItem
//   mkpVariant?: IMkpProductDetailVariant
//   disabled?: boolean
//   onPress: (coreIdx: number) => void
// }

// const PairText = (props: { label?: string }) => {
//   const { label = 'กำลังผูกอยู่กับ' } = props
//   return (
//     <View style={[S.WIDTH_FULL, S.ROW_MIDDLE_END, { paddingRight: 8 }]}>
//       <XIcon
//         family='FontAwesome'
//         name='link'
//         style={{
//           flex: 0,
//           flexBasis: 'auto',
//           fontSize: STYLES.FONT_ICON_SMALLEST,
//           width: STYLES.FONT_ICON_SMALLEST,
//           minWidth: STYLES.FONT_ICON_SMALLEST,
//           height: STYLES.FONT_ICON_SMALLEST,
//           color: COLORS.TEXT_INACTIVE,
//           marginRight: 4,
//         }}
//       />
//       <XText style={[S.TEXT_INACTIVE, { paddingBottom: 2 }]}>{label}</XText>
//     </View>
//   )
// }

// const VariantMappingPicker = (props: IVariantMappingPickerProps) => {
//   const { index, coreVariant, mkpId, mkpVariant = null, disabled = false, onPress } = props
//   const coreVariantName = coreVariant.name
//   const mkpVariantName = mkpVariant ? mkpVariant.name : null
//   const handlePress = () => onPress(index)
//   const isPaired = coreVariant && mkpVariant

//   return (
//     <TouchableOpacity onPress={handlePress} style={[S.BUTTON_OUTLINE_BLUE, S.ROW_MIDDLE_BETWEEN]}>
//       <View style={[S.FLEX]}>
//         {/* LINE 1 - MKP Product Variant Name */}
//         {mkpVariantName ? <MappingMkpVariantNameItem mkpId={mkpId} label='' value={mkpVariantName} /> : null}

//         {isPaired ? <PairText /> : null}

//         {/* LINE 2 - Core Product Variant Name */}
//         <MappingCoreVariantNameItem label='' value={coreVariantName} />

//         {/* --- */}
//       </View>
//       {disabled ? null : (
//         <View style={[S.ROW_CENTER, { width: 34 }]}>
//           <ForwardIcon style={{ color: COLORS.SECONDARY_MAIN }} />
//         </View>
//       )}
//     </TouchableOpacity>
//   )
// }

// const MappingMkpVariantNameItem = ({ label, value, mkpId }) => (
//   <View style={[S.ROW_MIDDLE_BETWEEN]}>
//     <View style={[S.FLEX_2, S.ROW_MIDDLE_START]}>
//       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
//       {/* <XIcon
//             name={'create'}
//             style={{
//               flex: 1,
//               flexBasis: 'auto',
//               fontSize: STYLES.FONT_ICON_NORMAL,
//               width: STYLES.FONT_ICON_NORMAL,
//               minWidth: STYLES.FONT_ICON_NORMAL,
//               height: STYLES.FONT_ICON_NORMAL,
//               color: COLORS.SECONDARY_MAIN,
//               paddingLeft: 1,
//             }}
//           /> */}
//       <View style={[S.ROW_MIDDLE_START]}>
//         <XText style={[S.TEXT_INACTIVE, { marginLeft: 8 }]}>{label}</XText>
//       </View>
//     </View>
//     <View style={[S.FLEX, S.ROW_MIDDLE_START, S.PADDING_HORIZONTAL_8]}>
//       <View style={[S.FLEX, S.ROW_MIDDLE_END]}>
//         <XText style={[S.TEXT_BLUE, S.TEXT_BOLD]}>{value}</XText>
//       </View>
//     </View>
//   </View>
// )

// const MappingCoreVariantNameItem = ({ label, value }) => {
//   const isNotChooseYet = typeof value !== 'string'

//   return (
//     <View style={[S.ROW_MIDDLE_BETWEEN]}>
//       <View style={[S.FLEX_2, S.ROW_MIDDLE_START]}>
//         <XSellyLogo width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
//         <XText style={[S.TEXT_PRIMARY, { marginLeft: 8 }]}>{label}</XText>
//       </View>

//       <View style={[S.FLEX, S.ROW_MIDDLE_END, S.PADDING_HORIZONTAL_8]}>
//         <XText style={[isNotChooseYet ? S.TEXT_PRIMARY : S.TEXT_PRIMARY]}>{value || '<<ยังไม่ได้เลือก>>'}</XText>
//       </View>
//     </View>
//   )
// }

// const LineThroughMappingCoreVariantNameItem = ({ label, oldValue, newValue }) => (
//   <View style={[S.ROW_MIDDLE_BETWEEN]}>
//     <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
//       <XSellyLogo width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
//       <XText style={[S.TEXT_PRIMARY, { marginLeft: 8 }]}>{label}</XText>
//     </View>

//     <View style={[S.FLEX_2, S.ROW_MIDDLE_END, S.PADDING_HORIZONTAL_8]}>
//       <XText style={[S.TEXT_INACTIVE, S.TEXT_STRIKETHROUGH]}>{oldValue}</XText>
//       <XText style={S.TEXT_PRIMARY}>{newValue}</XText>
//     </View>
//   </View>
// )

// const CoreVariantItem = (props: {
//   variant: IProductDetailVariant
//   idx: number
//   onPress: (idx: number) => void
//   isCurrentMapped?: boolean
//   isAlreadyMapped?: boolean
// }) => {
//   const { idx, variant, onPress, isCurrentMapped = false, isAlreadyMapped = false } = props
//   const handlePress = () => onPress(idx)
//   let showSelectedText = null
//   if (isAlreadyMapped) {
//     showSelectedText = `ผูกแล้ว`
//   }
//   if (isCurrentMapped) {
//     showSelectedText = '(ถูกเลือกอยู่)'
//   }

//   const buttonOutlineStyle = showSelectedText ? S.BUTTON_OUTLINE : S.BUTTON_OUTLINE_BLUE
//   return (
//     <TouchableOpacity
//       key={idx}
//       style={[
//         buttonOutlineStyle,
//         S.ROW_MIDDLE_BETWEEN,
//         S.PADDING_HORIZONTAL_12,
//         S.PADDING_VERTICAL_6,
//         {
//           marginBottom: 4,
//         },
//       ]}
//       onPress={handlePress}>
//       <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
//         <XText style={[S.TEXT_ACTIVE]}>{variant.name}</XText>
//       </View>
//       {showSelectedText ? (
//         <View style={[S.ROW_MIDDLE_END, { width: 100 }]}>
//           <XText variant='inactive'>{showSelectedText}</XText>
//         </View>
//       ) : null}
//     </TouchableOpacity>
//   )
// }

// const CurrentMkpVariant = (props: { variant: IMkpProductDetailVariantItem }) => {
//   const { variant } = props
//   return (
//     <View>

//     </View>
//   )
// }

// const MkpProductVariantPicker = (props: IMkpProductVariantPickerProps) => {
//   const { visibleAtIndex, coreProduct, mkpProduct, onPickVariant, onRequestClose } = props
//   const isVisible = visibleAtIndex >= 0

//   const currentMkpVariant = mkpProduct.variants[visibleAtIndex]
//   if (!currentMkpVariant) {
//     return null
//   }

//   const currentMkpVariantName = currentMkpVariant.name
//   // if (currentMkpVariantName.length > 40) {
//   //   currentMkpVariantName = currentMkpVariantName.substring(0, 40)
//   // }

//   const headerRightProps = _.isFunction(onRequestClose) ? { closeIcon: true, onPressItem: onRequestClose } : null
//   const handlePickMapping = (idx: number) => onPickVariant(visibleAtIndex, idx)

//   return (
//     <XOverlay contentStyle={{ padding: 8 }} visible={isVisible} onRequestClose={onRequestClose}>
//       <View style={{ flex: 1, backgroundColor: 'white' }}>
//         <XCustomHeader headerRightProps={headerRightProps} title={`${currentMkpVariantName}`} />
//         <View
//           style={{
//             height: 1,
//             width: '100%',
//             borderTopWidth: 0.5,
//             borderTopColor: p.op.isWeb() ? COLORS.BG_LIGHT_GREY : COLORS.TEXT_INACTIVE,
//           }}
//         />
//         <View style={[S.WIDTH_FULL]}>
//           {/* <View style={[S.MARGIN_VERTICAL_4]}>
//             <XText variant='inactive'>{'การผูกตัวเลือกเดิม'}</XText>
//           </View> */}

//           <View style={[S.MARGIN_VERTICAL_4]}>
//             <XText variant='inactive'>{'เลือกเพื่อผูกกับ...'}</XText>
//           </View>
//           {/* <CurrentMkpVariant variant={currentMkpVariant} /> */}
//           <CoreProductDisplay product={coreProduct} />
//           {/* <CoreVariantItem variant={coreProduct.variants[0]} idx={0} onPress={handlePickMapping} /> */}
//           {coreProduct.variants.map((coreV, idx) => {
//             return (
//               <>
//                 <VariantMappingPicker key={idx} index={idx} variant={coreV} onPress={handlePickMapping} />
//                 <View style={{ height: 6 }} />
//               </>
//             )
//           })}
//           {/* <View style={[S.MARGIN_VERTICAL_8]}>
//             <XText>{'ยังไม่ถูกเลือก'}</XText>
//           </View>
//           <View>{coreVariants.map(_renderCoreVariantItem)}</View>
//           <View style={[S.MARGIN_VERTICAL_8]}>
//             <XText>{'ถูกเลือกแล้ว'}</XText>
//           </View>
//           <View>{coreVariants.map(_renderCoreVariantItem)}</View> */}
//         </View>
//       </View>
//     </XOverlay>
//   )
// }
