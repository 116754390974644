import React from 'react'
import { Image, View } from 'react-native'

import FAQ from 'x/config/FAQ'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { COLORS } from 'x/config/styles'
import XCustomHeader from 'xui/components/XCustomHeader'
import BaseStoreSettingXShippingBestView from 'x/modules/xshipping/BaseStoreSettingXShippingBestView'
import { VIEW_LOADING } from 'xui/utils/BaseShareUI'
import XSettingRow from 'xui/components/XSettingRow'
import XHelpModal from 'xui/components/XHelpModal'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import Box from 'xui/components/Box'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HStack from 'xui/components/HStack'

export default abstract class BaseUIStoreSettingXShippingBestView extends BaseStoreSettingXShippingBestView {
  static displayName = 'BaseUIStoreSettingXShippingBestView'
  // abstract goBack: () => void

  _renderCustomHeader = () => (
    <XCustomHeader
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
      title={p.op.t('Order.shippingTypeItems.best')}
    />
  )

  _renderUseBest = () => {
    const { useBest, useAutoXShippingIsBestReadyToShip, isBestLoading, loadingBestWidth, loadingBestHeight } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบไม่เก็บเงินปลายทาง"'
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingBestWidth: width, loadingBestHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useBest}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useBest'
          helper={{
            title: 'Best ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_NOT_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
        />

        {useBest ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsBestReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsBestReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {isBestLoading ? VIEW_LOADING(loadingBestWidth, loadingBestHeight) : null}
      </View>
    )
  }

  _renderUseBestCOD = () => {
    const {
      useBestCOD,
      useAutoXShippingIsBestCODReadyToShip,
      loadingBestCODWidth,
      loadingBestCODHeight,
      isBestCODLoading,
      useAutoCreateCODPaymentWhenDelivered,
    } = this.state
    const textTitle = 'ใช้งานการขอเลขพัสดุ\n"แบบเก็บเงินปลายทาง"'
    return (
      <View
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingBestCODWidth: width, loadingBestCODHeight: height })
        }}>
        <XSettingRow
          title={textTitle}
          value={useBestCOD}
          onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
          stateKey='useBestCOD'
          helper={{
            title: 'Best ออกเลขพัสดุ',
            FAQ: FAQ.XSHIPPING_COD,
            initiateOpenIndex: 0,
          }}
          backgroundColor={COLORS.APP_SECONDARY}
        />

        {useBestCOD ? (
          <XSettingRow
            title='ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง'
            value={useAutoXShippingIsBestCODReadyToShip}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoXShippingIsBestCODReadyToShip'
            helper={{
              title: 'ขอเลขพัสดุอัตโนมัติเมื่อพร้อมจัดส่ง',
              FAQ: FAQ.XSHIPPING_READY_TO_SHIP,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}

        {useBestCOD ? (
          <XSettingRow
            title='บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว'
            value={useAutoCreateCODPaymentWhenDelivered}
            onClick={(newValue: { isValue: boolean; stateKey: string }) => this.isSwitchOnChange(newValue)}
            stateKey='useAutoCreateCODPaymentWhenDelivered'
            helper={{
              title: 'บันทึกรับชำระอัตโนมัติเมื่อส่งพัสดุถึงผู้รับแล้ว',
              FAQ: FAQ.XSHIPPING_AUTO_CREATE_COD_PAYMENT_WHEN_DELIVERED,
              initiateOpenIndex: 0,
            }}
            backgroundColor={COLORS.APP_SECONDARY}
          />
        ) : null}
        {isBestCODLoading ? VIEW_LOADING(loadingBestCODWidth, loadingBestCODHeight) : null}
      </View>
    )
  }

  _renderAgreement = (onPressOpenOverlay: () => void) => (
    <XButton onPress={() => onPressOpenOverlay()} w='48' alignSelf='center'>
      ดูข้อตกลงและวิธีการใช้งาน
    </XButton>
  )

  _renderHowtoFaq = (onPressOpenOverlay: () => void) => (
    <HStack flex={1} alignItems='center' justifyContent='center' mt='2'>
      <XText textDecorationLine='underline' color={COLORS.BRAND_Info} onPress={() => onPressOpenOverlay()}>
        ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง Best Express
      </XText>
    </HStack>
  )

  _renderBaseDirect = () => (
    <Box
      _light={{ bg: 'white' }}
      _dark={{ bg: 'gray.500' }}
      borderRadius='md'
      style={{
        // backgroundColor: COLORS.WHITE,
        marginTop: 10,
        marginRight: 6,
        marginLeft: 6,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 6,
        paddingBottom: 15,
      }}>
      <Box style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 10, paddingBottom: 10 }}>
        <Image
          source={require('../../images/courier/best.png')}
          resizeMode='stretch'
          style={{
            // position: 'absolute',
            width: 138,
            height: 40,
            backgroundColor: 'transparent',
            zIndex: 70,
          }}
        />
      </Box>
      <Box style={{ justifyContent: 'center', alignItems: 'center' }}>
        <XText variant='active'>ขอเลขพัสดุจาก Best Express โดยตรง</XText>
      </Box>

      <XHelpModal
        key='XHelpModalFromBast'
        headerTitle='ข้อตกลงและวิธีการใช้งาน'
        FAQ={FAQ.XSHIPPING_BEST_AGREEMENT}
        initiateOpenIndex={4}
        renderButton={(onPressOpenOverlay: () => void) => this._renderAgreement(onPressOpenOverlay)}
      />

      <XFaqModal
        key='XHelpModalBest_HowTo'
        headerTitle='ดูคลิปแนะนำวิธีเปิดใช้งานขอเลขส่ง Best Express'
        FAQ={FAQ_CONSTANTS.FAQ_BEST_EXPRESS_XSHIPPING}
        initiateOpenIndex={0}
        renderButton={(onPressOpenOverlay: () => void) => this._renderHowtoFaq(onPressOpenOverlay)}
      />

      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.best')}`}</XText>
      </Box>
      {this._renderUseBest()}
      <Box style={{ height: 10 }} />
      <Box style={{ paddingBottom: 2, flexDirection: 'row' }}>
        <XText variant='inactive'>{'รูปแบบจัดส่ง: '}</XText>
        <XText variant='active' bold>{`${p.op.t('Order.shippingTypeItems.best_cod')}`}</XText>
      </Box>
      {this._renderUseBestCOD()}
    </Box>
  )

  render() {
    return (
      <XContainer>
        <XContent>
          {this._renderCustomHeader()}
          {this._renderBaseDirect()}
        </XContent>
      </XContainer>
    )
  }
}
