import React from 'react'
import * as NavActions from 'x/utils/navigation'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import { ISelectedStoreMap, IProfileMap, IXScreenProps } from 'x/index'
import XIcon from 'xui/components/XIcon'
import { APP_CONFIG } from 'x/config/mode'
import { Linking } from 'react-native'
import CONS from 'x/config/constants'

interface IBaseSettingViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  profile: IProfileMap
  fetchUserProfile: any
  dispatch?: (param?: any) => void
}

interface IBaseSettingViewState {
  refreshing: boolean
}

interface IMenu {
  label: string
  icon: JSX.Element
  showMenu: boolean
  nav: () => void
}

export default abstract class BaseSettingView extends React.Component<IBaseSettingViewProps, IBaseSettingViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  MENU_ABOUT_ME: IMenu[]

  MENU_ABOUT_XSELLY: IMenu[]

  constructor(props) {
    super(props)
    this.state = {
      refreshing: false,
    }
    this.MENU_ABOUT_ME = [
      {
        label: 'ข้อมูลส่วนตัว',
        showMenu: true,
        icon: <XIcon name='account-circle' family='MaterialCommunityIcons' variant='inactive' />,
        nav: () => this.navToProfile(),
      },
      {
        label: 'บัตรตัวแทน',
        showMenu: this._showCardInfoView(),
        icon: <XIcon name='card-account-details' family='MaterialCommunityIcons' variant='inactive' />,
        nav: () => this.navToCardInfoViewContainer(),
      },
      {
        label: 'ลูกค้า',
        showMenu: APP_CONFIG.setting_view.show_menu_customer && util.isProfileAddressInUsed(),
        icon: <XIcon name='group' family='MaterialIcons' variant='inactive' />,
        nav: () => this.navToCustomerList(),
      },
      {
        label: 'อีเมลบัญชีผู้ใช้',
        showMenu: true,
        icon: <XIcon name='email' family='MaterialCommunityIcons' variant='inactive' />,
        nav: () => this._onPressEmailMenu(),
      },
      {
        label: 'เปลี่ยนรหัสผ่าน',
        showMenu: true,
        icon: <XIcon name='vpn-key' family='MaterialIcons' variant='inactive' />,
        nav: () => this._onPressPasswordMenu(),
      },
    ]

    this.MENU_ABOUT_XSELLY = [
      {
        label: 'คู่มือการใช้งาน',
        showMenu: APP_CONFIG.setting_view.show_menu_guidance,
        icon: <XIcon name='help' family='MaterialIcons' variant='inactive' />,
        nav: () => this._onPressHelpMenu(),
      },
      {
        label: `เกี่ยวกับ ${CONS.APP_NAMES[`${APP_CONFIG.app_name}`]}`,
        showMenu: true,
        icon: <XIcon name='info' family='MaterialIcons' variant='inactive' />,
        nav: () => this.navToAboutAppView(),
      },
    ]
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    await this._onRefresh()
  }

  _onRefresh = async () => {
    const { fetchUserProfile } = this.props
    await util.setStatePromise(this, { refreshing: true })
    const res = await new Promise((success, fail) => {
      fetchUserProfile(success, fail)
    })

    await util.setStatePromise(this, { refreshing: false })
    // if (res) {
    //   fetchSubscription()
    // }
    // this.setState({ refreshing: true }, () => {
    //   const successCallback = (res) => {
    //     // console.log('successCallback res => ', res)
    //     if (res && res.profile && res.profile.user_id) {
    //       // this._fetchSubscription(res.profile.user_id)
    //       fetchSubscription()
    //     }
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //
    //   const failedCallback = (err) => {
    //     if (this && !this.isUnmounting) {
    //       this.setState({ refreshing: false })
    //     }
    //   }
    //
    // })
  }

  _showCardInfoView = () => {
    const { selectedStore } = this.props
    const isHaveOrg = selectedStore.has('org') && selectedStore.get('org').size > 0
    let isOrgOwner = false
    if (isHaveOrg) {
      isOrgOwner = util.isOrgOwnerStore(selectedStore)
    }
    return isHaveOrg && !isOrgOwner
  }

  _onPressEmailMenu = () => {
    const { navigation, profile } = this.props
    const email_verified = profile.get('email_verified') || false
    if (email_verified) {
      if (p.op.isWeb()) {
        // @ts-ignore
        this.props.navigation.popToTop()
      }
      navigation.dispatch(NavActions.navToSettingEmailView)
    } else {
      const buttonLabels = ['รับอีเมลยืนยันตัวตน', 'เปลี่ยนอีเมลใหม่', 'ยกเลิก']
      const autoMethods = [
        () => this._getVerificationEmail(),
        () => {
          if (p.op.isWeb()) {
            // @ts-ignore
            this.props.navigation.popToTop()
          }
          navigation.dispatch(NavActions.navToSettingEmailView)
        },
      ]
      p.op.showActionSheet(
        {
          options: buttonLabels,
          cancelButtonIndex: buttonLabels.length - 1,
          title: 'ดำเนินการอัตโนมัติ...',
        },
        (buttonIndex) => {
          const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
          const selectedMethod = autoMethods[idx]
          if (_.isFunction(selectedMethod)) {
            selectedMethod()
          }
        }
      )
      // ActionSheet.show(
      //   {
      //     options: ['รับอีเมลยืนยันตัวตน', 'เปลี่ยนอีเมลใหม่', 'ยกเลิก'],
      //     cancelButtonIndex: 2,
      //     // destructiveButtonIndex: -1,
      //     title: 'ดำเนินการ...',
      //   },
      //   (buttonIndex) => {
      //     const idx = parseInt(buttonIndex)
      //     switch (idx) {
      //       case 0:
      //         this._getVerificationEmail()
      //         return
      //       case 1:
      //         navigation.dispatch(NavActions.navToSettingEmailView)

      //       default:
      //     }
      //   }
      // )
    }
  }

  _getVerificationEmail() {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    const { navigation, profile, dispatch } = this.props
    const email = profile.get('email') || null

    if (!email) {
      p.op.alert('ไม่มีอีเมล', 'กรุณาตั้งค่าอีเมลก่อน โดยกดปุ่ม อีเมลบัญชีผู้ใช้ > เปลี่ยนอีเมลใหม่')
      return
    }

    p.op.showConfirmationThreeButtons(
      'รับอีเมลยืนยันตัวตน',
      `"${email}"\nคืออีเมลที่ถูกต้องและคุณสามารถเข้าถึงเพื่อเช็กได้ ใช่หรือไม่?`,
      'ใช่ ส่งอีเมลเดี๋ยวนี้',
      () => {
        dispatch({ type: 'PROFILE/USER_PROFILE_GET_VERIFICATION_EMAIL', payload: email })
        setTimeout(() => {
          // this.inProcess = false
        }, 2000)
      },
      'ไม่ใช่ แก้ไขอีเมล',
      () => {
        // this.inProcess = false
        if (p.op.isWeb()) {
          // @ts-ignore
          this.props.navigation.popToTop()
        }
        navigation.dispatch(NavActions.navToSettingEmailView)
      },
      'ยกเลิก',
      () => {
        // this.inProcess = false
      }
    )
  }

  _onPressPasswordMenu = () => {
    const { navigation, profile, dispatch } = this.props
    // console.log('profile => ', profile.toJS())
    const email = profile.get('email') || null
    const generated_password = profile.get('generated_password') || null
    if (!email) {
      p.op.alert('ไม่มีอีเมล', 'กรุณาตั้งค่าอีเมลก่อน โดยกดปุ่ม อีเมลบัญชีผู้ใช้ > เปลี่ยนอีเมลใหม่')
      return
    }
    if (generated_password) {
      dispatch({
        type: 'AUTH/FORGOT_ACCOUNT_PASSWORD',
        payload: { email },
      })
    } else {
      if (p.op.isWeb()) {
        // @ts-ignore
        this.props.navigation.popToTop()
      }
      navigation.dispatch(NavActions.navToSettingResetPasswordView)
    }
  }

  _onPressHelpMenu = async () => {
    const { profile } = this.props
    try {
      const user_id = profile && profile.get('user_id') ? profile.get('user_id') : 0
      p.op.aLogEvent(CONS.EVENT_NAME.HELP_VIEW_SETTINGS, { user_id })
      const url = 'https://help.xselly.com'
      const supported = await Linking.canOpenURL(url)
      if (supported) {
        await Linking.openURL(url)
      }
    } catch (err) {
      console.log('_onPressHelpMenu err => ', err)
    }
  }

  navToProfile() {
    if (p.op.isWeb()) {
      // @ts-ignore
      this.props.navigation.popToTop()
    }
    this.props.navigation.dispatch(NavActions.navToProfile)
  }

  navToCardInfoViewContainer = () => {
    this.props.navigation.dispatch(NavActions.navToCardInfoViewContainer)
  }

  navToCustomerList = () => {
    if (p.op.isWeb()) {
      // @ts-ignore
      this.props.navigation.popToTop()
    }
    this.props.navigation.dispatch(
      NavActions.navToCustomerList({
        mode: 'VIEW_EDIT',
      })
    )
  }

  navToAboutAppView = () => {
    if (p.op.isWeb()) {
      // @ts-ignore
      this.props.navigation.popToTop()
    }
    this.props.navigation.dispatch(NavActions.navToAboutAppView)
  }

  _doSignOut = async () => {
    const isUserConfirm = await p.op.isUserConfirm('ออกจากระบบ', 'คุณยืนยันที่จะออกจากระบบหรือไม่')
    if (isUserConfirm) {
      await p.op.signOut()
      // this.props.navigation.navigate('GuestStackNavigator')
      await util.delay(100)
      this.props.navigation.dispatch(NavActions.resetToFirstPage)
    }
  }

  onCloseOverlayAfterAccountDeleted = (isAccountDeleted: boolean) => {
    this._onCloseOverlayAfterAccountDeleted(isAccountDeleted)
  }

  _onCloseOverlayAfterAccountDeleted = async (isAccountDeleted: boolean) => {
    if (isAccountDeleted) {
      await p.op.signOut()
      // this.props.navigation.navigate('GuestStackNavigator')
      await util.delay(100)
      this.props.navigation.dispatch(NavActions.resetToFirstPage)
    }
  }
}
