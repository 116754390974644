import React, { PureComponent } from 'react'
import { StyleSheet, View, TouchableOpacity, RefreshControl, Dimensions, TextStyle, Platform } from 'react-native'
import { List } from 'immutable'

import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import Divider from 'xui/components/Divider'
import Spinner from 'xui/components/XSpinner'

import * as util from 'x/utils/util'
import { logRender } from 'x/utils/util'
import { COLORS, STYLES, S } from 'x/config/styles'
import _ from 'lodash'

import BasePaymentListView from 'x/modules/payment/BasePaymentListView'
import xCONS from 'x/config/constants'
import p from 'x/config/platform-specific'
import * as NavActions from 'x/utils/navigation'
import XText from 'xui/components/XText'
import XCard from 'xui/components/XCard'
import XCustomHeader from 'xui/components/XCustomHeader'
import XButton from 'xui/components/XButton'
import XButtonWithIcon from 'xui/components/XButtonWithIcon'
import XIcon from 'xui/components/XIcon'
import { IPaymentListItemMap } from 'x/types'
import VerifySlipIcon from 'xui/components/VerifySlipIcon'
import { fetchReVerifySlip } from 'x/utils/api'
import XSpinner from 'xui/components/XSpinner'
import XContent from '../../components/XContent'
import XContainer from '../../components/XContainer'
// import XCustomHeader from '../../components/XCustomHeader'
// import XGalleryModal from '../../components/XGalleryModal'

const { OPENED, CONFIRMED, REJECTED_OR_CANCELED } = xCONS.PAYMENT_STATUS
const DEVICE_WIDTH = Dimensions.get('window').width

export const DECISION_STATUS = {
  CONFIRM: 1,
  REJECT: 2,
  CANCEL: 3,
}

// declare as a constant here as there is no change on the permission anyway unless reload the app
// const HAS_CONFIRM_PAYMENT_PERMISSION = xAcl.canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.PAYMENT_EDIT)

export default abstract class BaseUIPaymentListView extends BasePaymentListView {
  static displayName = 'BaseUIPaymentListView'
  // static navigationOptions = ({ navigation }) => {
  //   const { goBack, state } = navigation
  //   const { txtTitle, btnRight } = state.params
  //   let title = txtTitle ? txtTitle : 'รายการชำระ'
  //
  //   let injectedHeaderRight = {}
  //   if (btnRight && btnRight.onPress) {
  //     injectedHeaderRight = {
  //       headerRight: <NavHeaderButton addIcon={true} onPressItem={() => btnRight.onPress()} />,
  //     }
  //   }
  //
  //   return {
  //     headerLeft: <NavHeaderButton backIcon={true} onPressItem={() => goBack(state.key)} />,
  //     title,
  //     ...injectedHeaderRight,
  //   }
  // }

  abstract renderXGalleryModal(): JSX.Element

  // abstract renderCustomHeader(): JSX.Element

  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     previewVisible: false,
  //     previewImageURI: '',
  //     refreshing: false,
  //   }
  // }

  // componentWillMount() {
  //   // this._fetchPaymentList()
  //   const { navigation } = this.props
  //   const { state, setParams } = navigation
  //   log('PaymentListView componentWillMount navigation.state => ', state)
  //   if (state.params && state.params.isReceivedOnly) {
  //     setParams({ txtTitle: 'รายการรับชำระ', ...state.params })
  //   } else {
  //     setParams({ ...state.params })
  //   }
  // }

  // componentDidMount() {
  //   this.props.shouldFetchPaymentList()
  // }

  // componentWillReceiveProps(nextProps) {
  //   const { shouldFetchList } = nextProps
  //   log('PaymentListView componentWillReceiveProps shouldFetchList => ', shouldFetchList)
  //   if (this.props.shouldFetchList !== shouldFetchList && shouldFetchList) {
  //     this._fetchPaymentList()
  //   }
  // }

  // shouldComponentUpdate(nextProps, nextState) {
  //   const isStateChanged = xUtil.isDiff(this.state, nextState, ['previewVisible', 'previewImageURI', 'refreshing'])
  //   const isPropsChanged = xUtil.isDiff(this.props, nextProps, ['selectedPaymentList', 'shouldFetchList'])
  //   return isPropsChanged || isStateChanged
  // }

  // componentWillUnmount() {
  //   if (!this.isUnmounting) {
  //     this.isUnmounting = true
  //     this.props.paymentActions.reset()
  //   }
  //   const { state, goBack } = this.props.navigation
  //   if ((state && _.isNull(state.params.store_id)) || _.isUndefined(state.params.store_id)) {
  //     Alert.alert('ไม่พบข้อมูลร้าน', 'กรุณากลับไปเลือกร้านของคุณใหม่อีกครั้ง ขออภัยในความไม่สะดวก')
  //     goBack(state.key)
  //   }
  // }

  // Abstract method

  // _getParams(key: string): any {
  //   const { state } = this.props.navigation
  //   if (state && state.params) {
  //     if (state.params[key]) {
  //       return state.params[key]
  //     }
  //   }
  //   return null
  // }

  // _onRefresh() {
  //   // FIXME: use callback should be a better way.
  //   this.setState({ refreshing: true }, () => {
  //     this._fetchPaymentList()
  //     setTimeout(() => {
  //       if (this && !this.isUnmounting) {
  //         this.setState({ refreshing: false })
  //       }
  //     }, 1800)
  //   })
  // }

  // _confirmPayment(paymentId) {
  //   const { store_id, fromOrderDetail } = this.props.navigation.state.params
  //   this.props.paymentActions.confirmPayment({ store_id, payment_id: paymentId, refreshOrderDetail: fromOrderDetail })
  // }
  //
  // _rejectPayment(paymentId) {
  //   const _doRejectPayment = this.props.paymentActions.rejectPayment
  //   utilN.showConfirmation('ปฏิเสธการชำระ', 'คุณต้องการปฏิเสธการแจ้งชำระนี้ใช่หรือไม่',
  //     () => {
  //       const { store_id, fromOrderDetail } = this.props.navigation.state.params
  //       _doRejectPayment({ store_id, payment_id: paymentId, refreshOrderDetail: fromOrderDetail })
  //     }, null)
  // }

  // _renderPaymentItem({ index, item }) {
  //   const {
  //     isOddRow,
  //     paymentId,
  //     status,
  //     img_uris,
  //     img_uri,
  //     date,
  //     orderNameArray,
  //     isRefund,
  //     total_amount,
  //     isReadOnly,
  //     note,
  //     bank_id,
  //     txtBankName,
  //   } = this._getPaymentItem(index, item)
  //   const isGBPay = bank_id === xCONS.GBPAY
  //
  //   return (
  //     <Card
  //       transparent
  //       style={[
  //         CARD_COMMON,
  //         NO_MARGIN_PADDING,
  //         {
  //           paddingLeft: 5,
  //           paddingRight: 5,
  //           paddingTop: 5,
  //           paddingBottom: 5,
  //           borderBottomColor: COLORS.TEXT_INACTIVE,
  //           backgroundColor: isOddRow ? COLORS.BG_LIGHT_GREY : null,
  //           // backgroundColor: 'pink',
  //         },
  //       ]}>
  //       <Box cardBody style={{ flexDirection: 'column', backgroundColor: null }}>
  //         <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
  //           {/*{------------- Left -------------}*/}
  //           <View
  //             style={{
  //               // width: '30%',
  //               // flex: 3,
  //               width: 38,
  //               // paddingLeft: 5,
  //               flexDirection: 'column',
  //               justifyContent: 'center',
  //               alignItems: 'flex-start',
  //               // backgroundColor: 'tomato',
  //             }}>
  //             {img_uri ? (
  //               <TouchableOpacity
  //                 // onPress={() => this.setState({ modalVisible: true, viewImgUri: img_uri })}
  //                 onPress={() => this._openSlipImages(img_uris)}
  //                 style={[
  //                   BTN_MODE_ACTIVE_SECONDARY,
  //                   {
  //                     width: 38,
  //                     height: 38,
  //                     borderRadius: 5,
  //                     borderWidth: 1,
  //                     justifyContent: 'center',
  //                     alignItems: 'center',
  //                     // backgroundColor: COLORS.BTN_MODE_ACTIVE_SECONDARY,
  //                   },
  //                 ]}>
  //                 <XText
  //                   style={[
  //                     BTN_TEXT_MODE_ACTIVE_SECONDARY,
  //                     {
  //                       fontSize: FONT_SIZE_SMALLER,
  //                     },
  //                   ]}>
  //                   ดูสลิป
  //                 </XText>
  //               </TouchableOpacity>
  //             ) : (
  //               <View
  //               //style={[BTN_MODE_INACTIVE, { width: 38, height: 38 }]}
  //               />
  //             )}
  //           </View>
  //           {/*{------------- Center -------------}*/}
  //           <View
  //             style={{
  //               // width: '35%',
  //               flex: 4,
  //               flexDirection: 'column',
  //               justifyContent: 'flex-start',
  //               alignItems: 'flex-start',
  //               paddingLeft: 4,
  //             }}>
  //             <View
  //               style={{
  //                 flex: 1,
  //                 flexDirection: 'row',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'flex-start',
  //               }}>
  //               <XText
  //                 allowFontScaling={false}
  //                 numberOfLines={1}
  //                 style={{
  //                   fontSize: FONT_SIZE_NORMAL,
  //                   color: isGBPay ? COLORS.BRAND_Success : COLORS.TEXT_ACTIVE,
  //                   fontWeight: isGBPay ? 'bold' : 'normal',
  //                 }}>
  //                 {`${txtBankName}`}
  //               </XText>
  //             </View>
  //             <View
  //               style={{
  //                 flex: 1,
  //                 flexDirection: 'row',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'flex-start',
  //               }}>
  //               <XText allowFontScaling={false} numberOfLines={1} style={{ fontSize: FONT_SIZE_NORMAL }}>
  //                 {`${moment(date).format('D MMM   HH:mm')}`}
  //               </XText>
  //             </View>
  //             <View
  //               style={{
  //                 flex: 1,
  //                 flexDirection: 'row',
  //                 justifyContent: 'flex-start',
  //                 alignItems: 'flex-start',
  //               }}>
  //               <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: FONT_SIZE_SMALLER }}>{`ใบสั่งซื้อ ${orderNameArray.join()}`}</XText>
  //             </View>
  //           </View>
  //           <View
  //             style={{
  //               // width: '30%',
  //               // flex: 3,
  //               width: 85,
  //               paddingLeft: 5,
  //               paddingTop: 1,
  //               flexDirection: 'column',
  //               justifyContent: 'flex-start',
  //               alignItems: 'flex-end',
  //             }}>
  //             <View
  //               style={{
  //                 flex: 1,
  //                 flexDirection: 'column',
  //                 // backgroundColor: 'tomato',
  //               }}>
  //               <XText style={isRefund ? s.PAY_REFUND : s.PAY_NORMAL}>
  //                 {isRefund ? ' (เงินคืน) ' : ''}
  //                 {xFmt.formatCurrency(total_amount)}
  //               </XText>
  //               {isGBPay ? null : <XText style={s.PAY_REFUND}>{img_uri ? '' : 'ไม่ได้แนบสลิป'}</XText>}
  //             </View>
  //           </View>
  //
  //           {/*{------------- Right -------------}*/}
  //           <View
  //             style={{
  //               // width: '30%',
  //               // flex: 3,
  //               width: 91,
  //               flexDirection: 'column',
  //               justifyContent: 'center',
  //               alignItems: 'flex-end',
  //               // backgroundColor: 'green',
  //             }}>
  //             {this._renderDecisionButton(paymentId, status, isReadOnly, isGBPay)}
  //           </View>
  //         </View>
  //         {MODE === 'dev' ? (
  //           <Label style={{ fontSize: 8, color: 'gray' }}>
  //             {`Debug: id:${item.get('id')}, status:${status}, isReadOnly:${isReadOnly}, isRefund:${isRefund}, payer_store_id:${item.get(
  //               'payer_store_id',
  //             )}, payee_store_id:${item.get('payee_store_id')} `}
  //           </Label>
  //         ) : null}
  //       </Box>
  //       {note ? (
  //         <Box cardBody style={{ paddingLeft: 5, paddingRight: 5, backgroundColor: null }}>
  //           <XText style={{ color: COLORS.TEXT_INACTIVE, fontSize: FONT_SIZE_SMALLER }}>{`หมายเหตุ: ${note}`}</XText>
  //         </Box>
  //       ) : (
  //         <View />
  //       )}
  //     </Card>
  //   )
  // }

  // _renderDecisionButton(paymentId, status, isReadOnly, isGBPay = false) {
  //   const fromOrderDetail = this._getParams('fromOrderDetail')
  //   if (status === STATUS_OPEN) {
  //     if (isReadOnly) {
  //       return (
  //         <View
  //           style={{
  //             flex: 1,
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}>
  //           <XText>รอยืนยัน</XText>
  //         </View>
  //       )
  //     } else {
  //       // able to perform action
  //       return (
  //         <View
  //           style={{
  //             flex: 3,
  //             flexDirection: 'row',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //           }}>
  //           <TouchableOpacity
  //             style={[s.BTN_ICON, { backgroundColor: COLORS.FORM_ERROR }]}
  //             onPress={() => this._rejectPayment(paymentId, fromOrderDetail)}>
  //             <Icon name='close' style={s.ICON_IN_BUTTON} />
  //           </TouchableOpacity>
  //           <TouchableOpacity
  //             style={[s.BTN_ICON, { backgroundColor: COLORS.FORM_SUCCESS }]}
  //             onPress={() => this._confirmPayment(paymentId, fromOrderDetail)}>
  //             <Icon name='checkmark' style={s.ICON_IN_BUTTON} />
  //           </TouchableOpacity>
  //         </View>
  //       )
  //     }
  //   } else {
  //     return (
  //       <View
  //         style={{
  //           flex: 3,
  //           flexDirection: 'row',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}>
  //         <View style={s.BTN_ICON}>
  //           {status === STATUS_REJECTED_OR_CANCELED ? <Icon name='close' style={[s.ICON_IN_BUTTON, { color: COLORS.FORM_ERROR }]} /> : null}
  //         </View>
  //         <View style={s.BTN_ICON}>
  //           {status === STATUS_CONFIRMED && !isGBPay ? (
  //             <Icon name='checkmark' style={[s.ICON_IN_BUTTON, { color: COLORS.FORM_SUCCESS }]} />
  //           ) : null}
  //           {status === STATUS_CONFIRMED && isGBPay ? (
  //             <IconMCI name='marker-check' style={[s.ICON_IN_BUTTON, { color: COLORS.FORM_SUCCESS }]} />
  //           ) : null}
  //         </View>
  //       </View>
  //     )
  //   }
  // }

  // _renderStatusButton(statusBool) {
  //   return (
  //     <View
  //       style={{
  //         flex: 3,
  //         flexDirection: 'row',
  //         justifyContent: 'center',
  //         alignItems: 'flex-end',
  //       }}>
  //       {statusBool ? (
  //         <Icon name='checkmark' style={[s.ICON_IN_BUTTON, { color: COLORS.FORM_SUCCESS }]} />
  //       ) : (
  //         <Icon name='close' style={[s.ICON_IN_BUTTON, { color: COLORS.FORM_ERROR }]} />
  //       )}
  //     </View>
  //   )
  // }

  // _renderPaymentList() {
  //   const { selectedPaymentList, shouldFetchList } = this.props
  //   // if (!selectedPaymentList || selectedPaymentList.size === 0 && shouldFetchList) {
  //   if (shouldFetchList) {
  //     return (
  //       <View style={s.CONTAINER_BLANK_TEXT}>
  //         <XText>กำลังโหลดข้อมูล...</XText>
  //       </View>
  //     )
  //   } else if (selectedPaymentList.size > 0) {
  //     return (
  //       <FlatList
  //         data={selectedPaymentList.toArray()}
  //         keyExtractor={(item, index) => `PaymentItem_${index}`}
  //         renderItem={({ item, index }) => this._renderPaymentItem({ index, item })}
  //       />
  //     )
  //   } else if (selectedPaymentList.size === 0 && !shouldFetchList) {
  //     return (
  //       <View style={s.CONTAINER_BLANK_TEXT}>
  //         <XText>ไม่มีรายการชำระสำหรับบัญชีนี้</XText>
  //       </View>
  //     )
  //   } else {
  //     return null
  //   }
  // }

  _renderPaymentList = () => {
    if (!this.state.isInitialized) {
      return (
        <View style={s.CONTAINER_BLANK_TEXT}>
          <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_INACTIVE]}>กำลังโหลดข้อมูล...</XText>
        </View>
      )
    }

    const { selectedPaymentList = List([]) } = this.props

    if (_.isNil(selectedPaymentList) || selectedPaymentList.size === 0) {
      return this._renderEmptyList()
    }

    return selectedPaymentList.toArray().map(this._renderPaymentListItem)
    // return (
    //   <FlatList
    //     extraData={selectedPaymentList}
    //     // contentContainerStyle={s.CONTAINER_CONTENT}
    //     data={selectedPaymentList.toArray()}
    //     ListEmptyComponent={this._renderEmptyList}
    //     keyExtractor={this._keyExtractor}
    //     renderItem={this._renderPaymentListItem}
    //   />
    // )
  }

  _renderEmptyList = () => {
    const { refreshing } = this.state
    if (refreshing) {
      return null
    }

    const verify_slip_id = util.getNavParam(this.props, 'verify_slip_id')

    return (
      <HStack w='full' px='4' py='6' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>{verify_slip_id ? 'ไม่มีรายการชำระที่ใช้สลิปนี้' : 'ไม่มีรายการชำระสำหรับบัญชีนี้'}</XText>
      </HStack>
    )
  }

  _openPreviewImages = (imgUris: string[]) => {
    let previewImageUris = imgUris

    if (_.isString(imgUris)) {
      previewImageUris = [imgUris]
    }

    this.setState({ previewImageUris, previewVisible: true })
  }

  _clearPreviewImages = () => this.setState({ previewImageUris: [], previewVisible: false })

  _renderSlipButton = (imgUris?: string[], bankId?: number) => {
    const payment = this._isGatewayPaymentDontHaveSlip(bankId)
    if (imgUris && imgUris.length > 0) {
      const safeImgUris = imgUris.map((uri) => util.prependHttpsIfNotExists(uri))
      return <SlipButton imgUris={safeImgUris} onPress={() => this._openPreviewImages(safeImgUris)} />
    }

    if (_.isNil(payment)) {
      return <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_DANGER]}>ไม่ได้แนบสลิป</XText>
    }
    let color = COLORS.TEXT_ACTIVE
    if (bankId === xCONS.SYSTEM_BANK_IDS.GBPAY) {
      color = COLORS.GB_PAY_MAIN
    } else if (bankId === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
      color = COLORS.SCB_MAIN
    }
    return <XText style={[s.TXT_SIZE_NORMAL, { color }]} />
    // return <XText style={[s.TXT_SIZE_NORMAL, { color }]}>{payment}</XText>
  }

  // imgUris && imgUris.length > 0 ? (
  //   <SlipButton imgUris={imgUris} onPress={this._openPreviewImages} />
  // ) : (
  //   <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_DANGER]}>{'ไม่ได้แนบสลิป'}</XText>
  // )
  _renderOrderToAmountItems = (ods) => ods.map(this._renderOrderToAmountItem)

  _renderOrderToAmountItem = (od, index) => (
    // <HStack key={index} style={[s.NO_FLEX, s.BORDER_LINE_TOP_THIN, s.FIX_HEIGHT_15]}>
    <HStack key={index.toString()} py='1' alignItems='center' style={s.BORDER_LINE_TOP_THIN}>
      {/* <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_INACTIVE]}>{od.get('txtOrderName')}</XText> */}
      {this._renderOrderLabelItem(od, index)}
      <XText w='50%' variant='inactive' textAlign='right'>
        {od.get('txtOrderAmount')}
      </XText>
    </HStack>
  )

  _navToOrderDetial = (orderId: number) => {
    const { navigation } = this.props
    // const { state, goBack } = navigation
    const storeId = util.getNavParam(this.props, 'store_id')
    navigation.dispatch(
      NavActions.navToOrderDetail({
        store_id: storeId,
        order_id: orderId,
        fromPaymentListView: true,
      })
    )
  }

  _renderOrderLabelItem = (od, index) => {
    const orderId = od.get('id')
    const orderName = od.get('txtOrderName')
    if (this.isFromOrderDetial()) {
      return (
        <XText w='50%' variant='inactive'>
          {od.get('txtOrderName')}
        </XText>
      )
    }
    const isFirst = index === 0
    return (
      <HStack w='50%'>
        <XButtonWithIcon variant='outline' onPress={() => this._navToOrderDetial(orderId)}>
          <XText variant='inactive'>{orderName}</XText>
        </XButtonWithIcon>
      </HStack>
    )
  }

  _keyExtractor = (item, index) => index.toString()

  _getTxtTotalAmountColorStyle = (status: number, isRefund?: boolean) => {
    const txtAdditionalAmountStyle: TextStyle = { color: COLORS.BRAND_Success }
    if (isRefund) {
      txtAdditionalAmountStyle.color = COLORS.BRAND_Danger
    }
    if (status === REJECTED_OR_CANCELED) {
      txtAdditionalAmountStyle.color = COLORS.TEXT_INACTIVE
      txtAdditionalAmountStyle.textDecorationLine = 'line-through'
    }
    return txtAdditionalAmountStyle
  }

  // _renderPaymentListItem = ({ item, index }) => {
  _renderPaymentListItem = (item: IPaymentListItemMap, index: number) => {
    const paymentInfo = this._getPaymentInfoFromPaymentListItem(index, item)
    const {
      imgUris,
      orderToAmountItems,
      txtBankName,
      txtTotalAmount,
      txtPostDateTime,
      txtCreatedAt,
      txtTableHeaderAmount,
      txtTableHeaderOrder,
      status,
      isRefund,
      isGBPay,
      bankId,
      rejectedAt,
      hasVerifySlip,
      isVerifySlipSuccess,
    } = paymentInfo
    const txtAdditionalAmountStyle = this._getTxtTotalAmountColorStyle(status, isRefund)
    const txtAdditionalBankStyle = isGBPay ? s.TXT_COLOR_SUCCESS : {}
    let textBankNameColor = COLORS.TEXT_ACTIVE
    if (bankId === xCONS.SYSTEM_BANK_IDS.GBPAY) {
      textBankNameColor = COLORS.GB_PAY_MAIN
    } else if (bankId === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
      textBankNameColor = COLORS.SCB_MAIN
    } else if (bankId === xCONS.SYSTEM_BANK_IDS.BEAM) {
      textBankNameColor = COLORS.BEAM_MAIN
    }

    const itemBgColorStyle: { backgroundColor?: string } = {}
    switch (status) {
      case CONFIRMED:
        itemBgColorStyle.backgroundColor = COLORS.FORM_SUCCESS_BG
        break
      case REJECTED_OR_CANCELED:
        if (rejectedAt) {
          // highlight only rejected
          itemBgColorStyle.backgroundColor = COLORS.FORM_ERROR_BG
        }
        break
    }

    return (
      <XCard key={this._keyExtractor(item, index)} w='full' mt={index === 0 ? '1' : '2'}>
        <VStack w='full'>
          <VStack w='full' p='2'>
            <HStack w='full' alignItems='center'>
              <XText w='40%' numberOfLines={2} style={[txtAdditionalBankStyle, { color: textBankNameColor }]}>
                {txtBankName}
              </XText>
              <XText w='30%' style={[s.TXT_COLOR_ACTIVE]}>
                {txtPostDateTime}
              </XText>
              <XText w='30%' fontSize='lg' textAlign='right' bold style={[s.TXT_COLOR_PRIMARY, txtAdditionalAmountStyle]}>
                {txtTotalAmount}
              </XText>
            </HStack>
          </VStack>
          <Divider />

          <VStack w='full' p='2'>
            <HStack w='full'>
              <VStack w='30%' space='1'>
                <HStack>{isGBPay ? null : this._renderSlipButton(imgUris, bankId)}</HStack>
                <XText fontSize='xs' variant='inactive'>
                  {txtCreatedAt}
                </XText>
              </VStack>
              <VStack w='70%' pl='1' borderLeftWidth='1' borderLeftColor='muted.100'>
                <HStack w='full'>
                  <XText w='50%' variant='inactive'>
                    {txtTableHeaderOrder}
                  </XText>
                  <XText w='50%' variant='inactive' textAlign='right'>
                    {txtTableHeaderAmount}
                  </XText>
                </HStack>
                {this._renderOrderToAmountItems(orderToAmountItems)}
              </VStack>
            </HStack>
          </VStack>

          <VStack w='full' minH='64px'>
            {/* Keng on 3 Apr 2023: Trying to fix ปุ่ม ปฏิเสธ ยืนยัน รับชำระหาย คาดว่า เป็นเพราะไม่ได้กำหนด h ให้ VStack
        Here are the testing result on iPad Air 5th gen
        h=56 will add tons of bottom padding. Don't understand why
        h=57 is the minimum to see the button
        h=62 <-- use this one just in case 57 is not enough for iPhone
        */}
            <VStack minH='64px' w='full' space='1' flexWrap='wrap'>
              {this._renderPaymentNote(paymentInfo)}

              {this._renderReVerifySlipBar(paymentInfo)}

              <VStack w='full' minH='62px' borderTopWidth='1' borderTopColor='muted.200' style={itemBgColorStyle} p='2'>
                <HStack w='full' minH='38px'>
                  {this._renderBoxFooterColLeft(paymentInfo)}
                  {this._renderBoxFooterColRight(paymentInfo)}
                </HStack>

                {hasVerifySlip && item.get('verify_slip_transaction_ref') ? (
                  <HStack w='full' alignItems='center' justifyContent='space-between'>
                    <XText variant='inactive'>เลขที่รายการ</XText>
                    <XText variant='inactive'>{item.get('verify_slip_transaction_ref')}</XText>
                  </HStack>
                ) : null}
              </VStack>
            </VStack>
          </VStack>
        </VStack>
      </XCard>
    )
  }

  _renderPaymentNote = ({ note = null }) => {
    // const note = paymentInfo.get('note')
    if (!note || note === '') {
      return null
    }
    return (
      <HStack w='full' flexWrap='wrap' p='2'>
        <XText variant='inactive'>{`หมายเหตุ​: ${note}`}</XText>
      </HStack>
    )
  }

  _renderBoxFooterColLeft = (paymentInfo) => {
    const {
      paymentId,
      status,
      txtPaymentStatus,
      isReadOnly,
      payerIsMe,
      isGBPay,
      rejectedAt,
      bankId,
      hasVerifySlip,
      isVerifySlipSuccess,
      isVerifySlipDangerError,
    } = paymentInfo
    const isSubmitting = this._getSubmittingByPaymentId(paymentId)
    if (payerIsMe && status === 0 && this.hasConfirmPaymentPermission) {
      return (
        <HStack flex={1} alignItems='center'>
          {/* <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_INACTIVE]}>{'[ปุ่มยกเลิก]'}</XText> */}
          {isSubmitting ? (
            this._renderSubmittingSpinner()
          ) : (
            <DecisionButton id={paymentId} onPress={this.cancelPayment} actionToStatus={DECISION_STATUS.CANCEL} />
          )}
        </HStack>
      )
    }

    const txtStatusColorStyle = this._getTxtStyleByPaymentStatus(status, rejectedAt)
    let color = COLORS.BRAND_Success
    if (bankId === xCONS.SYSTEM_BANK_IDS.GBPAY) {
      color = COLORS.GB_PAY_MAIN
    } else if (bankId === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE) {
      color = COLORS.SCB_MAIN
    }
    const showMarkerCheckIcon =
      bankId === xCONS.SYSTEM_BANK_IDS.GBPAY || bankId === xCONS.SYSTEM_BANK_IDS.SCB_QR_CODE || bankId === xCONS.SYSTEM_BANK_IDS.BEAM
    return isReadOnly || status > 0 ? (
      <HStack flex={1} space='2' alignItems='center'>
        {hasVerifySlip ? (
          <VerifySlipIcon
            success={isVerifySlipSuccess}
            warning={!isVerifySlipSuccess && !isVerifySlipDangerError}
            danger={!isVerifySlipSuccess && isVerifySlipDangerError}
          />
        ) : null}
        <XText style={txtStatusColorStyle} bold>
          {txtPaymentStatus}
        </XText>
        {showMarkerCheckIcon && status === 1 ? (
          <XIcon
            activeDark
            family='MaterialCommunityIcons'
            name='marker-check'
            style={StyleSheet.flatten([s.TXT_COLOR_SUCCESS, { color }])}
          />
        ) : null}
      </HStack>
    ) : null
  }

  _onPressReVerifySlip = async (paymentId: number) => {
    if (this.isReVerifySlipButtonPressedMap[paymentId]) {
      return
    }
    this.isReVerifySlipButtonPressedMap[paymentId] = true
    const fetchingKey = this.getReverifyFetchingKey(paymentId)
    const preState = { refreshing: true }
    preState[fetchingKey] = true
    await util.setStatePromise(this, preState)

    try {
      const storeId = util.getNavParam(this.props, 'store_id')
      const isSuccess = await fetchReVerifySlip({ store_id: storeId, payment_id: paymentId })

      if (isSuccess) {
        await this.onRefresh()
      }
    } catch (err) {
      console.log('_onPressReVerifySlip err =>', JSON.stringify(err))
    }

    await util.delay(500)
    const postState = { refreshing: false }
    postState[fetchingKey] = false
    await util.setStatePromise(this, postState)
    this.isReVerifySlipButtonPressedMap[paymentId] = false
  }

  getReverifyFetchingKey = (paymentId: number) => `reverify_fetching_${paymentId}`

  _renderReVerifySlipBar = (pInfo) => {
    const { refreshing, myPaymentAccounts } = this.state

    // ซ่อน ถ้า payment จบไปแล้ว
    if (pInfo.status !== OPENED) {
      return null
    }

    // ซ่อน ถ้าฉันเป็นผู้บันทึกชำระ ที่ไม่ได้เป็นของร้านฉัน
    if (pInfo.payerIsMe && pInfo.payerStoreId !== pInfo.payeeStoreId) {
      return null
    }

    // ซ่อน ถ้าไม่มี perm
    // if (!this.hasConfirmPaymentPermission) {
    //   return null
    // }

    // ซ่อน ถ้าไม่ใช่ bank account ที่ support verify slip
    const isBankAcoountForVerifySlip = util.isVerifySlipBankID(pInfo.bankId)
    if (!isBankAcoountForVerifySlip) {
      return null
    }

    if (!pInfo.paymentAccountId) {
      return null
    }

    const foundMyPaymentAccount = myPaymentAccounts.find((pa) => pa.id === pInfo.paymentAccountId)
    if (foundMyPaymentAccount && !foundMyPaymentAccount.is_verify_slip_active) {
      // ถ้าพบว่าเป็น payment account ที่ไม่สามารถ verify slip ได้จะไม่เปิดให้ทำการ re-verify slip
      return null
    }

    const isFetching = this.state[this.getReverifyFetchingKey(pInfo.paymentId)] || false
    return (
      <HStack w='full' p='1.5' space='1' justifyContent='flex-end' borderTopWidth='1' borderTopColor='muted.200'>
        <VStack flex={1}>
          <XText variant='inactive'>
            สลิปไม่ผ่านการตรวจสอบ กรุณาตรวจสอบว่า เครดิตตรวจสลิปยังไม่หมด และตรวจดูรูปสลิปว่ามี QR และข้อมูลถูกต้องหรือไม่
            ถ้าใช่คุณสามารถกดปุ่ม "ตรวจสลิปอัตโนมัติ" เพื่อให้ระบบตรวจสอบสลิปอีกครั้ง
          </XText>
        </VStack>

        <HStack w='170px' alignItems='center' justifyContent='flex-end'>
          <TouchableOpacity disabled={isFetching} onPress={() => this._onPressReVerifySlip(pInfo.paymentId)}>
            <HStack
              w='170px'
              px='2'
              py='1'
              borderWidth='1'
              borderRadius='lg'
              borderColor={isFetching ? 'muted.400' : '#004071'}
              alignItems='center'
              space='1.5'>
              {isFetching ? <XSpinner variant='inactive' /> : <VerifySlipIcon blue />}
              <XText flex={1} bold textAlignVertical='center' textAlign='center' color={isFetching ? 'muted.500' : '#004071'}>
                ตรวจสลิปอัตโนมัติ
              </XText>
            </HStack>
          </TouchableOpacity>
        </HStack>
      </HStack>
    )
  }

  _renderSubmittingSpinner = () => <Spinner color={COLORS.TEXT_INACTIVE} size='sm' style={s.SPINNER} />

  _getTxtStyleByPaymentStatus = (status: 0 | 1 | 2, rejectedAt?: string) => {
    let txtStatusColorStyle
    switch (status) {
      case CONFIRMED:
        txtStatusColorStyle = { color: COLORS.BRAND_Success }
        break
      case REJECTED_OR_CANCELED:
        if (!_.isNil(rejectedAt)) {
          txtStatusColorStyle = { color: COLORS.BRAND_Danger }
        } else {
          // is canceledAt
          txtStatusColorStyle = { color: COLORS.TEXT_INACTIVE }
        }
        break
      default:
        txtStatusColorStyle = { color: COLORS.TEXT_INACTIVE }
    }
    return txtStatusColorStyle
  }

  _renderBoxFooterColRight = (paymentInfo) => {
    const { paymentId, status, txtPaymentStatus, isReadOnly, payerIsMe, txtDoneAt, isRefund } = paymentInfo
    const isSubmitting = this._getSubmittingByPaymentId(paymentId)
    // const footerRightView =
    //   this.hasConfirmPaymentPermission && !payerIsMe ? (
    //     <HStack flex={1} alignItems='center' justifyContent='flex-end'>
    //       {/* <VStack size={100} /> */}
    //       <DecisionButton id={paymentId} onPress={this.rejectPayment} actionToStatus={DECISION_STATUS.REJECT} />
    //       <DecisionButton id={paymentId} onPress={this.confirmPayment} actionToStatus={DECISION_STATUS.CONFIRM} />
    //     </HStack>
    //   ) : payerIsMe ? null : (
    //     <HStack flex={1} alignItems='center' justifyContent='flex-end'>
    //       <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_PRIMARY]}>{'การชำระนี้ยังไม่ได้รับ\nการยืนยันจากผู้มีสิทธิ์'}</XText>
    //     </HStack>
    //   )

    // payment'status 0 = not yet confirm, 1 = confirmed
    if (!this.hasConfirmPaymentPermission && status === 0) {
      return (
        <HStack flex={1} alignItems='center' justifyContent='flex-end'>
          <XText style={[s.TXT_SIZE_NORMAL, s.TXT_COLOR_PRIMARY]}>{'การชำระนี้ยังไม่ได้รับ\nการยืนยันจากผู้มีสิทธิ์'}</XText>
        </HStack>
      )
    }

    const footerRightView = (
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        {/* <VStack size={100} /> */}
        <DecisionButton id={paymentId} onPress={this.rejectPayment} actionToStatus={DECISION_STATUS.REJECT} />
        <DecisionButton id={paymentId} onPress={this.confirmPayment} actionToStatus={DECISION_STATUS.CONFIRM} />
      </HStack>
    )

    // if (!isReadOnly && status === 0 && !(payerIsMe && isRefund)) {
    if (!isReadOnly && status === 0 && !payerIsMe) {
      return (
        <HStack flex={1} alignItems='center' justifyContent='flex-end'>
          {isSubmitting ? <View style={S.ROW_CENTER}>{this._renderSubmittingSpinner()}</View> : footerRightView}
        </HStack>
      )
    }

    const txtStatusColorStyle = this._getTxtStyleByPaymentStatus(status)

    const rightItems =
      payerIsMe && status === 0 ? (
        <XText style={txtStatusColorStyle}>{txtPaymentStatus}</XText>
      ) : (
        <XText variant='inactive'>{txtDoneAt}</XText>
      )

    return (
      <HStack flex={1} alignItems='center' justifyContent='flex-end'>
        {rightItems}
      </HStack>
    )
  }

  _goBack = () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const isFinishBackgroundTasks = this.isFinishBackgroundTasks()
    if (!isFinishBackgroundTasks) {
      p.op.alert('มีการดำเนินการชำระ', 'กรุณารอให้ดำเนินการเสร็จสิ้น แล้วลองใหม่อีกครั้ง')
      this.inProcess = false
      return
    }

    util.navGoBack(this.props)
    setTimeout(() => {
      if (!this.isUnmounting) {
        this.inProcess = false
      }
    }, 2000)
  }

  _getFixedAndroidMarginTopStyle = () => {
    if (Platform.OS !== 'android') {
      return {}
    }
    return this.isFromOrderDetial() ? { marginTop: 0 } : {}
  }

  // _renderCustomHeader = () => {
  //   const { navigation } = this.props
  //   const btnRight = util.getNavParam(this.props, 'btnRight')
  //   let headerRightProps = null
  //   if (btnRight && _.isFunction(btnRight.onPress)) {
  //     headerRightProps = {
  //       addIcon: true,
  //       onPressItem: btnRight.onPress,
  //     }
  //   }
  //   // const fixedAndroidMarginTopStyle = this._getFixedAndroidMarginTopStyle()
  //   return (
  //     <XCustomHeader
  //       // headerStyle={fixedAndroidMarginTopStyle}
  //       headerLeftProps={{ backIcon: true, onPressItem: this._goBack }}
  //       headerRightProps={headerRightProps}
  //       title={this._getTxtHeaderTitle()}
  //     />
  //   )
  // }

  _renderRefreshControl = () => {
    const { isInitialized, refreshing } = this.state
    if (!isInitialized) {
      return null
    }
    return <RefreshControl onRefresh={this.onRefresh} refreshing={refreshing} />
  }

  // _renderXGalleryModal = () => {
  //   const { previewImageUris, previewVisible } = this.state
  //   return <XGalleryModal isVisible={previewVisible} onRequestClose={this._clearPreviewImages} images={previewImageUris} />
  // }

  renderCustomHeader = () => {
    // return null
    const { navigation } = this.props
    const btnRight = util.getNavParam(this.props, 'btnRight')
    let headerRightProps = null
    if (btnRight && _.isFunction(btnRight.onPress)) {
      headerRightProps = {
        addIcon: true,
        onPressItem: btnRight.onPress,
      }
    }

    const isGoBackButtonHidden = this.getIsGoBackButtonHidden()
    // const fixedAndroidMarginTopStyle = this._getFixedAndroidMarginTopStyle()
    return (
      <XCustomHeader
        // headerStyle={fixedAndroidMarginTopStyle}
        headerLeftProps={isGoBackButtonHidden ? undefined : { backIcon: true, onPressItem: this._goBack }}
        headerRightProps={headerRightProps}
        title={this._getTxtHeaderTitle()}
      />
    )
  }

  renderMain = () => (
    <XContainer style={s.CONTAINER}>
      {this.renderCustomHeader()}
      <XContent refreshControl={this._renderRefreshControl()}>
        <VStack w='full' p='2' space='2'>
          {this._renderPaymentList()}
        </VStack>
      </XContent>
      {this.renderXGalleryModal()}
    </XContainer>
  )

  render() {
    logRender(this)
    return this.renderMain()
  }
}

const s = StyleSheet.create({
  CONTAINER: {
    backgroundColor: COLORS.BG_LIGHT_GREY,
  },
  CONTENT_CONTAINER_WEB: {
    ...Platform.select({
      web: {
        paddingLeft: 4,
        paddingRight: xCONS.SPECIFIC_PLATFORM.WEB.SCROLLBAR_WIDTH,
      },
    }),
  },
  CONTAINER_CONTENT: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  CARD_ITEM_HEADER: {
    alignItems: 'center',
    // paddingHorizontal: 6,
    paddingTop: p.op.isWeb() ? 6 : 3,
    paddingBottom: p.op.isWeb() ? 6 : 3,
    paddingLeft: p.op.isWeb() ? 12 : 6,
    paddingRight: p.op.isWeb() ? 12 : 6,
    flexWrap: 'wrap',
    minHeight: 30,
  },
  CARD_ITEM: {
    alignItems: 'flex-start',
    // paddingHorizontal: 6,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 3,
    paddingBottom: 3,
    flexWrap: 'wrap',
  },
  CARD_ITEM_FOOTER: {
    alignItems: 'center',
    // paddingHorizontal: 6,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 8,
    paddingBottom: 0,
    flexWrap: 'wrap',
    minHeight: 42,
  },
  CONTAINER_BLANK_TEXT: {
    flex: 1,
    height: 400,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    // backgroundColor: 'red',
  },
  PAY_NORMAL: {
    color: COLORS.FORM_SUCCESS,
    fontSize: STYLES.FONT_SIZE_NORMAL,
    fontWeight: 'bold',
  },
  PAY_REFUND: {
    color: 'red',
    fontSize: STYLES.FONT_SIZE_NORMAL,
    fontWeight: 'bold',
  },
  BTN_ICON: {
    width: 38,
    height: 38,
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
    borderRadius: 5,
  },
  ICON_IN_BUTTON: {
    color: 'white',
    fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_ICON_SMALLER : STYLES.FONT_ICON_NORMAL,
    width: DEVICE_WIDTH <= 320 ? STYLES.FONT_ICON_SMALLER : STYLES.FONT_ICON_NORMAL,
    height: DEVICE_WIDTH <= 320 ? STYLES.FONT_ICON_SMALLER : STYLES.FONT_ICON_NORMAL,
    fontWeight: 'bold',
    textAlign: 'center',
    // paddingTop: 2,
    // paddingLeft: 2,
    paddingRight: 0,
  },
  TXT_BOLD: {
    fontWeight: 'bold',
  },
  TXT_COLOR_INACTIVE: {
    color: COLORS.TEXT_INACTIVE,
  },
  TXT_COLOR_ACTIVE: {
    color: COLORS.TEXT_ACTIVE,
  },
  TXT_COLOR_ACTIVE_DARK: {
    color: 'white',
  },
  TXT_SIZE_SMALLER: {
    fontSize: 10,
  },
  TXT_COLOR_PRIMARY: {
    color: COLORS.APP_MAIN,
  },
  TXT_COLOR_SUCCESS: {
    color: COLORS.BRAND_Success,
  },
  TXT_HEADER_BOLD_SUCCESS: {
    // fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_NORMAL : STYLES.FONT_SIZE_LARGER,
    color: COLORS.BRAND_Success,
    fontWeight: 'bold',
  },
  TXT_COLOR_DANGER: {
    color: COLORS.BRAND_Danger,
  },
  TXT_HEADER_BOLD_DANGER: {
    // fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_NORMAL : STYLES.FONT_SIZE_LARGER,
    color: COLORS.BRAND_Danger,
    fontWeight: 'bold',
  },
  TXT_NORMAL_BOLD_INACTIVE: {
    // fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    color: COLORS.TEXT_INACTIVE,
    fontWeight: 'bold',
  },
  TXT_SIZE_NORMAL: {
    // fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_SMALLER : STYLES.FONT_SIZE_NORMAL,
    // height: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_SMALLER + 2.5 : STYLES.FONT_SIZE_NORMAL + 2.5,
  },
  TXT_SIZE_HEADER: {
    // fontSize: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_NORMAL : STYLES.FONT_SIZE_LARGER,
    // height: DEVICE_WIDTH <= 320 ? STYLES.FONT_SIZE_NORMAL + 3.5 : STYLES.FONT_SIZE_LARGER + 3.5,
  },
  // ====
  BTN_SLIP: {
    borderColor: COLORS.FORM_PRIMARY,
    backgroundColor: COLORS.BG_LIGHT_GREY,
    width: 38,
    height: 38,
    borderRadius: 5,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  TXT_SLIP: {
    fontWeight: 'bold',
    fontSize: STYLES.FONT_SIZE_SMALLER,
    color: COLORS.APP_MAIN,
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
    textAlign: 'center',
  },

  BTN_DECISION: {
    // width: 38,
    // height: 40,
    // height: 44,
    borderRadius: 5,
    // borderWidth: 1,
    // flexDirection: 'row',
    // justifyContent: 'center',
    // alignItems: 'center',
    // backgroundColor: 'white',
    // marginRight: 4,
    // width: 80,
  },
  BTN_DECISION_CANCEL: {
    borderRadius: 5,
    borderWidth: 1,
    backgroundColor: COLORS.BRAND_Danger,
    paddingBottom: 3,
    paddingTop: 3,
    // marginBottom: p.op.isWeb() ? 0 : 6,
  },
  BTN_BG_SUCCESS: {
    borderColor: COLORS.BRAND_Success,
    backgroundColor: COLORS.BRAND_Success,
  },
  BTN_BG_DANGER: {
    borderColor: COLORS.BRAND_Danger,
    borderTopColor: COLORS.BRAND_Danger,
    borderLeftColor: COLORS.BRAND_Danger,
    borderBottomColor: COLORS.BRAND_Danger,
    borderRightColor: COLORS.BRAND_Danger,
  },
  BTN_BG_INACTIVE: {
    borderColor: COLORS.TEXT_INACTIVE,
  },
  ROW_MIDDLE_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  COL_MIDDLE_LEFT: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
  },
  ROW_BOTTOM_LEFT_PADDING_BOTTOM: {
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    paddingBottom: 1.5,
  },
  COL_MIDDLE_RIGHT: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    flex: 1,
  },
  COL_TOP: {
    justifyContent: 'flex-start',
  },
  NO_FLEX: {
    flex: p.op.isWeb() ? 1 : 0,
  },
  BORDER_LINE_TOP: {
    borderTopWidth: 0.5,
    borderTopColor: COLORS.TEXT_INACTIVE,
  },
  BORDER_LINE_TOP_THIN: {
    borderTopWidth: 1,
    borderTopColor: COLORS.BG_LIGHT_GREY,
  },
  FIX_HEIGHT_15: {
    height: 15,
  },
  TXT_STRIKE_THROUGH: {
    textDecorationLine: 'line-through',
  },
  SPINNER: {
    fontSize: STYLES.FONT_SIZE_SMALLER,
    width: STYLES.FONT_SIZE_SMALLER,
    height: 34,
    alignSelf: 'center',
    textAlign: 'center',
  },
})

interface ISlipButtonProps {
  imgUris: string[]
  onPress: (imgUris: string[]) => void
}

class SlipButton extends PureComponent<ISlipButtonProps> {
  _onPress = () => {
    const { onPress, imgUris = [] } = this.props
    if (_.isFunction(onPress)) {
      onPress(imgUris)
    }
  }

  render() {
    return (
      <XButton variant='outline' onPress={this._onPress}>
        ดูสลิป
      </XButton>
    )
  }
}

interface IDecisionButtonProps {
  id: number
  actionToStatus: number // 1 | 2 | 3
  onPress: (paymentId: number) => void
}

class DecisionButton extends PureComponent<IDecisionButtonProps> {
  _onPress = () => {
    const { onPress, id = [] } = this.props
    if (_.isFunction(onPress)) {
      onPress(id)
    }
  }

  _renderIcon = () => {
    const { actionToStatus } = this.props
    switch (actionToStatus) {
      case DECISION_STATUS.CONFIRM:
        return (
          <XIcon
            name='checkmark'
            activeDark
            //  style={StyleSheet.flatten(s.ICON_IN_BUTTON)}
          />
        )
      case DECISION_STATUS.REJECT:
        return (
          <XIcon
            name='close'
            danger
            // style={StyleSheet.flatten([s.ICON_IN_BUTTON, s.TXT_COLOR_DANGER])}
          />
        )
      case DECISION_STATUS.CANCEL:
        return null
      // return <Icon name='not-interested' type={'MaterialIcons'} style={[s.ICON_IN_BUTTON, s.TXT_COLOR_INACTIVE]} />
    }
    return null
  }

  _renderText = () => {
    const { actionToStatus } = this.props
    switch (actionToStatus) {
      case DECISION_STATUS.CONFIRM:
        return 'ยืนยัน'
      case DECISION_STATUS.REJECT:
        return 'ปฏิเสธ'
      case DECISION_STATUS.CANCEL:
        return 'ยกเลิกการชำระนี้'
    }
    return null
  }

  _getBtnStyle = () => {
    const { actionToStatus } = this.props
    switch (actionToStatus) {
      case DECISION_STATUS.CONFIRM:
        return [s.BTN_DECISION, s.BTN_BG_SUCCESS]
      case DECISION_STATUS.REJECT:
        return [S.BORDER, s.BTN_DECISION, s.BTN_BG_DANGER, { marginRight: 5 }]
      case DECISION_STATUS.CANCEL:
        return [s.BTN_DECISION_CANCEL, s.BTN_BG_DANGER]
    }
    return {}
  }

  _getTxtColorStyle = () => {
    const { actionToStatus } = this.props
    switch (actionToStatus) {
      case DECISION_STATUS.CONFIRM:
        return s.TXT_COLOR_ACTIVE_DARK
      case DECISION_STATUS.REJECT:
        return s.TXT_COLOR_DANGER
      case DECISION_STATUS.CANCEL:
        // return [s.TXT_COLOR_ACTIVE_DARK, s.TXT_SIZE_SMALLER]
        return [s.TXT_COLOR_ACTIVE_DARK]
    }
    return s.TXT_COLOR_ACTIVE
  }

  render() {
    return (
      <TouchableOpacity onPress={this._onPress} style={this._getBtnStyle()}>
        <HStack h='34' px='3' py='1' space='1' alignItems='center'>
          {this.props.actionToStatus === DECISION_STATUS.CANCEL ? null : this._renderIcon()}
          <XText bold style={[this._getTxtColorStyle()]}>
            {this._renderText()}
          </XText>
        </HStack>
      </TouchableOpacity>
    )
  }
}
