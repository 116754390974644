import { connect } from 'react-redux'
import { getSelectedStore, getSubscription } from 'x/redux/selectors'
import * as StoreActions from 'x/modules/store/StoreState'
import * as BankActions from 'x/modules/bank/BankState'
import { bindActionCreators } from 'redux'
import ReportPayment from './ReportPayment'

export default connect(
  (state) => ({
    selectedStore: getSelectedStore(state),
    subscription: getSubscription(state),
    // myStores: getMyStores(state),
  }),
  (dispatch) => ({
    // searchMumber: bindActionCreators(profileActions.fetchProfileList, dispatch),
    fetchAccounts: bindActionCreators(BankActions.fetchStorePaymentAccounts, dispatch),
    getPermissionMemberList: bindActionCreators(StoreActions.getPermissionMemberList, dispatch),
    dispatch,
  })
)(ReportPayment)
