/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react'
import _ from 'lodash'
import { Map } from 'immutable'
import {
  IDateRange,
  IMemberHelperList,
  IXCheckboxListOption,
  IXDateRangeSelectorChangeParams,
  IXDateRangeSelectorOptionKey,
  IXScreenProps,
} from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import dayjs from 'dayjs'

export interface IBaseReportWarehouseAdjustment extends IXScreenProps {
  selectedStore: Map<string, any>
  subscription: any
  getPermissionMemberList: any
}

export interface IBaseReportWarehouseAdjustmentState {
  isLoading: boolean
  loadingWidth: number
  loadingHeight: number
  createdByIndex: number

  checkboxReportOptionList: Array<IXCheckboxListOption>
  createdAtDateRange: IDateRange
  createdAtRangeOptionKey: IXDateRangeSelectorOptionKey

  helpers: IMemberHelperList[]

  addAndSubtractIndex: number
}

const CHECKBOX_LIST_OPTION_KEY = {
  SKU: 'SKU',
  UPC: 'UPC',
  DESC: 'DESC',
  PRICES: 'PRICES',
  CATEGORY: 'CATEGORY',
}

export default abstract class BaseReportWarehouseAdjustment extends React.Component<
  IBaseReportWarehouseAdjustment,
  IBaseReportWarehouseAdjustmentState
> {
  abstract _handleOnDownloadFile(url: string, fileName: string): Promise<void>

  protected constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      loadingHeight: 0,
      loadingWidth: 0,
      createdByIndex: 0,
      checkboxReportOptionList: null,
      createdAtDateRange: { begin: dayjs(dayjs().format('YYYY-MM-DD 00:00:00')), end: dayjs(dayjs().format('YYYY-MM-DD 23:59:59')) },
      createdAtRangeOptionKey: 'Custom',
      helpers: null,
      addAndSubtractIndex: 0,
    }
  }

  async componentDidMount() {
    const checkboxReportOptionList = [
      {
        label: 'รหัสสินค้า SKU',
        isChecker: false,
        value: CHECKBOX_LIST_OPTION_KEY.SKU,
      },
      {
        label: 'รหัสสินค้า UPC',
        isChecker: false,
        value: CHECKBOX_LIST_OPTION_KEY.UPC,
      },
    ]
    await util.setStatePromise(this, {
      checkboxReportOptionList,
    })
    // this._fetchHelpers()
  }

  _getCheckboxListOptionFromLatest = async () => {
    // TODO :: อยากเพิ่มตัวเลือกเพอ่มทำยังไง => ให้ค้นหาคำว่า ADD_STORAGE แล้วเพิ่มข้อมูลตามลำดับ
    // ADD_STORAGE 01 => เพิ่มค่าเริ่มต้นใน let STORAGE
    let STORAGE = {
      SKU: false,
      UPC: false,
      DESC: false,
      PRICES: false,
      CATEGORY: false,
    }
    await p.op.storageGet(CONS.STORAGE_KEYS.REPORT_STOCK).then((val: any) => {
      // console.log('val => ', val)
      if (!_.isNil(val)) {
        // ADD_STORAGE 02 => เพิ่มค่าในนี้ด้วย เพื่อเอามาจากที่จำครั้งล่าสุดที่ user เคยใช้
        STORAGE = {
          SKU: _.isNil(val.SKU) ? false : val.SKU,
          UPC: _.isNil(val.UPC) ? false : val.UPC,
          DESC: _.isNil(val.DESC) ? false : val.DESC,
          PRICES: _.isNil(val.PRICES) ? false : val.PRICES,
          CATEGORY: _.isNil(val.CATEGORY) ? false : val.CATEGORY,
        }
      }
    })
    // ADD_STORAGE 03 => เพิ่ม DATA ในนี้เพื่อ UI แสดงผล *อย่าลืมเพิ่ม key ใน value ที่ CHECKBOX_LIST_OPTION_KEY
    const DEFAULT_CHECKBOX_LIST_OPTION: IXCheckboxListOption[] = [
      {
        label: 'รหัสสินค้า SKU',
        isChecker: STORAGE.SKU,
        value: CHECKBOX_LIST_OPTION_KEY.SKU,
      },
      {
        label: 'รหัสสินค้า UPC',
        isChecker: STORAGE.UPC,
        value: CHECKBOX_LIST_OPTION_KEY.UPC,
      },
      {
        label: 'คำอธิบายสินค้า',
        isChecker: STORAGE.DESC,
        value: CHECKBOX_LIST_OPTION_KEY.DESC,
      },
      {
        label: 'ราคา',
        isChecker: STORAGE.PRICES,
        value: CHECKBOX_LIST_OPTION_KEY.PRICES,
      },
      {
        label: 'หมวดหมู่สินค้า',
        isChecker: STORAGE.CATEGORY,
        value: CHECKBOX_LIST_OPTION_KEY.CATEGORY,
      },
    ]
    await util.setStatePromise(this, {
      // checkboxListOption: DEFAULT_CHECKBOX_LIST_OPTION,
    })
  }

  onChangeCheckboxOption = async (selectedOption: IXCheckboxListOption[], selectedIndex: number) => {
    // console.log('selectedOption => ', selectedOption)
    // console.log('selectedIndex => ', selectedIndex)
    await util.setStatePromise(this, {
      checkboxReportOptionList: selectedOption,
    })
  }

  _onChangeSegmentCreatedByIndex = async (newIndex: number) => {
    if (newIndex === 1) {
      await this._fetchHelpers()
    }

    await util.setStatePromise(this, {
      createdByIndex: newIndex,
    })
    // this.setState({
    //   createdByIndex: newIndex,
    // })
  }

  _onChangeDateCreatedAtFromTo = async (params: IXDateRangeSelectorChangeParams) => {
    const { dates, option, optionKey } = params
    const { createdAtDateRange } = this.state
    const newCreatedAtDateRange = _.clone(createdAtDateRange)
    // const newState: Partial<IProductListFilterViewState> = {}
    newCreatedAtDateRange.begin = dates.begin
    newCreatedAtDateRange.end = util.changeSecondTime(dates.end, 59)
    // newState.createdAtRangeOptionKey = optionKey
    await util.setStatePromise(this, {
      createdAtDateRange: newCreatedAtDateRange,
      createdAtRangeOptionKey: optionKey,
    })
  }

  _fetchHelpers = async () => {
    const { selectedStore, getPermissionMemberList } = this.props
    // console.log('selectedStore => ', selectedStore.toJS())
    const { helpers } = this.state
    if (!_.isNil(helpers)) {
      return
    }
    // if (!_.isFunction(getPermissionMemberList)) {
    //   return
    // }
    // await xUtil.setStatePromise(this, { loading: true })
    let respone = null
    await new Promise((resolve) => {
      getPermissionMemberList({
        body: {
          store_id: selectedStore.get('id'),
        },
        successCallback: (res) => {
          respone = res
          resolve(null)
        },
        failedCallback: resolve,
      })
    })
    const newHelpers = respone.helpers
    await util.setStatePromise(this, { helpers: newHelpers })
  }

  _onSeletedHelper = (data: IMemberHelperList) => {
    const { helpers } = this.state
    const newHelper = []
    helpers.forEach((acc: IMemberHelperList) => {
      if (data.i === acc.i) {
        if (_.isNil(acc.seleted) || !acc.seleted) {
          acc.seleted = true
        } else {
          acc.seleted = false
        }
      } else {
        acc.seleted = false
      }
      newHelper.push(acc)
    })
    util.setStatePromise(this, { helpers: newHelper })
  }

  _showConfirmationDownloadReport = async () => {
    const { selectedStore, subscription } = this.props
    const { createdAtDateRange, addAndSubtractIndex, helpers, checkboxReportOptionList, createdByIndex } = this.state

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    const isNoobIos = util.isIosNoobCheckByPass()
    // if (isNoobIos) {
    //   p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
    //   return
    // }
    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy21qt
    if (!isNoobIos && util.isPackFreeAndSelectedStoreOwner()) {
      p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
      // this.inProcess = false
      // await util.setStatePromise(this, { downloadingReport: false })
      return
    }

    // TODO: Check for perm on BaseReportProductDailySales line 335?
    // const isHlper = util.isHelper()
    // // if (!isHlper && util.isStoreOwner() && subscription.get('type') === CONS.SUBSCRIPTION_PACKAGE.FREE) {
    // if (!isHlper && util.isStoreOwner()) {
    //   p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
    //   return
    // }

    // ADD_STORAGE 04 => เพิ่มค่าใหม่ใน STORAGE ตรงนี้ด้วย
    const STORAGE = {
      SKU: false,
      UPC: false,
      DESC: false,
      PRICES: false,
      CATEGORY: false,
    }
    // ADD_STORAGE 05 => เพิ่ม else if ค่าใหม่ด้วย เพื่อจำข้อมูลล่าสุดเมื่อดาวโหลด
    // checkboxListOption.map((val: IXCheckboxListOption) => {
    //   // console.log('val 01 => ', val)
    //   if (val.value === CHECKBOX_LIST_OPTION_KEY.SKU) {
    //     STORAGE.SKU = val.isChecker
    //   } else if (val.value === CHECKBOX_LIST_OPTION_KEY.UPC) {
    //     STORAGE.UPC = val.isChecker
    //   } else if (val.value === CHECKBOX_LIST_OPTION_KEY.DESC) {
    //     STORAGE.DESC = val.isChecker
    //   } else if (val.value === CHECKBOX_LIST_OPTION_KEY.PRICES) {
    //     STORAGE.PRICES = val.isChecker
    //   } else if (val.value === CHECKBOX_LIST_OPTION_KEY.CATEGORY) {
    //     STORAGE.CATEGORY = val.isChecker
    //   }
    // })

    // const newCheckboxReportStyleListOption = {
    //   REPORT_STYLE: false,
    // }
    // checkboxReportStyleListOption.map((val: IXCheckboxListOption) => {
    //   // console.log('val => ', val)
    //   if (val.value === 'REPORT_STYLE') {
    //     newCheckboxReportStyleListOption.REPORT_STYLE = val.isChecker
    //   }
    // })

    // await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_STOCK, STORAGE)
    // await p.op.storageSet(CONS.STORAGE_KEYS.REPORT_STOCK_REPORT_STYLE, newCheckboxReportStyleListOption)
    const store_id = selectedStore.get(`id`)
    // const startDate = dayjs().format('DD-MM-YYYY_hh-mm')
    // console.log('createdAtDateRange => ', createdAtDateRange)
    const created_at_from = dayjs(createdAtDateRange.begin).format('YYYY-MM-DD%20HH:mm')
    const created_at_to = dayjs(createdAtDateRange.end).format('YYYY-MM-DD%20HH:mm')

    const from_filename = dayjs(createdAtDateRange.begin).format('YYYY-MM-DD_HH-mm')
    const to_filename = dayjs(createdAtDateRange.end).format('YYYY-MM-DD_HH-mm')

    // console.log('created_at_from => ', created_at_from)
    // console.log('created_at_to => ', created_at_to)
    let action_type = 'all'
    if (addAndSubtractIndex === 1) {
      action_type = 'add'
    } else if (addAndSubtractIndex === 2) {
      action_type = 'subtract'
    } else if (addAndSubtractIndex === 3) {
      action_type = 'cancel_shipping'
    }

    let created_by_hepler_profile_ids = null
    if (!_.isNil(helpers)) {
      helpers.forEach((helper) => {
        if (helper.seleted) {
          created_by_hepler_profile_ids = [helper.i]
        }
      })
    }
    if (_.isNil(created_by_hepler_profile_ids) && createdByIndex === 2) {
      p.op.showConfirmationOkOnly('', 'กรุณาเลือกผู้ช่วย')
      return
    }

    let created_by_role = 'all'
    if (createdByIndex === 1) {
      created_by_role = 'owner'
    }
    if (createdByIndex === 2) {
      created_by_role = 'helper'
    }

    let showSKU = false
    let showUPC = false
    checkboxReportOptionList.forEach((option) => {
      if (option.value === CHECKBOX_LIST_OPTION_KEY.UPC && option.isChecker) {
        showUPC = true
      }
      if (option.value === CHECKBOX_LIST_OPTION_KEY.SKU && option.isChecker) {
        showSKU = true
      }
    })

    const warehouses = selectedStore.get('warehouses').toJS()
    // console.log('warehouses => ', warehouses)
    // console.log('warehouses => ', warehouses[0].id)
    // return
    const url = util.getReportWarehouseAdjustmentUrl({
      store_id,
      warehouse_ids: warehouses[0].id,
      created_at_from,
      created_at_to,
      action_type,
      created_by_hepler_profile_ids,
      showSKU,
      showUPC,
      created_by_role,
    })
    console.log('url => ', url)
    const fileName = `stock_history_${action_type}_from_${from_filename}_to_${to_filename}.xlsx`
    this._handleOnDownloadFile(url, fileName)
  }
}
