import React, { Component } from 'react'
import { Text, Spinner } from 'native-base'

import { View } from 'react-native'

import { isDiffAccuracy } from 'x/utils/util'
import { STYLES, COLORS } from 'x/config/styles'
import xCONS from 'x/config/constants'
import { Map, isImmutable } from 'immutable'
import { UserInUserGroupsMap } from 'x/index'
import p from 'x/config/platform-specific'
import _ from 'lodash'

import BaseResellerListTab from 'x/modules/userGroup/BaseResellerListTab'

import * as NavActions from 'x/utils/navigation'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { APP_CONFIG } from 'x/config/mode'
import ForwardIcon from '../../components/ForwardIcon'

export default class ResellerListJoinedTab extends BaseResellerListTab {
  static displayName = 'ResellerListJoinedTab'

  ACTION_SHEET_OPTIONS: {
    title: string
    options: string[]
    destructiveButtonIndex: number
    cancelButtonIndex: number
  }

  // Begin Abstract
  constructorExtended(that) {
    // Nothing
    // that.ACTION_SHEET_OPTIONS = {
    //   title: 'จัดการสมาชิก...',
    //   options: ['ย้ายสมาชิก', 'ไล่ออกจากร้าน', 'ยกเลิก'],
    //   destructiveButtonIndex: 1,
    //   cancelButtonIndex: 2,
    // }
  }

  doAfterRefresh(): void {}

  getListType(): 'p' | 'j' | 'b' {
    return 'j'
  }

  _onPressUserItem = (arg: { index?: number; data?: UserInUserGroupsMap }): void => {
    if (!APP_CONFIG.user_group_member_list_view.show_manage_member_menu) {
      return
    }
    if (!canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.UG_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขกลุ่มสมาชิก')
      return
    }
    if (this._inProcess) {
      return
    }
    const { index, data } = arg
    if (!data || !Map.isMap(data)) {
      return
    }
    if (data.get('kicked')) {
      p.op.showToast('ผู้ใช้ถูกไล่ออกจากร้านเรียบร้อยแล้ว', 'warning')
      return
    }
    if (data.get('changedGroup')) {
      p.op.showToast('ปัจจุบันผู้ใช้ไม่อยู่ในกลุ่มนี้แล้ว', 'warning')
      return
    }
    this._inProcess = true
    const clearInProcess = () =>
      setTimeout(() => {
        this._inProcess = false
      }, 100)

    if (!_.isNumber(index) || !Map.isMap(data)) {
      clearInProcess()
      return
    }
    // @ts-ignore
    this.props.navigation.dispatch(
      NavActions.navToResellerManageView({
        ug_id: this.props.selectedUserGroupId,
        selectedReseller: isImmutable(data) ? data.toJS() : data, // โดน navigation แปลงเป็น .toJS() เอง
        callBackUpdateResellerName: () => this._fetchUserList(),
      })
    )
    this._inProcess = false
    return
    this.ACTION_SHEET_OPTIONS = {
      title: 'จัดการสมาชิก...',
      options: ['ย้ายสมาชิก', 'ไล่ออกจากร้าน', 'ยกเลิก'],
      destructiveButtonIndex: 1,
      cancelButtonIndex: 2,
    }
    // log(`_onPressUserItem index = ${index} data.toJS() => `, data.toJS())
    if (!this.ACTION_SHEET_OPTIONS || _.isEmpty(this.ACTION_SHEET_OPTIONS.options)) {
      clearInProcess()
      return
    }
    p.op.showActionSheet(this.ACTION_SHEET_OPTIONS, (buttonIndex: string | number) => {
      const idx = _.isString(buttonIndex) ? parseInt(buttonIndex) : buttonIndex
      switch (idx) {
        case 0: // ย้ายสมาชิก
          this._handleOnChangeUserGroup(index, data)
          break
        case 1: // เตะออกจากร้าน
          this._handleOnKickUserFromStore(index, data)
          break
      }
    })

    clearInProcess()
  }

  _handleOnChangeUserGroup = (index: number, data: UserInUserGroupsMap): void => {
    if (!canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.MEMBER_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขสมาชิกในกลุ่ม')
      return
    }
    // p.op.alert('_handleOnChangeUserGroup', index.toString())
    // log('_handleOnChangeUserGroup data => ', data)
    // @ts-ignore
    this.props.navigation.dispatch(
      NavActions.navToResellerChangeGroupView({
        store_id: this.props.selectedStoreId,
        ug_id: this.props.selectedUserGroupId,
        selectedReseller: isImmutable(data) ? data.toJS() : data, // โดน navigation แปลงเป็น .toJS() เอง
      })
    )
  }

  _handleOnKickUserFromStore = async (index: number, data: UserInUserGroupsMap): Promise<void> => {
    if (!canDoAtSelectedStore(xCONS.PERM_STORE_HELPER.MEMBER_DELETE)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการลบสมาชิกออกจากกลุ่ม')
      return
    }
    // if (this._inProcess) {
    //   return
    // }
    this._inProcess = true
    const profile_id = data.get('i')
    const displayName = data.get('d')
    if (!profile_id || !displayName) {
      this._inProcess = false
      return
    }
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ไล่ตัวแทนออกจากร้าน',
        `กรุณายืนยันว่าคุณต้องการไล่ตัวแทน "${displayName}" ออกจากร้านฉันใช่หรือไม่` +
          '\n\nหากดำเนินการผู้ใช้คนนี้จะไม่เห็นสิ้นค้าจากร้านฉันอีกต่อไป แต่สินค้าที่ถูกสั่งออเดอร์ไปแล้วจะสามารถดำเนินการได้ตามปกติ',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })
    if (!isUserConfirmed) {
      this._inProcess = false
      return
    }
    const { selectedStoreId, kickUser } = this.props
    const body = {
      store_id: selectedStoreId,
      profile_id,
    }
    const isUserKicked = await new Promise((isKick) => {
      kickUser({
        body,
        successCallback: () => isKick(true),
        failedCallback: () => isKick(false),
      })
    })
    const txtSummary = `ดำเนินการไล่สมาชิก "${displayName}" ออกจากร้าน`
    if (isUserKicked) {
      p.op.showToast(`${txtSummary}สำเร็จ`, 'success')
    } else {
      p.op.showToast(`${txtSummary}ล้มเหลว`, 'success')
    }
    this._inProcess = false
  }

  // renderUsersRightComponent(index: number, data: UserInUserGroupsMap): JSX.Element {
  renderUsersRightComponent(arg: { index: number; data: UserInUserGroupsMap }): JSX.Element {
    // if (!Map.isMap(data)) {
    //   return null
    // }
    //
    // if (data.get('kicked')) {
    //   return (
    //     <View style={{
    //       // backgroundColor: 'red',
    //       width: '100%',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //     }}>
    //       <Text style={{
    //         fontSize: STYLES.FONT_SIZE_NORMAL,
    //         color: COLORS.BRAND_Danger,
    //         textAlign: 'center',
    //       }}>{'ไล่ออกแล้ว'}</Text>
    //     </View>
    //   )
    // }
    const { index, data } = arg
    // @ts-ignore
    return <JoinedUserActionsComponent index={index} data={data} />

    // return (
    //   <View style={{
    //     // backgroundColor: 'red',
    //     width: '100%',
    //     alignItems: 'flex-end',
    //   }}>
    //     <ForwardIcon inactive />
    //   </View>
    // )

    // return (
    //   <UserActionsComponent
    //     index={index}
    //     data={data}
    //     onChangeGroup={this._handleOnChangeUserGroup}
    //     onKickUser={this._handleOnKickUserFromStore}
    //    />
    // )
  }

  // End Abstract
}

// class UserActionsComponent extends Component {
//   shouldComponentUpdate(prevProps) {
//     return isDiffAccuracy(prevProps, this.props, ['data'])
//   }
//   // _onApproveUser = () => {
//   //   const { index, data, onApprove } = this.props
//   //   onApprove(index, data)
//   // }
//   _onPressChangeGroup = () => {
//     const { index, data, onChangeGroup, onKickUser } = this.props
//     if (_.isFunction(onChangeGroup)) {
//       onChangeGroup(index, data)
//     }
//   }
//   _renderBtnChangeGroup = () => {
//     return (
//       <TouchableOpacity
//         onPress={this._onPressChangeGroup}
//         style={{
//           height: 40,
//           width: 40,
//           paddingLeft: 4,
//           paddingRight: 4,
//           paddingTop: 4,
//           paddingBottom: 4,
//           borderRadius: 16,
//           alignSelf: 'center',
//           justifyContent: 'center',
//           alignItems: 'center'
//           // backgroundColor: 'transparent',
//         }}>
//         <View style={{
//           alignSelf: 'center',
//           height: 27,
//           width: 27,
//           borderRadius: 13,
//           backgroundColor: COLORS.BRAND_Warning,
//           justifyContent: 'center',
//           alignItems: 'center',
//           margin: 4,
//         }}>
//           <Icon
//             name='md-sync'
//             style={{
//               fontSize: 22,
//               height: 22,
//               width: 22,
//               color: COLORS.TEXT_ACTIVE_DARK,
//               fontWeight: 'bold',
//               textAlign: 'center',
//               paddingLeft: 0,
//               paddingRight: 0,
//               paddingTop: 0,
//               marginLeft: 0,
//               marginRight: 0,
//             }} />
//         </View>
//       </TouchableOpacity>
//     )
//   }
//   _renderBtnKick = () => {
//     return (
//       <TouchableOpacity
//         style={{
//           height: 40,
//           width: 40,
//           paddingLeft: 4,
//           paddingRight: 4,
//           paddingTop: 4,
//           paddingBottom: 4,
//           borderRadius: 16,
//           backgroundColor: 'transparent',
//           alignSelf: 'center',
//         }}>
//         <Icon
//           name='md-close-circle'
//           style={{
//             flex: 1,
//             fontSize: 32,
//             color: COLORS.BRAND_Danger,
//             textAlign: 'center',
//             paddingLeft: 0,
//             paddingRight: 0,
//             paddingTop: 0,
//             marginLeft: 0,
//             marginRight: 0,
//           }} />
//       </TouchableOpacity>
//     )
//   }
//   // _renderSpinner = () => {
//   //   return <Spinner color={COLORS.APP_MAIN} size='small' />
//   // }
//   render() {
//     // const { index, data } = this.props
//
//     // const submitting = data.get('submitting') || false
//     // const approved = data.get('approved') || false
//     // const rejected = data.get('rejected') || false
//
//     // if (approved) {
//     //   return <UserTextStatus approved />
//     // } else if (rejected) {
//     //   return <UserTextStatus rejected/>
//     // } else if (submitting) {
//     //   return <UserTextStatus submitting />
//     // }
//
//     return (
//       <Row style={{ flex: 1 }}>
//         <Button />
//         { this._renderBtnChangeGroup() }
//         { this._renderBtnKick() }
//       </Row>
//     )
//   }
// }

class JoinedUserActionsComponent extends Component {
  shouldComponentUpdate(prevProps) {
    return isDiffAccuracy(prevProps, this.props, ['index', 'data'])
  }

  // _onPressUserItem = () => {
  //   const { index, data, onPressUserItem } = this.props
  //   onPressUserItem(index, data)
  // }
  render() {
    // @ts-ignore
    const { index, data } = this.props
    if (!data || !Map.isMap(data)) {
      return null
    }

    const submitting = data.get('submitting') || false
    if (submitting) {
      return <Spinner color={COLORS.APP_MAIN} size='small' />
    }

    const isKicked = !!data.get('kicked')
    const isChangedGroup = !!data.get('changedGroup')
    if (isKicked || isChangedGroup) {
      return (
        <View
          style={{
            // backgroundColor: 'red',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              fontWeight: 'bold',
              textAlign: 'center',
              color: isKicked ? COLORS.BRAND_Danger : COLORS.BRAND_Warning,
            }}>
            {isKicked ? 'ไล่ออกแล้ว' : null}
            {isChangedGroup ? 'ถูกย้ายกลุ่มแล้ว' : null}
          </Text>
        </View>
      )
    }

    return (
      <View
        style={{
          // backgroundColor: 'red',
          flex: 1,
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}>
        <ForwardIcon inactive style={{ flex: 0 }} />
      </View>
    )
  }
}
