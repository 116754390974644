/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { IPaymentAccount, ISelectedStoreMap, IShippingType, IStoreWebsite, IXScreenProps, StoreWebsiteShippingAndPayment } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
// https://stackoverflow.com/questions/47542928/lodash-refers-to-a-umd-global-and-lodash-js-is-not-a-module-errors
import * as _ from 'lodash'
import * as api from 'x/utils/api'
import dayjs from 'dayjs'

interface IBaseStoreWebsiteSettingPaymentViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseStoreWebsiteSettingPaymentViewState {
  storeWebsite: IStoreWebsite
  isShipping: boolean
  selectedShippingTypeIndex: number
  isShippingCod: boolean
  selectedShippingTypeCodIndex: number
  shippingTypeNotCodItems: IShippingType[]
  shippingTypIsCodItems: IShippingType[]

  selectedPaymentAccountId: number

  shippingCost: string
  shippingCodCost: string

  isEdit: boolean
}

export default abstract class BaseStoreWebsiteSettingPaymentView extends React.Component<
  IBaseStoreWebsiteSettingPaymentViewProps,
  IBaseStoreWebsiteSettingPaymentViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>
  // mainPackageItems: any

  constructor(props) {
    super(props)
    this.state = {
      storeWebsite: null,
      isShipping: false,
      isShippingCod: false,
      selectedShippingTypeIndex: -1,
      selectedShippingTypeCodIndex: -1,
      shippingTypeNotCodItems: [],
      shippingTypIsCodItems: [],
      selectedPaymentAccountId: 0,
      shippingCost: '0',
      shippingCodCost: '0',
      isEdit: false,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
    // @ts-ignore
    // eslint-disable-next-line react/no-unused-class-component-methods
    // this.mainPackageItems = xCONS.SUBSCRIPTION_MAIN_PACKAGE_ITEMS.map((item) => ({
    //   ...item,
    //   label: p.op.t(`Subscription.main.${item.key}`),
    // }))
  }

  async componentDidMount() {
    const params = util.getNavParams(this.props)
    const { storeWebsite } = params
    // console.log('storeWebsite => // ', storeWebsite)
    await this._setShippingTypeNotCodItems()
    await this._setShippingTypeIsCodItems()
    const haveDraftConfigJson = _.has(storeWebsite, 'draft_config_json')
    const draftConfigJson = haveDraftConfigJson ? storeWebsite.draft_config_json : null
    const default_shipping_and_payment: StoreWebsiteShippingAndPayment =
      !_.isNil(draftConfigJson) && _.has(draftConfigJson, 'default_shipping_and_payment')
        ? storeWebsite.draft_config_json.default_shipping_and_payment
        : null

    let selectedShippingTypeIndex = -1
    let shippingCost = '0'
    let isShipping = false
    let selectedShippingTypeCodIndex = -1
    let shippingCodCost = '0'
    let isShippingCod = false
    if (!_.isNil(default_shipping_and_payment)) {
      selectedShippingTypeIndex = default_shipping_and_payment.is_not_cod ? default_shipping_and_payment.is_not_cod.shipping_type_id : -1
      shippingCost = default_shipping_and_payment.is_not_cod ? default_shipping_and_payment.is_not_cod.shipping_cost : '0'
      isShipping = !!default_shipping_and_payment.is_not_cod
      selectedShippingTypeCodIndex = default_shipping_and_payment.is_cod ? default_shipping_and_payment.is_cod.shipping_type_id : -1
      shippingCodCost = default_shipping_and_payment.is_cod ? default_shipping_and_payment.is_cod.shipping_cost : '0'
      isShippingCod = !!default_shipping_and_payment.is_cod
      await util.setStatePromise(this, {
        selectedShippingTypeIndex,
        shippingCost,
        selectedShippingTypeCodIndex,
        shippingCodCost,
        isShipping,
        isShippingCod,
      })
    }

    await util.setStatePromise(this, {
      storeWebsite,
    })
  }

  _setShippingTypeNotCodItems = async () => {
    const shippingTypeNotCodItems = []
    shippingTypeNotCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      // console.log('shippingData => ', shippingData)
      if (!_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
      // const shipping = _.find(CONS.COD_SHIPPING_TYPE_IDS, (id) => id !== shippingData.value)
      // console.log('shipping => ', shipping)
      // if (!_.isNil(shipping)) {
      //   shippingTypeNotCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      // }
    })

    await util.setStatePromise(this, {
      shippingTypeNotCodItems,
    })

    // const selfDeliveryDefaultShippingTypeId =
    //   _.has(channel, 'self_delivery_default_shipping_type_id') && !_.isNil(channel.self_delivery_default_shipping_type_id)
    //     ? channel.self_delivery_default_shipping_type_id
    //     : null

    // let shipping = -1
    // if (!_.isNil(selfDeliveryDefaultShippingTypeId)) {
    //   shippingTypeNotCodItems.forEach((sp, idx) => {
    //     if (selfDeliveryDefaultShippingTypeId === sp.value) {
    //       shipping = idx
    //     }
    //   })
    // }
  }

  _setShippingTypeIsCodItems = async () => {
    const shippingTypIsCodItems = []
    shippingTypIsCodItems.push({ index: -1, label: 'ไม่ระบุ' })
    CONS.ORDER_VIEW_SHIPPING_TYPE_ITEMS.forEach((shippingData) => {
      if (_.includes(CONS.COD_SHIPPING_TYPE_IDS, shippingData.value)) {
        shippingTypIsCodItems.push({ ...shippingData, label: util.getShippingTypeNameById(shippingData.value) })
      }
    })

    await util.setStatePromise(this, {
      shippingTypIsCodItems,
    })
  }

  _onChangePaymentAccount = (selectedPaymentAccount: IPaymentAccount) => {
    // console.log('_onChangePaymentAccount selectedPaymentAccount => ', selectedPaymentAccount)
    this.setState({ selectedPaymentAccountId: selectedPaymentAccount.bank_id })
    // const isSelectedPaymentAccountVerifySlipSupported = selectedPaymentAccount.is_for_verify_slip || false

    // await util.setStatePromise(this, { selectedSellerPaymentAccount: selectedPaymentAccount, isSelectedPaymentAccountVerifySlipSupported })
    // await this._onSuccessChooseSlipToVerify()
    // this.setState({ selectedSellerPaymentAccount: selectedPaymentAccount, isSelectedPaymentAccountVerifySlipSupported }, () => {
    //   this._onSuccessChooseSlipToVerify()
    // })
    // return selectedPaymentAccount
  }

  _setIsEdit = () => {
    this.setState({ isEdit: true })
  }

  _saveBtn = async () => {
    const { isShipping, shippingCost, selectedShippingTypeIndex, isShippingCod, shippingCodCost, selectedShippingTypeCodIndex } = this.state
    const newDefaultShippingAndPayment: StoreWebsiteShippingAndPayment = {
      is_not_cod: isShipping
        ? {
            shipping_cost: shippingCost,
            shipping_type_id: selectedShippingTypeIndex,
          }
        : null,
      is_cod: isShippingCod
        ? {
            shipping_cost: shippingCodCost,
            shipping_type_id: selectedShippingTypeCodIndex,
          }
        : null,
    }

    const { selectedStore } = this.props
    const { storeWebsite } = this.state
    // console.log('storeWebsite /// => ', storeWebsite)
    await util.setStatePromise(this, { isLoading: true })
    await util.delay(200)
    const newJson = _.has(storeWebsite, 'draft_config_json')
      ? _.cloneDeep(storeWebsite.draft_config_json)
      : { default_shipping_and_payment: null }
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment

    // const newDefaultShippingAndPayment = _.has(newJson, 'default_shipping_and_payment')
    //   ? // @ts-ignore
    //     _.cloneDeep(newJson.default_shipping_and_payment)
    //   : { is_cod: null, is_not_cod: null, updated_at: null }
    // console.log('newJson => ', newJson)
    // newDefaultShippingAndPayment.is_cod = ordersPicker
    // newDefaultShippingAndPayment.is_not_cod = ordersPicker
    newDefaultShippingAndPayment.updated_at = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()
    // console.log('newOrderPicker => ', newOrderPicker)
    // @ts-ignore
    newJson.default_shipping_and_payment = newDefaultShippingAndPayment

    // // @ts-ignore
    // delete newJson.order_pickers
    // // @ts-ignore
    // delete newJson.order_picker
    // console.log('newJson // => ', newJson)
    // return
    try {
      const res = await api.updateStoreWebsite({
        store_id: selectedStore.get('id'),
        website_id: storeWebsite.id,
        config_json: newJson,
      })
      // console.log('res => ', res)
      const params = util.getNavParams(this.props)
      const { callBackSucceedUpdateApi } = params
      if (_.isFunction(callBackSucceedUpdateApi)) {
        callBackSucceedUpdateApi(res)
        // p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
        util.navGoBack(this.props)
      }
    } catch (error) {
      // console.log('error => ', error)
    }
    await util.delay(200)
    await util.setStatePromise(this, { isLoading: false, haveEditing: false })
  }

  _navToStoreWebsiteView = (storeWebsite: IStoreWebsite) => {
    const { navigation } = this.props
    // console.log('SalePage Nav => ', salePage)
    navigation.dispatch(
      NavActions.navToStoreWebsiteView({
        storeWebsite,
        callBackForSucceedDeleteStoreWebsite: null,
      })
    )
  }
}
