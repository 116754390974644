import React from 'react'

import _ from 'lodash'
// import { COLORS, STYLES } from '../../config/styles'
import { IApiOptions, IUserListTab, IXScreenProps } from 'x/index'
import api from 'x/utils/api'
import p from '../../../config/platform-specific'
import * as util from '../../../utils/util'
import { IPermission } from './BaseAssistantGroupListView'

export interface BaseStoreAssistantMemberProps extends IXScreenProps {
  getPermissionMemberList: Function
  changePermission: Function
  deleteHelperFromPermission: Function
  addHelper: Function
  selectedStore: any
}

export interface BaseStoreAssistantMemberState {
  username: string
  members: IMember[]
  pageLoading: boolean
  showOverlayChangePermission: boolean
  permissions: IPermission[]
  permissionId: number
  selectedPermissionIndex: number
  storeId: number
  permissionName: string
  permissionDescription: string
  loadingMember: boolean
  refreshControl: boolean

  selectedSegmentIndex: number

  helperName: string
  isHelperEditOpenModal: boolean
  selectedHelper: {
    i: number // ID
    d: string // Name
    t: string // IMG
    u: string // เข้าเมื่อไร
    p: string // permission_id
  }
}

export interface ResponesPermissionMemberList {
  helpers: [
    {
      i: number // ID
      d: string // Name
      t: string // IMG
      u: string // เข้าเมื่อไร
      p: string // permission_id
    }
  ]
}

export interface IMember {
  i: number // ID
  d: string // Name
  t: string // IMG
  u: string // เข้าเมื่อไร
  p: string // permission_id
}

export default abstract class BaseStoreAssistantMember extends React.Component<
  BaseStoreAssistantMemberProps,
  BaseStoreAssistantMemberState
> {
  DISPLAY_TABS: IUserListTab[]

  _tabRef: React.RefObject<any>

  helperProfileId: number

  protected constructor(props) {
    super(props)

    this.state = {
      username: '',
      members: null,
      pageLoading: false,
      showOverlayChangePermission: false,
      permissions: null,
      permissionId: null,
      selectedPermissionIndex: -1,
      storeId: null,
      permissionName: '',
      permissionDescription: '',
      loadingMember: true,
      refreshControl: false,
      selectedSegmentIndex: 0,
      helperName: '',
      isHelperEditOpenModal: false,
      selectedHelper: null,
    }
    this._tabRef = React.createRef()
    this.helperProfileId = null
  }

  async componentDidMount() {
    const { navigation, selectedStore, getPermissionMemberList } = this.props
    // const { state } = navigation
    const params = util.getNavParams(this.props)
    const { tabIndex, permissionList, permission_id } = params
    // await this._changeTab(_.isNil(tabIndex) ? 0 : tabIndex)
    const store_id = selectedStore.get('id')
    const permission = _.find(permissionList, (data: IPermission) => data.id === permission_id)
    const permissionName = permission.name
    const permissionDescription = permission.description
    const permissions = _.isNil(permissionList) ? null : permissionList
    const permissionId = _.isNil(permission_id) ? null : permission_id
    const res: ResponesPermissionMemberList = await new Promise((resolve) => {
      getPermissionMemberList({
        body: {
          store_id,
          permission_id,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    // const resFromJS = fromJS(res)
    // const respone = resFromJS.size > 0 ? resFromJS.get('helpers').toJS() : null
    let respone = null
    if (res.helpers) {
      respone = res.helpers
    }
    await util.setStatePromise(this, {
      members: respone,
      permissions,
      permissionId,
      storeId: store_id,
      permissionName,
      permissionDescription,
      loadingMember: false,
      selectedSegmentIndex: _.isNil(tabIndex) ? 0 : tabIndex,
    })
    navigation.setParams({
      goBackAndCallBack: () => this._callBackToAssistanGroupList(true),
    })
  }

  _refreshControl = async () => {
    const { getPermissionMemberList } = this.props
    const { storeId, permissionId, members } = this.state
    await util.setStatePromise(this, { refreshControl: true })
    const res: ResponesPermissionMemberList = await new Promise((resolve) => {
      getPermissionMemberList({
        body: {
          store_id: storeId,
          permission_id: permissionId,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    let respone = members
    if (res.helpers) {
      respone = res.helpers
    }
    await util.setStatePromise(this, {
      members: respone,
    })
    await util.setStatePromise(this, { refreshControl: false })
  }

  _changeTab = async (index: number) => {
    await util.delay(200)
    this._tabRef.current.goToPage(index)
  }

  _handleOnChangeUsername = async (newText: string) => {
    util.setStatePromise(this, { username: newText })
  }

  _handleOpenPermissionOverlay = () => {
    util.setStatePromise(this, { showOverlayChangePermission: true })
  }

  _handleClosePermissionOverlay = () => {
    util.setStatePromise(this, { showOverlayChangePermission: false })
  }

  _onChangeSelectedPermissionIndex = (index: number) => {
    util.setStatePromise(this, { selectedPermissionIndex: index })
  }

  _submitPermissionChange = async (permission_id: number) => {
    const { permissionId, storeId } = this.state
    const { changePermission } = this.props
    if (permission_id === permissionId) {
      p.op.showConfirmationOkOnly('ผู้ช่วยอยู่ในรายการสิทธิ์ที่เลือกไว้อยู่แล้ว', 'หากต้องการย้ายสิทธิ์ผู้ช่วย กรุณาเลือกรายการสิทธ์ใหม่')
      return
    }
    if (_.isNil(this.helperProfileId)) {
      p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาปิดแอพฯ แล้วลองทำรายการใหม่อีกครั้ง')
      return
    }
    const res: ResponesPermissionMemberList = await new Promise((resolve) => {
      changePermission({
        body: {
          store_id: storeId,
          helper_profile_id: this.helperProfileId,
          response_permission_id: permissionId,
          new_permission_id: permission_id,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    // const resFromJS = fromJS(res)
    // const respone = resFromJS.size > 0 ? resFromJS.get('helper').toJS() : null
    this._handleClosePermissionOverlay()
    let respone = null
    if (res.helpers) {
      respone = res.helpers
      await util.setStatePromise(this, { members: respone })
      p.op.showToast('ย้ายรายการสิทธิ์เรียบร้อยแล้ว', 'success')
      this._callBackToAssistanGroupList()
    } else {
      // p.op.showToast('ถอดถอนสิทธิ์ไม่สำเร็จ', 'warning')
    }
    this.helperProfileId = null
  }

  _deleteHelperConfirm = async (data: IMember) => {
    const { deleteHelperFromPermission } = this.props
    const { storeId, permissionId } = this.state
    const res: ResponesPermissionMemberList = await new Promise((resolve) => {
      deleteHelperFromPermission({
        body: {
          store_id: storeId,
          permission_id: permissionId,
          helper_profile_id: data.i,
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    // const resFromJS = fromJS(res)
    // const respone = resFromJS.size > 0 ? resFromJS.get('helper').toJS() : null
    let respone = null
    if (res.helpers) {
      respone = res.helpers
      await util.setStatePromise(this, { members: respone })
      p.op.showToast('ถอดถอนสิทธิ์เรียบร้อยแล้ว', 'success')
      this._callBackToAssistanGroupList()
    } else {
      // p.op.showToast('ถอดถอนสิทธิ์ไม่สำเร็จ', 'warning')
    }
  }

  _deleteHelperFromPermission = (data: IMember) => {
    p.op.showConfirmation(
      `ถอดถอนผู้ช่วย ${data.d}`,
      `คุณต้องการถอดถอนผู้ช่วยใช่หรือไม่`,
      () => {
        this._deleteHelperConfirm(data)
      },
      () => null,
      `ถอดถอนผู้ช่วย`,
      `ยกเลิก`
    )
  }

  _addHelper = async () => {
    const { addHelper } = this.props
    const { storeId, permissionId, username } = this.state
    const res: ResponesPermissionMemberList = await new Promise((resolve) => {
      addHelper({
        body: {
          store_id: storeId,
          permission_id: permissionId,
          helper_username: username.toLocaleLowerCase(),
        },
        successCallback: resolve,
        failedCallback: resolve,
      })
    })
    // const resFromJS = fromJS(res)
    // const respone = resFromJS.size > 0 ? resFromJS.get('helper').toJS() : null
    let respone = null
    if (res.helpers) {
      respone = res.helpers
      await util.setStatePromise(this, { members: respone })
      p.op.showConfirmationOkOnly(``, `เพิ่มผู้ช่วยเรียบร้อยแล้ว`)
      this._changeTab(0)
      // p.op.showToast('เพิ่มผู้ช่วยเรียบร้อยแล้ว', 'success')
      this._callBackToAssistanGroupList()
    } else {
      // p.op.showToast('เพิ่มผู้ช่วยไม่สำเร็จ', 'warning')
    }
  }

  _callBackToAssistanGroupList = (outView?: boolean) => {
    // const { state } = this.props.navigation
    // const { callBack } = state.params
    const callBack = util.getNavParam(this.props, 'callBack')
    if (_.isFunction(callBack)) {
      if (_.isNil(outView)) {
        callBack()
      } else {
        callBack(outView)
      }
    }
  }

  _onRequestCloseEditHelperName = () => {
    this.setState({ isHelperEditOpenModal: false })
  }

  _onChangeHelperName = async () => {
    const { selectedStore } = this.props
    const { helperName, selectedHelper } = this.state

    if (helperName.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อผู้ช่วย')
      return
    }

    try {
      const store_id = selectedStore.get('id')
      const reqBody = {
        store_id,
        helper_profile_id: selectedHelper.i,
        response_permission_id: selectedHelper.p,
        name: helperName,
      }

      const apiOptions: IApiOptions = {
        showSpinner: true,
      }
      const res = await api.patchV2('permissions/helpers', reqBody, apiOptions)
      // console.log('res => ', res)
      if (res.helpers) {
        const { helpers } = res
        // console.log('newReseller => ', newReseller)
        await util.setStatePromise(this, {
          members: helpers,
        })
        p.op.showToast('เปลี่ยนชื่อผู้ช่วยเรียบร้อย', 'success')
      }
    } catch (error) {
      // console.log('error => ', error)
    }
  }
}
