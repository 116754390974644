// import PropTypes from 'prop-types'
import { Component } from 'react'
import { log, setStatePromise } from 'x/utils/util'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import CONS from 'x/config/constants'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { List, isImmutable } from 'immutable'
import { UserGroup, ProductGroupMap, UGListViewProps, ProductGroup, ISelectedStoreMap, ActionApiParams, IXScreenProps } from 'x/index'

interface IBaseProductGroupSettingForUserGroupProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  selectedProductGroups: List<ProductGroupMap>
  pgs_updated_at: string
  saveSyncProductGroupsToUserGroup: (params: ActionApiParams) => void
}

interface IBaseProductGroupSettingForUserGroupState {
  userGroup: UserGroup
  selectedProductGroups: ProductGroup[]
  isEditing: boolean
}

export default class BaseProductGroupSettingForUserGroup extends Component<
  IBaseProductGroupSettingForUserGroupProps,
  IBaseProductGroupSettingForUserGroupState
> {
  _inProcess: boolean

  // isGoback: boolean
  qrModalRef: any

  constructor(props: UGListViewProps) {
    // @ts-ignore
    super(props)
    this._inProcess = false
    // this.isGoback = false

    this.state = {
      userGroup: null,
      selectedProductGroups: null,
      isEditing: false,
    }
  }

  async componentDidMount() {
    await this._firstInitialize()
  }

  _firstInitialize = async () => {
    const { navigation, selectedProductGroups } = this.props
    // const { userGroup } = navigation.state.params
    const userGroup = util.getNavParam(this.props, 'userGroup')
    const selectedProductGroupsJS = isImmutable(selectedProductGroups) ? selectedProductGroups.toJS() : selectedProductGroups
    // console.log('userGroup => ', userGroup)
    // console.log('selectedProductGroupsJS => ', selectedProductGroupsJS)
    await setStatePromise(this, {
      userGroup,
      selectedProductGroups: selectedProductGroupsJS,
    })

    await this._firstInitializeEditingProductGroups()
  }

  _firstInitializeEditingProductGroups = async (): Promise<void> => {
    const { userGroup, selectedProductGroups } = this.state
    // const ug_id = userGroup.id
    // const { mode, ug_id } = this._getParams()
    const editingProductGroups = _.clone(selectedProductGroups)
    // console.log(`editingProductGroups`, editingProductGroups)
    const { pgs } = userGroup
    // console.log('_updateEditingProductGroups pgs => ', pgs)
    editingProductGroups.forEach((pg, idx) => {
      // @ts-ignore
      let foundAssignedPG = null
      pgs.map((xPg) => {
        // @ts-ignore
        if (xPg.id === pg.id) {
          foundAssignedPG = xPg
        }
      })
      // console.log('foundAssignedPG => ', foundAssignedPG)
      if (_.isNil(foundAssignedPG)) {
        return null
      }
      // if (foundAssignedPG.pg_id) {
      const assignedObject = _.cloneDeep(pg)
      // assignedObject.isAssigned = true
      assignedObject.isAssigned = true
      if (foundAssignedPG.discount_percent) {
        assignedObject.discount_type = 1
        // @ts-ignore
        assignedObject.discount_percent = foundAssignedPG.discount_percent.toString()
      } else if (foundAssignedPG.discount_amount) {
        assignedObject.discount_type = 2
        // @ts-ignore
        assignedObject.discount_amount = foundAssignedPG.discount_amount.toString()
      } else {
        assignedObject.discount_type = 0
      }
      // console.log('assignedObject => ', assignedObject)
      editingProductGroups[idx] = assignedObject
      // }
    })
    // }
    // console.log('/// => ', editingProductGroups)
    await setStatePromise(this, {
      selectedProductGroups: editingProductGroups,
    })
    // this._setEditing()
  }

  _toggleProductGroupCheckbox = (index: number, productGroup: ProductGroup) => {
    const editingProductGroups = _.cloneDeep(this.state.selectedProductGroups)
    const {
      isAssigned = false,
      // discount_percent = null,
      // discount_amount = null,
      // discount_type = null,
    } = editingProductGroups[index]
    if (isAssigned) {
      editingProductGroups[index].isAssigned = false
    } else {
      editingProductGroups[index].isAssigned = true
      editingProductGroups[index].discount_type = 0
      editingProductGroups[index].discount_percent = null
      editingProductGroups[index].discount_amount = null
    }

    const isAssignedArray = editingProductGroups.map((epg) => _.has(epg, 'isAssigned') && epg.isAssigned)
    let hasAssignedSomePG = false
    for (let i = 0; i < isAssignedArray.length; i++) {
      if (isAssignedArray[i]) {
        hasAssignedSomePG = true
        break
      }
    }
    if (!hasAssignedSomePG) {
      p.op.showConfirmationOkOnly('คำแนะนำ', 'กรุณาเลือกอย่างน้อย 1 ราคา')
      return
    }

    this.setState({
      selectedProductGroups: editingProductGroups,
    })
    this._setEditing()
  }

  _onDiscountTypeChange = (index: number, data: ProductGroup, optionIndex: number): void => {
    // console.log('_onDiscountTypeChange index => ', index)
    // console.log('_onDiscountTypeChange data => ', data)
    // console.log('_onDiscountTypeChange optionIndex => ', optionIndex)
    const editingProductGroups = _.cloneDeep(this.state.selectedProductGroups)
    // console.log('editingProductGroups => ', editingProductGroups)
    editingProductGroups[index].discount_type = optionIndex
    editingProductGroups[index].discount_percent = optionIndex === 1 ? 0 : null
    editingProductGroups[index].discount_amount = optionIndex === 2 ? 0 : null
    // console.log('editingProductGroups END => ', editingProductGroups)
    this.setState({ selectedProductGroups: editingProductGroups })
    this._setEditing()
  }

  _onDiscountAmountChange = (index: number, data: ProductGroup, optionIndex: number, newDiscount: number | string): void => {
    // log(`_onDiscountAmountChange index=${index} newText => `, newText)
    // log(`_onDiscountAmountChange index=${index} data => `, data)
    // log(`_onDiscountAmountChange index=${index} optionIndex => `, optionIndex)
    const editingProductGroups = _.cloneDeep(this.state.selectedProductGroups)
    if (optionIndex === 1) {
      // @ts-ignore
      editingProductGroups[index].discount_percent = !_.isNaN(parseFloat(newDiscount)) ? parseFloat(newDiscount) : 0
      editingProductGroups[index].discount_amount = null
    } else if (optionIndex === 2) {
      editingProductGroups[index].discount_percent = null
      // @ts-ignore
      editingProductGroups[index].discount_amount = !_.isNaN(parseFloat(newDiscount)) ? parseFloat(newDiscount) : 0
    } else {
      editingProductGroups[index].discount_percent = null
      editingProductGroups[index].discount_amount = null
    }
    // log(`_onDiscountAmountChange index=${index} editingProductGroups[index] => `, editingProductGroups[index])
    this.setState({ selectedProductGroups: editingProductGroups })
    this._setEditing()
  }

  _setEditing = () => {
    this.setState({
      isEditing: true,
    })
  }

  _UnSetEditing = () => {
    this.setState({
      isEditing: false,
    })
  }

  _getPgAssignedCount = (): number => {
    const { selectedProductGroups } = this.state
    if (!selectedProductGroups || !_.isArray(selectedProductGroups)) {
      return 0
    }
    let count = 0
    selectedProductGroups.forEach((pg) => {
      if (pg && pg.isAssigned) {
        count += 1
      }
    })
    return count
  }

  _saveSyncProductGroups = async (): Promise<void> => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.UG_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขกลุ่มสมาชิก')
      return
    }
    if (this._inProcess) {
      return
    }
    this._inProcess = true
    // await this._beginSubmitting()
    const { selectedStore } = this.props
    const { selectedProductGroups, userGroup } = this.state
    // console.log('_saveSyncProductGroups editingProductGroups => ', selectedProductGroups)

    const body: { [key: string]: any } = {}

    const store_id = selectedStore.get('id')
    if (store_id) {
      body.store_id = store_id
    } else {
      // should impossible
      p.op.alert('ไม่พบข้อมูลร้านค้า', 'กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
      this._inProcess = false
      return
    }

    if (userGroup && userGroup.id) {
      body.ug_id = userGroup.id
    } else {
      // should impossible
      p.op.alert('ไม่พบข้อมูลรายการราคา', 'กรุณากลับไปเลือกรายการราคาใหม่อีกครั้ง')
      this._inProcess = false
      return
    }

    body.pgs = []
    for (let i = 0; i < selectedProductGroups.length; i++) {
      const focusedPG = selectedProductGroups[i]
      const { id, name, isAssigned, discount_type, discount_amount, discount_percent } = focusedPG
      if (id && isAssigned && _.includes([0, 1, 2], discount_type)) {
        if (discount_type === 1 && (!discount_percent || discount_percent <= 0)) {
          p.op.alert('ระบุข้อมูลไม่ครบถ้วน', `กรุณาระบุส่วนลดที่เป็นเปอร์เซ็นของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่า 1-100%)`)
          this._inProcess = false
          return
        }
        if (discount_type === 2 && (!discount_amount || discount_amount <= 0)) {
          p.op.alert('ระบุข้อมูลไม่ครบถ้วน', `กรุณาระบุส่วนลดที่เป็นบาทของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่ามากกว่า ฿1)`)
          this._inProcess = false
          return
        }

        // body.pgs.push({ pg_id: id, discount_percent, discount_amount })
        const pgProperties = { pg_id: id }
        // @ts-ignore
        if (!_.isNaN(parseFloat(discount_percent))) {
          // @ts-ignore
          const discountPercent = parseFloat(discount_percent)
          if (discountPercent < 1 || discountPercent > 100) {
            p.op.alert('ส่วนลดไม่ถูกต้อง', `กรุณาระบุส่วนลดที่เป็นเปอร์เซ็นของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่า 1-100%)`)
            this._inProcess = false
            return
          }
          // @ts-ignore
          pgProperties.discount_percent = Math.abs(discountPercent)
        }
        // @ts-ignore
        if (!_.isNaN(parseFloat(discount_amount))) {
          // @ts-ignore
          const discountAmount = parseFloat(discount_amount)
          if (discountAmount < 1) {
            p.op.alert('ส่วนลดไม่ถูกต้อง', `กรุณาระบุส่วนลดที่เป็นบาทของรายการราคา "${name}" ให้ถูกต้อง\n(ส่วนลดต้องมีค่ามากกว่า ฿1)`)
            this._inProcess = false
            return
          }
          // @ts-ignore
          pgProperties.discount_amount = Math.abs(discountAmount)
        }
        body.pgs.push(pgProperties)
      }
    }

    // const { state } = this.props.navigation
    // if (_.isNil(state)) {
    //   return null
    // }
    // const { params } = state
    const params = util.getNavParams(this.props)
    const isMKP = !_.isNil(params.is_mkp) && params.is_mkp ? params.is_mkp : false

    let mag = 'กรุณายืนยันว่าคุณต้องการบันทึกการตั้งค่าใหม่สำหรับสมาชิก (หากยืนยัน การดำเนินการอาจจะใช้เวลานาน กรุณารอสักครู่)'
    let title = 'ยืนยันบันทึกการตั้งค่ารายการราคา'
    if (isMKP) {
      title = ' ยืนยันการผูกรายการราคากับช่องทางขายนี้'
      mag = ''
    }
    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        title,
        mag,
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      this._inProcess = false
      return
    }

    if (body.pgs && body.pgs.length === 0) {
      p.op.alert(
        'ไม่สามารถถอดรายการราคาออกจากกลุ่มสมาชิก',
        'กรุณาเลือกรายการราคาอย่างน้อยหนึ่งกลุ่ม เนื่องจากการปลดกลุ่มสินค้าทั้งหมดออกจากกลุ่มสมาชิก สมาชิกจะได้รับผลกระทบคือไม่สามารถมองเห็นสินค้า และเปิดออเดอร์ใดๆ ได้'
      )
      this._inProcess = false
      return
    }

    log('_saveSyncProductGroups body => ', body)
    // await new Promise(changeModeDelay => setTimeout(changeModeDelay, 3200))

    const res = await new Promise((submitted) => {
      const failedCallback = () => submitted(null)
      // TODO: sync with backend api again and watch response is correctly before nav back
      this.props.saveSyncProductGroupsToUserGroup({ body, successCallback: submitted, failedCallback })
    })

    // console.log('_saveSyncProductGroups res => ', res)
    if (res) {
      // await this._firstInitialize()
      // await new Promise((changeModeDelay) => setTimeout(changeModeDelay, 200))
      p.op.showToast('บันทึกการเลือกรายการราคาสำเร็จ', 'success')
      this._UnSetEditing()
      // const params = this._getParams()
      // if (_.isFunction(params.callBackMKP)) {
      //   params.callBackMKP(body.pgs)
      // }
      // this._goback()
    }

    this._inProcess = false
  }
}
