import React from 'react'
import * as util from 'x/utils/util'
// import BackIcon from '../../components/BackIcon'
// import XTabNavIcon from '../../components/XTabNavIcon'
// import MCIcon from 'react-native-vector-icons/MaterialCommunityIcons'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseCreateSalePageView from 'x/modules/storeWebsite/BaseCreateSalePageView'
import { COLORS } from 'x/config/styles'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import _ from 'lodash'
import { TouchableOpacity } from 'react-native'
import XIcon from 'xui/components/XIcon'
import VStack from 'xui/components/VStack'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import HelpButton from 'xui/components/HelpButton'
import Box from 'xui/components/Box'
import XButton from 'xui/components/XButton'

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUICreateSalePageView extends BaseCreateSalePageView {
  _renderHeader = () => {
    const title = 'สร้างเซลเพจ'
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderInputName = () => {
    const { salePageName } = this.state
    return (
      <HStack w='full'>
        <XText variant='active'>ชื่อ:</XText>
        <XInput
          multiline
          maxFontSizeMultiplier={2}
          style={{ marginRight: 4, flex: 1 }}
          ml='2'
          h='16'
          placeholder='ระบุชื่อเซลเพจ'
          value={salePageName}
          onChangeText={(newText: string) => this._onChangeSalePageName(newText)}
        />
      </HStack>
    )
  }

  _renderSelctedSalePageTemplate = () => {
    const { selectedTemplateIndex } = this.state
    if (_.isNil(this.TEMPLATE_LIST)) {
      return null
    }
    return (
      <VStack w='full' mt='2'>
        <HStack w='full' alignItems='center' justifyContent='flex-start'>
          <XText variant='inactive'>กรุณาเลือกรูปแบบเซลเพจ</XText>
          <Box ml='2' />
          <XFaqModal
            key='SelctedSalePageTemplate'
            headerTitle='Sale Page'
            // @ts-ignore
            FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
            initiateOpenIndex={[0, 1]}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XCard p='2'>
          {this.TEMPLATE_LIST.map((template, index) => {
            const { title } = template
            const { description } = template
            return (
              <TouchableOpacity style={{ flex: 1 }} key={template.template} onPress={() => this._onPressSelectedTemplate(index)}>
                <HStack w='full' h='16'>
                  <HStack w='10' alignItems='center' justifyContent='center'>
                    <XIcon family='Ionicons' name={selectedTemplateIndex === index ? 'radio-button-on' : 'radio-button-off'} />
                  </HStack>
                  <VStack w='full' alignItems='flex-start' justifyContent='center'>
                    <XText>{title}</XText>
                    <XText variant='inactive'>{description}</XText>
                  </VStack>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </XCard>
      </VStack>
    )
  }

  _renderMainView = () => {
    const { salePageName } = this.state
    return (
      <XCard w='full' p='2'>
        {this._renderInputName()}
        {/* {this._renderSelctedSalePageTemplate()} */}
      </XCard>
    )
  }

  _renderFooter = () => (
    // const { selectedPackages } = this.state
    // if (_.isNil(selectedPackages)) {
    //   return null
    // }
    <XButton mb='0.5' mx='0.5' onPress={() => this._onPressCreateSalePage()}>
      สร้างเว็บไซต์
    </XButton>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderMainView()}
        </XContent>
        {this._renderFooter()}
      </XContainer>
    )
  }
}
