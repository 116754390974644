import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, StyleSheet, Platform } from 'react-native'
import Icon from 'xui/components/Icon'
// import { diff } from 'deep-object-diff'
import FastImage from 'react-native-fast-image'

import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import p from 'x/config/platform-specific'
import { S, STYLES, COLORS } from 'x/config/styles'
// import CONS from 'x/config/constants'

import { IBarcodeScannerOutput, IOrderProductItem, IShippingOrderProductItem, IShippingProductsCardProps } from 'x/index'

import CollapsableCard from '../CollapsableCard'
import BarcodeScannerWithSegment from '../BarcodeScannerWithSegment'
import XCustomHeader from '../XCustomHeader'
import XOverlay from '../XOverlay'
import XInput from '../XInput'
import Sound from '../../utils/sound'
import XText from '../XText'

interface ISavedState {
  segmentBarcodeIndex?: number
}

const ShippingProductsCard: React.FC<IShippingProductsCardProps> = (props) => {
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)

  const [pendingProductList, setPendingProductList] = React.useState<IShippingOrderProductItem[]>([])
  const [finishedProductList, setFinishedProductList] = React.useState<IShippingOrderProductItem[]>([])
  const [shippedProductList, setShippeddProductList] = React.useState<IShippingOrderProductItem[]>([])
  // const [scanedMap, setScanedMap] = React.useState<{ [key: number]: number }>(null)
  const [isFinished, setIsFinished] = React.useState<boolean>(false)
  const [isCheckButtonPressed, setIsCheckButtonPressed] = React.useState<boolean>(false)

  const [isBarcodeRead, setIsBarcodeRead] = React.useState<boolean>(false)
  const [isForceExpanded, setIsForceExpanded] = React.useState<boolean>(false)
  const [isNoNeedQty, setIsNoNeedQty] = React.useState<boolean>(false)

  const [segmentBarcodeIndex, setSegmentBarcodeIndex] = React.useState(0)
  const [openEditQtyAtIndex, setOpenEditQtyAtIndex] = React.useState<number>(-1)

  const isCheckAll = pendingProductList.length === 0 && finishedProductList.length > 0
  const isPreventExpand = props.skip && !isFinished

  React.useEffect(() => {
    _initialize()
  }, [])

  React.useEffect(() => {
    if (_.isArray(props.products) && props.products.length > 0 && pendingProductList.length === 0 && finishedProductList.length === 0) {
      const convertedProducts = props.products.map(convertOrderProductItemToPendingOrderProductItem)
      const convertedProductsToScan = convertedProducts.filter(filterAvailableToShipQty)
      const convertedShippedProducts = convertedProducts.filter(filterShipedQty)
      setPendingProductList(convertedProductsToScan)
      setShippeddProductList(convertedShippedProducts)
      setFinishedProductList([])
      setIsForceExpanded(true)

      const noNeedQty = convertedProductsToScan.length === 0 && convertedShippedProducts.length > 0
      if (noNeedQty) {
        Sound.badBeep()
        setIsFinished(true)
        setIsNoNeedQty(true)
      }
    } else if (!_.isArray(props.products) || props.products.length === 0) {
      setPendingProductList([])
      setFinishedProductList([])
      setShippeddProductList([])
    }
  }, [props.products])

  async function _initialize() {
    // Load saved state
    const savedState = (await util.loadDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_PRODUCTS_CARD)) as ISavedState
    if (savedState && savedState.segmentBarcodeIndex) {
      setSegmentBarcodeIndex(savedState.segmentBarcodeIndex)
    }

    setIsInitialized(true)
  }

  // React.useEffect(() => {
  //   if (_.isArray(props.products) && props.products.length > 0 && pendingProductList.length === 0 && finishedProductList.length > 0) {
  //     if (!isCheckButtonPressed) {
  //       _onFinishScanProducts()
  //     }
  //   } else {
  //     if (props.skip) {
  //       _doCheckAllProducts()
  //     }
  //   }
  // }, [pendingProductList])

  async function _onFinishScanProducts() {
    console.log('_onFinishScanProducts finishedProductList.concat(pendingProductList) => ', finishedProductList.concat(pendingProductList))
    await props.onFinish(finishedProductList.concat(pendingProductList))
    setIsFinished(true)
    setIsForceExpanded(false)
  }

  async function _onBarcodeScanned(barcode: IBarcodeScannerOutput) {
    setIsBarcodeRead(true)
    if (barcode.data && !isBarcodeRead) {
      const scannedBarcodeText = barcode.data
      let isFound = scanPendingProductSKU(scannedBarcodeText)
      if (!isFound) {
        isFound = scanPendingProductUPC(scannedBarcodeText)
      }
      if (!isFound) {
        isFound = scanFinishedProductSKU(scannedBarcodeText)
      }
      if (!isFound) {
        isFound = scanFinishedProductUPC(scannedBarcodeText)
      }
      if (!isFound) {
        p.op.showToast(`ไม่พบสินค้าที่มี SKU ตรงกับ ${scannedBarcodeText}`, 'warning')
        Sound.badBeep()
      } else {
        Sound.goodBeep()
      }
      if (isCheckButtonPressed) {
        setIsCheckButtonPressed(false)
      }
    }
    await util.delay(500)
    setIsBarcodeRead(false)
  }

  function scanPendingProductSKU(scannedSKU: string): boolean {
    // Scanned Found Index
    const sIdx = pendingProductList.findIndex((pd) => pd.sku && pd.sku === scannedSKU)
    return scanPendingProductAtIndex(sIdx)
  }

  function scanPendingProductUPC(scannedUPC: string): boolean {
    // Scanned Found Index
    const sIdx = pendingProductList.findIndex((pd) => pd.upc && pd.upc === scannedUPC)
    return scanPendingProductAtIndex(sIdx)
  }

  function scanPendingProductAtIndex(scannedIndex: number): boolean {
    const sIdx = scannedIndex
    if (sIdx > -1) {
      const newPPL = [...pendingProductList]

      // scanned product item (cloned)
      const sp = { ...newPPL[sIdx] }
      const productFullName = getProductFullName(sp)

      sp.scannedQty += 1
      sp.lastUpdatedAt = new Date()
      newPPL[sIdx] = { ...sp }
      const isFullfilled = isShippedQtyFullfilled(sp)

      if (isFullfilled) {
        newPPL.splice(sIdx, 1)
        setFinishedProductList(finishedProductList.concat(sp))
      }

      newPPL.sort(sortPendingOrderProductItemByDateDesc)
      setPendingProductList(newPPL)

      const scannedQtyInfoText = isFullfilled ? 'ครบถ้วน' : `(${sp.scannedQty}/${sp.needQty})`
      p.op.showToast(`${productFullName} ${scannedQtyInfoText}`, 'success')

      return true
    }
    return false
  }

  function scanFinishedProductSKU(scannedSKU: string): boolean {
    // Scanned Found Index
    const sIdx = finishedProductList.findIndex((pd) => pd.sku && pd.sku === scannedSKU)
    return scanFinishedProductAtIndex(sIdx)
  }

  function scanFinishedProductUPC(scannedUPC: string): boolean {
    // Scanned Found Index
    const sIdx = finishedProductList.findIndex((pd) => pd.upc && pd.upc === scannedUPC)
    return scanFinishedProductAtIndex(sIdx)
  }

  function scanFinishedProductAtIndex(scannedIndex: number): boolean {
    const sIdx = scannedIndex
    if (sIdx > -1) {
      // scanned product item
      const sp = finishedProductList[sIdx]
      const productFullName = getProductFullName(sp)
      p.op.alert(
        'สแกนสินค้าเกินจำนวนแล้ว',
        `โปรดตรวจสอบจำนวนสินค้า ${productFullName} อีกครั้ง เพื่อป้องกันไม่ให้แพ็คสินค้าเกินจำนวน (${sp.needQty})`
      )
      return true
    }
    return false
  }

  function hasSkuInPendingProducts() {
    //  ถ้าไม่ใช้ for-of ธรรมดาจะชิง break looop ไม่ได้
    for (let i = 0; i < pendingProductList.length; i++) {
      const ppi = pendingProductList[i]
      if (ppi.sku && ppi.sku !== '') {
        return true
      }
    }
    return false
  }

  function hasUpcInPendingProducts() {
    //  ถ้าไม่ใช้ for-of ธรรมดาจะชิง break looop ไม่ได้
    for (let i = 0; i < pendingProductList.length; i++) {
      const ppi = pendingProductList[i]
      if (ppi.sku && ppi.sku !== '') {
        return true
      }
    }
    return false
  }

  function renderNoBarcodeInfo() {
    if (!pendingProductList || pendingProductList.length === 0) {
      return null
    }
    return (
      <View style={[S.WIDTH_FULL, S.FLEX_WRAP, S.COLUMN_CENTER_TOP, S.BG_LIGHT_GREY, S.PADDING_8]}>
        <XText variant='inactive' style={[S.TEXT_ALIGN_CENTER, S.TEXT_ALIGN_VERTICAL_CENTER]}>
          {'กรุณากดติ๊กเพื่อตรวจสอบรายการสินค้า เนื่องจากในรายการสินค้าที่รอจัดส่งไม่มีรหัส SKU/UPC '}
        </XText>
      </View>
    )
  }

  async function _onBarcodeSegmentChange(newIndex: number) {
    setSegmentBarcodeIndex(newIndex)

    // save segment state
    await util.saveDefaultState(CONS.STATE_SAVE_KEYS.SHIPPING_PRODUCTS_CARD, { segmentBarcodeIndex: newIndex })
  }

  function renderBarcodeScanner() {
    if (isFinished || props.disabled || !props.expanded) {
      return null
    }

    const hasSKU = hasSkuInPendingProducts()
    const hasUPC = hasUpcInPendingProducts()
    if (!hasSKU && !hasUPC) {
      return renderNoBarcodeInfo()
    }

    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP]}>
        {/* <View style={{ height: 200, width: 320 }}>
          <BarcodeScanner onBarcodeScanned={_onBarcodeScanned}>
            <View style={{ backgroundColor: 'rgba(88,88,88,0.7)' }}>
              <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>สแกนออเดอร์</XText>
            </View>
          </BarcodeScanner>
        </View> */}
        <BarcodeScannerWithSegment
          saveStateKey={CONS.STATE_SAVE_KEYS.BARCODE_SCANNER_SHIPPING_PRODUCTS}
          inputPlaceholder='ระบุ SKU/UPC สินค้า...'
          disabled={isBarcodeRead}
          onBarcodeScanned={_onBarcodeScanned}
          selectedIndex={segmentBarcodeIndex}
          onChangeSegment={_onBarcodeSegmentChange}
        />
      </View>
    )
  }

  function _onRequestEditQty(index: number) {
    setOpenEditQtyAtIndex(index)
  }

  function _onRequestCloseEditQty() {
    setOpenEditQtyAtIndex(-1)
  }

  function _onSubmitEditQty(index: number, filledQty: number) {
    const sIdx = index // submittedIndex
    const newPPL = [...pendingProductList]

    // scanned product item (cloned)
    const sp = { ...newPPL[sIdx] }
    const productFullName = getProductFullName(sp)

    sp.scannedQty = filledQty
    sp.lastUpdatedAt = new Date()
    newPPL[sIdx] = { ...sp }

    const isNotInteger = !_.isInteger(sp.scannedQty)
    const isMinus = sp.scannedQty < 0
    const isExceeded = isShippedQtyExceeded(sp)
    if (isNotInteger || isMinus || isExceeded) {
      p.op.alert('ระบุจำนวนไม่ถูกต้อง', `กรุณาระบุจำนวนระหว่าง 0-${sp.needQty} สำหรับสินค้า ${productFullName}`)
      return
    }

    const isFullfilled = isShippedQtyFullfilled(sp)

    if (isFullfilled) {
      newPPL.splice(sIdx, 1)
      setFinishedProductList(finishedProductList.concat(sp))
    }

    newPPL.sort(sortPendingOrderProductItemByDateDesc)
    setPendingProductList(newPPL)

    if (!isCheckButtonPressed) {
      setIsCheckButtonPressed(true)
    }

    _onRequestCloseEditQty()
  }

  function _onPressCheckPendingProduct(index: number) {
    const checkProduct = { ...pendingProductList[index] }
    checkProduct.scannedQty = checkProduct.needQty

    const newFPL = [...finishedProductList]
    newFPL.push(checkProduct)
    setFinishedProductList(newFPL)

    const newPPL = [...pendingProductList]
    newPPL.splice(index, 1)
    setPendingProductList(newPPL)

    if (!isCheckButtonPressed) {
      setIsCheckButtonPressed(true)
    }
  }

  function _onPressResetFinisheProduct(index: number) {
    const removedProduct = { ...finishedProductList[index] }
    removedProduct.scannedQty = 0

    const newPPL = [...pendingProductList]
    newPPL.push(removedProduct)
    setPendingProductList(newPPL)

    const newFPL = [...finishedProductList]
    newFPL.splice(index, 1)
    setFinishedProductList(newFPL)

    if (!isCheckButtonPressed) {
      setIsCheckButtonPressed(true)
    }
  }

  function renderProductsListItem(pd: IShippingOrderProductItem, index: number) {
    // const isFullfilled = isShippedQtyFullfilled(pd)
    // const isShipped = pd.needQty === 0
    // const isFirstPending = !isFullfilled && index === 0

    // const textColorStyle = isFullfilled || isFinished ? S.TEXT_ACTIVE : S.TEXT_INACTIVE
    // const textBoldStyle = isFirstPending ? S.TEXT_BOLD : {}

    // // const productFullName = getProductFullName(pd)
    // const shippedQtyInfoText = `${pd.scannedQty}/${pd.needQty}`

    // const key = `${isFullfilled ? 'f' : 'p'}_${index}`
    // const isEven = index % 2 === 0

    // const onCheckPress = isFullfilled ? _onPressResetFinisheProduct : _onPressCheckPendingProduct

    return (
      <CheckShippingOrderProductItem
        key={index.toString()}
        index={index}
        product={pd}
        isFinished={isFinished}
        onCheckPress={_onPressCheckPendingProduct}
        onUncheckPress={_onPressResetFinisheProduct}
        onRequestEditQty={_onRequestEditQty}
      />
    )

    // return (
    //   <View
    //     key={key}
    //     style={[
    //       // S.FLEX,
    //       S.WIDTH_FULL,
    //       S.ROW_TOP_START,
    //       // S.PADDING_VERTICAL_6,
    //       // S.PADDING_HORIZONTAL_6,
    //       isEven ? S.BG_LIGHT_GREY : S.BG_WHITE,
    //       isFullfilled && !isFinished ? S.BG_SUCCESS : {},
    //       {
    //         borderTopColor: COLORS.TEXT_INACTIVE,
    //         borderTopWidth: 0.5,
    //       },
    //     ]}>
    //     {/* Checkbox */}
    //     {isFinished ? null : (
    //       <View style={[S.HEIGHT_FULL, S.WIDTH_34, S.ROW_CENTER, { borderRightColor: COLORS.TEXT_INACTIVE, borderRightWidth: 0.5 }]}>
    //         {isShipped ? null : (
    //           <CheckButton
    //             checkID={index}
    //             onPress={isFullfilled ? _onPressResetFinisheProduct : _onPressCheckPendingProduct}
    //             checked={isFullfilled}
    //           />
    //         )}
    //       </View>
    //     )}

    //     {/* Separator */}
    //     <View style={{ width: isFinished ? 0 : 4 }} />

    //     <View style={[S.ROW_TOP_START, S.PADDING_4]}>
    //       <FastImage style={[S.HEIGHT_44, S.WIDTH_44]} source={{ uri: pd.thumbnail_uris[0] }} resizeMode={'cover'} />
    //     </View>

    //     {/* Separator */}
    //     <View style={{ width: 4 }} />

    //     {/* Product Info */}
    //     <View style={[S.FLEX, S.COLUMN_LEFT_TOP, S.PADDING_4]}>
    //       {/* <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER]}>{productFullName}</XText> */}
    //       <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER]} numberOfLines={2}>
    //         {pd.name}
    //       </XText>
    //       {pd.variant && pd.variant !== '' ? <XText style={[S.TEXT_INACTIVE, S.TEXT_LARGER]}>{`ตัวเลือก: ${pd.variant}`}</XText> : null}
    //       {pd.sku && pd.sku !== '' ? <XText style={[S.TEXT_INACTIVE]}>{`SKU: ${pd.sku}`}</XText> : null}
    //       {pd.upc && pd.upc !== '' ? <XText style={[S.TEXT_INACTIVE]}>{`UPC: ${pd.upc}`}</XText> : null}
    //     </View>

    //     {/* Separator */}
    //     <View style={{ width: 4 }} />

    //     {/* Product Qty */}
    //     <View style={[S.MIN_WIDTH_44, S.COLUMN_RIGHT_TOP, S.PADDING_4]}>
    //       {isShipped ? (
    //         <XText style={[S.TEXT_SUCCESS, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_RIGHT]}>{'จัดส่งแล้ว'}</XText>
    //       ) : (
    //         <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_RIGHT]}>{shippedQtyInfoText}</XText>
    //       )}
    //     </View>
    //   </View>
    // )
  }

  function renderPendingProductsList() {
    return <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP]}>{pendingProductList.map(renderProductsListItem)}</View>
  }
  function renderFinishedProductsList() {
    return <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP]}>{finishedProductList.map(renderProductsListItem)}</View>
  }
  function renderShippedProductList() {
    return <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP]}>{shippedProductList.map(renderProductsListItem)}</View>
  }

  function renderProductTableHeader() {
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_TOP_12]}>
        <XText variant='inactive' bold textAlign='center'>
          {isFinished ? 'รายการสินค้า' : 'รายการสินค้าที่ต้องจัดส่ง'}
        </XText>
      </View>
    )
  }

  function _doCheckAllProducts() {
    // do check all
    const newFinishedList = pendingProductList
      .concat(finishedProductList)
      .map((pd) => ({ ...pd, scannedQty: pd.needQty, lastUpdatedAt: new Date() }))
    setFinishedProductList(newFinishedList)
    setPendingProductList([])
  }

  function _doUncheckAllProducts() {
    // do uncheck all
    const newPendingList = pendingProductList.concat(finishedProductList).map((pd) => ({ ...pd, scannedQty: 0, lastUpdatedAt: new Date() }))
    setPendingProductList(newPendingList)
    setFinishedProductList([])
  }

  function _onPressToggleCheckAllProducts() {
    // ถ้าเลือกอยู่แล้วทั้งหมด
    if (isCheckAll) {
      _doUncheckAllProducts()
      setIsCheckButtonPressed(false)
    } else {
      setIsCheckButtonPressed(true) // ใช้ป้องกันการข้าม step ไปสแกนเลขพัสดุ โดยไม่ได้ตั้งใจ
      _doCheckAllProducts()
    }
  }

  async function _onPressSubmitShippingProducts() {
    const summaryScannedCount = _getSummaryScannedCount()
    if (!summaryScannedCount || summaryScannedCount < 1) {
      await p.op.alertPromise('ยังไม่ได้ตรวจสอบสินค้า', 'กรุณาเลือกตรวจสอบสินค้าจำนวนอย่างน้อย 1 เพื่อทำการจัดส่ง')
      return
    }

    if (!isCheckAll) {
      const summaryNeedCount = _getSummaryNeedCount()
      const isUserConfirm = await p.op.isUserConfirm(
        '',
        `จัดส่งไม่ครบจำนวน\n${summaryScannedCount} จาก ${summaryNeedCount}\nใช่หรือไม่?`,
        'ใช่',
        'ไม่'
      )
      if (!isUserConfirm) {
        return
      }
    }
    await _onFinishScanProducts()
  }

  function renderProductTableCheackAllBar() {
    if (isFinished) {
      return null
    }
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_TOP_12]}>
        <View
          style={[S.WIDTH_FULL, S.ROW_MIDDLE_START, S.PADDING_VERTICAL_6, { borderTopColor: COLORS.TEXT_INACTIVE, borderTopWidth: 0.5 }]}>
          <TouchableOpacity
            onPress={_onPressToggleCheckAllProducts}
            style={[S.ROW_CENTER, { height: '100%', width: 34, borderRightColor: COLORS.TEXT_INACTIVE, borderRightWidth: 0.5 }]}>
            {isCheckAll ? (
              <Icon
                style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.BRAND_Success }}
                name='checkbox-multiple-marked-circle'
                type='MaterialCommunityIcons'
              />
            ) : (
              <Icon
                style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.TEXT_INACTIVE }}
                name='checkbox-blank-circle-outline'
                type='MaterialCommunityIcons'
              />
            )}
          </TouchableOpacity>
          <View style={[S.ROW_MIDDLE_START, { marginLeft: 8 }]}>
            <XText
              style={[
                S.TEXT_INACTIVE,
                S.TEXT_LARGER,
              ]}>{`ยืนยันจำนวนแล้ว ${_getSummaryScannedCount()} จาก ${_getSummaryNeedCount()}`}</XText>
          </View>
        </View>
      </View>
    )
  }

  function renderEditQtyOverlay() {
    if (openEditQtyAtIndex < 0) {
      return null
    }
    return (
      <EditQtyOverlay
        key={openEditQtyAtIndex.toString()}
        index={openEditQtyAtIndex}
        product={pendingProductList[openEditQtyAtIndex]}
        onRequestClose={_onRequestCloseEditQty}
        onSubmit={_onSubmitEditQty}
      />
    )
  }

  function renderSubmitShippingProductsButton() {
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, { paddingTop: 12, borderTopColor: COLORS.TEXT_INACTIVE, borderTopWidth: 0.5 }]}>
        {isFinished ? null : (
          <TouchableOpacity
            style={[
              S.BORDER,
              S.ROW_CENTER,
              S.HEIGHT_34,
              S.PADDING_HORIZONTAL_12,
              S.BORDER_RADIUS_8,
              isCheckAll ? S.BORDERS_PRIMARY : S.BORDERS_INACTIVE,
              isCheckAll ? S.BG_PRIMARY : S.BG_LIGHT_GREY,
            ]}
            onPress={_onPressSubmitShippingProducts}>
            <XText variant={isCheckAll ? 'activeDark' : 'inactive'} fontSize='md' bold={isCheckAll}>
              ยืนยันตรวจสอบสินค้าเสร็จสิ้น
            </XText>
          </TouchableOpacity>
        )}
      </View>
    )
  }

  function renderContent() {
    if (_.isNil(props.products) || props.products.length === 0) {
      return (
        <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.PADDING_8]}>
          <XText bold textAlign='center'>
            ไม่มีสินค้าที่ต้องจัดส่ง
          </XText>
        </View>
      )
    }

    if (pendingProductList.length === 0 && finishedProductList.length === 0 && shippedProductList.length > 0) {
      return (
        <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP, S.PADDING_8]}>
          <View style={[S.WIDTH_FULL, S.ROW_CENTER]}>
            <XText bold textAlign='center'>
              รายการสินค้าที่จัดส่งครบถ้วนแล้ว
            </XText>
          </View>
          {renderShippedProductList()}
          <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_8]}>
            <XText variant='danger' bold textAlign='center'>
              {'ไม่สามารถดำเนินการต่อได้\nเนื่องจากออเดอร์นี้ไม่มีจำนวนสินค้าค้างจัดส่งแล้ว'}
            </XText>
          </View>
          <View style={[S.WIDTH_FULL, S.ROW_CENTER, S.MARGIN_TOP_16]}>
            <TouchableOpacity
              style={[
                S.BORDER,
                S.BORDERS_PRIMARY,
                S.BORDER_RADIUS_8,
                S.HEIGHT_34,
                S.BG_PRIMARY,
                S.ROW_CENTER,
                S.PADDING_VERTICAL_4,
                S.PADDING_HORIZONTAL_8,
              ]}
              onPress={props.onRequestReset}>
              <XText variant='activeDark' bold textAlign='center'>
                สแกนออเดอร์ใหม่
              </XText>
            </TouchableOpacity>
          </View>
        </View>
      )
    }

    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_LEFT_TOP, S.PADDING_8]}>
        <ShippingOrderProductChecker
          disabled={!props.products || props.disabled || isFinished || isNoNeedQty}
          pendingProductList={pendingProductList}
          finishedProductList={finishedProductList}
          skip={props.skip}
          isCheckButtonPressed={isCheckButtonPressed}
          doCheckAllProducts={() => _doCheckAllProducts()}
          onFinishScanProducts={() => _onFinishScanProducts()}
        />
        {renderSkipScanSettingInfo()}
        {renderBarcodeScanner()}
        {renderProductTableHeader()}
        {renderProductTableCheackAllBar()}
        {renderPendingProductsList()}
        {renderFinishedProductsList()}
        {renderShippedProductList()}
        {renderSubmitShippingProductsButton()}
        {renderEditQtyOverlay()}
      </View>
    )
  }

  function renderSkipScanSettingInfo() {
    if (!props.showSkipScanSettingInfo) {
      return null
    }
    return (
      <View style={[S.WIDTH_FULL, S.COLUMN_CENTER_TOP, S.MARGIN_VERTICAL_8]}>
        <View style={[S.PADDING_8, S.BG_LIGHT_GREY]}>
          <XText variant='inactive' textAlign='center'>
            {'ต้องการสแกนตรวจสอบ หรือ แก้ไขจำนวนจัดส่ง?\nกดที่ปุ่ม "เฟือง" ด้านขวาเพื่อตั้งค่าได้'}
          </XText>
        </View>
      </View>
    )
  }

  function _getSummaryScannedCount() {
    const summaryOrderProducts = finishedProductList.concat(pendingProductList)
    const scannedCount = summaryOrderProducts.reduce((prevCount, currProduct) => prevCount + currProduct.scannedQty, 0)
    return scannedCount
  }

  function _getSummaryNeedCount() {
    const summaryOrderProducts = finishedProductList.concat(pendingProductList)
    const needCount = summaryOrderProducts.reduce((prevCount, currProduct) => prevCount + currProduct.needQty, 0)
    return needCount
  }

  function _renderProductCountSummary() {
    if (!_.isArray(props.products) || (pendingProductList.length === 0 && finishedProductList.length === 0)) {
      return null
    }
    const scannedCount = _getSummaryScannedCount()
    const needCount = _getSummaryNeedCount()
    const isCompleted = scannedCount === needCount
    return (
      <View style={[S.ROW_MIDDLE_END, { width: 120 }]}>
        {/* <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_LARGER_2]}>{`(${scannedCount}/${needCount})`}</XText> */}
        <XText bold fontSize='md'>
          {'( '}
        </XText>
        <XText variant={isCompleted ? 'success' : 'primary'} bold fontSize='md'>
          {scannedCount}
        </XText>
        <XText bold fontSize='md'>
          {' / '}
        </XText>
        <XText bold fontSize='md'>
          {needCount}
        </XText>
        <XText bold fontSize='md'>
          {' ) '}
        </XText>
      </View>
    )
  }

  if (!isInitialized) {
    return null
  }

  return (
    <CollapsableCard
      disabledAnimation
      disabled={isForceExpanded}
      expanded={props.expanded && !isPreventExpand}
      onPressToggle={props.onPressToggle}
      renderContent={renderContent}>
      <View
        style={[
          S.HEIGHT_44,
          S.WIDTH_FULL,
          S.ROW_MIDDLE_BETWEEN,
          S.PADDING_8,
          props.disabled ? S.BG_GREY : S.BG_SECONDARY,
          isFinished ? S.BG_SUCCESS : {},
          isNoNeedQty ? S.BG_DANGER : {},
        ]}>
        <View style={[S.FLEX, S.ROW_MIDDLE_START]}>
          <XText variant={isForceExpanded ? 'active' : 'inactive'} bold>
            {isFinished ? '2. สินค้าที่จัดส่ง' : '2. ตรวจสอบสินค้า'}
          </XText>
        </View>
        {_renderProductCountSummary()}
      </View>
      {/* <TouchableOpacity onPress={() => _onBarcodeScanned({ data: 'YELLOW-PART-01-05' })}>
        <XText>DEBUG:: Scan SKU YELLOW-PART-01-05</XText>
      </TouchableOpacity>
      <TouchableOpacity onPress={() => _onBarcodeScanned({ data: 'SKU10' })}>
        <XText>DEBUG:: Scan SKU SKU10</XText>
      </TouchableOpacity> */}
    </CollapsableCard>
  )
}

// side components
const ShippingOrderProductChecker = ({
  disabled = false,
  pendingProductList,
  finishedProductList,
  isCheckButtonPressed,
  onFinishScanProducts,
  doCheckAllProducts,
  skip = false,
}) => {
  React.useEffect(() => {
    _doShippingOrderProductCheck()
  }, [pendingProductList])

  function _doShippingOrderProductCheck() {
    if (disabled) {
      return
    }
    if (pendingProductList.length === 0 && finishedProductList.length > 0) {
      if (!isCheckButtonPressed) {
        onFinishScanProducts()
      }
    } else if (skip) {
      doCheckAllProducts()
    }
  }

  return null
}

const CheckButton = ({ checkID, onPress, checked, style = {} }) => {
  function _onPressCheckBtn() {
    onPress(checkID)
  }
  return (
    <TouchableOpacity
      key={`CheckButton_${checkID}`}
      onPress={_onPressCheckBtn}
      style={[S.WIDTH_FULL, S.HEIGHT_FULL, S.ROW_CENTER, S.BG_LIGHT_GREY, StyleSheet.flatten(style)]}>
      <CheckIcon checked={checked} />
    </TouchableOpacity>
  )
}

const CheckIcon = ({ checked = false }) => {
  if (checked) {
    return (
      <Icon
        type='FontAwesome'
        name='check-circle'
        style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_SUCCESS, backgroundColor: 'transparent' }}
      />
    )
  }
  return (
    <Icon
      type='FontAwesome'
      name='circle-o'
      style={{ fontSize: STYLES.FONT_ICON_NORMAL, color: COLORS.FORM_SUCCESS, backgroundColor: 'transparent' }}
    />
  )
}

interface CheckShippingOrderProductItemProps {
  index: number
  product: IShippingOrderProductItem
  isFinished?: boolean
  onCheckPress: (index: number) => void
  onUncheckPress: (index: number) => void
  onRequestEditQty: (index: number) => void
}

const CheckShippingOrderProductItem: React.FC<CheckShippingOrderProductItemProps> = ({
  index,
  product,
  isFinished = false,
  onCheckPress,
  onUncheckPress,
  onRequestEditQty,
}) => {
  const pd = product
  const isFullfilled = isShippedQtyFullfilled(pd)
  const isShipped = pd.needQty === 0

  const isFirstPending = !isFullfilled && index === 0

  const textColorStyle = isFullfilled || isFinished ? S.TEXT_ACTIVE : S.TEXT_INACTIVE
  const textBoldStyle = isFirstPending ? S.TEXT_BOLD : {}

  // const productFullName = getProductFullName(pd)
  const shippedQtyInfoText = `${pd.scannedQty}/${pd.needQty}`

  const isEven = index % 2 === 0

  return (
    <View
      style={[
        // S.FLEX,
        S.WIDTH_FULL,
        // S.FLEX_WRAP,
        S.ROW_TOP_START,
        // S.PADDING_VERTICAL_6,
        // S.PADDING_HORIZONTAL_6,
        isEven ? S.BG_LIGHT_GREY : S.BG_WHITE,
        isFullfilled && !isFinished ? S.BG_SUCCESS : {},
        {
          borderTopColor: COLORS.TEXT_INACTIVE,
          borderTopWidth: 0.5,
        },
      ]}>
      {/* Checkbox */}
      {isFinished ? null : (
        <View
          style={[
            S.FLEX,
            S.ROW_CENTER,
            { minWidth: 34, maxWidth: 34, alignSelf: 'stretch', borderRightColor: COLORS.TEXT_INACTIVE, borderRightWidth: 0.5 },
          ]}>
          {isShipped ? null : (
            <CheckButton
              checkID={index}
              // onPress={isFullfilled ? _onPressResetFinisheProduct : _onPressCheckPendingProduct}
              onPress={isFullfilled ? onUncheckPress : onCheckPress}
              checked={isFullfilled}
            />
          )}
        </View>
      )}

      {/* Separator */}
      <View style={{ width: isFinished ? 0 : 4 }} />

      <View style={[S.ROW_TOP_START, S.FLEX_WRAP, S.PADDING_4]}>
        <FastImage style={[S.HEIGHT_44, S.WIDTH_44]} source={{ uri: pd.thumbnail_uris[0] }} resizeMode='cover' />
      </View>

      {/* Separator */}
      <View style={{ width: 4 }} />

      {/* Product Info */}
      <View style={[S.FLEX, S.FLEX_WRAP, S.COLUMN_LEFT_TOP, S.PADDING_4]}>
        {/* <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER]}>{productFullName}</XText> */}
        <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER, S.FLEX_SHRINK]} numberOfLines={2}>
          {pd.name}
        </XText>
        {pd.variant && pd.variant !== '' ? (
          <XText style={[S.TEXT_INACTIVE, S.TEXT_LARGER, S.FLEX_SHRINK]} numberOfLines={1}>{`ตัวเลือก: ${pd.variant}`}</XText>
        ) : null}
        {pd.sku && pd.sku !== '' ? (
          <XText style={[S.TEXT_INACTIVE, S.FLEX_SHRINK]} numberOfLines={1} ellipsizeMode='tail'>{`SKU: ${pd.sku}`}</XText>
        ) : null}
        {pd.upc && pd.upc !== '' ? (
          <XText style={[S.TEXT_INACTIVE, S.FLEX_SHRINK]} numberOfLines={1} ellipsizeMode='tail'>{`UPC: ${pd.upc}`}</XText>
        ) : null}
      </View>

      {/* Separator */}
      <View style={{ width: 4 }} />

      {/* Product Qty */}
      <View style={[S.MIN_WIDTH_44, S.FLEX_WRAP, S.COLUMN_RIGHT_TOP, S.PADDING_4]}>
        {isShipped ? (
          <>
            <XText style={[S.TEXT_SUCCESS, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_RIGHT]}>จัดส่งแล้ว</XText>
            <XText style={[S.TEXT_INACTIVE, S.TEXT_ALIGN_RIGHT]}>{`(${pd.shipped_qty}/${pd.qty})`}</XText>
          </>
        ) : null}
        {!isShipped && (isFullfilled || isFinished) ? (
          <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_RIGHT]}>{shippedQtyInfoText}</XText>
        ) : null}
        {!isShipped && !isFullfilled && !isFinished ? (
          <>
            <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_RIGHT]}>{shippedQtyInfoText}</XText>
            <TouchableOpacity style={[S.PADDING_8, { paddingRight: 0 }]} onPress={() => onRequestEditQty(index)}>
              <Icon name='edit' type='FontAwesome' style={{ fontSize: STYLES.FONT_ICON_SMALLER, color: COLORS.APP_MAIN }} />
            </TouchableOpacity>
          </>
        ) : null}
        {/* {!isShipped && !isFullfilled ? (
          <TouchableOpacity
            style={[
              S.BORDER,
              S.BORDERS_INACTIVE,
              S.ROW_CENTER,
              S.PADDING_VERTICAL_2,
              S.PADDING_HORIZONTAL_4,
              S.BORDER_RADIUS_8,
              S.BG_SECONDARY,
              { width: 100 },
            ]}
            onPress={() => onRequestEditQty(index)}>
            <XText style={[textColorStyle, textBoldStyle, S.TEXT_LARGER, S.TEXT_ALIGN_CENTER, S.TEXT_ALIGN_VERTICAL_CENTER]}>
              {'9877/9876'}
            </XText>
          </TouchableOpacity>
        ) : null} */}
      </View>
    </View>
  )
}

interface IEditQtyOverlayProps {
  index: number
  product?: IShippingOrderProductItem
  onRequestClose: () => void
  onSubmit: (index: number, qty: number) => void
}

const EditQtyOverlay: React.FC<IEditQtyOverlayProps> = ({ index, product, onRequestClose, onSubmit }) => {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(false)
  const [inputQtyText, setInputQtyText] = React.useState('')

  React.useEffect(() => {
    if (index > -1) {
      if (!isOverlayVisible) {
        setIsOverlayVisible(true)

        if (product && _isNormalInteger(product.needQty.toString())) {
          setInputQtyText(product.needQty.toString())
        }
      }
    } else {
      setIsOverlayVisible(false)
    }
  }, [index])

  function _onChangeInputQtyText(newText: string) {
    setInputQtyText(newText)
  }

  function _onSubmitInputQty() {
    if (!_isNormalInteger(inputQtyText)) {
      p.op.alert('ระบุข้อมูลไม่ถูกต้อง', `กรุณาระบุเป็นตัวเลขจำนวนเต็ม ระหว่าง 0-${needQty}`)
    }
    onSubmit(index, parseInt(inputQtyText))
  }
  function _isNormalInteger(str: string) {
    const n = Math.floor(Number(str))
    return n !== Infinity && String(n) === str && n >= 0
  }

  if (!product || !_.isNumber(index) || index < 0) {
    return null
  }

  const productFullName = getProductFullName(product)
  const { needQty } = product

  return (
    <XOverlay
      // contentStyle={{ padding: 8 }}
      contentStyle={{
        width: 300,
        minHeight: 220,
        height: p.op.isWeb() ? 220 : undefined,

        // avoid keyboard style
        ...Platform.select({
          ios: {
            marginBottom: 200,
          },
          // android: {
          //   marginBottom: 35,
          // },
        }),
      }}
      visible={index > -1}
      onRequestClose={onRequestClose}>
      <View style={[S.FLEX, S.BG_WHITE]}>
        <XCustomHeader headerRightProps={{ closeIcon: true, onPressItem: onRequestClose }} title='ระบุจำนวนสินค้าที่จัดส่ง' />
        <View style={[S.WIDTH_FULL, S.BOTTOM_LINE, { height: 1 }]} />
        <View style={[S.COLUMN_LEFT_TOP, S.WIDTH_FULL, S.PADDING_HORIZONTAL_12]}>
          {/* // content begin // */}

          <View style={[S.ROW_CENTER, S.WIDTH_FULL, S.MARGIN_TOP_6, S.FLEX_WRAP]}>
            <XText style={[S.TEXT_ALIGN_VERTICAL_CENTER, S.TEXT_ALIGN_CENTER]} numberOfLines={1}>
              {product.name}
            </XText>
          </View>

          <View style={[S.ROW_CENTER, S.WIDTH_FULL, S.MARGIN_TOP_6, S.FLEX_WRAP]}>
            <XText variant='inactive' style={[S.TEXT_ALIGN_VERTICAL_CENTER, S.TEXT_ALIGN_CENTER]} numberOfLines={1}>
              {product.variant}
            </XText>
          </View>

          <View style={[S.COLUMN_CENTER_TOP, S.WIDTH_FULL, S.HEIGHT_34, S.MARGIN_TOP_6]}>
            <XInput
              placeholder='ระบุจำนวนสินค้า...'
              autoFocus
              textAlign='center'
              focusable
              isInteger
              style={{ flex: 1, maxWidth: 120 }}
              value={inputQtyText}
              onChangeText={_onChangeInputQtyText}
              onSubmitEditing={_onSubmitInputQty}
            />
          </View>

          <View style={[S.COLUMN_CENTER_TOP, S.WIDTH_FULL, S.MARGIN_TOP_6]}>
            <XText variant='inactive'>{`(ระบุจำนวนระหว่าง 0-${needQty})`}</XText>
          </View>

          <View style={[S.ROW_CENTER, S.WIDTH_FULL, S.MARGIN_TOP_6]}>
            <TouchableOpacity
              onPress={_onSubmitInputQty}
              style={{
                backgroundColor: COLORS.APP_MAIN,
                borderRadius: 7,
                height: 34,
                width: 80,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <XText variant='activeDark' bold>
                ยืนยัน
              </XText>
            </TouchableOpacity>
          </View>

          {/* // content end // */}
        </View>
      </View>
    </XOverlay>
  )
}

// compute functions
function convertOrderProductItemToPendingOrderProductItem(pd: IOrderProductItem) {
  const lastUpdatedAt = new Date()
  const convertedProduct: IShippingOrderProductItem = {
    ...pd,
    needQty: pd.qty - pd.shipped_qty,
    scannedQty: 0,
    lastUpdatedAt,
  }
  return convertedProduct
}

function filterAvailableToShipQty(convertedProducts: IShippingOrderProductItem, idx: number) {
  return convertedProducts.needQty && convertedProducts.needQty > 0
}

function filterShipedQty(convertedProducts: IShippingOrderProductItem, idx: number) {
  return !convertedProducts.needQty || convertedProducts.needQty === 0
}

function sortPendingOrderProductItemByDateDesc(pdA: IShippingOrderProductItem, pdB: IShippingOrderProductItem) {
  return pdB.lastUpdatedAt.getTime() - pdA.lastUpdatedAt.getTime()
}

function isShippedQtyFullfilled(pd: IShippingOrderProductItem) {
  return pd.scannedQty >= pd.needQty
}
function isShippedQtyExceeded(pd: IShippingOrderProductItem) {
  return pd.scannedQty > pd.needQty
}
function getProductFullName(pd: IShippingOrderProductItem) {
  return pd.name + (pd.variant ? ` ${pd.variant}` : '')
}

ShippingProductsCard.displayName = 'ShippingProductsCard'
// ShippingProductsCard.defaultProps = {}

export default ShippingProductsCard
