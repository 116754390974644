import React from 'react'
import { FlatList, TouchableOpacity, ListRenderItemInfo } from 'react-native'

import * as util from 'x/utils/util'

import _ from 'lodash'
import {
  IAddress,
  IAddressFormSubmitReturn,
  ICreateStoreContactRequestBody,
  ICreateStoreContactWithAddress,
  IStoreContactDetailNavParams,
  IStoreContactListItem,
  IStoreContactListViewProps,
  IStoreContactListViewState,
} from 'x/index'
import XContainer from 'xui/components/XContainer'
import { VStack } from 'native-base'
import XText from 'xui/components/XText'
import HStack from 'xui/components/HStack'
import * as api from 'x/utils/api'
import XInput from 'xui/components/XInput'
import XIconButton from 'xui/components/XIconButton'
import * as NavActions from 'x/utils/navigation'
import ForwardIcon from 'xui/components/ForwardIcon'
import XSpinner from 'xui/components/XSpinner'
import XCustomHeader from 'xui/components/XCustomHeader'
import XIcon from 'xui/components/XIcon'

const FETCH_LIMIT = 40

export default class StoreContactListView extends React.Component<IStoreContactListViewProps, IStoreContactListViewState> {
  static displayName = 'StoreContactListView'

  isRefreshing?: boolean

  isLoadingMore?: boolean

  inQueueSearch?: NodeJS.Timeout

  constructor(props) {
    super(props)
    this.state = {
      isInitialized: false,
      isRefreshing: false,
      isLoadingMore: false,

      contacts: [],
      hasMore: false,

      searchContacts: [],
      hasSearchMore: false,
      searchText: '',
    }
  }

  async componentDidMount(): Promise<void> {
    await this._initialize()
  }

  _initialize = async () => {
    await this._refresh()
    await util.setStatePromise(this, { isInitialized: true })
  }

  _refresh = async () => {
    if (this.isRefreshing) {
      return
    }

    this.isRefreshing = true
    await util.setStatePromise(this, { isRefreshing: true })

    if (this.isSearch()) {
      await util.setStatePromise(this, { isSearching: true })
    }

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const reqBody: any = {
        store_id,
        offset: 0,
        limit: FETCH_LIMIT,
      }

      if (this.isSearch()) {
        reqBody.search_name = this.state.searchText
      }

      const loadedContacts = await api.getStoreContactList(reqBody)
      const hasMore = loadedContacts.length === FETCH_LIMIT

      if (this.isSearch()) {
        await util.setStatePromise(this, { searchContacts: loadedContacts, hasSearchMore: hasMore })
      } else {
        await util.setStatePromise(this, { contacts: loadedContacts, hasMore })
      }
    } catch (err) {
      console.log('_refresherr => ', err)
    }

    await util.setStatePromise(this, { isRefreshing: false, isSearching: false })
    this.isRefreshing = false
  }

  _loadMore = async () => {
    if (this.isRefreshing || this.isLoadingMore) {
      return
    }

    const { hasMore, hasSearchMore } = this.state
    if (!this.isSearch() && !hasMore) {
      return
    }

    if (this.isSearch() && !hasSearchMore) {
      return
    }

    this.isLoadingMore = true
    await util.setStatePromise(this, { isLoadingMore: true })

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const { contacts, searchContacts, searchText } = this.state
      const offset = this.isSearch() ? searchContacts.length : contacts.length
      const reqBody: any = {
        store_id,
        offset,
        limit: FETCH_LIMIT,
      }

      if (this.isSearch()) {
        reqBody.search_name = searchText
      }

      const loadedContacts = await api.getStoreContactList(reqBody)
      const hasMore = loadedContacts.length === FETCH_LIMIT

      if (this.isSearch()) {
        await util.setStatePromise(this, { searchContacts: searchContacts.concat(loadedContacts), hasSearchMore: hasMore })
      } else {
        await util.setStatePromise(this, { contacts: contacts.concat(loadedContacts), hasMore })
      }
    } catch (err) {
      console.log('_refresherr => ', err)
    }

    await util.setStatePromise(this, { isLoadingMore: false })
    this.isLoadingMore = false
  }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _onSearchTextChange = (text: string) => {
    const isSearching = text.length > 0

    this.setState({ searchText: text, searchContacts: [], hasSearchMore: false, isSearching }, () => {
      if (this.inQueueSearch) {
        clearTimeout(this.inQueueSearch)
      }

      this.inQueueSearch = setTimeout(this._refresh, 1000)
    })
  }

  _clearSearchText = () => {
    this.setState({ searchContacts: [], hasSearchMore: false, isSearching: false, searchText: '' })
  }

  _onSubmitAddContact = async (contactDetail: ICreateStoreContactWithAddress): Promise<IAddressFormSubmitReturn> => {
    console.log('_onSubmitAddContact => ', contactDetail)

    let shouldGoBack = false

    try {
      const store_id = util.getNavParam(this.props, 'store_id')
      const fullName = `${contactDetail.first_name} ${contactDetail.last_name || ''}`
      console.log('_onSubmitAddContact fullName => ', fullName)

      const address: IAddress = {
        ...contactDetail,
        name: fullName,

        // @ts-ignore
        store_id,
        // contact_type: 'main',
        // contact_type: 'shipping',
      }

      // @ts-ignore
      delete address.first_name
      // @ts-ignore
      delete address.last_name

      if (address.address2 === '') {
        delete address.address2
      }

      // @ts-ignore
      if (address.address3 === '') {
        // @ts-ignore
        delete address.address3
      }

      const reqBody: ICreateStoreContactRequestBody = {
        store_id,
        first_name: contactDetail.first_name,
        last_name: contactDetail.last_name || '',
        phone: contactDetail.telephone || '',
        email: contactDetail.email || '',
        address,
      }

      console.log('_onSubmitAddContact reqBody => ', reqBody)

      const newContact = await api.createStoreContact(reqBody)
      if (newContact && 'id' in newContact) {
        await util.setStatePromise(this, { contacts: [newContact, ...this.state.contacts] })
        shouldGoBack = true
      }
    } catch (error) {
      console.log('_onSubmitAddContact error => ', error)
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: contactDetail,
    }
  }

  _getRandomFullName = () => {
    const firstNames = [
      'Aaron',
      'Beth',
      'Charles',
      'Diana',
      'Edward',
      'Fiona',
      'George',
      'Hannah',
      'Isaac',
      'Julia',
      'Kevin',
      'Lily',
      'Matthew',
      'Nina',
      'Oliver',
      'Paula',
      'Quincy',
      'Rachel',
      'Steven',
      'Tina',
      'Ulysses',
      'Victoria',
      'William',
      'Xena',
      'Yvonne',
      'Zachary',
    ]

    const lastNames = [
      'Anderson',
      'Brown',
      'Carter',
      'Davis',
      'Evans',
      'Foster',
      'Gonzalez',
      'Harris',
      'Irvine',
      'Jackson',
      'King',
      'Lee',
      'Morgan',
      'Nelson',
      'Owens',
      'Parker',
      'Quinn',
      'Reed',
      'Scott',
      'Turner',
      'Underwood',
      'Vance',
      'White',
      'Xiong',
      'Young',
      'Zimmerman',
    ]

    const firstName = firstNames[Math.floor(Math.random() * firstNames.length)]
    const lastName = lastNames[Math.floor(Math.random() * lastNames.length)]

    return [firstName, lastName]
  }

  _onSubmitAddMockContacts = async (contactDetail: ICreateStoreContactWithAddress): Promise<IAddressFormSubmitReturn> => {
    console.log('_onSubmitAddContact => ', contactDetail)

    let shouldGoBack = false

    for (let i = 0; i < 100; i++) {
      try {
        const store_id = util.getNavParam(this.props, 'store_id')

        const [firstName, lastName] = this._getRandomFullName()

        const address: IAddress = {
          ...contactDetail,
          name: `${firstName} ${lastName}`,

          // @ts-ignore
          store_id,
          // contact_type: 'main',
          // contact_type: 'shipping',
        }

        // @ts-ignore
        delete address.first_name
        // @ts-ignore
        delete address.last_name

        if (address.address2 === '') {
          delete address.address2
        }

        // @ts-ignore
        if (address.address3 === '') {
          // @ts-ignore
          delete address.address3
        }

        const reqBody: ICreateStoreContactRequestBody = {
          store_id,
          first_name: firstName,
          last_name: lastName || '',
          phone: contactDetail.telephone || '',
          email: contactDetail.email || '',
          address,
        }

        // eslint-disable-next-line no-await-in-loop
        const newContact = await api.createStoreContact(reqBody)
        if (newContact && 'id' in newContact) {
          // eslint-disable-next-line no-await-in-loop
          await util.setStatePromise(this, { contacts: [newContact, ...this.state.contacts] })
          shouldGoBack = true
        }
      } catch (error) {
        console.log('_onSubmitAddContact error => ', error)
      }
    }

    return {
      isGoBack: shouldGoBack,
      newAddress: contactDetail,
    }
  }

  _navToAddContact = () => {
    const { navigation } = this.props

    navigation.dispatch(
      NavActions.navToAddressFormView({
        headerTitle: 'เพิ่มรายชื่อลูกค้า',
        type: 'create_store_contact',
        onSubmit: this._onSubmitAddContact,

        // onSubmit: this._onSubmitAddMockContacts,
      })
    )
  }

  _onSuccessDeleteContact = (deletedContactId: number) => {
    const { contacts } = this.state

    this.setState({ contacts: contacts.filter((c) => c.id !== deletedContactId) })
  }

  _onAddressCardPress = (address: IAddress) => {
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')

    onAddressCardPress(address)
    this._goBack()
  }

  _navToContactDetail = (listItem: IStoreContactListItem) => {
    const { navigation } = this.props
    const contactDetailTitle = util.getNavParam(this.props, 'contactDetailTitle')
    const onAddressCardPress = util.getNavParam(this.props, 'onAddressCardPress')

    const navParams: IStoreContactDetailNavParams = {
      store_id: util.getNavParam(this.props, 'store_id'),
      contact_id: listItem.id,
      onSuccessDeleteContact: this._onSuccessDeleteContact,
    }

    if (_.isFunction(onAddressCardPress)) {
      navParams.onAddressCardPress = this._onAddressCardPress
    }

    if (_.isString(contactDetailTitle)) {
      navParams.title = contactDetailTitle
    }

    navigation.dispatch(NavActions.navToStoreContactDetailView(navParams))
  }

  renderCustomHeader = () => {
    // return <XCustomHeader title='รายชื่อลูกค้า' headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} />
    const { searchText = '' } = this.state
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)

    return (
      <VStack w='full'>
        <XCustomHeader
          title='รายชื่อลูกค้า'
          headerLeftProps={isGoBackButtonHidden ? null : { backIcon: true, onPressItem: this._goBack }}
          headerRight={
            <TouchableOpacity onPress={this._navToAddContact} style={{ width: 44, alignItems: 'center', justifyContent: 'center' }}>
              {/* <AddIcon /> */}
              <XIcon name='person-add' />
            </TouchableOpacity>
          }
        />

        <HStack
          w='full'
          px='2'
          py='1'
          minH='44px'
          space='1'
          alignItems='center'
          justifyContent='center'
          borderBottomWidth='1'
          borderBottomColor='gray.300'>
          {/* {isGoBackButtonHidden ? null : <NavHeaderButton backIcon onPressItem={this._goBack} />} */}
          <HStack flex={1}>
            <XInput style={{ width: '100%' }} value={searchText} onChangeText={this._onSearchTextChange} placeholder='ค้นหาชื่อลูกค้า...' />

            {this.isSearch() && (
              <HStack position='absolute' right='0' top='0' bottom='0'>
                <XIconButton name='close' onPress={this._clearSearchText} />
              </HStack>
            )}
          </HStack>
          {/* <TouchableOpacity onPress={this._navToAddContact} style={{ width: 44, alignItems: 'center', justifyContent: 'center' }}>
          <AddIcon />
        </TouchableOpacity> */}
        </HStack>
      </VStack>
    )
  }

  renderContactListItem = (itemInfo: ListRenderItemInfo<IStoreContactListItem>) => {
    const { index, item } = itemInfo

    return (
      <VStack w='full'>
        <TouchableOpacity style={{ width: '100%' }} onPress={() => this._navToContactDetail(item)}>
          <HStack
            w='full'
            p='2'
            space='1'
            alignItems='center'
            borderBottomWidth='1'
            borderBottomColor='gray.300'
            minH='44px'
            bg={index % 2 === 0 ? 'white' : 'coolGray.50'}>
            <HStack flex={1}>
              <XText w='full' numberOfLines={1}>{`${item.first_name} ${item.last_name}`}</XText>
            </HStack>

            <ForwardIcon />
          </HStack>
        </TouchableOpacity>
      </VStack>
    )
  }

  keyExtractor = (item, index) => `cItem_${index}_${item.id}`

  isSearch = () => {
    const { searchText = '' } = this.state
    return _.isString(searchText) && searchText.length > 0
  }

  _renderListFooter = () => {
    const { isLoadingMore, isSearching, searchContacts, searchText } = this.state

    if (isSearching) {
      return (
        <HStack w='full' px='4' py='8' bg='white' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    if (isLoadingMore) {
      return (
        <HStack w='full' p='4' bg='white' alignItems='center' justifyContent='center'>
          <XSpinner variant='inactive' />
        </HStack>
      )
    }

    if (this.isSearch() && !isSearching && searchContacts.length === 0) {
      return (
        <HStack w='full' p='4' bg='white' alignItems='center' justifyContent='center'>
          <XText variant='inactive'>{`ไม่พบรายชื่อลูกค้าจาก "${searchText}"`}</XText>
        </HStack>
      )
    }

    return (
      <HStack w='full' p='2' bg='white' alignItems='center' justifyContent='center'>
        <XText variant='inactive'>สิ้นสุดรายการ</XText>
      </HStack>
    )
  }

  renderContactList = () => {
    const { contacts, searchContacts, isRefreshing, isInitialized } = this.state
    if (!isInitialized) {
      return null
    }

    const cts = this.isSearch() ? searchContacts : contacts
    const listKey = this.isSearch() ? 'searchContacts' : 'contacts'

    return (
      <FlatList<IStoreContactListItem>
        key={listKey}
        data={cts}
        refreshing={isRefreshing}
        onRefresh={this._refresh}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderContactListItem}
        onEndReached={this._loadMore}
        onEndReachedThreshold={0.8}
        ListFooterComponent={this._renderListFooter}
      />
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {/* <XContent> */}
        {/* <VStack w='full'> */}
        {this.renderContactList()}
        {/* </VStack> */}
        {/* </XContent> */}
      </XContainer>
    )
  }
}
