import React from 'react'
import _ from 'lodash'
import { List } from 'immutable'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'

import p from 'x/config/platform-specific'
import api from 'x/utils/api'
import { STYLES } from 'x/config/styles'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'

import {
  IProductListViewProps,
  IProductListViewState,
  IProductListViewMkpMappingParams,
  ISelectedStoreMap,
  ImmutableObject,
  IMKPChannelDetail,
  IMKPProductItemData,
  IApiOptions,
  IMkpProductDetail,
  IProductItemData,
  IViewProductRequest,
  IViewProductResponse,
} from 'x/index'

import { IMkpProductContextProps } from 'x/modules/marketplace/MkpProductContext'
import XText from 'xui/components/XText'
import XIconButton from 'xui/components/XIconButton'
import XButton from 'xui/components/XButton'
import VStack from 'xui/components/VStack'
import XSellyLogo from 'xui/components/XSellyLogo'
import BaseUIProductListView from './BaseUIProductListView'
import MkpLogo from '../../components/MkpLogo'

import { withMkpProductContextPropsAndRef } from '../marketplace/MkpProductContextHOC'
import XImage from 'xui/components/XImage'
// import { connect } from 'react-redux'
// import { getSelectedStore, getSelectedProduct } from 'x/redux/selectors'

interface IProductListViewMkpMappingProps extends IProductListViewProps<IProductListViewMkpMappingParams>, IMkpProductContextProps {
  selectedProduct: ISelectedStoreMap
  mkpChannels: List<ImmutableObject<IMKPChannelDetail>>
}

interface IProductListViewMkpMappingState extends IProductListViewState {
  isPreviewPeekProductVisible?: boolean
  selectedPeekProductItem?: {
    name: string
    description: string
    thumbnail_url: string
  }
  selectedMkpProductListItem?: IMKPProductItemData
  isMkpProductFetching?: boolean
}

class ProductListViewMkpMapping extends BaseUIProductListView<
  IProductListViewMkpMappingProps,
  IProductListViewMkpMappingState,
  // IMKPProductItemData
  IProductItemData
> {
  static displayName = 'ProductListViewMkpMapping'

  inProcessMkpProductFetching?: boolean

  inProductPeekProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      isPreviewPeekProductVisible: false,
      selectedPeekProductItem: null,
      selectedMkpProductListItem: null,
      isMkpProductFetching: false,
    }
  }

  // override
  // getApiEndpoint = () => 'mkp/products'
  // getApiEndpoint = () => 'mkp/v2/products'

  // true
  // isGoApi = () => true
  isOmitStockQty = () => true

  // override
  isProductListItemPressDisabled = () => true

  // // override
  // isProductListItemSellerIconDisabled = () => true

  // override
  isProductListItemPriceDisabled = () => true

  // override
  isProductListItemQtyDisabled = () => true

  // override
  // isRequiredProductVariants = () => false
  isRequiredProductVariants = () => true

  // override
  isAddProductButtonVisible = () => false

  // override
  isUpcSearchAvailable = () => false

  // override
  isSkuSearchAvailable = () => false

  // override disabled no-op
  onPressProductItem = (item) => {
    // this.navToProductView(item.data.id)
  }

  // // override
  // getModifiedRequestBody = (body) => {
  //   const { navigation, selectedStore } = this.props
  //   const myStoreId = util.getNavParam(this.props, 'store_id')
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   // const mkpId = util.getNavParam(this.props, 'mkp_id')
  //   const newBody = _.cloneDeep(body)
  //   newBody.store_id = myStoreId
  //   // newBody.mkp_channel_id = mkpChannelId
  //   // newBody.mkp_ch_id = mkpChannelId
  //   // newBody.mkp_id = mkpId
  //   return newBody
  // }

  // // override
  // renderFilterProductsButton = () => null

  // override disabled no-op
  navToProductView = (product_id: number) => {
    // const { navigation } = this.props
    // const storeId = util.getNavParam(this.props, 'store_id')
    // navigation.dispatch(
    //   NavActions.navToProductViewMyStore({
    //     store_id: storeId,
    //     product_id,
    //     onSuccessEditProductCallback: this.onSuccessEditProductCallback,
    //     onSuccessDeleteProductCallback: this.onSuccessDeleteProductCallback,
    //   })
    // )
  }

  // override
  getHaveNoProductItemText = () => 'ไม่พบรายการสินค้า\nคุณสามารถสร้างสิ นค้าได้จากปุ่ม (+) ด้านบน'

  // override
  renderProductListItemIcon = () => <XSellyLogo width={24} height={24} />

  // renderProductListItemBottom = (item: { index: number; data: IMKPProductItemData }) => (
  //   <XButton w='full' onPress={() => this._handleShowProductPeekToConfirm(item)}>
  //     ผูกกับสินค้าชิ้นนี้
  //   </XButton>
  // )

  _onMappingSuccess = async () => {
    const { navigation, mkpProductContext } = this.props
    const onMappingSuccess = util.getNavParam(this.props, 'onMappingSuccess')

    if (_.isFunction(onMappingSuccess)) {
      await onMappingSuccess()
    }

    // mkpProductContext.destroy(mkpProductContext)

    util.navGoBack(this.props)
  }

  _navToMkpProductDetailMapping = async (product_id: number) => {
    const { navigation, selectedStore } = this.props
    const { selectedMkpProductListItem } = this.state
    const store_id = util.getNavParam(this.props, 'store_id')
    const mkp_id = util.getNavParam(this.props, 'mkp_id')
    const mkp_ch_id = util.getNavParam(this.props, 'mkp_ch_id')
    // @ts-ignore
    const mkpProduct = util.getNavParam(this.props, 'mkpProduct')

    try {
      const role_id = selectedStore.has('role_id') ? selectedStore.get('role_id') : null
      const body: any = { store_id, product_id }
      // backend require if role_id === 2 (helper)
      if (role_id === 2) {
        // ถ้าเป็นการ fetch สินค้าของร้านฉันให้ส่ง role_id ของ selectedStore ของฉันไป
        body.role_id = role_id
      }

      const apiOptions: IApiOptions = {}

      const res = await api.post<IViewProductRequest, IViewProductResponse>(api.POST_PRODUCT, body, apiOptions)
      // console.log('_fetchPeekProduct res => ', res)
      if (res && res.product) {
        navigation.dispatch(
          NavActions.navToMkpProductDetailMapping({
            store_id,
            mkp_ch_id,
            // pt_id: selectedProduct.get(`id`),
            // @ts-ignore
            item_uuid: mkpProduct.uuid,
            mkp_id,
            // viewKey: mkpViewKey,
            // viewKey: 'MAPPING',
            // store_id: storeId,
            // product_id,

            // onSuccessEditProductCallback: this.onSuccessEditProductCallback,
            // onSuccessDeleteProductCallback: this.onSuccessDeleteProductCallback,

            targetXSellyProduct: res.product,

            onMappingSuccess: this._onMappingSuccess,
          })
        )
      }
    } catch (err) {
      console.log('_fetchPeekProduct err => ', err)
      // await util.setStatePromise(this, {
      //   selectedPeekProductItem: null,
      //   selectedMkpProductListItem: null,
      //   isPreviewPeekProductVisible: false,
      // })
      p.op.alert('เกิดข้อผิดพลาดในการโหลดข้อมูล', 'กรุณาลองใหม่อีกครั้ง')
    }
  }

  // // Render Methods
  // renderProductListItem = (pItem: { item?: ProductListItemType; index: number; itemWidth: number }) => (
  //   <ProductListItem
  //     // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
  //     // key={`PLI_${pItem.index}_${pItem.item.id}`}
  //     // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
  //     data={pItem.item}
  //     index={pItem.index}
  //     maxWidth={pItem.itemWidth}
  //     disabled={this.isProductListItemPressDisabled()}
  //     // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
  //     onPress={this._onPressProductItem}
  //     // @ts-ignore FIXME: พังจากการพยายาม generic ProductListItemType
  //     renderBottom={this.renderProductListItemBottom}
  //     numOfColumns={this.state.mainProductNumOfColumns}
  //     disabledIsSellerProductIcon={this.isProductListItemSellerIconDisabled()}
  //     disabledPrice={this.isProductListItemPriceDisabled()}
  //     disabledQty={this.isProductListItemQtyDisabled()}
  //   />
  // )

  getMkpProduct = (): IMkpProductDetail => {
    const { navigation } = this.props
    // @ts-ignore
    return util.getNavParam(this.props, 'mkpProduct')
  }

  // getPairedPtIds = (): IMkpProductDetail => {
  //   const mkpProduct = this.getMkpProduct()

  //   return mkpProduct.variants[1].paired_pt_id
  // }

  isPtIdPairedToMkp = (pt_id: number): boolean => {
    const mkpProduct = this.getMkpProduct()
    return mkpProduct.variants.findIndex((mpv) => mpv.paired_pt_id === pt_id) > -1
  }

  isOnlyOneVariantMkpProduct = (): boolean => {
    const mkpProduct = this.getMkpProduct()
    return mkpProduct.variants.length === 1
  }

  // renderProductListItemBottom = (item: { index: number; data: IMKPProductItemData }) => {
  renderProductListItemBottom = (item: { index: number; data: IProductItemData }) => {
    const { index, data } = item
    const ptId = data.id
    const isPaired = this.isPtIdPairedToMkp(ptId)
    // console.log(`renderProductListItemBottom::${index}:: data => `, data)

    // @ts-ignore
    const isPlaceholderData = !data || data.isEmpty
    if (isPlaceholderData) {
      return null
    }

    const isOnlyOneXSellyVariants = (data && data.v && data.v.length === 1) || false

    if (isPaired && this.isOnlyOneVariantMkpProduct() && isOnlyOneXSellyVariants) {
      return (
        <VStack key={`${index}_${ptId}`}>
          <XButton w='full' disabled colorScheme='muted' variant='outline' onPress={() => this._navToMkpProductDetailMapping(ptId)}>
            ถูกผูกกับสินค้านี้
          </XButton>
        </VStack>
      )
    }

    return (
      <VStack key={`${index}_${ptId}`}>
        {/* <XText>is already paired = {JSON.stringify(isPaired)}</XText> */}
        <XButton w='full' variant={isPaired ? 'outline' : 'solid'} onPress={() => this._navToMkpProductDetailMapping(ptId)}>
          {isPaired ? 'สลับการผูกเดิม' : 'ดำเนินการผูกใหม่'}
        </XButton>
      </VStack>
    )
  }

  _fetchPeekProduct = async (productTempleteId: number) => {
    const requestBody = {
      store_id: this.getTargetStoreId(),
      pt_id: productTempleteId,
    }
    const apiOptions: IApiOptions = {
      axiosOptions: {
        retry: 0,
        timeout: 60000,
      },
      isApiV2: true,
    }
    try {
      const res = await api.post('product/peek', requestBody, apiOptions)
      if (res && res.product) {
        await util.setStatePromise(this, { selectedPeekProductItem: res.product })
      }
      // console.log('_fetchPeekProduct res => ', res)
    } catch (err) {
      // console.log('_fetchPeekProduct err => ', err)
      await util.setStatePromise(this, {
        selectedPeekProductItem: null,
        selectedMkpProductListItem: null,
        isPreviewPeekProductVisible: false,
      })
      p.op.alert('เกิดข้อผิดพลาดในการโหลดข้อมูล', 'กรุณาลองใหม่อีกครั้ง')
    }
  }

  _handleShowProductPeekToConfirm = async (item: { index: number; data: IMKPProductItemData }) => {
    if (this.inProductPeekProcess) {
      return
    }
    this.inProductPeekProcess = true
    await util.setStatePromise(this, { isPreviewPeekProductVisible: true, selectedMkpProductListItem: item.data })
    await util.delay(500)
    await this._fetchPeekProduct(item.data.ti)
    await util.delay(200)
    this.inProductPeekProcess = false
  }

  _onRequestCloseProductPeek = () => {
    this.setState({ isPreviewPeekProductVisible: false, selectedPeekProductItem: null, selectedMkpProductListItem: null })
  }

  // _renderProductPeekOverlay = () => {
  //   const { navigation, mkpProductContext } = this.props
  //   const {
  //     isPreviewPeekProductVisible = false,
  //     selectedPeekProductItem = null,
  //     selectedMkpProductListItem = null,
  //     isMkpProductFetching = false,
  //   } = this.state
  //   const isPeekProductItemAvailable = selectedPeekProductItem && selectedPeekProductItem.name
  //   const ptID = util.getNavParam(this.props, 'product_id')
  //   const coreProduct = mkpProductContext.getCoreProduct(ptID.toString()) as IProductDetailItem
  //   return (
  //     <XOverlay
  //       contentStyle={StyleSheet.flatten([
  //         S.COLUMN_CENTER,
  //         S.PADDING_4,
  //         { flexBasis: 'auto', height: 350, width: 300, justifyContent: 'space-between' },
  //       ])}
  //       onRequestClose={this._onRequestCloseProductPeek}
  //       visible={isPreviewPeekProductVisible}>
  //       <View style={[S.FLEX, { justifyContent: 'flex-start', alignItems: 'center', width: '100%' }]}>
  //         <View style={[S.ROW_CENTER, { width: '100%', borderBottomWidth: 0.5 }]}>
  //           <View style={{ width: 34 }} />
  //           <XText style={{ flex: 1, textAlign: 'center' }}>สลับการผูกสินค้านี้</XText>
  //           <NavHeaderButton closeIcon onPressItem={this._onRequestCloseProductPeek} />
  //         </View>
  //         {isPeekProductItemAvailable ? (
  //           <>
  //             <View style={{ height: 12 }} />
  //             <XText style={S.TEXT_INACTIVE}>จากที่ผูกอยู่กับสินค้านี้</XText>
  //             <ProductPeekItem productPeekItem={selectedPeekProductItem} />

  //             <View style={[S.COLUMN_CENTER]}>
  //               <XIcon
  //                 family='MaterialCommunityIcons'
  //                 name='arrow-down-bold'
  //                 size={55}
  //                 style={{ flex: 0, flexBasis: 'auto', height: 55 }}
  //               />
  //             </View>

  //             <XText style={S.TEXT_INACTIVE}>เปลี่ยนมาผูกกับสินค้านี้</XText>
  //             <CoreProductInfo product={coreProduct} />
  //           </>
  //         ) : (
  //           <Spinner style={{ flex: 1, alignSelf: 'center', width: '100%' }} color={COLORS.TEXT_INACTIVE} />
  //         )}
  //       </View>
  //       <View style={[S.ROW_CENTER, { flex: 0, flexBasis: 'auto', width: '100%' }]}>
  //         <TouchableOpacity onPress={this._onRequestCloseProductPeek} style={[S.BUTTON_OUTLINE_PRIMARY, { height: 34, width: 44 }]}>
  //           <XText style={[S.TEXT_PRIMARY, S.TEXT_BOLD]}>ปิด</XText>
  //         </TouchableOpacity>
  //         <View style={{ width: 8 }} />
  //         <TouchableOpacity
  //           disabled={!isPeekProductItemAvailable || isMkpProductFetching}
  //           onPress={() => this._confirmNavToMkpProductDetailMapping(selectedMkpProductListItem)}
  //           style={[
  //             S.FLEX,
  //             S.ROW_CENTER,
  //             isPeekProductItemAvailable && !isMkpProductFetching ? S.BUTTON_PRIMARY : S.BUTTON_INACTIVE,
  //             { height: 34, paddingTop: 6 },
  //           ]}>
  //           {isMkpProductFetching ? (
  //             <Spinner size='sm' color={COLORS.APP_MAIN} style={{ height: STYLES.FONT_ICON_SMALLER, width: STYLES.FONT_ICON_SMALLER }} />
  //           ) : (
  //             <XText style={[isPeekProductItemAvailable ? S.TEXT_ACTIVE_DARK : S.TEXT_ACTIVE, S.TEXT_LARGER, S.TEXT_BOLD]}>
  //               ตรวจสอบและยืนยัน
  //             </XText>
  //           )}
  //         </TouchableOpacity>
  //       </View>
  //     </XOverlay>
  //   )
  // }

  // _confirmNavToMkpProductDetailMapping = async (selectedMkpProductListItem: IMKPProductItemData) => {
  //   if (this.inProcessMkpProductFetching) {
  //     return
  //   }
  //   this.inProcessMkpProductFetching = true
  //   // console.log('_confirmNavToMkpProductDetailMapping mkpProductUUID => ', mkpProductUUID)
  //   const { navigation, mkpChannels, mkpProductContext, selectedProduct } = this.props
  //   const storeId = util.getNavParam(this.props, 'store_id')
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
  //   const mkpId = util.getNavParam(this.props, 'mkp_id')
  //   const corePtId = util.getNavParam(this.props, 'product_id')
  //   const mkpViewKey = corePtId ? corePtId.toString() : selectedMkpProductListItem.uuid

  //   try {
  //     await util.setStatePromise(this, { isMkpProductFetching: true })
  //     await util.delay(200)

  //     const mkpProduct = await mkpProductContext.fetchMkpProduct(mkpViewKey, {
  //       store_id: storeId,
  //       // mkp_channel_id: mkpChannelId,
  //       mkp_ch_id: mkpChannelId,
  //       item_uuid: selectedMkpProductListItem.uuid,
  //       // mkp_id: mkpId,
  //     })
  //     // console.log('_confirmNavToMkpProductDetailMapping mkpProduct => ', mkpProduct)

  //     if (mkpProduct) {
  //       const coreProductCount = selectedProduct.get('variants').size
  //       const mkpProductVariantCount = mkpProduct.variants.length
  //       if (coreProductCount < mkpProductVariantCount) {
  //         const productName = selectedProduct.get('name')
  //         p.op.alert('ตัวเลือกสินค้ามีไม่เพียงพอ', `กรุณากลับไปสร้างตัวเลือกสินค้าของ "${productName}" ให้เพียงพอที่จะผูกกับสินค้าชิ้นนี้`)
  //         await util.delay(200)
  //         this.inProcessMkpProductFetching = false
  //         await util.setStatePromise(this, { isMkpProductFetching: false })
  //         return
  //       }
  //       this._onRequestCloseProductPeek()
  //       await util.delay(200)

  //       navigation.dispatch(
  //         NavActions.navToMkpProductDetailMapping({
  //           store_id: storeId,
  //           // mkp_channel_id: mkpChannelId,
  //           mkp_ch_id: mkpChannelId,
  //           // pt_id: selectedProduct.get(`id`),
  //           item_uuid: selectedMkpProductListItem.uuid,
  //           mkp_id: mkpId,
  //           viewKey: mkpViewKey,
  //         })
  //       )
  //     }
  //   } catch (err) {
  //     // console.log('_confirmNavToMkpProductDetailMapping err => ', err)
  //   }

  //   await util.setStatePromise(this, { isMkpProductFetching: false })
  //   await util.delay(200)
  //   this.inProcessMkpProductFetching = false
  // }

  // _renderSelectedProductBar = () => {
  //   const { navigation, mkpChannels, mkpProductContext } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   const ptID = util.getNavParam(this.props, 'product_id')
  //   const coreProduct = mkpProductContext.getCoreProduct(ptID.toString()) as IProductDetailItem
  //   return (
  //     <View
  //       style={[
  //         S.CARDLIKE_BORDERS,
  //         S.BG_WHITE,
  //         { borderTopWidth: 0, borderBottomWidth: 0, alignItems: 'center', justifyContent: 'center', width: '100%' },
  //       ]}>
  //       <View style={[S.CARDLIKE_BODY]}>
  //         <View style={[S.PADDING_VERTICAL_4, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
  //           <XText style={S.TEXT_ACTIVE}>กำลังจะผูกต่อกับสินค้านี้</XText>
  //         </View>
  //         <CoreProductInfo product={coreProduct} />
  //       </View>
  //     </View>
  //   )
  // }

  _renderSelectedMkpStoreBar = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    // return (
    //   <View style={[S.CARDLIKE_BORDERS, S.BG_WHITE, { borderTopWidth: 0, alignItems: 'center', justifyContent: 'center', width: '100%' }]}>
    //     {/* <View style={[S.CARDLIKE_BODY]}>
    //       <View style={[S.PADDING_VERTICAL_4, { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }]}>
    //         <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_NORMAL} height={STYLES.FONT_ICON_NORMAL} />
    //         <XText style={[S.TEXT_ACTIVE, { paddingLeft: 4 }]}>{mkpChaneelName}</XText>
    //       </View>
    //     </View> */}
    //     <View style={[S.CARDLIKE_BODY, S.ROW_CENTER, { height: 54, paddingBottom: 4 }]}>
    //       <View style={[S.ROW_CENTER, { width: 44 }]}>
    //         <XIcon
    //           inactive
    //           name='search'
    //           style={{ flex: 0, flexBasis: 'auto', width: STYLES.FONT_ICON_NORMAL, height: STYLES.FONT_ICON_NORMAL }}
    //         />
    //       </View>
    //       {this.renderSearchBar()}
    //     </View>
    //   </View>
    // )

    return (
      <HStack w='full' p='2'>
        {this.renderSearchBar()}
      </HStack>
    )
  }

  _renderHeaderTitleBar = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    // const mkpChaneelName = mkpChannel.get('name')
    const mkpId = mkpChannel.get('mkp_id')
    // return (
    //   <View style={[S.CARDLIKE_BODY, { flexDirection: 'row' }]}>
    //     <View style={[S.PADDING_4, { alignItems: 'center', justifyContent: 'center' }]}>
    //       <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGEST} height={STYLES.FONT_ICON_LARGEST} />
    //     </View>
    //     <View style={[S.PADDING_4, { height: '100%', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }]}>
    //       <XText style={S.TEXT_INACTIVE}>{'เลือกสินค้าจากรายการของช่องทางขาย'}</XText>
    //       <XText style={S.TEXT_ACTIVE}>{mkpChaneelName}</XText>
    //     </View>
    //   </View>
    // )
    return (
      <HStack minH='10' p='2' space='1'>
        <XIconButton name='arrow-back' onPress={this.goBack} />
        <HStack flex={1} space='1' alignItems='center'>
          <MkpLogo mkpId={mkpId} width={STYLES.FONT_ICON_LARGER} height={STYLES.FONT_ICON_LARGER} />
          {/* <XText flex={1} bold>
            เลือกสินค้าจากรายการของช่องทางขาย
          </XText> */}
          <XText flex={1} bold>
            เลือกสินค้าจาก XSelly
          </XText>
        </HStack>

        <Box w='10'>
          {/* <TouchableOpacity style={S.BUTTON_ICON}>
            <XIcon name='search' family='FontAwesome' size={STYLES.FONT_ICON_NORMAL} />
          </TouchableOpacity> */}
        </Box>
      </HStack>
    )
  }

  // overrdie
  getSearchTextWithCountPlaceholder = (mainProductCount: number) => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    const mkpChaneelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    return `${mainProductCount} สินค้า ที่พร้อมค้นหาใน ${mkpChaneelName}`
  }

  // override
  getSearchTextWithoutCountPlaceholder = () => {
    const { navigation, mkpChannels } = this.props
    // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
    const mkpChannelId = util.getNavParam(this.props, 'mkp_ch_id')
    const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
    const mkpChannelName = mkpChannel.get('name')
    // const mkpId = mkpChannel.get('mkp_id')
    return `ไม่พบรายการสินค้าพร้อมใช้งานใน ${mkpChannelName}`
  }

  // renderContent = () => {
  //   const { navigation, mkpChannels } = this.props
  //   // const mkpChannelId = util.getNavParam(this.props, 'mkp_channel_id')
  //   // const mkpChannel = mkpChannels.find((mch) => mch.get('id') === mkpChannelId)
  //   // const mkpChaneelName = mkpChannel.get('name')
  //   // const mkpId = mkpChannel.get('mkp_id')

  //   return (
  //     <>
  //       {this._renderHeaderTitleBar()}
  //       {/* {this.renderCustomHeader()} */}
  //       {/* {this._renderSelectedProductBar()} */}
  //       {this._renderSelectedMkpStoreBar()}
  //       {this.renderWatingSearchTypingInfo()}
  //       {this.renderMainList()}
  //       {this.renderSearchList()}
  //       {/* {this._renderProductPeekOverlay()} */}
  //     </>
  //   )
  // }

  _renderMkpProductInfo = () => {
    const mkpProduct = util.getNavParam(this.props, 'mkpProduct')

    if (!mkpProduct || !mkpProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [], mkp_id } = mkpProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    const thumbnailImgUri = thumbnail_uris[0] || null

    return (
      <VStack w='full' py='1.5' px='2' space='1' borderWidth='1' borderColor='muted.400' bg='blue.100'>
        <HStack w='full' space='1' alignItems='center' justifyContent='center'>
          <XText variant='inactive' bold>
            ดำเนินการผูกสินค้าไปยังสินค้าอื่นของ XSelly
          </XText>
        </HStack>

        <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
          {thumbnailImgUri && thumbnailImgUri !== '' && (
            <VStack w='16' h='16'>
              <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailImgUri }} />

              <Box position='absolute' top='1' left='1'>
                <MkpLogo mkpId={mkp_id} height={24} width={24} />
              </Box>
            </VStack>
          )}

          <VStack flex={1} space='1'>
            <XText>{name}</XText>
            {variantCount > 1 ? (
              <XText fontSize='xs' w='full' numberOfLines={2}>
                <XText variant='inactive' fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
                {variantNames}
              </XText>
            ) : null}
          </VStack>
        </HStack>
      </VStack>
    )
  }

  // override
  renderContent = () => {
    const renderNavReference = util.getNavParam(this.props, 'renderNavReference')

    return (
      <VStack flex={1} w='full'>
        {this._renderMkpProductInfo()}
        {this.renderCustomHeader()}
        {this.renderSearchInfoBar()}
        {this.renderCatListBar()}
        <VStack w='full' flex={1} _light={{ bg: 'coolGray.200' }} _dark={{ bg: 'warmGray.700' }}>
          {this.renderMainList()}
          {this.renderWatingSearchTypingInfo()}
          {this.renderSearchList()}
        </VStack>
        {/* {this.renderDrawerCategoryFilterView()} */}
      </VStack>
    )
  }
}

const ProductListViewMkpMappingWithContext = withMkpProductContextPropsAndRef(ProductListViewMkpMapping)
// // @ts-ignore
// ProductListViewMkpMappingWithContext.navigationOptions = { header: null }

// const ProductListViewMkpMappingWithContextContainer = connect(
//   (state) => ({
//     selectedProduct: getSelectedProduct(state),
//   }),
//   (dispatch) => {
//     return {
//       dispatch,
//     }
//   }
// )(ProductListViewMkpMappingWithContext)

export default ProductListViewMkpMappingWithContext
