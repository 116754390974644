/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, IXScreenProps, IWebsiteConfig, IFaqModal, IImportExcelMenuItem, IStoreWebsite } from 'x/index'
import * as util from 'x/utils/util'
import * as NavActions from 'x/utils/navigation'
import CONS from 'x/config/constants'

interface IAddItemMenuList {
  key: string
  title: string
  faq: IFaqModal[]
}

interface IBaseSalePageAddItemWebViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseSalePageAddItemWebViewState {
  salePage: IStoreWebsite
  webItems: IWebsiteConfig[]
  indexAddItem: number
}

export default abstract class BaseSalePageAddItemWebView extends React.Component<
  IBaseSalePageAddItemWebViewProps,
  IBaseSalePageAddItemWebViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // _editor: any
  MENU_ITRMS: IImportExcelMenuItem[]
  // ITEM_MENU_LIST: IAddItemMenuList[]

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      webItems: [],
      indexAddItem: 0,
    }

    this.MENU_ITRMS = [
      {
        label: 'รูป',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'รูปเดี่ยว',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'รูปสไลด์โชว์',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ตัวหนังสือ',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'ตัวหนังสือ',
        // iconName: 'group',
        // iconFamily: 'MaterialIcons',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'อื่นๆ',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'นับเวลาถอยหลัง',
        onclick: () => this._navToEditCountdownTimeView(),
        isShowMenu: () => true,
      },
      {
        label: 'Flash Sale',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ส่วนลดและขายแล้วจำนวนกี่ชิ้น',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ส่วนลด',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ขายแล้วจำนวนกี่ชิ้น',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ปุ่ม',
        isHeader: true,
        isShowMenu: () => true,
      },
      {
        label: 'ปุ่มสั่งซื้อทาง Line',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ปุ่มสั่งซื้อทาง Facebook',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ปุ่มสั่งซื้อทาง Shopee',
        onclick: () => null,
        isShowMenu: () => true,
      },
      {
        label: 'ปุ่มสั่งซื้อทาง Lazada',
        onclick: () => null,
        isShowMenu: () => true,
      },
    ]

    // this.ITEM_MENU_LIST = [
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'รูปเดี่ยว',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'รูปสไลด์โชว์',
    //   },
    //   {
    //     key: 'countdown_timer_in_minute',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'นับเวลาถอยหลัง',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ตัวหนังสือ',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'Flash Sale',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ส่วนลด',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ขายแล้วจำนวนกี่ชิ้น',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ปุ่มสั่งซื้อทาง Line',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ปุ่มสั่งซื้อทาง Facebook',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ปุ่มสั่งซื้อทาง Shopee',
    //   },
    //   {
    //     key: '',
    //     faq: FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS,
    //     title: 'ปุ่มสั่งซื้อทาง Lazada',
    //   },
    // ]
  }

  componentDidMount() {
    const params = util.getNavParams(this.props)
    const { salePage, index } = params
    // console.log('salePage => ', salePage)
    // console.log('index => ', index)
    this.setState({
      salePage,
      indexAddItem: index,
      // webItems: _.isNil(salePage) ? null : salePage.web_items,
    })
  }

  _onPressItem = (item: IAddItemMenuList) => {
    console.log('item => ', item)
    const { key } = item
    if (key === CONS.SALE_PAGE_ITEMS_KEY.COUNTDOWN_TIMER_IN_MINUTE) {
      this._navToEditCountdownTimeView()
    }
  }

  _navToEditCountdownTimeView = () => {
    // const { webItems } = this.state
    const params = util.getNavParams(this.props)
    const { callBack } = params
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToEditCountdownTimeView({
        index: this.state.indexAddItem,
        countdownTime: null,
        popViewNumber: 2,
        callBack: (newItem: IWebsiteConfig, newIndex: number) => callBack(newItem, newIndex),
      })
    )
  }
}
