import React from 'react'
import { FlatList, TouchableOpacity } from 'react-native'
import _ from 'lodash'
import { COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
import { canDoAtSelectedStore } from 'x/utils/acl'
import XIcon from 'xui/components/XIcon'
import BaseReportListView from 'x/modules/report/BaseReportListView'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import { Center } from 'native-base'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import * as util from 'x/utils/util'

// interface IReportMenuItem {
//   label: string
//   iconName?: string
//   iconFamily?: 'Ionicons' | 'FontAwesome' | 'MaterialIcons' | 'MaterialCommunityIcons' | 'AntDesign' | 'Foundation'
//   iconStyle?: Object
//   nav?: () => void
//   perm?: string | string[]
//   isHeader?: boolean
//   permBitIndex?: number
// }

export default abstract class BaseUIReportListView extends BaseReportListView {
  // abstract renderReportProductDailySales: () => void

  abstract openFileFromUrl(url: string, fileName: string, type: string): Promise<void>
  // abstract renderReportHelperSales: () => void
  // abstract renderReportPayment: () => void

  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: !p.op.isWeb(),
      onPressItem: () => {
        util.navGoBack(this.props)
      },
    }
    return <XCustomHeader title='รายงาน' headerLeftProps={leftBtn} />
  }

  async handleOnDownloadFile(url: string, fileName: string): Promise<void> {
    await this.openFileFromUrl(url, fileName, 'xlsx')
  }

  _renderMenuItems = ({ index, item }) => {
    const {
      label,
      iconName = null,
      iconFamily = null,
      nav = null,
      perm,
      isHeader = false,
      // iconStyle = null,
      permBitIndex = null,
      advancedChecking = null,
      isShowHeader = null,
    } = item
    // const addIconStyle = _.isNil(iconStyle) ? {} : iconStyle
    if (perm && !canDoAtSelectedStore(perm, permBitIndex)) {
      return null
    }

    if (!_.isNil(advancedChecking) && !advancedChecking()) {
      return null
    }

    // console.log(`item !=> `, item)
    if (isHeader) {
      if (_.isFunction(isShowHeader) && !isShowHeader()) {
        return null
      }
      return (
        <Box bgColor='#aed6f1' justifyContent='center' key={index} py='2'>
          <XText pl='2' bold variant='active'>
            {label}
          </XText>
        </Box>
      )
    }
    const iconLeft = (
      <XIcon
        name={iconName}
        family={iconFamily}
        variant='inactive'
        // size='sm'
        // style={{ flex: 0, fontSize: FONT_ICON_NORMAL, width: 30, minWidth: 30, color: TEXT_INACTIVE, ...addIconStyle }}
      />
    )
    const iconRight = nav ? <XIcon name='arrow-forward' family='Ionicons' /> : null
    // let titlePaddingLeft = 10
    // if (iconName === `cubes`) {
    //   titlePaddingLeft = 5
    // }
    return (
      <TouchableOpacity
        key={index.toString()}
        onPress={() => this._onPressToNav(item)}
        style={{ borderBottomWidth: 1, borderBottomColor: COLORS.TEXT_INACTIVE }}>
        <HStack py={p.op.isWeb() ? '3' : '4'} px='1' space='2'>
          <Center w='10'>{iconLeft}</Center>
          <HStack alignItems='center' flex={1}>
            <XText variant='active'>{label}</XText>
          </HStack>
          <Center w='8'>{iconRight}</Center>
        </HStack>
      </TouchableOpacity>
    )
  }

  // renderReportHelperSales = () => {
  //   const { subscription, getPermissionMemberList, selectedStore } = this.props
  //   const { isReportHelperSales } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const store_id = selectedStore.get(`id`)
  //   // const heightForPlatform = p.op.isWeb() ? 505 : 465
  //   return (
  //     <XOverlay
  //       // contentStyle={{ padding: 8, width: 315, alignSelf: 'center', flexBasis: 'auto' }}
  //       contentStyle={{ height: 'auto', width: 315 }}
  //       visible={isReportHelperSales}
  //       children={
  //         <ReportHelperSales
  //           handleOnDownloadFile={(url: string, fileName: string, type: string) => this.openFileFromUrl(url, fileName, type)}
  //           store_id={store_id}
  //           allowOnlyDaysFromToday={r_shpg_day}
  //           getPermissionMemberList={getPermissionMemberList}
  //           onRequestClose={() => this._handleCloseisReportHelperSales}
  //         />
  //       }
  //       key={store_id}
  //       onRequestClose={this._handleCloseisReportHelperSales}
  //     />
  //   )
  // }

  // renderReportPayment = () => {
  //   const { subscription, fetchAccounts, selectedStore } = this.props
  //   const { isReportPayment } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const store_id = selectedStore.get(`id`)
  //   return (
  //     <XOverlay
  //       contentStyle={{
  //         padding: 4,
  //         width: 365,
  //         alignSelf: 'center',
  //         flexBasis: 'auto',
  //         height: p.op.isWeb() ? 525 : 510,
  //       }}
  //       visible={isReportPayment}
  //       children={
  //         <ReportPayment
  //           // @ts-ignore
  //           // dateRangePicker={DateRangePicker}
  //           handleOnDownloadFile={(url: string, fileName: string, type: string) => this.openFileFromUrl(url, fileName, type)}
  //           store_id={store_id}
  //           allowOnlyDaysFromToday={r_shpg_day}
  //           fetchAccounts={fetchAccounts}
  //           onRequestClose={() => this._handleCloseReportPayment}
  //         />
  //       }
  //       key={store_id}
  //       onRequestClose={this._handleCloseReportPayment}
  //     />
  //   )
  // }

  // renderReportPayment = () => {
  //   const { subscription, fetchAccounts, selectedStore } = this.props
  //   const { isReportPayment } = this.state
  //   const r_shpg_day = subscription.get('r_shpg_day')
  //   const store_id = selectedStore.get(`id`)
  //   return (
  //     <XOverlay
  //       // contentStyle={{ padding: 8, width: 315, alignSelf: 'center', flexBasis: 'auto', height: 465 }}
  //       visible={isReportPayment}
  //       children={
  //         <ReportPayment
  //           handleOnDownloadFile={(url: string, fileName: string, type: string) => this.openFileFromUrl(url, fileName, type)}
  //           store_id={store_id}
  //           allowOnlyDaysFromToday={r_shpg_day}
  //           fetchAccounts={fetchAccounts}
  //           onRequestClose={() => this._handleCloseReportPayment}
  //         />
  //       }
  //       key={store_id}
  //       onRequestClose={this._handleCloseReportPayment}
  //     />
  //   )
  // }

  render() {
    // console.log(` RENDER !! `, this.menuItems)
    return (
      <XContainer>
        {this.renderCustomHeader()}
        {/* {this.renderReportProductDailySales()} */}
        {/* {this._renderReportPayment()} */}
        {/* {this.renderReportHelperSales()} */}
        {/* {this.renderReportPayment()} */}
        {/* // ** ห้ามใช้ XContent กับ FlatList ซ้อนกัน ไม่งั้ง UI จะพัง */}
        {/* <XContent style={{ backgroundColor: COLORS.WHITE }}> */}
        <FlatList data={this.menuItems} renderItem={this._renderMenuItems} />
        {/* </XContent> */}
      </XContainer>
    )
  }
}
