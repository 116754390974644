import React from 'react'
import { FlatList, ListRenderItemInfo, TouchableOpacity } from 'react-native'

import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XIcon, { XIconFamily } from 'xui/components/XIcon'
import p from 'x/config/platform-specific'

import {
  IMkpProductDetailVariant,
  IMkpAutoMappingVariantsViewProps,
  IMkpAutoMappingVariantsViewState,
  IProductDetailVariant,
  IStagingPariedItem,
} from 'x/index'

import XText from 'xui/components/XText'
import XContent from 'xui/components/XContent'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XButton from 'xui/components/XButton'
import XSellyLogo from 'xui/components/XSellyLogo'
import Box from 'xui/components/Box'
import XImage from 'xui/components/XImage'
import MkpLogo from 'xui/components/MkpLogo'
import XIconButton from 'xui/components/XIconButton'

import XSegment from 'xui/components/XSegment'
import XSpinner from 'xui/components/XSpinner'
import XCard from 'xui/components/XCard'

const PAIR_BY_FIELD_OPTIONS = [
  { label: 'ชื่อตัวเลือกสินค้า', value: 'name' },
  { label: 'SKU', value: 'sku' },
]

const SIMILARITY_PERCENTAGE_OPTIONS = [
  { label: '100%', value: 100 },
  { label: '> 90%', value: 90 },
  { label: '> 75%', value: 75 },
  { label: '> 50%', value: 50 },
  { label: '> 25%', value: 25 },
]

const IS_SKIP_PAIRED_CHECK_OPTIONS = [
  { label: 'ผูกใหม่ทั้งหมด', value: true },
  { label: 'เฉพาะที่ยังไม่ได้ผูก', value: false },
]

export default class MkpAutoMappingVariantsView extends React.Component<
  IMkpAutoMappingVariantsViewProps,
  IMkpAutoMappingVariantsViewState
> {
  static displayName = 'MkpAutoMappingVariantsView'

  isPairing?: boolean

  constructor(props) {
    super(props)

    this.state = {
      isInitialized: false,

      segmentOptionPairFieldAtIndex: 0,
      segmentOptionSimilarityBaselinePercentAtIndex: 2,
      segmentOptionIsSkipPairedCheckAtIndex: 0,

      stagingPairedItems: [],
      stagingCheckedMap: {},
    }
  }

  async componentDidMount() {
    await this._initialize()
  }

  // componentWillUnmount() {
  // }

  _goBack = () => {
    util.navGoBack(this.props)
  }

  _initialize = async () => {
    // const navParams = util.getNavParams(this.props)
    // console.log('MkpAutoMappingVariantsView initialize navParams => ', navParams)

    const editingMkpProduct = util.getNavParam(this.props, 'editingMkpProduct')
    const xSellyProduct = util.getNavParam(this.props, 'xSellyProduct')

    if (editingMkpProduct && xSellyProduct) {
      await util.setStatePromise(this, { editingMkpProduct, xSellyProduct })
    } else {
      this._goBack()
    }

    await util.setStatePromise(this, { isInitialized: true })
    this._doAutoMapping()
  }

  _doAutoMapping = async () => {
    if (this.isPairing) {
      return
    }
    this.isPairing = true
    await util.setStatePromise(this, { isPairing: true })
    await util.delay(100)

    const { editingMkpProduct, xSellyProduct, newPairedMap } = this.state

    // console.log('MkpAutoMappingVariantsView _doAutoMapping editingMkpProduct.paired_pts => ', editingMkpProduct.paired_pts)

    // const newPairedMap = {}
    // for (const pp of editingMkpProduct.paired_pts) {
    //   newPairedMap[pp.item_id] = pp
    // }

    const pairField = this._getPairedFieldValue()
    const mkpVariantNames = editingMkpProduct.variants.map((v) => v[pairField] || '')
    const xSellyVariantNames = xSellyProduct.variants.map((v) => v[pairField] || '')

    // const pairedMapByIndex: { [mkpVariantIndex: number]: number } = {}
    const selectedXSellyVariantIndexs: number[] = []
    const stagingPairedItems: IStagingPariedItem[] = []

    // console.log('MkpAutoMappingVariantsView _doAutoMapping mkpVariantNames => ', mkpVariantNames)
    // console.log('MkpAutoMappingVariantsView _doAutoMapping xSellyVariantNames => ', xSellyVariantNames)

    for (let i = 0; i < mkpVariantNames.length; i++) {
      const isAlreadyMapped = _.isNumber(editingMkpProduct.variants[i].paired_pp_id)
      if (isAlreadyMapped && !this._IsSkipPairedCheck()) {
        // console.log(`MkpAutoMappingVariantsView _doAutoMapping i=${i} SKIP 1 !!!`)
        continue
      }

      const mkpVariantName = util.replaceOutUnknowString(mkpVariantNames[i]).trim()

      const item_id = editingMkpProduct.variants[i].item_id
      const item_variant_id = editingMkpProduct.variants[i].item_variant_id || 'null'
      const mkpMappingKey = `${item_id}_${item_variant_id}`

      if (_.has(newPairedMap, mkpMappingKey) && !this._IsSkipPairedCheck()) {
        // console.log(`MkpAutoMappingVariantsView _doAutoMapping i=${i} SKIP 2 !!!`)
        continue
      }

      // const similarityPercents = xSellyVariantNames.map(() => 0)
      const similarityPercents = new Array(xSellyVariantNames.length).fill(0)

      // calculate similarity loop
      for (let j = 0; j < xSellyVariantNames.length; j++) {
        if (selectedXSellyVariantIndexs.includes(j)) {
          // console.log(`MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${j} J Already Used!!!`)
          continue
        }

        // if (j === 11) {
        //   console.log(`MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${j} J JUST SKIP FOR DEBUG!!!`)
        //   continue
        // }

        const xSellyVariantName = util.replaceOutUnknowString(xSellyVariantNames[j]).trim()
        // console.log(`MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${j} mkp=${mkpVariantName} x=${xSellyVariantName} 11111`)

        // const similarityScore = util.getJaroWinklerDistance(mkpVariantName, xSellyVariantName)
        const similarityScore = util.getLevenshteinSimilarity(mkpVariantName, xSellyVariantName)
        // console.log(
        //   `MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${j} mkp=${mkpVariantName} x=${xSellyVariantName} similarityScore => `,
        //   similarityScore
        // )
        const similarityPercent = Math.round(similarityScore * 100)
        // console.log('MkpAutoMappingVariantsView _doAutoMapping mkpVariantName => ', mkpVariantName)
        // console.log('MkpAutoMappingVariantsView _doAutoMapping xSellyVariantName => ', xSellyVariantName)
        // console.log('MkpAutoMappingVariantsView _doAutoMapping similarityScore => ', similarityScore)
        // console.log(
        //   `MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${j} mkp=${mkpVariantName} x=${xSellyVariantName} similarityPercent => `,
        //   similarityPercent
        // )

        similarityPercents[j] = similarityPercent

        if (similarityPercent === 100) {
          break
        }

        // if (similarityPercent >= this._getSimilarityPercentBaselineValue()) {
        //   console.log('MkpAutoMappingVariantsView PASSED similarityScore => ', similarityScore)
        //   pairedMapByIndex[i] = j
        //   selectedXSellyVariantIndexs.push(j)
        //   break
        // }
      }

      // console.log('MkpAutoMappingVariantsView _doAutoMapping SUMMARY similarityPercents => ', similarityPercents)

      const maxSimilarityXSellyVariantIndex = util.indexOfMax(similarityPercents)
      if (maxSimilarityXSellyVariantIndex >= 0) {
        const selectedSimilarityPercent = similarityPercents[maxSimilarityXSellyVariantIndex]
        // console.log(
        //   `MkpAutoMappingVariantsView _doAutoMapping i=${i} j=${maxSimilarityXSellyVariantIndex} WINNER similarityPercent => `,
        //   selectedSimilarityPercent
        // )

        if (selectedSimilarityPercent >= this._getSimilarityPercentBaselineValue()) {
          // pairedMapByIndex[i] = maxSimilarityXSellyVariantIndex
          stagingPairedItems.push({
            mkpVariantIndex: i,
            xSellyVariantIndex: maxSimilarityXSellyVariantIndex,
            similarityPercent: selectedSimilarityPercent,
          })
          selectedXSellyVariantIndexs.push(maxSimilarityXSellyVariantIndex)
        }
      }
    }

    // console.log('MkpAutoMappingVariantsView Final mapped pairedMapByIndex => ', pairedMapByIndex)

    // const stagingPairedMap = {}
    // const stagingPairedMapByIndex = {}
    // for (const [mkpVariantIndex, xSellyVariantIndex] of Object.entries(pairedMapByIndex)) {
    //   const item_id = editingMkpProduct.variants[mkpVariantIndex].item_id
    //   const item_variant_id = editingMkpProduct.variants[mkpVariantIndex].item_variant_id || 'null'
    //   const mkpMappingKey = `${item_id}_${item_variant_id}`

    //   const pp_id = xSellyProduct.variants[xSellyVariantIndex].pp_id

    //   if (_.has(newPairedMap, mkpMappingKey) && !this._IsSkipPairedCheck()) {
    //     continue
    //   }

    //   stagingPairedMap[`${item_id}_${item_variant_id}`] = pp_id
    //   stagingPairedMapByIndex[mkpVariantIndex] = xSellyVariantIndex
    // }

    // console.log('MkpAutoMappingVariantsView Final mapped stagingPairedMap => ', stagingPairedMap)
    // console.log('MkpAutoMappingVariantsView Final mapped stagingPairedMapByIndex => ', stagingPairedMapByIndex)

    const stagingCheckedMap = {}
    for (let i = 0; i < stagingPairedItems.length; i++) {
      stagingCheckedMap[i] = true
    }

    // await util.setStatePromise(this, { stagingPairedMap, stagingPairedMapByIndex, stagingPairedItems, stagingCheckedMap })
    await util.setStatePromise(this, { stagingPairedItems, stagingCheckedMap })

    await util.delay(100)
    await util.setStatePromise(this, { isPairing: false })
    this.isPairing = false
  }

  _getSimilarityPercentBaselineValue = () => {
    const { segmentOptionSimilarityBaselinePercentAtIndex } = this.state
    return SIMILARITY_PERCENTAGE_OPTIONS[segmentOptionSimilarityBaselinePercentAtIndex].value
  }

  _getSimilarityPercentBaselineLabel = () => {
    const { segmentOptionSimilarityBaselinePercentAtIndex } = this.state
    return SIMILARITY_PERCENTAGE_OPTIONS[segmentOptionSimilarityBaselinePercentAtIndex].label
  }

  _getPairedFieldLabel = () => {
    const { segmentOptionPairFieldAtIndex } = this.state
    return PAIR_BY_FIELD_OPTIONS[segmentOptionPairFieldAtIndex].label
  }

  _getPairedFieldValue = () => {
    const { segmentOptionPairFieldAtIndex } = this.state
    return PAIR_BY_FIELD_OPTIONS[segmentOptionPairFieldAtIndex].value
  }

  _IsSkipPairedCheck = () => {
    const { segmentOptionIsSkipPairedCheckAtIndex } = this.state
    return IS_SKIP_PAIRED_CHECK_OPTIONS[segmentOptionIsSkipPairedCheckAtIndex].value
  }

  renderCustomHeader = () => {
    const { navigation } = this.props

    return <XCustomHeader title={'ผูกตัวเลือกสินค้าอัตโนมัติ'} headerLeftProps={{ backIcon: true, onPressItem: this._goBack }} />
  }

  onSegmentPairFieldChange = async (selectedOption, selectedIndex: number) => {
    if (this.isPairing) {
      return
    }
    await util.setStatePromise(this, { segmentOptionPairFieldAtIndex: selectedIndex })
    await util.delay(100)
    await this._doAutoMapping()
  }

  onSegmentSimilarityChange = async (selectedOption, selectedIndex: number) => {
    if (this.isPairing) {
      return
    }
    await util.setStatePromise(this, { segmentOptionSimilarityBaselinePercentAtIndex: selectedIndex })
    await util.delay(100)
    await this._doAutoMapping()
  }

  onSegmentIsSkipPairedCheckChange = async (selectedOption, selectedIndex: number) => {
    if (this.isPairing) {
      return
    }

    await util.setStatePromise(this, { segmentOptionIsSkipPairedCheckAtIndex: selectedIndex })
    await util.delay(100)
    await this._doAutoMapping()
  }

  renderMkpIcon = () => {
    const { editingMkpProduct } = this.state

    return <MkpLogo mkpId={editingMkpProduct.mkp_id} height={24} width={24} />
  }

  // _getStagingPairedListData = (): Array<[number, number]> => {
  //   const { stagingPairedMapByIndex } = this.state

  //   if (!stagingPairedMapByIndex || _.isEmpty(stagingPairedMapByIndex)) {
  //     return []
  //   }

  //   return Object.entries(stagingPairedMapByIndex).map(([mkpVariantIndex, xSellyVariantIndex]) => [
  //     Number(mkpVariantIndex),
  //     Number(xSellyVariantIndex),
  //   ])
  // }

  // _keyExtractor = (item, index) => `${item[0]}_${item[1]}_${index}`
  _keyExtractor = (item, index) => `${item.mkpVariantIndex}_${item.xSellyVariantIndex}_${index}`

  _onStagingPairedItemPress = (index: number) => {
    const { stagingCheckedMap: oldMap = {} } = this.state
    const stagingCheckedMap = _.cloneDeep(oldMap)
    stagingCheckedMap[index] = !stagingCheckedMap[index]
    this.setState({ stagingCheckedMap })
  }

  renderStagingPairedListItem = (info: ListRenderItemInfo<IStagingPariedItem>) => {
    const { editingMkpProduct, xSellyProduct, stagingCheckedMap } = this.state
    const { item, index } = info
    // const [mkpVariantIndex, xSellyVariantIndex] = item
    const { mkpVariantIndex, xSellyVariantIndex, similarityPercent } = item
    // console.log(`MkpAutoMappingVariantsView renderStagingPairedListItem index=${index} item => `, item)

    const mkpVariant: IMkpProductDetailVariant = editingMkpProduct.variants[mkpVariantIndex]
    const xSellyVariant: IProductDetailVariant = xSellyProduct.variants[xSellyVariantIndex]

    let mkpThumbnailImgUri = editingMkpProduct.thumbnail_uris[0]
    if (mkpVariant.img_uri && mkpVariant.img_uri !== '') {
      mkpThumbnailImgUri = mkpVariant.img_uri
    }

    const xSellyThumbnailImgUri = xSellyVariant.thumbnail_url || xSellyProduct.thumbnail_uris[0]
    const checked = stagingCheckedMap[index]
    const isPerfectMatch = similarityPercent === 100
    const isSKUMatch = this._getPairedFieldValue() === 'sku'
    return (
      <HStack w='full' px='2' pt='1'>
        <TouchableOpacity style={{ width: '100%' }} onPress={() => this._onStagingPairedItemPress(index)}>
          <HStack
            w='full'
            p='2'
            borderRadius='lg'
            borderWidth='1'
            borderTopColor='gray.300'
            borderLeftColor='gray.300'
            borderRightColor='gray.400'
            borderBottomColor='gray.400'
            bg='white'
            alignItems='center'
            space='1'>
            <HStack p='2' alignItems='center' justifyContent='center'>
              <XIcon family='FontAwesome' name={checked ? 'check-circle' : 'circle-o'} variant={checked ? 'success' : 'inactive'} />
            </HStack>

            <VStack flex={1} space='1'>
              <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
                {mkpThumbnailImgUri && mkpThumbnailImgUri !== '' && (
                  <VStack w='16' h='16'>
                    <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: mkpThumbnailImgUri }} />

                    <Box position='absolute' top='1' left='1'>
                      {this.renderMkpIcon()}
                    </Box>
                  </VStack>
                )}

                <VStack flex={1}>
                  <XText variant={isSKUMatch ? 'inactive' : 'active'}>{mkpVariant.name}</XText>
                  {isSKUMatch && <XText>SKU: {mkpVariant.sku}</XText>}
                </VStack>
              </HStack>

              <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
                {xSellyThumbnailImgUri && xSellyThumbnailImgUri !== '' && (
                  <VStack w='16' h='16'>
                    <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: xSellyThumbnailImgUri }} />

                    <Box position='absolute' top='1' left='1'>
                      <XSellyLogo height={24} width={24} />
                    </Box>
                  </VStack>
                )}

                <VStack flex={1}>
                  <XText variant={isSKUMatch ? 'inactive' : 'active'}>{xSellyVariant.name}</XText>
                  {isSKUMatch && <XText>SKU: {xSellyVariant.sku || ''}</XText>}
                </VStack>
              </HStack>
            </VStack>

            <VStack w='100px' alignItems='center' justifyContent='center'>
              <XText variant={isPerfectMatch ? 'success' : 'inactive'} bold={isPerfectMatch}>
                {isPerfectMatch ? 'เหมือนกัน' : 'คล้ายกัน'}
              </XText>
              <XText variant={isPerfectMatch ? 'success' : 'active'} bold={isPerfectMatch}>{`${similarityPercent}%`}</XText>
            </VStack>

            <HStack p='2' position='absolute' right='0' top='0' alignItems='center' justifyContent='flex-end'>
              <XText textAlign='right' size='xs' variant='inactive'>{`#${index + 1}`}</XText>
            </HStack>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _isAllChecked = () => {
    const { stagingCheckedMap } = this.state
    return _.every(stagingCheckedMap, (checked) => checked) && !_.isEmpty(stagingCheckedMap)
  }

  _onAllCheckedPress = () => {
    const { stagingCheckedMap: oldOne } = this.state
    const allChecked = this._isAllChecked()
    const stagingCheckedMap = _.mapValues(oldOne, () => !allChecked)
    this.setState({ stagingCheckedMap })
  }

  _checkedCount = () => {
    const { stagingCheckedMap } = this.state
    return _.filter(stagingCheckedMap, (checked) => checked).length
  }

  _getSimilarityWordText = () => {
    return this._getSimilarityPercentBaselineValue() === 100 ? 'เหมือนกัน' : 'คล้ายกัน'
  }

  renderStagingPairedList = () => {
    const { stagingPairedItems, stagingCheckedMap, isPairing } = this.state

    if (isPairing) {
      return (
        <VStack w='full' flex={1} px='2' py='40px' alignItems='center' bg='coolGray.200'>
          <XText textAlign='center' variant='inactive'>
            กำลังผูก...
          </XText>
          <XSpinner />
        </VStack>
      )
    }

    if (_.isNil(stagingPairedItems) || stagingPairedItems.length === 0) {
      const notFoundText = `ไม่พบตัวเลือกที่ ${this._getPairedFieldLabel()} ที่${this._getSimilarityWordText()} ${this._getSimilarityPercentBaselineLabel()}`
      return (
        <VStack w='full' flex={1} px='2' py='40px' alignItems='center' bg='coolGray.200'>
          <XText variant='inactive'>{notFoundText}</XText>
          <XText pt='4' variant='inactive' textAlign='center'>
            {'คุณสามารถเลือกปรับเปอร์เซ็นต์ความคล้ายให้น้อยลง\nเพื่อทดลองหาผลลัพธ์ที่มากขึ้น'}
          </XText>
        </VStack>
      )
    }

    return (
      <VStack w='full' flex={1} bg='coolGray.200'>
        <FlatList
          scrollEnabled={false}
          extraData={stagingCheckedMap}
          data={stagingPairedItems}
          keyExtractor={this._keyExtractor}
          // @ts-ignore
          renderItem={this.renderStagingPairedListItem}
        />
      </VStack>
    )
  }

  renderXSellyProductInfo = () => {
    const { xSellyProduct: xProduct } = this.state

    if (!xProduct || !xProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [] } = xProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    return (
      <HStack w='full' px='3' py='2' space='1'>
        <VStack>
          <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnail_uris[0] }} />
          <Box position='absolute' top='1' left='1'>
            <XSellyLogo width={24} height={24} />
          </Box>
        </VStack>
        <VStack flex={1} space='1'>
          <XText w='full' numberOfLines={2}>
            {name}
          </XText>
          {/* <XText variant='inactive' fontSize='xs' w='full' numberOfLines={1}>
            {description}
          </XText> */}
          {variantCount > 1 ? (
            <XText fontSize='xs' w='full' numberOfLines={2}>
              <XText variant='active' bold fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
              {variantNames}
            </XText>
          ) : null}
        </VStack>
      </HStack>
    )
  }

  renderMkpProductInfo = () => {
    const { editingMkpProduct: mkpProduct } = this.state

    if (!mkpProduct || !mkpProduct.name) {
      return null
    }

    const { name, description, thumbnail_uris = [], variants = [] } = mkpProduct
    const variantCount = variants.length
    const variantNames = variants.map((xv) => xv.name).join(', ')

    const thumbnailImgUri = thumbnail_uris[0] || null

    return (
      <HStack w='full' px='2' py='1' space='1' alignItems='center' justifyContent='center'>
        {thumbnailImgUri && thumbnailImgUri !== '' && (
          <VStack w='16' h='16'>
            <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: thumbnailImgUri }} />

            <Box position='absolute' top='1' left='1'>
              {this.renderMkpIcon()}
            </Box>
          </VStack>
        )}

        <VStack flex={1} space='1'>
          <XText>{name}</XText>
          {variantCount > 1 ? (
            <XText fontSize='xs' w='full' numberOfLines={2}>
              <XText variant='active' bold fontSize='xs'>{`มี ${variantCount} ตัวเลือก: `}</XText>
              {variantNames}
            </XText>
          ) : null}
        </VStack>
      </HStack>
    )
  }

  renderHeaderOptions = () => {
    const {
      editingMkpProduct,
      xSellyProduct,
      segmentOptionPairFieldAtIndex,
      segmentOptionSimilarityBaselinePercentAtIndex,
      segmentOptionIsSkipPairedCheckAtIndex,
      isPairing,
    } = this.state

    if (!editingMkpProduct || !xSellyProduct) {
      return null
    }

    return (
      <VStack w='full' space='1'>
        {/* <XText>{JSON.stringify(editingMkpProduct)}</XText> */}
        <VStack w='full' px='4' py='2.5' space='3' bg='white'>
          <XCard>
            <VStack w='full' space='1'>
              {this.renderMkpProductInfo()}
              <Box w='full' h='1px' bg='gray.300' />
              {this.renderXSellyProductInfo()}
            </VStack>
          </XCard>

          <VStack w='full' space='1'>
            <XText variant='inactive'>ผูกตัวเลือกโดย...</XText>
            <XSegment
              disabled={isPairing}
              selectedIndex={segmentOptionPairFieldAtIndex}
              options={PAIR_BY_FIELD_OPTIONS}
              onSegmentChange={this.onSegmentPairFieldChange}
            />
          </VStack>

          <VStack w='full' space='1'>
            <XText variant='inactive'>{`ผูกโดยที่ ${this._getPairedFieldLabel()} มีเปอร์เซ็นต์ความ${this._getSimilarityWordText()}...`}</XText>
            <XSegment
              disabled={isPairing}
              selectedIndex={segmentOptionSimilarityBaselinePercentAtIndex}
              options={SIMILARITY_PERCENTAGE_OPTIONS}
              onSegmentChange={this.onSegmentSimilarityChange}
            />
          </VStack>

          <VStack w='full' space='1'>
            <XText variant='inactive'>ผูกที่ตัวเลือก...</XText>
            <XSegment
              disabled={isPairing}
              selectedIndex={segmentOptionIsSkipPairedCheckAtIndex}
              options={IS_SKIP_PAIRED_CHECK_OPTIONS}
              onSegmentChange={this.onSegmentIsSkipPairedCheckChange}
            />
          </VStack>
        </VStack>
      </VStack>
    )
  }

  _submitStagingPaired = () => {
    const { stagingPairedItems, editingMkpProduct, xSellyProduct, stagingCheckedMap } = this.state
    const onConfirm = util.getNavParam(this.props, 'onConfirm')

    const newPairedMap = {}

    stagingPairedItems.forEach((spi, index) => {
      const { mkpVariantIndex, xSellyVariantIndex } = spi

      const item_id = editingMkpProduct.variants[mkpVariantIndex].item_id
      const item_variant_id = editingMkpProduct.variants[mkpVariantIndex].item_variant_id
      const pp_id = xSellyProduct.variants[xSellyVariantIndex].pp_id

      if (stagingCheckedMap[index]) {
        newPairedMap[`${item_id}_${item_variant_id}`] = pp_id
      }
    })

    onConfirm(newPairedMap)
    // p.op.showToast('ผูกตัวเลือกสินค้าเรียบร้อย', 'success')
    this._goBack()
  }

  renderFooter = () => {
    const { isPairing, stagingPairedItems } = this.state

    if (_.isNil(stagingPairedItems) || stagingPairedItems.length === 0 || isPairing) {
      return null
    }

    const isDisabled = this._checkedCount() === 0

    return (
      <VStack w='full' p='2' bg='white' space='1' borderTopWidth='1' borderTopColor='gray.200'>
        <HStack w='full' px='2.5' pt='1' pb='1.5' bg='white' alignItems='center' space='2'>
          <XIconButton
            family='MaterialCommunityIcons'
            name={this._isAllChecked() ? 'checkbox-multiple-marked-circle' : 'checkbox-blank-circle-outline'}
            variant={this._isAllChecked() ? 'success' : 'inactive'}
            iconVariant={this._isAllChecked() ? 'success' : 'inactive'}
            onPress={this._onAllCheckedPress}
          />
          <XText variant='inactive'>
            เลือก <XText variant='active'>{this._checkedCount()}</XText> จาก <XText variant='active'>{stagingPairedItems.length}</XText>{' '}
            ตัวเลือกที่ชื่อคล้ายกัน
          </XText>
        </HStack>

        <XButton w='full' py='3' h='44px' isDisabled={isDisabled} disabled={isDisabled} onPress={this._submitStagingPaired}>
          {isDisabled ? 'เลือกรายการที่ต้องการผูก' : 'ยืนยันการผูกตัวเลือกสินค้า'}
        </XButton>
      </VStack>
    )
  }

  renderMain = () => (
    <XContainer>
      {this.renderCustomHeader()}
      <XContent>
        {this.renderHeaderOptions()}
        {this.renderStagingPairedList()}
      </XContent>

      {this.renderFooter()}
    </XContainer>
  )

  render() {
    return this.renderMain()
  }
}

// const MkpProductViewContainer = withMkpProductContextPropsAndRef(MkpProductView)
// // @ts-ignore
// MkpProductViewContainer.navigationOptions = { header: null }
// export default MkpProductViewContainer
