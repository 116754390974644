/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import _ from 'lodash'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import { ISelectedStoreMap, IStoreWebsite, ITrackingPixels, IXScreenProps } from 'x/index'
import { StackNavigationProp } from '@react-navigation/stack'
import CONS from 'x/config/constants'
import dayjs from 'dayjs'

// @ts-ignore
interface IBaseSalePagePixelViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
  navigation: StackNavigationProp<any>
}

interface IBaseSalePagePixelViewState {
  salePage: IStoreWebsite
  isLoading: boolean
  isEditing: boolean
  trackingPixels: ITrackingPixels
}

export default abstract class BaseSalePagePixelView extends React.Component<IBaseSalePagePixelViewProps, IBaseSalePagePixelViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  imgManagerRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      isLoading: false,
      isEditing: false,
      trackingPixels: null,
    }
    this.imgManagerRef = React.createRef()
  }

  async componentDidMount() {
    await this._setSalePage()
    await this._setDraftConfigJson()
  }

  _setSalePage = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    if (!_.has(params, 'salePage')) {
      return
    }

    const { salePage } = params
    // console.log('salePage => ', salePage)
    await util.setStatePromise(this, {
      salePage,
    })
  }

  _setDraftConfigJson = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    if (!_.has(params, 'salePage')) {
      return
    }
    const { salePage } = params
    // console.log('salePage // => ', salePage)
    const draftConfigJson = salePage.draft_config_json
    // const trackingPixels = draftConfigJson?.draft_config_json?.tracking_pixels ?? {}
    let trackingPixels = _.isNil(draftConfigJson) ? {} : draftConfigJson.tracking_pixels
    // console.log('trackingPixels => ', trackingPixels)
    if (_.isNil(trackingPixels)) {
      trackingPixels = {}
    }
    await util.setStatePromise(this, {
      trackingPixels,
    })
  }

  _onChangeFacebookPixel = (newPixel: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'facebook')) {
      newTrackingPixels.facebook = {
        pixel: newPixel,
      }
    } else {
      newTrackingPixels.facebook.pixel = newPixel
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeFacebookAccessToken = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'facebook')) {
      newTrackingPixels.facebook = {
        access_token: newAccessToken,
      }
    } else {
      newTrackingPixels.facebook.access_token = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeFacebookTestPixcel = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'facebook')) {
      newTrackingPixels.facebook = {
        test: newAccessToken,
      }
    } else {
      newTrackingPixels.facebook.test = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeTiktokPixel = (newPixel: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'tiktok')) {
      newTrackingPixels.tiktok = {
        pixel: newPixel,
      }
    } else {
      newTrackingPixels.tiktok.pixel = newPixel
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeTiktokAccessToken = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'tiktok')) {
      newTrackingPixels.tiktok = {
        access_token: newAccessToken,
      }
    } else {
      newTrackingPixels.tiktok.access_token = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeTiktokTestPixcel = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'tiktok')) {
      newTrackingPixels.tiktok = {
        test: newAccessToken,
      }
    } else {
      newTrackingPixels.tiktok.test = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeGooglePixel = (newPixel: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'google')) {
      newTrackingPixels.google = {
        pixel: newPixel,
      }
    } else {
      newTrackingPixels.google.pixel = newPixel
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeGoogleAccessToken = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'google')) {
      newTrackingPixels.google = {
        access_token: newAccessToken,
      }
    } else {
      newTrackingPixels.google.access_token = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeGoogleTestPixcel = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'google')) {
      newTrackingPixels.google = {
        test: newAccessToken,
      }
    } else {
      newTrackingPixels.google.test = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeLinePixel = (newPixel: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'line')) {
      newTrackingPixels.line = {
        pixel: newPixel,
      }
    } else {
      newTrackingPixels.line.pixel = newPixel
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _onChangeLineAccessToken = (newAccessToken: string) => {
    const { trackingPixels } = this.state
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    if (!_.has(newTrackingPixels, 'line')) {
      newTrackingPixels.line = {
        access_token: newAccessToken,
      }
    } else {
      newTrackingPixels.line.access_token = newAccessToken
    }

    this.setState({ trackingPixels: newTrackingPixels, isEditing: true })
  }

  _submitApi = async () => {
    const { selectedStore } = this.props
    const { salePage, trackingPixels } = this.state
    // console.log('storeWebsite /// => ', storeWebsite)
    await util.setStatePromise(this, { isLoading: true })
    await util.delay(200)

    // @ts-ignore
    const newJson = _.has(salePage, 'tracking_pixels') ? _.cloneDeep(salePage.tracking_pixels) : { tracking_pixels: null }
    const newTrackingPixels = _.cloneDeep(trackingPixels)
    // @ts-ignore
    newTrackingPixels.updated_at = dayjs().format(CONS.SERVER_DATETIME_FORMAT).toString()
    // console.log('newOrderPicker => ', newOrderPicker)
    // @ts-ignore
    newJson.tracking_pixels = newTrackingPixels

    try {
      const res = await api.updateStoreWebsite({
        store_id: selectedStore.get('id'),
        website_id: salePage.id,
        config_json: newJson,
      })
      console.log('res => ', res)
      // @ts-ignore
      const params = util.getNavParams(this.props)
      const { callBackSucceedUpdateApi } = params
      if (_.isFunction(callBackSucceedUpdateApi)) {
        callBackSucceedUpdateApi(res)
        // p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
        // @ts-ignore
        util.navGoBack(this.props)
      }
    } catch (error) {
      // console.log('error => ', error)
    }
  }
}
