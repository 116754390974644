/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, IXScreenProps, IWebsiteConfig, IStoreWebsite } from 'x/index'
import * as util from 'x/utils/util'
import _ from 'lodash'
import XWebView from 'src/components/XWebView'

interface IBaseSalePageSettingWebViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseSalePageSettingWebViewState {
  salePage: IStoreWebsite
  webItems: IWebsiteConfig
  widthImg: number
  isStopSetWidthImg: boolean
  indexShowToolBar: number // -1 is close toolbar

  isEditable?: boolean
  isLoading?: boolean
  isWebLoading?: boolean
}

export default abstract class BaseSalePageSettingWebView extends React.Component<
  IBaseSalePageSettingWebViewProps,
  IBaseSalePageSettingWebViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  _editor: any

  webviewRef: React.RefObject<XWebView>

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      webItems: null,
      widthImg: 80,
      isStopSetWidthImg: false,
      indexShowToolBar: -1,
    }
    this._editor = React.createRef()
    this.webviewRef = React.createRef()
  }

  componentDidMount() {
    const params = util.getNavParams(this.props)
    const { salePage } = params
    // console.log('salePage => ', salePage)
    this.setState({
      salePage,
      // webItems: _.isNil(salePage) ? null : salePage.web_items,
    })
  }

  // _navToEditorView = (html: string, index: number) => {
  //   // const { webItems } = this.state
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToEditorView({
  //       html,
  //       index,
  //       callBack: (Html: string, Index: number) => this._callBackFromEditingHtml(Html, Index),
  //     })
  //   )
  // }

  // _callBackFromEditingHtml = (html: string, index: number) => {
  //   // console.log('_callBackFromEditingHtml => ', html)
  //   const { webItems } = this.state
  //   const newWebItems = _.clone(webItems)
  //   newWebItems.item[index].html = html
  //   // const newSalePage = _.clone(salePage)
  //   // newSalePage.web_items = newWebItems
  //   this.setState({
  //     // salePage: newSalePage,
  //     webItems: newWebItems,
  //   })
  // }

  _onChangeCountdownTimer = (newTime: string, index: number) => {
    // console.log('newTime.length => ', newTime.length)
    if (newTime.length > 4) {
      return
    }
    if (newTime.length === 0 && !_.isNumber(+newTime)) {
      // console.log('PASS !')
      return
    }
    const { webItems } = this.state
    const newWebItems = _.clone(webItems)
    newWebItems[index].countdown_timer_in_minute = +newTime
    // console.log('newWebItems => ', newWebItems)
    this.setState({ webItems: newWebItems })
  }

  // // This function adds a new web item to the state array at a given index
  // // It also updates the index of the toolbar to -1
  // _callBackAddNewItem = (newItem: IOLDWebItem, index: number, isEdit: boolean) => {
  //   // Destructure the webItems array from the state object
  //   const { webItems } = this.state
  //   // Create a copy of the webItems array
  //   const newWebItems = _.cloneDeep(webItems)
  //   // Check if the newItem is for editing an existing item or adding a new one
  //   if (!_.isNil(isEdit) && isEdit) {
  //     // Replace the item at the index with the newItem
  //     newWebItems.item[index] = newItem
  //   } else {
  //     // Insert the newItem after the item at the index
  //     newWebItems.item.splice(index + 1, 0, newItem)
  //   }
  //   // Update the state with the new webItems array and the toolbar index
  //   this.setState({ webItems: newWebItems, indexShowToolBar: -1 })
  // }

  // _navToSalePageAddItemWebView = (index: number) => {
  //   // const { webItems } = this.state
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToSalePageAddItemWebView({
  //       index,
  //       callBack: (newItem: IOLDWebItem, newIndex: number, isEdit: boolean) => this._callBackAddNewItem(newItem, newIndex, isEdit),
  //     })
  //   )
  // }

  // _navToEditCountdownTimeView = (countdownTime: number, index: number, popViewNumber: number) => {
  //   // const { webItems } = this.state
  //   // const params = util.getNavParams(this.props)
  //   // const { callBack } = params
  //   const { navigation } = this.props
  //   navigation.dispatch(
  //     NavActions.navToEditCountdownTimeView({
  //       index,
  //       countdownTime,
  //       popViewNumber,
  //       callBack: (newItem: IOLDWebItem, newIndex: number, isEdit: boolean) => this._callBackAddNewItem(newItem, newIndex, isEdit),
  //     })
  //   )
  // }
}
