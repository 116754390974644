/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseERPShippingSetting from 'x/modules/erp/BaseERPShippingSetting'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import HelpButton from 'xui/components/HelpButton'
import VStack from 'xui/components/VStack'
import MkpLogo from 'xui/components/MkpLogo'
import XSwitch from 'xui/components/XSwitch'

const LOGO = require('../../img/logo_1024.png')

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIERPShippingSetting extends BaseERPShippingSetting {
  renderCustomHeader = () => {
    const isGoBackButtonHidden = util.getNavParam(this.props, 'isGoBackButtonHidden', false)
    // const { isRenderWebView } = this.state
    return (
      <XCustomHeader
        title='ตั้งค่าขนส่งระบบบัญชี'
        headerLeftProps={{ backIcon: true, onPressItem: () => this.goBack() }}
        // headerRightProps={{ label: 'บันทึก' }}
      />
    )
  }

  _renderTitle = () => (
    <XCard p='2' w='full'>
      <HStack alignItems='center' w='full'>
        <HStack flex={1}>
          <XText>
            หากเปิดการตั้งค่า ค่าจัดส่งที่ลูกค้าชำระมาในแต่ละออเดอร์ที่มาจากช่องทางขายนั้นๆ จะถูกส่งที่ไป PEAK สำหรับการสร้างเอกสาร
            เพื่อบันทึกค่าขนส่งเป็นรายได้ ด้วย
          </XText>
        </HStack>
        {/* <HStack w='8'>
            <XFaqModal
              key='รหัสผู้ติดต่อ'
              headerTitle='รหัสผู้ติดต่อ'
              // @ts-ignore
              FAQ={FAQ_CONSTANTS.FAQ_ERP_CONTACT}
              initiateOpenIndex={[0, 1, 2]}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack> */}
      </HStack>
    </XCard>
  )

  _renderContactListView = () => {
    const { erpMkpContacts, mkpChannels } = this.state
    if (_.isNil(erpMkpContacts) || _.isNil(mkpChannels)) {
      return null
    }
    return (
      <VStack>
        {erpMkpContacts.map((contact, idx) => {
          // console.log('mkpChannels => ', erpMkpContacts)
          // console.log('contact => ', contact)
          if (contact.contact_code === 'XSELLY_CONTACT') {
            return null
          }
          const mkpChannel = mkpChannels.find((mkp) => mkp.id === contact.sales_channel_id)
          const erpcontactName = mkpChannel ? mkpChannel.name : '-'

          const LOGO_RENDER = <MkpLogo mkpId={mkpChannel.mkp_id} />

          const useShippingCostAsIncome = contact.use_shipping_cost_as_income

          return (
            // eslint-disable-next-line react/no-array-index-key
            <XCard p='2' w='full' mt='2' key={`contact_${idx}`}>
              {/* // Header */}
              <HStack alignItems='center'>
                {_.isNil(LOGO_RENDER) ? null : LOGO_RENDER}
                <XText ml='2' bold>
                  {erpcontactName}
                </XText>
              </HStack>
              {/* //บันทึกค่าขนส่งเป็นรายได้  */}
              <HStack w='full' alignItems='center' mt='2'>
                <HStack flex={1}>
                  <XText>บันทึกค่าขนส่งเป็นรายได้</XText>
                </HStack>
                <HStack flex={1} justifyContent='flex-end'>
                  <XSwitch value={useShippingCostAsIncome} onValueChange={(newValue) => this._warningToSubmitContact(contact, newValue)} />
                </HStack>
              </HStack>
            </XCard>
          )
        })}
      </VStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent p='2'>
          {this._renderTitle()}
          {this._renderContactListView()}
          <HStack mt='4' />
        </XContent>
      </XContainer>
    )
  }
}
