import {
  IApiOptions,
  IStoreContactDetail,
  IStoreContactListItem,
  ICreateStoreContactRequestBody,
  IEditStoreContactRequestBody,
  IGetStoreContactDetailRequestBody,
  IDeleteStoreContactRequestBody,
  IAddress,
  // IStoreContactAddress,
  IGetStoreContactListRequestBody,
} from 'x/types'

import _ from 'lodash'
import api from './api'

// interface IServerStoreContactDetail extends Omit<IStoreContactDetail, 'addresses'> {
//   // addresses: IStoreContactAddress[]
//   addresses: IAddress[]
// }

type IGetStoreContactListResponse = {
  contacts: IStoreContactListItem[]
}

export async function getStoreContactList(params: IGetStoreContactListRequestBody): Promise<IStoreContactListItem[]> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('getStoreContactList:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'แก้ไขการตั้งค่าร้านค้าสำเร็จ',
    //   errorMsg: 'ล้มเหลวในการแก้ไขการตั้งค่า',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In getStoreContactList:: reqBody => ', params)
    const res = await api.post<IGetStoreContactListRequestBody, IGetStoreContactListResponse>('contact/list', params, apiOptions)
    // console.log('In getStoreContactList:: response => ', res)

    if (_.isNil(res.contacts)) {
      throw new Error('getStoreContactList:: No "contacts" in response')
    }

    return res.contacts
  } catch (err) {
    console.log('In getStoreContactList:: err => ', err)
    throw err
  }
}

export async function getStoreContactDetail(params: IGetStoreContactDetailRequestBody): Promise<IStoreContactDetail> {
  const { store_id, contact_id } = params

  if (!store_id) {
    throw new Error('getStoreContactDetail:: Need store_id')
  }

  if (!contact_id) {
    throw new Error('getStoreContactDetail:: Need contact_id')
  }

  const apiOptions: IApiOptions = {
    // messages: {
    //   successMsg: 'สร้างบัญชีลูกค้าสำเร็จ',
    //   errorMsg: 'ล้มเหลวในการสร้างบัญชีลูกค้า',
    // },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In getStoreContactDetail:: reqBody => ', params)
    const res = await api.post<IGetStoreContactDetailRequestBody, IStoreContactDetail>('contact/detail', params, apiOptions)
    // console.log('In getStoreContactDetail:: response => ', res)

    if ('id' in res) {
      // return convertServerStoreContactToClientStoreContact(res)
      return res
    }

    throw new Error('getStoreContactDetail:: No contact "id" in response')
  } catch (err) {
    console.log('In getStoreContactDetail:: err => ', err)
    throw err
  }
}

export async function createStoreContact(params: ICreateStoreContactRequestBody): Promise<IStoreContactDetail> {
  const { store_id, first_name } = params

  if (!store_id) {
    throw new Error('createStoreContact:: Need store_id')
  }

  if (!first_name) {
    throw new Error('createStoreContact:: Need first_name')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'สร้างบัญชีลูกค้าสำเร็จ',
      errorMsg: 'ล้มเหลวในการสร้างบัญชีลูกค้า',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    // const reqBody: ICreateStoreContactRequestBody = convertClientStoreContactToServerStoreContact(params)
    const reqBody: ICreateStoreContactRequestBody = _.cloneDeep(params)

    // console.log('In createStoreContact:: reqBody => ', params)
    const res = await api.post<ICreateStoreContactRequestBody, IStoreContactDetail>('contact/create', reqBody, apiOptions)
    // console.log('In createStoreContact:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('createStoreContact:: No contact "id" in response')
  } catch (err) {
    console.log('In createStoreContact:: err => ', err)
    throw err
  }
}

export async function editStoreContact(params: IEditStoreContactRequestBody): Promise<IStoreContactDetail> {
  const { store_id, contact_id } = params

  if (!store_id) {
    throw new Error('editStoreContact:: Need store_id')
  }

  if (!contact_id) {
    throw new Error('editStoreContact:: Need contact_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'สร้างบัญชีลูกค้าสำเร็จ',
      errorMsg: 'ล้มเหลวในการสร้างบัญชีลูกค้า',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    // const reqBody: IEditStoreContactRequestBody = convertClientStoreContactToServerStoreContact(params)
    const reqBody: IEditStoreContactRequestBody = _.cloneDeep(params)

    // console.log('In editStoreContact:: reqBody => ', params)
    const res = await api.post<IEditStoreContactRequestBody, IStoreContactDetail>('contact/edit', reqBody, apiOptions)
    // console.log('In editStoreContact:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('editStoreContact:: No contact "id" in response')
  } catch (err) {
    console.log('In editStoreContact:: err => ', err)
    throw err
  }
}

interface IEditStoreContactAddress extends IAddress {
  store_id: number
}

export async function editStoreContactAddress(params: IEditStoreContactAddress): Promise<any> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('editStoreContactAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    // const reqBody: IEditStoreContactAddress = convertNormalAddressToStoreContactAddress(params)
    const reqBody: IEditStoreContactAddress = _.cloneDeep(params)
    // @ts-ignore
    // reqBody.address_id = params.id
    // delete reqBody.id

    if (_.isString(reqBody.postal_code)) {
      // @ts-ignore
      reqBody.postal_code = parseInt(reqBody.postal_code)
    }

    // @ts-ignore
    // if (_.isNil(reqBody.type)) {
    // @ts-ignore
    // reqBody.type = 'CONTACT'
    // }

    if (reqBody.type) {
      // @ts-ignore
      // reqBody.contact_type = 'shipping'
      // reqBody.contact_type = reqBody.type
      // @ts-ignore
      delete reqBody.type
    }

    if (_.isNil(reqBody.branch_type)) {
      delete reqBody.branch_type
    }

    if (_.isNil(reqBody.branch_number)) {
      delete reqBody.branch_number
    }

    if (_.isNil(reqBody.legal_entity_id)) {
      delete reqBody.legal_entity_id
    }

    if (_.isNil(reqBody.branch_number)) {
      delete reqBody.branch_number
    }

    // console.log('In editStoreContactAddress:: reqBody => ', params)
    const res = await api.post<IEditStoreContactAddress, any>('address/edit', reqBody, apiOptions)
    // console.log('In editStoreContactAddress:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('editStoreContactAddress:: No contact "id" in response')
  } catch (err) {
    console.log('In editStoreContact:: err => ', err)
    throw err
  }
}

interface IAddStoreContactAddress extends IAddress {
  store_id: number
  addressable_id: number
}

export async function addStoreContactAddress(params: IAddStoreContactAddress): Promise<any> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('addStoreContactAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // @ts-ignore
    // const reqBody: IEditStoreContactAddress = convertNormalAddressToStoreContactAddress(params)
    const reqBody: IEditStoreContactAddress = _.cloneDeep(params)

    // @ts-ignore
    // if (_.isNil(reqBody.type)) {
    // @ts-ignore
    reqBody.type = 'CONTACT'
    // }

    // console.log('In addStoreContactAddress:: reqBody => ', params)
    const res = await api.post<IEditStoreContactAddress, any>('address/create', reqBody, apiOptions)
    // console.log('In addStoreContactAddress:: response => ', res)

    if ('id' in res) {
      return res
    }

    throw new Error('addStoreContactAddress:: No contact "id" in response')
  } catch (err) {
    console.log('In addStoreContactAddress:: err => ', err)
    throw err
  }
}

interface IDeleteStoreContactAddress {
  store_id: number

  // address_id
  id: number
}

export async function deleteStoreContactAddress(params: IDeleteStoreContactAddress): Promise<void> {
  const { store_id } = params

  if (!store_id) {
    throw new Error('deleteStoreContactAddress:: Need store_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'บันทึกที่อยู่สำเร็จ',
      errorMsg: 'บันทึกที่อยู่ล้มเหลว',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    const reqBody: IDeleteStoreContactAddress = _.cloneDeep(params)

    // console.log('In deleteStoreContactAddress:: reqBody => ', params)
    const res = await api.post<IDeleteStoreContactAddress, any>('address/delete', reqBody, apiOptions)
    // console.log('In deleteStoreContactAddress:: response => ', res)
    return
  } catch (err) {
    // console.log('In deleteStoreContactAddress:: err => ', err)
    throw err
  }
}

export async function deleteStoreContact(params: IDeleteStoreContactRequestBody): Promise<boolean> {
  const { store_id, contact_id } = params

  if (!store_id) {
    throw new Error('deleteStoreContact:: Need store_id')
  }

  if (!contact_id) {
    throw new Error('deleteStoreContact:: Need contact_id')
  }

  const apiOptions: IApiOptions = {
    messages: {
      successMsg: 'ลบบัญชีลูกค้าสำเร็จ',
      errorMsg: 'ล้มเหลวในการลบบัญชีลูกค้า',
    },
    showSpinner: false,
    isApiV2: true,
    axiosOptions: {
      timeout: 18000,
    },
  }

  try {
    // console.log('In deleteStoreContact:: reqBody => ', params)
    const res = await api.post<IDeleteStoreContactRequestBody, any>('contact/delete', params, apiOptions)
    // console.log('In deleteStoreContact:: response => ', res)
    return true
  } catch (err) {
    // console.log('In deleteStoreContact:: err => ', err)
    return false
  }
}

// function convertServerStoreContactToClientStoreContact(sc: IServerStoreContactDetail): IStoreContactDetail {
//   const newSc: any = _.cloneDeep(sc)

//   if (newSc.addresses) {
//     const newAddresses: IAddress[] = newSc.addresses.map(convertStoreContactAddressToNormalAddress)
//     newSc.addresses = newAddresses
//   }

//   if (newSc.address) {
//     newSc.address = convertStoreContactAddressToNormalAddress(newSc.address)
//   }

//   return newSc
// }

// function convertClientStoreContactToServerStoreContact(sc: IStoreContactDetail): IServerStoreContactDetail {
//   const newSc: any = _.cloneDeep(sc)

//   if (newSc.addresses) {
//     const newAddresses: IStoreContactAddress[] = newSc.addresses.map(convertNormalAddressToStoreContactAddress)
//     newSc.addresses = newAddresses
//   }

//   if (newSc.address) {
//     newSc.address = convertNormalAddressToStoreContactAddress(newSc.address)
//   }

//   return newSc
// }

// function convertStoreContactAddressToNormalAddress(scAddr: IStoreContactAddress): IAddress {
//   const newAddr: any = { ...scAddr }

//   if (scAddr.address_1) {
//     newAddr.address1 = scAddr.address_1
//     delete newAddr.address_1
//   }

//   if (scAddr.address_2) {
//     newAddr.address2 = scAddr.address_2
//     delete newAddr.address_2
//   }

//   if (scAddr.address_3) {
//     newAddr.address3 = scAddr.address_3
//     delete newAddr.address_3
//   }

//   return newAddr
// }

// function convertNormalAddressToStoreContactAddress(addr: IAddress): IStoreContactAddress {
//   const newAddr: any = { ...addr }

//   if (newAddr.address1) {
//     newAddr.address_1 = newAddr.address1
//     delete newAddr.address1
//   }

//   if (newAddr.address2) {
//     newAddr.address_2 = newAddr.address2
//     delete newAddr.address2
//   }

//   if (newAddr.address3) {
//     newAddr.address_3 = newAddr.address3
//     delete newAddr.address3
//   }

//   return newAddr
// }
