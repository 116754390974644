/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import * as util from 'x/utils/util'
import * as api from 'x/utils/api'
import ps from 'x/config/platform-specific'
import _ from 'lodash'
import { ISelectedStoreMap, IStoreWebsite, IXScreenProps } from 'x/index'
import { StackNavigationProp } from '@react-navigation/stack'

// @ts-ignore
interface IBaseSalePageEditInfoViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
  navigation: StackNavigationProp<any>
}

interface IBaseSalePageEditInfoViewState {
  salePage: IStoreWebsite
  isLoading: boolean
  isUploading: boolean
  isEditing: boolean

  isEditingImg: boolean // ใช้ดูว่ามีรูปต้องอัปโหลดไหม
}

export default abstract class BaseSalePageEditInfoView extends React.Component<
  IBaseSalePageEditInfoViewProps,
  IBaseSalePageEditInfoViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  imgManagerRef: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      isLoading: false,
      isUploading: false,
      isEditing: false,
      isEditingImg: false,
    }
    this.imgManagerRef = React.createRef()
  }

  async componentDidMount() {
    await this._setSalePage()
    this._loadImagesToImgMgr()
  }

  _setSalePage = async () => {
    // @ts-ignore
    const params = util.getNavParams(this.props)
    if (!_.has(params, 'salePage')) {
      return
    }
    const { salePage } = params
    await util.setStatePromise(this, {
      salePage,
    })
  }

  // eslint-disable-next-line consistent-return
  _loadImagesToImgMgr = () => {
    const { salePage } = this.state
    if (_.isNil(salePage)) {
      return null
    }
    const cover_uri = salePage.img_url
    if (this.imgManagerRef && this.imgManagerRef.current.loadImageUrls) {
      const cover_uris = [cover_uri]
      // const cover_thumbnail_uris = [cover_thumbnail_uri]
      this.imgManagerRef.current.loadImageUrls({
        p: cover_uris,
        // t: cover_thumbnail_uris,
      })
    }
  }

  _setLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: true,
    })
  }

  stopLoading = async () => {
    await util.setStatePromise(this, {
      isLoading: false,
    })
  }

  _setUploading = async () => {
    await util.setStatePromise(this, { isUploading: true })
  }

  _unSetUploading = async () => {
    await util.setStatePromise(this, { isUploading: false })
  }

  _uploadImg = async () => {
    const { isEditingImg } = this.state
    await this._setLoading()
    if (isEditingImg) {
      if (!this.imgManagerRef || !this.imgManagerRef.current.submitUploadImages) {
        await this._handleError('เกิดข้อผิดพลาด', 'กรุณาลองอัพโหลดรูปใหม่อีกครั้ง')
        return
      }

      await this._setUploading()

      const { p, t, allUploaded } = await this.imgManagerRef.current.submitUploadImages()
      await this._unSetUploading()
      // console.log('allUploaded => ', allUploaded)
      if (allUploaded) {
        await util.delay(1000) // Delay for action dispatch

        const newSalePage = _.cloneDeep(this.state.salePage)
        // @ts-ignore
        newSalePage.img_url = p[0]

        await util.setStatePromise(this, {
          salePage: newSalePage,
        })

        await this._submitApi()
        return
      }

      await this._handleError('เกิดข้อผิดพลาด', 'กรุณาลองอัพโหลดรูปใหม่อีกครั้ง')
    } else {
      await this._submitApi()
    }
  }

  _handleError = async (title, message) => {
    ps.op.showConfirmationOkOnly(title, message)
    await this.stopLoading()
    await util.setStatePromise(this, { isUploading: true })
  }

  _submitApi = async () => {
    const { selectedStore } = this.props
    const { salePage } = this.state
    // await this._setLoading()
    try {
      const res = await api.updateStoreWebsite({
        store_id: selectedStore.get('id'),
        website_id: salePage.id,
        name: salePage.name,
        img_url: salePage.img_url,
      })
      // console.log('res => ', res)
      // ps.op.showToast('แก้ไขเรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, {
        salePage: res,
      })
      // @ts-ignore
      const params = util.getNavParams(this.props)
      if (_.has(params, 'callBackSucceedUpdateApi') && _.isFunction(params.callBackSucceedUpdateApi)) {
        params.callBackSucceedUpdateApi(res)
      }

      // @ts-ignore
      util.navGoBack(this.props)
    } catch (error) {
      // console.log('error => ', error)
    }
    await util.setStatePromise(this, {
      isEditing: false,
      isLoading: false,
      isEditingImg: false,
    })
  }
}
