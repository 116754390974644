/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { IApiOptions, ISelectedStoreMap, IStoreWebsite, IXScreenProps } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import api from 'x/utils/api'
import _ from 'lodash'
import * as util from 'x/utils/util'

interface IBaseStoreWebsiteListViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseStoreWebsiteListViewState {
  storeWebsiteList: IStoreWebsite[]
  isLoadMore: boolean
  nextOffset: number
  maxLoad: number
  type: 'storefront' | 'sale_page'
}

export default abstract class BaseStoreWebsiteListView extends React.Component<
  IBaseStoreWebsiteListViewProps,
  IBaseStoreWebsiteListViewState
> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  scrollViewRef: any

  constructor(props) {
    super(props)
    this.state = {
      isLoadMore: true,
      storeWebsiteList: [],
      nextOffset: 0,
      maxLoad: 0,
      type: null,
    }
    this.scrollViewRef = React.createRef()
  }

  async componentDidMount() {
    await this._fetchListWebSite(0)
  }

  _fetchListWebSite = async (newOffset: number) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const params = util.getNavParams(this.props)
    // console.log('params => ', params)
    if (!_.has(params, 'type') || _.isNil(newOffset)) {
      return
    }

    const { type } = params

    const reqBody = {
      store_id,
      offset: newOffset,
      limit: 10,
      types: [type],
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2('store/website/list', reqBody, apiOptions)

    // console.log('res! => ', res)
    if (res.websites) {
      const { websites } = res
      const newWebsites = _.cloneDeep(this.state.storeWebsiteList)
      websites.forEach((web) => {
        newWebsites.push(web)
      })
      await util.setStatePromise(this, {
        storeWebsiteList: newWebsites,
        nextOffset: res.next_offset,
        maxLoad: res.total_count,
        type,
      })
    }

    await util.setStatePromise(this, {
      isLoadMore: false,
      type,
    })
  }

  _callBackForSucceedEditStoreWebsite = (newWensiteUpdate: IStoreWebsite) => {
    const { storeWebsiteList } = this.state
    const newWebsites = []
    storeWebsiteList.forEach((website) => {
      if (website.id === newWensiteUpdate.id) {
        newWebsites.push(newWensiteUpdate)
      } else {
        newWebsites.push(website)
      }
    })
    this.setState({ storeWebsiteList: newWebsites })
  }

  _navToStoreWebsiteView = (storeWebsite: IStoreWebsite) => {
    const { navigation } = this.props
    // console.log('SalePage Nav => ', salePage)
    navigation.dispatch(
      NavActions.navToSalePageView({
        salePage: storeWebsite,
        callBackForSucceedDeleteStoreWebsite: () => {
          this.setState({ storeWebsiteList: [] })
          this._fetchListWebSite(0)
        },
        callBackForSucceedEditStoreWebsite: (newWensiteUpdate: IStoreWebsite) => this._callBackForSucceedEditStoreWebsite(newWensiteUpdate),
      })
    )
    // navigation.dispatch(
    //   NavActions.navToStoreWebsiteView({
    //     storeWebsite,
    //     callBackForSucceedDeleteStoreWebsite: () => this._fetchListWebSite(),
    //   })
    // )
  }

  _callBackCreateSucceed = (website: IStoreWebsite) => {
    const { navigation } = this.props
    const { storeWebsiteList, maxLoad, type } = this.state

    // Initialize a new array with the `website` as the first element
    // If `storeWebsiteList` is not null or undefined, its elements are spread into the new array
    const newStoreWebsiteList = [website, ...(storeWebsiteList || [])]

    if (!_.isNil(website)) {
      this.setState({
        storeWebsiteList: newStoreWebsiteList,
        maxLoad: maxLoad + 1,
      })
      if (type === 'sale_page') {
        navigation.dispatch(
          NavActions.navToSalePageView({
            salePage: website,
            callBackForSucceedDeleteStoreWebsite: () => {
              this.setState({ storeWebsiteList: [] })
              this._fetchListWebSite(0)
            },
            callBackForSucceedEditStoreWebsite: (newSalePage: IStoreWebsite) => this._callBackForSucceedEditStoreWebsite(newSalePage),
          })
        )
      } else {
        navigation.dispatch(
          NavActions.navToStoreWebsiteView({
            storeWebsite: website,
            callBackForSucceedDeleteStoreWebsite: () => {
              this.setState({ storeWebsiteList: [] })
              this._fetchListWebSite(0)
            },
          })
        )
      }
    }
  }

  _navToCreateStoreWebsiteView = () => {
    const { navigation } = this.props
    const { type } = this.state
    // console.log('type => ', type)
    if (_.isNil(type)) {
      return
    }
    if (type === 'sale_page') {
      navigation.dispatch(
        NavActions.navToCreateSalePageView({
          callBackCreateSucceed: (website: IStoreWebsite) => this._callBackCreateSucceed(website),
        })
      )
    } else {
      navigation.dispatch(
        NavActions.navToCreateStoreWebsiteView({
          callBackCreateSucceed: (website: IStoreWebsite) => this._callBackCreateSucceed(website),
        })
      )
    }
  }

  _handleScroll = (event) => {
    const { nextOffset, maxLoad, storeWebsiteList } = this.state
    if (_.isNil(storeWebsiteList) || storeWebsiteList.length < 1) {
      return
    }
    const { contentOffset, layoutMeasurement, contentSize } = event.nativeEvent

    const contentHeight = contentSize.height
    const viewportHeight = layoutMeasurement.height
    const scrollOffsetY = contentOffset.y

    const contentHeightWithoutViewport = contentHeight - viewportHeight
    const percentScrolled = (scrollOffsetY / contentHeightWithoutViewport) * 100

    const scrollPercent = Math.min(Math.max(percentScrolled, 0), 100)
    // console.log('scrollPercent => ', scrollPercent)
    // ถ้า scroll มากว่า 79 % แล้วให้โหลดเพิ่มถ้ายังมีให้โหลดต่อ
    if (scrollPercent > 79) {
      if (storeWebsiteList.length < maxLoad) {
        this.setState({ isLoadMore: true })
        this._fetchListWebSite(nextOffset)
      }
    }
  }
}
