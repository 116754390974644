/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { TouchableOpacity } from 'react-native'
import _ from 'lodash'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import p from 'x/config/platform-specific'
import BaseStoreWebsiteSettingOptionsOrderView from 'x/modules/storeWebsite/BaseStoreWebsiteSettingOptionsOrderView'
import { COLORS } from 'x/config/styles'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIStoreWebsiteSettingOptionsOrderView extends BaseStoreWebsiteSettingOptionsOrderView {
  _renderHeader = () => {
    const title = 'รายการคำสั่งซื้อ'
    const { haveEditing } = this.state
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (haveEditing) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      leftProps = { label: 'บันทึก', onPressItem: () => this._saveBtn() }
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'คุณต้องการกยเลิกการแก้ไขคำสั่งซื้อนี้ใช่หรือไม่',
            () => util.navGoBack(this.props),
            () => null,
            'ยกเลิกและออกจากหน้านี้',
            'แก้ไขต่อ'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftProps}
        headerRightProps={rightProps}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  // _renderBtnAddOption = () => {
  //   const { ordersPicker } = this.state
  //   const ordersPickerIndex = _.isNil(ordersPicker) ? 0 : ordersPicker.length
  //   return (
  //     <XButton onPress={() => this._navToSalePageCreateOptionsOrderView(ordersPickerIndex)}>
  //       <XIcon name='add' family='Ionicons' />
  //       เพิ่มคำสั่งซื้อ
  //     </XButton>
  //   )
  // }

  _renderProductName = (mode: 'SINGLE_PRODUCT_MODE' | 'PRODUCT_MULTI_OPTIONS_MODE' | 'PRODUCT_SET_MODE') => {
    // const { name, variants } = product
    const renderMode = () => {
      let text = 'สินค้ารายการเดียวแบบไม่มีตัวเลือก'
      let textColor = COLORS.TEXT_INACTIVE
      let icon = 'box'

      if (mode === 'PRODUCT_MULTI_OPTIONS_MODE') {
        text = 'สินค้ารายการเดียวแบบไม่มีตัวเลือก'
        textColor = COLORS.WEBSITE_PRODUCT_MULTI_OPTIONS_MODE
        icon = 'box-open'
      }
      if (mode === 'PRODUCT_SET_MODE') {
        text = 'สินค้าจัดเซ็ท (หลายรายการ)'
        textColor = COLORS.WEBSITE_PRODUCT_SET_MODE
        icon = 'boxes'
      }

      return (
        <HStack alignItems='center'>
          <XIcon name={icon} family='FontAwesome5' color={textColor} />
          <XText variant='active' pl='2' color={textColor}>
            {text}
          </XText>
        </HStack>
      )
    }
    // console.log('variants => ', variants)
    return <VStack mt='2'>{renderMode()}</VStack>
  }

  _renderIOrderPickerList = () => {
    const { ordersPicker } = this.state
    if (_.isNil(ordersPicker) || ordersPicker.length < 1) {
      return null
    }
    return (
      <VStack w='full'>
        {ordersPicker.map((products, idx) => (
          // console.log('products => ', products)
          // eslint-disable-next-line react/no-array-index-key
          <XCard p='2' key={`product_${idx}`} mt={idx > 0 ? '2' : '0'} w='full'>
            <VStack w='full'>
              <VStack flex={1}>
                <HStack flex={1} alignItems='center' justifyContent='center'>
                  <HStack flex={1}>
                    <XText variant='inactive'>{`คำสั่งซื้อ #${idx + 1}`}</XText>
                  </HStack>
                  <HStack flex={1} space='1' justifyContent='flex-end'>
                    <TouchableOpacity
                      onPress={() => this.navToStoreWebsiteCreateOptionsOrderView(products.mode, idx)}
                      style={{
                        width: 80,
                        backgroundColor: COLORS.BG_LIGHT_GREY,
                        borderRadius: 90,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'flex-end',
                        paddingBottom: 4,
                        paddingTop: 4,
                      }}>
                      <XIcon name='edit' family='Feather' />
                      <XText color={COLORS.APP_MAIN} variant='inactive' ml='2'>
                        แก้ไข
                      </XText>
                    </TouchableOpacity>
                  </HStack>
                </HStack>
                <HStack borderBottomColor={COLORS.TEXT_INACTIVE} borderBottomWidth='1' mt='2' mx={-4} />
                <HStack w='full' mt='2'>
                  <XText numberOfLines={2} bold>
                    {products.name}
                  </XText>
                </HStack>
                {this._renderProductName(products.mode)}
              </VStack>
              {/* <HStack w='4' alignItems='center' justifyContent='center'>
                <XIcon name='arrowright' family='AntDesign' />
              </HStack> */}
            </VStack>
          </XCard>
        ))}
      </VStack>
    )
  }

  _renderBtnOption = () => {
    const { ordersPicker } = this.state
    const ordersPickerIndex = _.isNil(ordersPicker) ? 0 : ordersPicker.length
    return (
      <HStack w='full' p='2' bgColor={COLORS.BG_LIGHT_GREY_ALTERNATIVE}>
        <HStack flex={1}>
          {!_.isNil(ordersPicker) && ordersPicker.length > 1 ? (
            <TouchableOpacity
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                borderRadius: 90,
                backgroundColor: COLORS.BG_LIGHT_GREY,
                paddingLeft: 8,
                paddingRight: 8,
                paddingBottom: 4,
                paddingTop: 4,
              }}>
              <XIcon name='sort' family='MaterialCommunityIcons' />
              <XText color={COLORS.APP_MAIN} ml='1'>
                เรียง
              </XText>
            </TouchableOpacity>
          ) : null}
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <TouchableOpacity
            onPress={() => this._navToSalePageSelectedCheckoutOptionModeView(ordersPickerIndex)}
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'row',
              borderRadius: 90,
              backgroundColor: COLORS.APP_MAIN,
              paddingLeft: 4,
              paddingRight: 8,
              paddingBottom: 4,
              paddingTop: 4,
            }}>
            <XIcon name='plus' family='Entypo' color={COLORS.WHITE} />
            <XText color={COLORS.WHITE}>เพิ่ม</XText>
          </TouchableOpacity>
        </HStack>
      </HStack>
    )
  }

  _renderEmptyOrderPicker = () => {
    const { ordersPicker } = this.state
    if (!_.isNil(ordersPicker) && ordersPicker.length > 0) {
      return null
    }
    return (
      <HStack w='full' alignItems='center' justifyContent='center'>
        <XText>กรุณากดปุ่ม "+ เพิ่ม" เพื่อเพิ่มรายการคำสั่งซื้อ</XText>
      </HStack>
    )
  }

  _renderMain = () => (
    <VStack w='full' space='2'>
      {this._renderEmptyOrderPicker()}
      {this._renderIOrderPickerList()}
      {/* {this._renderBtnAddOption()} */}
    </VStack>
  )

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        {this._renderBtnOption()}
        <XContent p='2' style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          {this._renderMain()}
        </XContent>
      </XContainer>
    )
  }
}
