import { Platform } from 'react-native'
import Sound from 'react-native-sound'

// const mainBundle = Platform.OS === 'ios' ? encodeURIComponent(Sound.MAIN_BUNDLE) : Sound.MAIN_BUNDLE
const mainBundle = Sound.MAIN_BUNDLE

// import * as util from 'x/utils/util'

const barcodeBeep = require('../sounds/barcode_sound.mp3')
const beepSuccess = require('../sounds/ring_success.mp3')
const beepError = require('../sounds/ring_error.mp3')

class XSellySound {
  _barcodeSound: Sound

  _beepSuccess: Sound

  _beepError: Sound

  constructor() {
    // Enable playback in silence mode (iOS only)
    // https://github.com/zmxv/react-native-sound/wiki/API
    // https://developer.apple.com/library/archive/documentation/Audio/Conceptual/AudioSessionProgrammingGuide/AudioSessionCategoriesandModes/AudioSessionCategoriesandModes.html
    // For Android to not stop playing music, set the 2nd param "mixWithOthers" to true https://github.com/zmxv/react-native-sound/issues/480
    Sound.setCategory('Ambient', true) // Use Ambient NOT Playback so iOS users can continue listening to music/podcast while using XSelly

    // Load the sound file
    // this._barcodeSound = new Sound('barcode_sound.mp3', mainBundle, (error) => {
    if (Platform.OS === 'web') {
      this._barcodeSound = new Sound(barcodeBeep, error => {
        if (error) {
          console.log('failed to load barcodeBeep sound', error)
        }
      })
      this._beepSuccess = new Sound(beepSuccess, error => {
        if (error) {
          console.log('failed to load beepSuccess sound', error)
        }
      })
      this._beepError = new Sound(beepError, error => {
        if (error) {
          console.log('failed to load beepError sound', error)
        }
      })
    } else {
      let barcodeBeepFilePath = barcodeBeep
      let beepSuccessFilePath = beepSuccess
      let beepErrorFilePath = beepError

      if (Platform.OS === 'android') {
        // In Android Studio, it seems that all files path have been flatten out, so we have to set it accordingly
        barcodeBeepFilePath = 'xui_x_sounds_barcode_sound.mp3'
        beepSuccessFilePath = 'xui_x_sounds_ring_success.mp3'
        beepErrorFilePath = 'xui_x_sounds_ring_error.mp3'
      }
      this._barcodeSound = new Sound(barcodeBeepFilePath, mainBundle, error => {
        if (error) {
          console.log('App failed to load barcodeBeep sound', error)
        }
      })
      this._beepSuccess = new Sound(beepSuccessFilePath, mainBundle, error => {
        if (error) {
          console.log('App failed to load beepSuccess sound', error)
        }
      })
      this._beepError = new Sound(beepErrorFilePath, mainBundle, error => {
        if (error) {
          console.log('App failed to load beepError sound', error)
        }
      })

    }
  }

  // async ringPromise(anySound: Sound) {
  //   // Play the sound with an onEnd callback
  //   await new Promise((resolve) => {
  //     anySound.play((success) => {
  //       if (success) {
  //         // console.log('successfully finished playing')
  //         // anySound.release()
  //         resolve(null)
  //       } else {
  //         // console.log('playback failed due to audio decoding errors')
  //         resolve(null)
  //       }
  //     })
  //   })
  // }

  beep() {
    this._barcodeSound.play()
  }

  goodBeep() {
    this._beepSuccess.play()
  }

  badBeep() {
    // console.log("badBeep() play")
    this._beepError.play()
  }
}

const xSound = new XSellySound()

export default xSound
