// import PropTypes from 'prop-types'
import React from 'react'

import { TouchableOpacity } from 'react-native'

import { STYLES, btnOnMode, textOnBtnMode, COLORS } from 'x/config/styles'
import * as util from 'x/utils/util'
import CONS from 'x/config/constants'
// import QRCodeModal from '../../components/QRCodeModal'
// import ForwardIcon from '../../components/ForwardIcon'
import p from 'x/config/platform-specific'
import _ from 'lodash'

import { getConfig } from 'x/config/mode'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import BaseUserGroup from 'x/modules/store/BaseUserGroup'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import Box from 'xui/components/Box'
import XText from 'xui/components/XText'
import VStack from 'xui/components/VStack'
import XIcon from 'xui/components/XIcon'
import XButton from 'xui/components/XButton'
import XInput from 'xui/components/XInput'
import ImgManager from 'xui/components/ImgManager'

import HStack from 'xui/components/HStack'
import { canDoAtSelectedStore } from 'x/utils/acl'

const config = getConfig()
const { ADD, VIEW, EDIT } = CONS.PRODUCT_GROUP_VIEW_MODE
const INTL_STYLES: { [key: string]: any } = {
  GRID_COL_MIDDLE_CENTER: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_LEFT: {
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  GRID_COL_MIDDLE_RIGHT: {
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}

export default abstract class BaseUIUserGroup extends BaseUserGroup {
  abstract renderQRCodeModal: () => void

  _renderCustomHeader = () => {
    const { mode } = this.state
    const title = 'เพิ่มกลุ่มสมาชิก'
    let leftBtn = {
      backIcon: true,
      onPressItem: () => util.navGoBack(this.props),
    }

    let rightBtn = {
      label: 'แก้ไข',
      onPressItem: () => {
        if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.UG_EDIT)) {
          p.op.alert('คุณไม่มีสิทธิ์ในการเพิ่มแก้ไขสมาชิก')
          return
        }
        this.setState({ mode: 'EDIT' })
      },
    }
    if (mode !== 'VIEW') {
      leftBtn = {
        // @ts-ignore
        label: 'บันทึก',
        onPressItem: () => (mode === 'ADD' ? this._submitCreateUserGroup() : this._submitEditUserGroup()),
      }
      let title = 'ยกเลิกการเพิ่มกลุ่มสมาชิก'
      const dc = ''
      const txtLabel = 'ยกเลิก'
      if (mode === 'EDIT') {
        title = 'ยกเลิกการแก้ไขกลุ่มสมาชิก'
      }
      rightBtn = {
        label: txtLabel,
        onPressItem: () => {
          p.op.showConfirmation(
            title,
            dc,
            () => util.navGoBack(this.props),
            () => null,
            'ตกลง',
            'ยกเลิก'
          )
        },
      }
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={leftBtn}
        headerRightProps={rightBtn}
        // renderHeaderLeft={() => this.renderLeftBtn()}
        // renderHeaderRight={() => this.renderRightBtn()}
      />
    )
  }

  // renderLeftBtn = () => {
  //   const { mode } = this.state
  //   if (mode !== 'VIEW') {
  //     return (
  //       <XButton
  //         variant='ghost'
  //         ml='1'
  //         alignItems='center'
  //         onPress={() => {
  //           if (mode === 'ADD') {
  //             this._submitCreateUserGroup()
  //           } else {
  //             this._submitEditUserGroup()
  //           }
  //         }}>
  //         บันทึก
  //       </XButton>
  //     )
  //   }
  //   const { state, goBack } = this.props.navigation
  //   return (
  //     <XButton variant='ghost' mr='2' onPress={() => goBack(state.key)}>
  //       <BackIcon />
  //     </XButton>
  //   )
  // }

  // renderRightBtn = () => {
  //   const { mode } = this.state
  //   const { state, goBack } = this.props.navigation
  //   if (mode !== 'VIEW') {
  //     let title = 'ยกเลิกการเพิ่มกลุ่มสมาชิก'
  //     const dc = ''
  //     const txtLabel = 'ยกเลิก'
  //     if (mode === 'EDIT') {
  //       title = 'ยกเลิกการแก้ไขกลุ่มสมาชิก'
  //     }
  //     return (
  //       <XButton
  //         variant='ghost'
  //         mr='2'
  //         onPress={() => {
  //           p.op.showConfirmation(
  //             title,
  //             dc,
  //             () => goBack(state.key),
  //             () => null,
  //             'ตกลง',
  //             'ยกเลิก'
  //           )
  //         }}>
  //         {txtLabel}
  //       </XButton>
  //     )
  //   }
  //   return (
  //     <XButton variant='ghost' mr='2' onPress={() => this.setState({ mode: 'EDIT' })}>
  //       แก้ไข
  //     </XButton>
  //   )
  // }

  _renderName = () => {
    const { name, mode } = this.state
    const txtName = name ? name.toString() : ''
    if (mode === 'VIEW') {
      return (
        <VStack w='full' py='2'>
          <XText variant='inactive'>ชื่อกลุ่ม</XText>
          <HStack>
            <XText variant='active'>{txtName}</XText>
          </HStack>
        </VStack>
      )
    }
    return (
      <VStack w='full' py='2'>
        <XText variant='inactive'>ชื่อกลุ่ม</XText>
        <HStack>
          <XInput
            placeholder='ระบุชื่อของกลุ่ม'
            // disabled={!isEditable}
            // editable={isEditable}
            onChangeText={this._handleChangeName}
            value={txtName}
            style={{ width: '100%' }}
          />
        </HStack>
      </VStack>
    )
  }

  _renderDesc = () => {
    const { description, mode } = this.state
    let txtDesc = description ? description.toString() : ''
    if (txtDesc.length === 0 && mode === 'VIEW') {
      txtDesc = '(ไม่มีคำอธิบายกลุ่ม)'
    }
    if (mode === 'VIEW') {
      return (
        <VStack w='full' py='2'>
          <XText variant='inactive'>ชื่อกลุ่ม</XText>
          <HStack>
            <XText variant='active'>{txtDesc}</XText>
          </HStack>
        </VStack>
      )
    }
    return (
      <VStack w='full' py='2'>
        <XText variant='inactive'>คำอธิบายของกลุ่ม</XText>
        <HStack>
          <XInput
            placeholder='ระบุคำอธิบายของกลุ่ม'
            // disabled={!isEditable}
            // editable={isEditable}
            onChangeText={this._handleChangeDesc}
            value={txtDesc}
            style={{ width: '100%' }}
          />
        </HStack>
      </VStack>
    )
  }

  _renderInviteCode = () => {
    const { invite_code, mode } = this.state
    if (mode !== 'VIEW') {
      return null
    }
    const txtInviteCode = invite_code || 'ยังไม่มีรหัส'

    return (
      <VStack>
        <HStack style={{ height: 34 }}>
          <VStack style={INTL_STYLES.GRID_COL_MIDDLE_LEFT}>
            <XText style={STYLES.TXT_LABEL}>รหัสขอเข้ากลุ่ม</XText>
          </VStack>
        </HStack>
        <HStack w='full' alignItems='center' space='2'>
          {/* <TouchableWithoutFeedback
            style={{
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() => this._handlePressCopyTextToClipboard(invite_code)}>
            <XText style={[STYLES.TXT_NORMAL, { paddingRight: 5 }]}>{txtInviteCode}</XText>
          </TouchableWithoutFeedback> */}
          <XButton
            onPress={() => this._handlePressCopyTextToClipboard(invite_code)}
            variant='solid'
            leftIcon={<XIcon activeDark name='copy' family='FontAwesome5' />}
            w='32'>
            <XText variant='active'>{txtInviteCode}</XText>
          </XButton>
          <XButton
            onPress={invite_code ? this._onPressResetInviteCodeWithConfirmation : this._onPressResetInviteCode}
            borderRadius='3px'
            borderColor={invite_code ? COLORS.BRAND_Danger : COLORS.APP_MAIN}
            borderWidth='1px'
            bgColor={COLORS.BG_LIGHT_GREY}
            flex={1}
            // style={[
            //   btnOnMode(invite_code ? CONS.BTN_MODE.ACTIVE_SECONDARY : CONS.BTN_MODE.ACTIVE_PRIMARY),
            //   {
            //     borderRadius: 3,
            //     borderColor: invite_code ? COLORS.BRAND_Danger : COLORS.APP_MAIN,
            //     justifyContent: 'center',
            //     alignSelf: 'flex-end',
            //     flex: 1,
            //   },
            // ]}
          >
            <XText
              style={{
                color: invite_code ? COLORS.BRAND_Danger : COLORS.TEXT_ACTIVE_DARK,
              }}>
              {invite_code ? 'ยกเลิกและสร้างรหัสใหม่' : 'สร้างรหัสขอเข้ากลุ่ม'}
            </XText>
          </XButton>
        </HStack>
      </VStack>
    )
  }

  _renderInviteLink = () => {
    const { invite_code, invite_link, mode } = this.state
    if (_.isNil(invite_code) || mode !== 'VIEW') {
      return null
    }
    // const txtInviteLink = invite_link ? invite_link : 'ยังไม่มีลิงก์'
    const lableLinkAndQR = p.op.isWeb()
      ? 'ลิงก์ขอเข้ากลุ่ม\nคุณสามารถแชร์ลิงก์ให้เพื่อให้สมาชิกส่งคำขอเข้า (หรือย้ายมา) กลุ่มนี้ได้'
      : 'ลิงก์ขอเข้ากลุ่ม\nคุณสามารถแชร์ลิงก์หรือ รูป QR Code ให้เพื่อให้สมาชิกส่งคำขอเข้า (หรือย้ายมา) กลุ่มนี้ได้'
    return (
      <VStack mt='2'>
        <XText variant='inactive'>{lableLinkAndQR}</XText>
        <HStack style={{ height: invite_link ? 54 : 28 }} alignItems='center' justifyContent='center' w='full'>
          <TouchableOpacity
            onPress={invite_link ? () => this._handlePressCopyTextToClipboard(invite_link) : this._onPressGenerateInviteLink}
            style={[
              btnOnMode(CONS.BTN_MODE.ACTIVE_PRIMARY),
              {
                borderRadius: 3,
                borderColor: COLORS.APP_MAIN,
                justifyContent: 'center',
                // @ts-ignore
                alignItem: 'center',
                flex: 1,
              },
            ]}>
            <XText color='white' textAlign='center'>
              คัดลอกลิงก์เข้าร่วมกลุ่ม
              {invite_link ? `\n${invite_link}` : null}
            </XText>
          </TouchableOpacity>
        </HStack>

        {invite_code && !p.op.isWeb() ? (
          <HStack mt='2' w='full'>
            <XButton variant='outline' w='full' onPress={this._onPressDownloadQRCode}>
              ดาวน์โหลดรูป QR Code
            </XButton>
          </HStack>
        ) : null}
      </VStack>
    )
  }

  _renderDeleteUserGroupButton() {
    const { mode } = this.state
    if (mode !== EDIT) {
      return null
    }

    const btnStyle = {
      ...btnOnMode(CONS.BTN_MODE.ACTIVE_SECONDARY),
      borderColor: COLORS.BRAND_Danger,
      borderRadius: 4,
    }

    const txtStyle = {
      ...textOnBtnMode(CONS.BTN_MODE.ACTIVE_SECONDARY),
      color: COLORS.BRAND_Danger,
    }

    return (
      <XButton mt='8' bgColor='white' borderColor={COLORS.BRAND_Danger} onPress={this._deleteUserGroup}>
        <XText color={COLORS.BRAND_Danger}>ลบกลุ่มสมาชิก</XText>
      </XButton>
    )
  }

  _renderUserGroupImage = () => {
    const { mode } = this.state

    if (mode === VIEW && !this.state.img_uri) {
      if (p.op.isWeb()) {
        return (
          <Box w='full' style={{ justifyContent: 'center', alignItems: 'center' }}>
            <XIcon style={{ fontSize: 92, paddingTop: 32 }} variant='inactive' name='people-circle-outline' family='Ionicons' />
          </Box>
        )
      }
      return (
        <Box w='full' style={{ justifyContent: 'center', alignItems: 'center' }}>
          <XIcon style={{ fontSize: 92, paddingTop: 72 }} variant='inactive' name='people-circle-outline' family='Ionicons' />
        </Box>
      )
    }

    return (
      <Box>
        <ImgManager
          ref={this.ImgMgr}
          metadata={[
            { key: 'p', ...CONS.IMG_MANAGER_SIZES.USER_GROUP_LOGO, fixedSize: true },
            { key: 't', ...CONS.IMG_MANAGER_SIZES.USER_GROUP_LOGO_THUMBNAIL, fixedSize: true },
          ]}
          fileNamePrefix={config.s3_prefix_type_user_group}
          uploading={this.state.uploading}
          displayFullSize
          showUploaded={this.state.firstUpload}
          // onFinishUpload={(output) => {
          //   log('ImgManager onFinishUpload output => ', output)
          // }}
          shape='circle'
          readonly={this.state.submitting || this.state.uploading || mode === 'VIEW'}
          maxLength={1}
        />
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderCustomHeader()}
        <XContent p='2' w='full'>
          <XCard p='2' w='full'>
            {this._renderUserGroupImage()}
            {this._renderName()}
            {this._renderDesc()}
            {this._renderInviteLink()}
            {this._renderInviteCode()}
          </XCard>
          {this._renderDeleteUserGroupButton()}
        </XContent>
        {this.renderQRCodeModal()}
      </XContainer>
    )
  }
}
