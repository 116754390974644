// import PropTypes from 'prop-types'
import { Component } from 'react'
import { setStatePromise, isAlreadySelectedStore, delay } from 'x/utils/util'
import p from 'x/config/platform-specific'
import _ from 'lodash'
import { UGListViewProps, PGViewProps, PGViewState } from 'x/index'
import CONS from 'x/config/constants'
import * as util from 'x/utils/util'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { Map } from 'immutable'

const { ADD, VIEW, EDIT } = CONS.PRODUCT_GROUP_VIEW_MODE
export default class BaseProductGroupView extends Component<PGViewProps, PGViewState> {
  inProcess: boolean

  isGoback: boolean

  constructor(props: UGListViewProps) {
    // @ts-ignore
    super(props)
    this.inProcess = false
    this.isGoback = false

    this.state = {
      pg_id: null,
      name: null,
      description: null,
      is_default: false,
      isEdited: false,
      submitting: false,
    }
  }

  componentDidMount() {
    const { selectedStore, fetchMyStore, navigation } = this.props
    // const { state, setParams } = navigation
    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      this._cannotInitialize()
    } else if (!isAlreadySelectedStore()) {
      const { store_id } = params
      const body = { store_id }
      fetchMyStore({ body, successCallback: this._firstInitialize, failedCallback: this._cannotInitialize })
    } else {
      this._firstInitialize()
    }
  }

  async _firstInitialize(): Promise<void> {
    const { selectedProductGroups, navigation } = this.props
    // const { state, setParams } = navigation
    const params = util.getNavParams(this.props)
    if (params) {
      const { mode, pg_id = null } = params
      // const doSetParams = () => {
      //   setParams({
      //     submitAddPGFunc: this._submitCreateProductGroup,
      //     submitEditPGFunc: this._submitEditProductGroup,
      //     cancelEditPGFunc: this._cancelEditProductGroup,
      //     goBack: this._goBack,
      //   })
      // }

      if (mode === ADD) {
        // doSetParams()
      } else if (_.includes([EDIT, VIEW], mode) && pg_id) {
        const foundPG = selectedProductGroups.find((pg) => Map.isMap(pg) && pg.get('id') === pg_id)
        if (!foundPG) {
          p.op.alert('เกิดข้อผิดพลาด', 'ไม่พบข้อมูลรายการราคา')
          this._goBack()
          return
        }

        await setStatePromise(this, {
          pg_id: foundPG.get('id'),
          name: foundPG.get('name'),
          description: foundPG.get('description'),
          is_default: foundPG.get('is_default'),
          isEdited: false,
        })

        // doSetParams()
      } else {
        this._goBack() // should be impossible
      }
    }
  }

  _cannotInitialize() {
    p.op.alert('เกิดข้อผิดพลาด', 'ไม่สามารถโหลดรายการราคาได้ กรุณากลับไปเลือกร้านค้าใหม่อีกครั้ง')
    this._goBack()
  }

  _goBack = () => {
    if (this.isGoback) {
      return
    }
    this.isGoback = true

    const callBack = util.getNavParam(this.props, 'callBack')
    if (_.isFunction(callBack)) {
      callBack()
    }
    util.navGoBack(this.props)
  }

  _submitCreateProductGroup = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation, createProductGroup } = this.props
    // const { setParams, state } = navigation

    navigation.setParams({ submitting: true })
    await delay(200)
    // log('_submitCreateProductGroup begin')

    const { name, description } = this.state
    const body: { [key: string]: any } = {}

    if (_.isString(name) && name.length > 4) {
      body.name = name.trim()
    } else if (_.isString(name) && name.length > 0 && name.length < 4) {
      p.op.alert('ข้อมูลไม่ถูกต้อง', 'กรุณาระบุชื่อรายการราคาที่มีมากกว่า 4 ตัวอักษร')
    } else {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'กรุณาระบุชื่อรายการราคา')
    }

    if (_.isString(description)) {
      body.description = description.trim()
    }

    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
    } else {
      body.store_id = params.store_id
    }

    // ถ้าเป็น IOS และเข้าเงื่อนไขก็ให้แจ้งเตือน // https://app.clickup.com/t/86cvy1xnd
    const isNoobIos = util.isIosNoobCheckByPass()
    if (isNoobIos) {
      p.op.showConfirmationOkOnly('', p.op.t('Subscription.warning.iosNoobCheckMsg'))
      return
    }

    if (!_.isEmpty(body) && body.name && body.store_id) {
      const res = await new Promise((submitted) => {
        createProductGroup({
          body,
          successCallback: this._goBack,
          failedCallback: submitted,
        })
      })
      // log('_submitCreateProductGroup res => ', res)
    }

    await delay(200)
    navigation.setParams({ submitting: false })
    this.inProcess = false
    // log('_submitCreateProductGroup finish')
  }

  _submitEditProductGroup = async () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PG_EDIT)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการแก้ไขรายการราคา')
      return
    }
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { isEdited } = this.state
    const { navigation, updateProductGroup, selectedProductGroups } = this.props
    // const { setParams, state } = navigation

    if (!isEdited) {
      navigation.setParams({ mode: VIEW })
      p.op.showToast('ไม่มีการแก้ไขข้อมูลใหม่', 'warning')
      this.inProcess = false
      return
    }

    navigation.setParams({ submitting: true })
    await delay(200)
    // log('_submitEditProductGroup begin')

    const { pg_id, name, description } = this.state
    const body: { [key: string]: any } = {}

    const foundPG = selectedProductGroups.find((pg) => Map.isMap(pg) && pg.get('id') === pg_id)
    if (!foundPG || !Map.isMap(foundPG)) {
      p.op.alert('ไม่พบข้อมูลข้อมูลรายการราคา', 'กรุณาเลือกรายการราคาเพื่อแก้ไขใหม่อีกครั้ง')
      navigation.setParams({ mode: VIEW })
      this.inProcess = false
      return
    }

    const oldName = foundPG.get('name')
    const oldDesc = foundPG.get('description')
    // log('_submitEditProductGroup oldName => ', oldName)
    // log('_submitEditProductGroup oldDesc => ', oldDesc)
    // log('_submitEditProductGroup name => ', name)
    // log('_submitEditProductGroup description => ', description)

    if (_.isString(name) && name !== oldName) {
      body.name = name.trim()
    }

    if (_.isString(description) && description !== oldDesc) {
      body.description = description.trim()
    }

    const params = util.getNavParams(this.props)
    if (!_.isEmpty(body)) {
      if (!params || !params.store_id) {
        p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
      } else {
        body.store_id = params.store_id
      }

      if (pg_id) {
        body.pg_id = _.isString(pg_id) ? parseInt(pg_id) : pg_id
      } else {
        p.op.alert('ข้อมูลไม่ครบถ้วน', 'ไม่สามารถระบุรายการราคาได้ กรุณากลับไปเลือกรายการราคาใหม่อีกครั้ง')
      }

      if (body.pg_id && body.store_id) {
        const isSuccess = await new Promise((success) => {
          updateProductGroup({
            body,
            successCallback: () => success(true),
            failedCallback: () => success(false),
          })
        })
        if (isSuccess) {
          navigation.setParams({ mode: VIEW })
        }
        // log('_submitEditProductGroup res => ', res)
      }
    } else {
      navigation.setParams({ mode: VIEW })
      p.op.showToast('ไม่มีการแก้ไขข้อมูลใหม่', 'warning')
    }

    await new Promise((finishDelay) => setTimeout(finishDelay, 200))
    navigation.setParams({ submitting: false })
    this.inProcess = false
    // log('_submitEditProductGroup finish')
  }

  _cancelEditProductGroup = async () => {
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    const { navigation } = this.props
    const { setParams } = navigation
    const { isEdited = false } = this.state

    const doCancel = async () => {
      await this._firstInitialize()
      setParams({ mode: VIEW, submitting: false })
    }

    if (isEdited) {
      await new Promise((resolve) => {
        p.op.showConfirmation(
          'ยกเลิกแก้ไขรายการราคา',
          'คุณยืนยันที่จะไม่แก้ไขรายการราคาแล้วหรือไม่ ข้อมูลที่ได้ทำการแก้ไขไปแล้วจะสูญหายทั้งหมด',
          async () => {
            await doCancel()
            // @ts-ignore
            resolve()
          },
          // @ts-ignore
          resolve,
          'ยืนยัน',
          'กลับไปแก้ไขข้อมูลต่อ'
        )
      })
    } else {
      await doCancel()
    }

    this.inProcess = false
    // log('_submitCreateProductGroup finish')
  }

  _deleteProductGroup = async () => {
    if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.PG_DELETE)) {
      p.op.alert('คุณไม่มีสิทธิ์ในการลบรายการราคา')
      return
    }

    if (this.inProcess) {
      return
    }
    this.inProcess = true

    const { navigation, deleteProductGroup, selectedUserGroups, selectedProductGroups } = this.props
    // const { setParams, state } = navigation
    const { pg_id, name } = this.state

    if (selectedProductGroups.size <= 1) {
      p.op.alert(
        'ไม่สามารถลบรายการราคาได้',
        'กรุณาเปลี่ยน ชื่อ/คำอธิบาย แทนการลบสร้างใหม่ เนื่องจากการลบรายการราคาทั้งหมดออก จะทำให้ร้านฉันไม่สามารถขายสินค้าใดๆ ได้'
      )
      this.inProcess = false
      return
    }

    const isUserConfirmed = await new Promise((passConfirm) => {
      p.op.showConfirmation(
        'ลบรายการราคา',
        `กรุณายืนยันว่าคุณต้องการลบรายการราคา "${name}" ราคาสำหรับสินค้าที่อยู่ภายใต้สินค้ากลุ่มนี้จะถูกลบออก` +
          ' และหากสินค้าชิ้นใดที่ไม่ได้อยู่ภายใต้รายการราคาใดๆ เลย จะไม่ปรากฏในรายการสินค้าที่ตัวแทนสามารถสั่งซื้อได้ คุณแน่ใจหรือไม่',
        () => passConfirm(true),
        () => passConfirm(false)
      )
    })

    if (!isUserConfirmed) {
      this.inProcess = false
      return
    }

    navigation.setParams({ submitting: true })
    await delay(200)
    // log('_deleteProductGroup begin')

    const body: any = {}

    if (pg_id) {
      body.pg_id = _.isString(pg_id) ? parseInt(pg_id) : pg_id
    } else {
      p.op.alert('ข้อมูลไม่ครบถ้วน', 'ไม่สามารถระบุรายการราคาได้ กรุณากลับไปเลือกรายการราคาใหม่อีกครั้ง')
    }

    const params = util.getNavParams(this.props)
    if (!params || !params.store_id) {
      p.op.alert('ไม่สามารถดึงข้อมูลร้านค้าได้', 'กรุณากลับออกไปเลือกร้านค้าแล้วดำเนินการใหม่อีกครั้ง')
    } else {
      body.store_id = params.store_id
    }

    if (!_.isEmpty(body) && body.pg_id && body.store_id) {
      // Step 1: Request server for check PG Status
      const res = await new Promise<any>((resolveStepOne) => {
        deleteProductGroup({
          body,
          successCallback: resolveStepOne,
          failedCallback: () => resolveStepOne(null),
        })
      })

      // Step 2: Warning user to details
      // log('_deleteProductGroup res => ', res)
      if (res && _.isObject(res) && !_.isEmpty(res)) {
        // @ts-ignore
        if (_.has(res, 'status') && res.status === 'ok') {
          // แปลว่าไม่มีอะไรผูกติดอยู่ จะ show custom toast และ goBack
          p.op.showToast('ลบรายการราคาสำเร็จ', 'success')
          this._goBack()
          return
        }
        // console.log('res => ', res)
        // @ts-ignore
        const { user_group_ids = null, product_count = null } = res
        let txtWarningMessage = `กรุณายืนยันการลบรายการราคา ${name} โดย`
        if (product_count && product_count > 0) {
          txtWarningMessage += `ราคาของสินค้าที่ถูกกำหนดไว้ของสินค้าจำนวน ${product_count} ชิ้นจะถูกลบออก`
        }

        if (_.isArray(user_group_ids) && user_group_ids.length > 0) {
          if (product_count > 0) {
            txtWarningMessage += ' และ'
          }
          const userGroupCount = user_group_ids.length
          txtWarningMessage += `สินค้าที่ถูกผูกไว้จะหายไปจากของสมาชิกทั้งหมดจำนวน ${userGroupCount} กลุ่ม ต่อไปนี้`

          for (let ugIndex = 0; ugIndex < userGroupCount; ugIndex++) {
            const ugId = user_group_ids[ugIndex]
            const foundUserGroup = selectedUserGroups.find((sug) => Map.isMap(sug) && sug.get('id') === ugId)
            if (Map.isMap(foundUserGroup) && ugIndex <= 4) {
              const ugName = foundUserGroup.get('name')
              txtWarningMessage += `\n${ugName}`
            } else if (Map.isMap(foundUserGroup) && ugIndex === 5) {
              const ugLeftCount = userGroupCount - (ugIndex + 1)
              if (ugLeftCount > 1) {
                txtWarningMessage += `\nและกลุ่มอื่นๆ อีก ${ugLeftCount} กลุ่ม`
              } else {
                const ugName = foundUserGroup.get('name')
                txtWarningMessage += `\n${ugName}`
              }
              break
            }
          }

          txtWarningMessage += '\n\nการดำเนินการนี้ไม่สามารถกู้คืนได้ภายหลัง คุณจะทำการลบรายการราคาหรือไม่'
        }
        const isUserConfirmedUG = await p.op.isUserConfirm('ยืนยันการลบรายการราคา', txtWarningMessage, 'ดำเนินการลบ', 'ยกเลิก')

        if (isUserConfirmedUG) {
          const isSuccess = await new Promise<boolean>((success) => {
            deleteProductGroup({
              body: { ...body, is_confirm: true },
              successCallback: () => success(true),
              failedCallback: () => success(false),
            })
          })
          if (isSuccess) {
            this._goBack()
            this.inProcess = false
            return
          }
        }
      }
    }

    await new Promise((finishDelay) => setTimeout(finishDelay, 200))
    navigation.setParams({ submitting: false })
    this.inProcess = false
    // log('_deleteProductGroup finish')
  }

  _handleChangeName = (newText: string) => {
    this.setState({ name: newText, isEdited: true })
  }

  _handleChangeDesc = (newText: string) => {
    this.setState({ description: newText, isEdited: true })
  }

  _handleChangeIsDefault = (newFlag: boolean) => {
    this.setState({ is_default: newFlag, isEdited: true })
  }
}
