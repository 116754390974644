// use interface => IQuestionsAndAnswers
const FAQ_CONSTANTS = {
  FAQ_CONNECT_MKP_CHANNELS: [
    {
      title: 'คลิปเตรียมตั้งค่า SKU สินค้าก่อนทำการเชื่อมช่องทางขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sku-shopee.png',
            title: 'วิธีแก้ไข SKU แบบชุดบน Shopee',
            clip_url: 'https://www.youtube.com/watch?v=ANydb3WgWiQ',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sku-lazada.png',
            title: 'วิธีแก้ไข SKU แบบชุดบน Lazada',
            clip_url: 'https://www.youtube.com/watch?v=KbYoDi63mAk',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sku-tiktok.png',
            title: 'วิธีแก้ไข SKU แบบชุดบน TikTok',
            clip_url: 'https://www.youtube.com/watch?v=XMsY0ehHkn8',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sku-line_shopping.png',
            title: 'วิธีแก้ไข SKU แบบชุดบน LINE SHOPPING',
            clip_url: 'https://www.youtube.com/watch?v=guzh0G7wt1o',
          },
        },
      ],
    },
    {
      title: 'คลิปวิธีเชื่อมช่องทางขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_shopee.png',
            title: 'วิธีเชื่อม Shopee',
            clip_url: 'https://www.youtube.com/watch?v=R53WlTEG5kQ',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_lazada.png',
            title: 'วิธีเชื่อม Lazada',
            clip_url: 'https://www.youtube.com/watch?v=fFkoyiqgrA4',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_tiktok.png',
            title: 'วิธีเชื่อม Tiktok',
            clip_url: 'https://www.youtube.com/watch?v=jbz_37rRAlc',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_LineShopping.png',
            title: 'วิธีเชื่อม LINE SHOPPING',
            clip_url: 'https://www.youtube.com/watch?v=HzoZfCTFl8I',
          },
        },
      ],
    },
    {
      title: 'คลิปวิธีตั้งค่าการใช้งานเชื่อมช่องทางขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/multiparcel-mkp-ep1.png',
            title: 'วิธีตั้งค่าเชื่อมช่องทางขาย EP.1',
            clip_url: 'https://www.youtube.com/watch?v=bEY3SJ6Yyz4',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/multiparcel-mkp-ep2.png',
            title: 'วิธีตั้งค่าเชื่อมช่องทางขาย EP.2',
            clip_url: 'https://www.youtube.com/watch?v=6kpKFk_h8sQ',
          },
        },
      ],
    },
    {
      title: 'เทคนิคเพิ่มยอดขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/reseller-mkp.png',
            title: 'เป็นตัวแทนขายก็เพิ่มยอดขายได้ง่ายๆผ่านช่องทางขาย',
            clip_url: 'https://www.youtube.com/watch?v=Rov4E2q8WZc',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/reseller-dropship.png',
            title: 'ขาย Dropship ยังไงให้ยอดขายพุ่งทุกช่องทาง',
            clip_url: 'https://www.youtube.com/watch?v=ecQqJz6mFQM',
          },
        },
      ],
    },
  ],
  FAQ_USER_GROUP_LIST_VIEW: [
    {
      title: 'ร้านค้าขายส่ง/ตัวแทนขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/wholesale.png',
            title: 'ระบบตัวแทน',
            clip_url: 'https://www.youtube.com/watch?v=ZfcNCyiDVX0',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/create-group-member.png',
            title: 'วิธีตั้งค่าใช้งานและสร้างกลุ่มสมาชิก(สำหรับร้านผู้ขายส่ง)',
            clip_url: 'https://www.youtube.com/watch?v=PIkDrgMxdK0',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/addgroup-member.png',
            title: 'วิธีเพิ่มตัวแทนและขอเข้าร่วมกลุ่มตัวแทน',
            clip_url: 'https://www.youtube.com/watch?v=M3619w5UZFU',
          },
        },
      ],
    },
    {
      title: 'เทคนิคเพิ่มยอดขาย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/reseller-mkp.png',
            title: 'เชื่อมสต๊อกตัวแทน ง่ายทุกช่องทาง',
            clip_url: 'https://www.youtube.com/watch?v=Rov4E2q8WZc',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sales/reseller-dropship.png',
            title: 'ขาย Dropship ยังไงให้ยอดขายพุ่งทุกช่องทาง',
            clip_url: 'https://www.youtube.com/watch?v=ecQqJz6mFQM',
          },
        },
      ],
    },
  ],
  FAQ_ORDER: [
    {
      title: 'สร้างออเดอร์ใหม่โดยใช้ข้อมูลออเดอร์นี้',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/order/clone-order.png',
            title: 'โคลนออเดอร์ โดยใช้ข้อมูลออเดอร์นี้ (CloneOrder)',
            clip_url: 'https://www.youtube.com/watch?v=EZIDFImlaqQ',
          },
        },
      ],
    },
  ],
  FAQ_MULTI_PARCELS: [
    {
      title: 'วิธีใช้งานจัดการหลายพัสดุ',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/order/muti-xhipping.png',
            title: 'วิธีใช้งานจัดการหลายพัสดุ',
            clip_url: 'https://www.youtube.com/watch?v=Y3FeyHgaTgs',
          },
        },
      ],
    },
  ],
  FAQ_RE_AUTH_LAZADA: [
    {
      title: 'วิธีเชื่อมต่อ Lazada เมื่อหมดอายุ',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/sync/sync_lazada.png',
            title: 'วิธีเชื่อมต่อ Lazada เมื่อหมดอายุ',
            clip_url: 'https://www.youtube.com/watch?v=_kNWxk21vs8',
          },
        },
      ],
    },
  ],
  FAQ_SCAN_SHIPPING: [
    {
      title: 'วิธีใช้งานสแกนจัดส่ง',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Forder%2Fscandelivery.png',
            title: 'วิธีสแกนจัดส่ง',
            clip_url: 'https://www.youtube.com/watch?v=7DeZ7yxYtk8',
          },
        },
      ],
    },
  ],
  FAQ_ERP_PAYMENT_ACCOUNT: [
    {
      title: 'บัญชีรับชำระ',
      blocks: [
        {
          text:
            'บัญชีรับชำระที่ถูกระบุในออเดอร์ของ XSelly จะถูกส่งไปที่ PEAK เมื่อสร้างใบเสร็บรับเงิน โดยจะส่งเป็น “เลขที่ช่องทางการเงิน” ตามที่ผูกไว้ในตั้งค่านี้ หากบัญชีรับชำระนี้ยังไม่เคยมีอยู่ใน เลขที่ช่องทางการเงิน PEAK แต่ได้ทำการตั้งค่าผูกบัญชีรับชำระไว้ระบบจะทำการสร้าง เลขที่ช่องทางการเงิน บน PEAK ให้อัตโนมัติ แต่หากยังไม่ได้เปิดใช้งานผูกบัญชีรับชำระนี้ XSelly จะไม่สามารถสร้างเอกสารใบเสร็จบน PEAK ได้',
        },
      ],
    },
  ],
  FAQ_ERP_CONTACT: [
    {
      title: 'รหัสผู้ติดต่อคืออะไร ?',
      blocks: [
        {
          text:
            'รหัสผู้ติดต่อคือรหัสที่ใช้สำหรับอ้างอิงถึงแต่ละคู่ค้า ซึ่งรหัสจะต้องไม่ซ้ำกันระหว่าง 2 คู่ค้า รหัสผู้ติดต่อนี้จะถูกใช้สำหรับการสร้างเอกสารไปที่ PEAK เช่น ใบแจ้งหนี้ หรือใบเสร็จรับเงิน โดย ณ เวลาที่สร้างเอกสาร หากไม่เคยมีรหัสผู้ติดต่อใน PEAK มาก่อน ก็จะถูกสร้างเป็นผู้ติดต่อใหม่ด้วยรหัสผู้ติดต่อนี้ แต่ถ้าหากมีรหัสผู้ติดต่อนี้ที่ PEAK อยู่แล้ว *และชื่อผู้ติดต่อตรงกัน* เอกสารที่ถูกสร้างก็จะอ้างอิงถึงรหัสผู้ติดต่อเดียวกัน',
        },
      ],
    },
    {
      title: 'ตั้งค่าการใช้งาน',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-contact-mkp.png',
            title: 'ตั้งค่าใช้งานรหัสบัญชีและรหัสผู้ติดต่อช่องทางขาย',
            clip_url: 'https://www.youtube.com/watch?v=z8uEn5xRyDg',
          },
        },
      ],
    },
    {
      title: 'รหัสผู้ติดต่อระบุอย่างไร ?',
      blocks: [
        {
          text: 'สามารถระบุได้ที่ เลขประจำตัวผู้เสียภาษี หรือ เลขประจำตัวบัตรประชาชน ของที่อยู่ผู้รับสินค้า',
        },
      ],
    },
    {
      title: 'ข้อควรระวังในการตั้งรหัสผู้ติดต่อ ?',
      blocks: [
        {
          text:
            '1.รหัสผู้ติดต่อของแต่ละลูกค้าต้องไม่ซ้ำกัน\n2.หากมีรหัสผู้ติดต่อนี้บน PEAK อยู่แล้ว ชื่อและรหัสผู้ติดต่อใน XSelly และ PEAK ต้องตรงกัน ไม่เช่นนั้นจะไม่สามารถสร้างเอกสารได้',
        },
      ],
    },
  ],
  FAQ_ERP_CONTACT_ONLY: [
    {
      title: 'รหัสผู้ติดต่อเฉพาะช่องทางขาย คืออะไร ?',
      blocks: [
        {
          text:
            'คือรหัสผู้ติดต่อที่จะถูกนำไปใช้ในการสร้างเอกสารไปที่ PEAK หากข้อมูลผู้ซื้อของออเดอร์นั้นๆใน XSelly ไม่มีข้อมูลที่ใช้เป็นรหัสผู้ติดต่อได้ (เช่น ไม่มีเลขประจำตัวผู้เสียภาษี) ซึ่งจะทำให้ทุกออเดอร์ที่มาจากช่องทางขายนั้นมีผู้ซื้อเป็นผู้ติดต่อเดียวกัน\n\n ตัวอย่างเช่น ออเดอร์จาก Shopee ที่ผู้ซื้อไม่ได้ระบุขอใบกำกับภาษีมา ทาง Shopee ก็จะซ่อนข้อมูลผู้ซื้อด้วย *** ทำให้เราไม่สามารถระบุตัวตนได้ ออเดอร์เหล่านี้เมื่อส่งไปสร้างเอกสารที่ PEAK ก็จะใช้ ชื่อและรหัสผู้ติดต่อเฉพาะช่องทางขายของ Shopee นี้ เป็นผู้ซื้อของทุกออเดอร์ที่ข้อมูลถูกซ่อน ซึ่งจะช่วยให้รายชื่อผู้ติดต่อบน PEAK ไม่รกและบริหารจัดการง่าย',
        },
      ],
    },
  ],
  FAQ_ERP_PRODUCT_NUMBER: [
    {
      title: 'เลขที่สินค้า',
      blocks: [
        {
          text:
            'ใช้สำหรับอ้างอิงสินค้าแต่ละรายการสินค้า โดย XSelly จะใช้ข้อมูลนี้เป็นเลขที่สินค้า ในขณะที่สร้างเอกสารไปที่ PEAK\n\nหมายเหตุ: ในระหว่างการสร้างเอกสาร รายการสินค้าพร้อมเลขที่สินค้าจาก XSelly จะถูกสร้างเป็นสินค้าใหม่ให้อัตโนมัติบน PEAK  หากไม่เคยมีเลขที่สินค้านี้มาก่อน ในทางกลับกัน หากเลขที่สินค้ามีอยู่แล้วบน PEAK รายการสินค้าในเอกสารก็จะอ้างอิงถึงสินค้าที่มีอยู่แล้ว',
        },
      ],
    },
    {
      title: 'ตั้งค่าการใช้งาน',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-product-number.png',
            title: 'ตั้งค่าเลขที่สินค้า',
            clip_url: 'https://www.youtube.com/watch?v=qmRLaQsmLNk',
          },
        },
      ],
    },
  ],
  FAQ_ERP_DOCUMENT_TYPE: [
    {
      title: 'ประเภทเอกสาร',
      blocks: [
        {
          text:
            'ประเภทเอกสารที่ต้องการให้ XSelly ส่งไปสร้างที่ PEAK ได้ เช่น ใบแจ้งหนี้ และ ใบเสร็จรับเงิน/ใบกำกับภาษี โดยคุณสามารถตั้งค่าการใช้งานสำหรับแต่ละประเภทเอกสารได้ เช่น วันที่ออกเอกสาร เป็นต้น',
        },
      ],
    },
    {
      title: 'วิธีตั้งค่าประเภทเอกสาร',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-document.png',
            title: 'วิธีตั้งค่าประเภทเอกสาร',
            clip_url: 'https://www.youtube.com/watch?v=2ovPpWcvCB8',
          },
        },
      ],
    },
  ],
  FAQ_ERP_VAT: [
    {
      title: 'ภาษีมูลค่าเพิ่ม (VAT)',
      blocks: [
        {
          text:
            'ประเภทของภาษีมูลค่าเพิ่มสำหรับราคาสินค้าในออเดอร์ เช่น หากเลือกเป็นแบบ “รวม VAT แล้ว“ และราคาสินค้าในออเดอร์ของ XSelly มีราคา/หน่วย 100 บาท เมื่อส่งไปสร้างเอกสารที่ PEAK ราคา/หน่วย จะเป็น 93.46 บาท และมี ภาษีมูลค่าเพิ่ม 6.54 บาท',
        },
      ],
    },
    {
      title: 'ตั้งค่าการใช้งานภาษีมูลค่าเพิ่ม',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-vat.png',
            title: 'ตั้งค่าใช้งาน Vat ภาษีมูลค่าเพิ่ม',
            clip_url: 'https://www.youtube.com/watch?v=B2MCAZJjXxU',
          },
        },
      ],
    },
  ],
  FAQ_ERP_SHIPPING: [
    {
      title: 'ค่าจัดส่ง',
      blocks: [
        {
          text:
            'ประเภทของภาษีมูลค่าเพิ่มสำหรับ ”ค่าจัดส่ง” ในออเดอร์ เช่น หากเลือกเป็นแบบ “รวม VAT แล้ว“ และค่าจัดส่งในออเดอร์ของ XSelly คือ 50 บาท เมื่อส่งไปสร้างเอกสารที่ PEAK ราคา/หน่วย จะเป็น 46.73 บาท และมี ภาษีมูลค่าเพิ่ม 3.27 บาท',
        },
      ],
    },
  ],
  FAQ_ERP_PAYMENT_ACCOUNT_SHIPPING: [
    {
      title: 'รหัสบัญชีสำหรับค่าจัดส่ง',
      blocks: [
        {
          text:
            'รหัสบัญชีที่ใช้ในการสร้างเอกสารไปที่ PEAK สำหรับ “ค่าจัดส่ง” ในออเดอร์ของ XSelly โดยต้องเป็นรหัสบัญชีที่มีอยู่แล้วในผังบัญชีบน PEAK',
        },
      ],
    },
  ],
  FAQ_ERP_OTHER: [
    {
      title: 'ค่าอื่นๆ',
      blocks: [
        {
          text:
            'ประเภทของภาษีมูลค่าเพิ่มสำหรับ “ค่าอื่นๆ” ในออเดอร์ เช่น หากเลือกเป็นแบบ “รวม VAT แล้ว“ และ”ค่าอื่นๆ”ในออเดอร์ของ XSelly คือ 50 บาท เมื่อส่งไปสร้างเอกสารที่ PEAK ราคา/หน่วย จะเป็น 46.73 บาท และมี ภาษีมูลค่าเพิ่ม 3.27 บาท',
        },
      ],
    },
  ],
  FAQ_ERP_PAYMENT_ACCOUNT_OTHER: [
    {
      title: 'รหัสบัญชีสำหรับค่าอื่นๆ',
      blocks: [
        {
          text:
            'รหัสบัญชีที่ใช้ในการสร้างเอกสารไปที่ PEAK สำหรับ “ค่าอื่นๆ” ในออเดอร์ของ XSelly โดยต้องเป็นรหัสบัญชีที่มีอยู่แล้วในผังบัญชีบน PEAK',
        },
      ],
    },
  ],
  FAQ_ERP_ROUND_ADJUSTMENT: [
    {
      title: 'รหัสบัญชีสำหรับยอดปัดเศษ',
      blocks: [
        {
          text:
            'รหัสบัญชีที่ใช้ในการสร้างเอกสารไปที่ PEAK สำหรับ ยอด“ปัดเศษ” ในออเดอร์ของ XSelly โดยต้องเป็นรหัสบัญชีที่มีอยู่แล้วในผังบัญชีบน PEAK',
        },
      ],
    },
  ],
  FAQ_EMS_AND_ECOPOST_XSHIPPING: [
    {
      title: 'ตั้งค่าเปิดใช้งาน ออกเลขพัสดุขนส่งไปรณีย์ไทย',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fsetting-ems.png',
            title: 'ตั้งค่าเปิดใช้งาน ออกเลขพัสดุขนส่ง EMS และ eCo post ไปรณีย์ไทย',
            clip_url: 'https://www.youtube.com/watch?v=5Tsfd8VOSIQ',
          },
        },
      ],
    },
  ],
  FAQ_BEST_EXPRESS_XSHIPPING: [
    {
      title: 'ตั้งค่าเปิดใช้งานออกเลขพัสดุ BEST Express',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fsetting-best.png',
            title: 'ตั้งค่าเปิดใช้งานออกเลขพัสดุ BEST Express',
            clip_url: 'https://www.youtube.com/watch?v=w-yquHa2EFw',
          },
        },
      ],
    },
  ],
  FAQ_KERRY_EXPRESS_XSHIPPING: [
    {
      title: 'ตั้งค่าเปิดใช้งานออกเลขพัสดุ Kerry Express',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fsetting-kerry.png',
            title: 'ตั้งค่าเปิดใช้งานออกเลขพัสดุ Kerry Express',
            clip_url: 'https://www.youtube.com/watch?v=pOsXfiLCnsg',
          },
        },
      ],
    },
  ],
  FAQ_PEAK_IN_LIST_VIEW: [
    {
      title: 'วิธีเชื่อมระบบบัญชี',
      blocks: [
        {
          text: 'จำเป็นต้องตั้งค่าทั้งหมด 6 ขั้นตอนนี้ก่อนทำการสร้างเอกสารครั้งแรก',
        },
      ],
    },
    {
      title: 'ขั้นตอนตั้งค่าระบบบัญชี PEAK',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak_sync.png',
            title: 'ขั้นตอนที่ 1 วิธีเชื่อมระบบบัญชี PEAK',
            clip_url: 'https://www.youtube.com/watch?v=FmB2kwn8Lj4',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-account.png',
            title: 'ขั้นตอนที่ 2 ตั้งค่ารหัสบัญชี',
            clip_url: 'https://youtu.be/0oe7aoj7bXY',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-account-mkp.png',
            title: 'ขั้นตอนที่ 2.1 ตั้งค่าใช้งาน รหัสบัญชี สำหรับช่องทางขาย',
            clip_url: 'https://youtu.be/O7eqXAhT7aQ',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-contact-mkp.png',
            title: 'ขั้นตอนที่ 3 ตั้งค่ารหัสผู้ติดต่อและช่องทางขาย',
            clip_url: 'https://youtu.be/z8uEn5xRyDg',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-product-number.png',
            title: 'ขั้นตอนที่ 4 ตั้งค่าเลขที่สินค้า',
            clip_url: 'https://youtu.be/fJLSoD_Bi-4',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-document.png',
            title: 'ขั้นตอนที่ 5 ตั้งค่าประเภทเอกสาร',
            clip_url: 'https://youtu.be/2ovPpWcvCB8',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-vat.png',
            title: 'ขั้นตอนที่ 6 ตั้งค่าภาษีมูลค่าเพิ่ม VAT',
            clip_url: 'https://youtu.be/B2MCAZJjXxU',
          },
        },
      ],
    },
  ],
  FAQ_PEAK_ACCOUNT: [
    {
      title: 'ตั้งค่าการใช้งาน',
      blocks: [
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq%2Fsetting%2Fpeak%2Fpeak-setting-account.png',
            title: '1. ตั้งค่าใช้งานรหัสบัญชี',
            clip_url: 'https://www.youtube.com/watch?v=0oe7aoj7bXY',
          },
        },
        {
          clip: {
            poster_url: 'https://pub-306753000c6c4a7ba6c43bdeaff03854.r2.dev/faq/setting/peak/peak-setting-account-mkp.png',
            title: '2. ตั้งค่าใช้งาน รหัสบัญชี สำหรับช่องทางขาย',
            clip_url: 'https://youtu.be/O7eqXAhT7aQ',
          },
        },
      ],
    },
  ],
}

const convertObjectToKeysObject = (obj) => {
  const initialValue = {}
  const keys = Object.keys(obj)
  return keys.reduce(
    (currKeysObj, item) => ({
      ...currKeysObj,
      [item]: item,
    }),
    initialValue
  )
}

export type IFaqKey = keyof typeof FAQ_CONSTANTS
type IFaqKeysObject = { [P in IFaqKey]: Extract<IFaqKey, P> }
export const FAQ_KEYS = convertObjectToKeysObject(FAQ_CONSTANTS) as IFaqKeysObject

export default FAQ_CONSTANTS
