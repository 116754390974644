import React from 'react'

import BaseReportWarehouseAdjustment from 'x/modules/report/BaseReportWarehouseAdjustment'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import * as util from 'x/utils/util'
import XIcon from 'xui/components/XIcon'
import XCard from 'xui/components/XCard'
import XButton from 'xui/components/XButton'
import HStack from 'xui/components/HStack'
import VStack from 'xui/components/VStack'
import XText from 'xui/components/XText'
import Segment from 'xui/components/Segment'
import Box from 'xui/components/Box'
import XCheckboxList from 'xui/components/XCheckboxList'
import _ from 'lodash'
import XDateRangeSelectorButton from 'xui/components/XDateRangeSelectorButton'
import { IMemberHelperList, IXDateRangeSelectorChangeParams } from 'x/index'
import { ScrollView, Linking } from 'react-native'
import { COLORS } from 'x/config/styles'

export default abstract class BaseUIReportWarehouseAdjustment extends BaseReportWarehouseAdjustment {
  static displayName = 'BaseUIReportWarehouseAdjustment'

  abstract _downloadReportStockView(url: string, fileName: string): Promise<void>

  _handleOnDownloadFile = async (url, fileName) => {
    await this._downloadReportStockView(url, fileName)
  }

  _renderSelctedDateTime = () => {
    const { createdAtDateRange, createdAtRangeOptionKey } = this.state

    // const dateRange = util.getDateRangeFromOption(newState.createdAtRangeOptionKey)
    //   newState.createdAtFrom = dateRange.begin
    //   newState.createdAtTo = dateRange.end

    return (
      <VStack>
        <XText bold>เลือกช่วงที่มีการปรับคลัง</XText>
        <XDateRangeSelectorButton
          // allowDateRangeLimitDays={this.props.allowDateRangeLimitDays}
          // isOptionsScrollable={IS_SMALL_PHONE}
          // isOptionsScrollable
          // title='สร้างภายใน...'
          selectedDates={createdAtDateRange}
          selectedOptionKey={createdAtRangeOptionKey}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={(params: IXDateRangeSelectorChangeParams) => this._onChangeDateCreatedAtFromTo(params)}
          showTime
        />
      </VStack>
    )
  }

  _renderSelctedAddAndSubtract = () => {
    const { addAndSubtractIndex } = this.state
    return (
      <VStack mt='4'>
        <XText bold>ลักษณะการปรับคลัง</XText>
        <Segment
          options={['เพิ่มและลดคลัง', 'เพิ่มคลัง', 'ลดคลัง', 'ยกเลิกจัดส่ง']}
          selectedIndex={addAndSubtractIndex}
          onSegmentChange={(newIndex) => this.setState({ addAndSubtractIndex: newIndex })}
        />
      </VStack>
    )
    // return <XSegment options={['ทั้งหมด', 'เพิ่ม', 'ลด']} title='สถานะการจัดส่งของออเดอร์'  />
  }

  _renderCreatedBy = () => {
    const { createdByIndex } = this.state
    return (
      <Box mt='4'>
        <HStack alignItems='center'>
          <XText bold>ปรับคลังโดย...</XText>
        </HStack>
        <HStack alignItems='center'>
          <Segment
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSegmentChange={(newIndex) => this._onChangeSegmentCreatedByIndex(newIndex)}
            options={['ทั้งหมด', 'เจ้าของ', 'ผู้ช่วย']}
            selectedIndex={createdByIndex}
          />
        </HStack>
      </Box>
    )
  }

  _renderOtherOptions = () => {
    const { checkboxReportOptionList } = this.state
    if (_.isNil(checkboxReportOptionList)) {
      return null
    }
    return (
      <VStack mt='4'>
        {/* <XText bold>เลือกติ๊กข้อมูลที่ต้องการเพิ่ม</XText> */}
        <XCheckboxList
          title='เลือกติ๊กข้อมูลที่ต้องการเพิ่ม'
          // titleStyle={{ fontWeight: 'bold' }}
          options={checkboxReportOptionList}
          onChange={this.onChangeCheckboxOption}
        />
      </VStack>
    )
  }

  _renderNoHelper = () => (
    <VStack flex={1} pt='2' pb='2'>
      <XText variant='inactive' fontSize='xs'>
        ไม่พบผู้ช่วย
      </XText>
    </VStack>
  )

  _renderHelperList = () => {
    const { helpers, createdByIndex } = this.state
    const hasHelper = !_.isNil(helpers) && helpers.length > 0
    // ถ้าผู้ช่วยไม่ถูกเลือก ไม่ต้องแสดงผล
    if (createdByIndex !== 2) {
      return null
    }
    if (hasHelper) {
      return (
        <Box w='full' p='2' mt='2' h='40' borderRadius='sm' borderWidth='1px' borderColor='primary.500'>
          <ScrollView>
            {helpers.map((data: IMemberHelperList) => {
              const select = _.isNil(data.seleted) || !data.seleted ? false : data.seleted
              // const select = true
              if (select) {
                return (
                  <HStack py='2' alignItems='center' key={data.i}>
                    <Box w='8'>
                      <XIcon family='AntDesign' name='caretright' />
                    </Box>
                    <XButton variant='solid' colorScheme='primary' flex={1} mr='1' onPress={() => this._onSeletedHelper(data)}>
                      {data.d}
                    </XButton>
                  </HStack>
                )
              }
              return (
                <HStack py='2' alignItems='center' key={data.i}>
                  <Box w='8' />
                  <XButton variant='outline' flex={1} mr='1' onPress={() => this._onSeletedHelper(data)}>
                    {data.d}
                  </XButton>
                </HStack>
              )
            })}
          </ScrollView>
        </Box>
      )
    }
    return this._renderNoHelper()
  }

  _renderExampleReport = () => (
    <HStack alignItems='center' justifyContent='flex-end'>
      {/* <XText bold variant='active'>
      รายงาน การขายสินค้ารายวัน
    </XText> */}
      <XButton
        onPress={() =>
          Linking.openURL('https://s1.xselly.com/x/samples/rp/sample_stock_history_all_from_2023-10-15_00-00_to_2024-01-12_23-59.xlsx')
        }
        h='7'
        p='0'
        variant='outline'>
        <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
          ดูตัวอย่าง
        </XText>
      </XButton>
    </HStack>
  )

  _renderMain = () => {
    const {} = this.state
    // console.log('_renderMain => ')
    return (
      <XCard
        p='2'
        w='full'
        onLayout={(event) => {
          const { x, y, width, height } = event.nativeEvent.layout
          util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
        }}>
        {this._renderExampleReport()}
        {this._renderSelctedDateTime()}
        {this._renderSelctedAddAndSubtract()}
        {this._renderCreatedBy()}
        {this._renderHelperList()}
        {this._renderOtherOptions()}
      </XCard>
    )
  }

  renderFooter = () => (
    <HStack w='full' p='1'>
      <XButton
        py='3'
        w='full'
        onPress={() => {
          this._showConfirmationDownloadReport()
        }}
        leftIcon={<XIcon family='FontAwesome' name='file-excel-o' />}>
        ดาวน์โหลดรายงาน
      </XButton>
    </HStack>
  )

  render() {
    const { isLoading, loadingHeight, loadingWidth } = this.state
    return (
      <XContainer>
        <XCustomHeader title='การปรับคลังสินค้า' headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }} />
        <XContent p='2'>{this._renderMain()}</XContent>
        {this.renderFooter()}
      </XContainer>
    )
  }
}
