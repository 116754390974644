import React from 'react'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSalePageEditInfoView from 'x/modules/storeWebsite/BaseSalePageEditInfoView'
import { COLORS } from 'x/config/styles'
import { getConfig } from 'x/config/mode'
import HelpButton from 'xui/components/HelpButton'
import XCard from 'xui/components/XCard'
import ImgManager from 'xui/components/ImgManager'
import XText from 'xui/components/XText'
import XInput from 'xui/components/XInput'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'

const config = getConfig()
const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUISalePageEditInfoView extends BaseSalePageEditInfoView {
  _renderHeader = () => {
    const { isEditing } = this.state
    const title = 'ตั้งค่าทั่วไป'
    // @ts-ignore
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isEditing) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      leftProps = {
        // @ts-ignore
        label: 'บันทึก',
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPressItem: () => this._uploadImg(),
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'ยกเลิกการทำรายการ',
            // @ts-ignore
            () => util.navGoBack(this.props),
            () => null
          )
        },
      }
    }
    return <XCustomHeader title={title} headerLeftProps={leftProps} headerRightProps={rightProps} />
  }

  _renderImg = () => {
    const { isUploading } = this.state
    return (
      <XCard p='2' mt='2'>
        <XText variant='inactive'>รูปภาพเซลเพจ</XText>
        <ImgManager
          ref={this.imgManagerRef}
          fileNamePrefix={config.s3_prefix_type_website}
          metadata={[{ key: 'p', width: 1386, height: 2460 }]}
          uploading={isUploading}
          // showUploaded={showUploaded}
          maxLength={1}
          disabledRemoveSingleImage
          onPressChooseSuccess={() => this.setState({ isEditing: true, isEditingImg: true })}
        />
      </XCard>
    )
  }

  _onChangeSalePageName = (newName: string) => {
    const { salePage } = this.state
    const newSalePage = _.cloneDeep(salePage)
    newSalePage.name = newName
    this.setState({ salePage: newSalePage, isEditing: true })
  }

  _renderTextTitle = () => {
    const { salePage } = this.state
    if (_.isNil(salePage)) {
      return null
    }
    return (
      <XCard p='2'>
        <XText variant='inactive'>ชื่อเซลเพจ</XText>
        <XInput value={salePage.name} onChangeText={(newText) => this._onChangeSalePageName(newText)} />
      </XCard>
    )
  }

  _renderLoadingSpinner = () => {
    const { isLoading } = this.state

    if (!isLoading) {
      return null
    }

    return (
      <Box alignItems='center' justifyContent='center' position='absolute' top={0} left={0} right={0} bottom={0} bg='gray.400:alpha.50'>
        <XSpinner />
      </Box>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }} p='2'>
          {this._renderTextTitle()}
          {this._renderImg()}
          {this._renderLoadingSpinner()}
        </XContent>
      </XContainer>
    )
  }
}
