import React from 'react'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseSalePagePixelView from 'x/modules/storeWebsite/BaseSalePagePixelView'
import { COLORS } from 'x/config/styles'
import { getConfig } from 'x/config/mode'
import HelpButton from 'xui/components/HelpButton'
import Box from 'xui/components/Box'
import XSpinner from 'xui/components/XSpinner'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import XInput from 'xui/components/XInput'
import XText from 'xui/components/XText'

const config = getConfig()
const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUISalePagePixelView extends BaseSalePagePixelView {
  _renderHeader = () => {
    const { isEditing } = this.state
    const title = 'ตั้งค่า Pixel'
    // @ts-ignore
    let leftProps = { backIcon: true, onPressItem: () => util.navGoBack(this.props) }
    let rightProps = null
    if (isEditing) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      leftProps = {
        // @ts-ignore
        label: 'บันทึก',
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPressItem: () => this._submitApi(),
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      rightProps = {
        label: 'ยกเลิก',
        onPressItem: () => {
          p.op.showConfirmation(
            '',
            'ยกเลิกการทำรายการ',
            // @ts-ignore
            () => util.navGoBack(this.props),
            () => null
          )
        },
      }
    }
    return <XCustomHeader title={title} headerLeftProps={leftProps} headerRightProps={rightProps} />
  }

  _renderLoadingSpinner = () => {
    const { isLoading } = this.state

    if (!isLoading) {
      return null
    }

    return (
      <Box alignItems='center' justifyContent='center' position='absolute' top={0} left={0} right={0} bottom={0} bg='gray.400:alpha.50'>
        <XSpinner />
      </Box>
    )
  }

  _renderFacebookPixel = () => {
    const { trackingPixels } = this.state
    if (_.isNil(trackingPixels)) {
      return null
    }
    const pixel = trackingPixels?.facebook ? trackingPixels.facebook.pixel : ''
    const accessToken = trackingPixels?.facebook ? trackingPixels.facebook.access_token : ''
    const test = trackingPixels?.facebook ? trackingPixels.facebook.test : ''
    return (
      <XCard p='2'>
        <XText variant='active' alignSelf='center'>
          Facebook
        </XText>
        <XText variant='inactive'>Facebook Pixel</XText>
        <XInput value={pixel} onChangeText={(newText) => this._onChangeFacebookPixel(newText)} />
        <XText variant='inactive'>Facebook Access Token</XText>
        <XInput value={accessToken} onChangeText={(newText) => this._onChangeFacebookAccessToken(newText)} />
        <XText variant='inactive'>Facebook Test</XText>
        <XInput value={test} onChangeText={(newText) => this._onChangeFacebookTestPixcel(newText)} />
      </XCard>
    )
  }

  _renderTiktokPixel = () => {
    const { trackingPixels } = this.state
    if (_.isNil(trackingPixels)) {
      return null
    }
    const pixel = trackingPixels?.tiktok ? trackingPixels.tiktok.pixel : ''
    const accessToken = trackingPixels?.tiktok ? trackingPixels.tiktok.access_token : ''
    const test = trackingPixels?.tiktok ? trackingPixels.tiktok.test : ''
    return (
      <XCard p='2' mt='2'>
        <XText variant='active' alignSelf='center'>
          Tiktok
        </XText>
        <XText variant='inactive'>Tiktok Pixel</XText>
        <XInput value={pixel} onChangeText={(newText) => this._onChangeTiktokPixel(newText)} />
        <XText variant='inactive'>Tiktok Access Token</XText>
        <XInput value={accessToken} onChangeText={(newText) => this._onChangeTiktokAccessToken(newText)} />
        <XText variant='inactive'>Tiktok Test</XText>
        <XInput value={test} onChangeText={(newText) => this._onChangeTiktokTestPixcel(newText)} />
      </XCard>
    )
  }

  _renderGooglePixel = () => {
    const { trackingPixels } = this.state
    if (_.isNil(trackingPixels)) {
      return null
    }
    const pixel = trackingPixels?.google ? trackingPixels.google.pixel : ''
    const accessToken = trackingPixels?.google ? trackingPixels.google.access_token : ''
    const test = trackingPixels?.google ? trackingPixels.google.test : ''
    return (
      <XCard p='2' mt='2'>
        <XText variant='active' alignSelf='center'>
          Google
        </XText>
        <XText variant='inactive'>Google Pixel</XText>
        <XInput value={pixel} onChangeText={(newText) => this._onChangeGooglePixel(newText)} />
        <XText variant='inactive'>Google Access Token</XText>
        <XInput value={accessToken} onChangeText={(newText) => this._onChangeGoogleAccessToken(newText)} />
        <XText variant='inactive'>Google Test</XText>
        <XInput value={test} onChangeText={(newText) => this._onChangeGoogleTestPixcel(newText)} />
      </XCard>
    )
  }

  _renderLinePixel = () => {
    const { trackingPixels } = this.state
    if (_.isNil(trackingPixels)) {
      return null
    }
    const pixel = trackingPixels?.line ? trackingPixels.line.pixel : ''
    const accessToken = trackingPixels?.line ? trackingPixels.line.access_token : ''
    return (
      <XCard p='2' mt='2'>
        <XText variant='active' alignSelf='center'>
          Line
        </XText>
        <XText variant='inactive'>Line Pixel</XText>
        <XInput value={pixel} onChangeText={(newText) => this._onChangeLinePixel(newText)} />
        <XText variant='inactive'>Line Access Token</XText>
        <XInput value={accessToken} onChangeText={(newText) => this._onChangeLineAccessToken(newText)} />
      </XCard>
    )
  }

  _renderMain = () => {
    const { salePage } = this.state
    if (_.isNil(salePage)) {
      return null
    }
    return (
      <VStack>
        {this._renderFacebookPixel()}
        {this._renderTiktokPixel()}
        {this._renderGooglePixel()}
        {this._renderLinePixel()}
      </VStack>
    )
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent style={{ backgroundColor: COLORS.BG_LIGHT_GREY }} p='2'>
          {this._renderMain()}
          {this._renderLoadingSpinner()}
        </XContent>
      </XContainer>
    )
  }
}
