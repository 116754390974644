/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-class-component-methods */
import React from 'react'
import api from 'x/utils/api'
import { IApiOptions, IErpContact, IMKPChannelDetail, ISelectedStoreMap, IXScreenProps } from 'x/types'
import * as util from 'x/utils/util'
import _ from 'lodash'
import p from 'x/config/platform-specific'

interface IBaseERPShippingSettingProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
}

interface IBaseERPShippingSettingState {
  erpMkpContacts: IErpContact[]
  mkpChannels: IMKPChannelDetail[]

  backUpErpMkpContacts: IErpContact[]
}

export default abstract class BaseERPShippingSetting extends React.Component<IBaseERPShippingSettingProps, IBaseERPShippingSettingState> {
  // abstract async handleSubmitForm(response: any): Promise<void>
  // abstract didMountCheckPermissionForPhone?: () => void
  // validationSchema: Yup.ObjectSchema<any>
  // imgManagerIDRef: React.RefObject<any>
  // webview: React.RefObject<any>

  constructor(props) {
    super(props)
    this.state = {
      erpMkpContacts: null,
      mkpChannels: null,
      backUpErpMkpContacts: null,
    }
    // this.webview = React.createRef()
    // this.imgManagerIDRef = React.createRef()
  }

  async componentDidMount() {
    this._geMkpChannels()
    await this._fetchErpPaymentAccounts()
  }

  _fetchErpPaymentAccounts = async () => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    // const params = util.getNavParams(this.props)
    // const { erpChannel } = params
    const reqBody = {
      store_id,
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: { erp_mkp_contacts: IErpContact[] } = await api.postV2('erp/contact/mkp/lists', reqBody, apiOptions)
    // console.log('res => ', res)
    await util.setStatePromise(this, {
      erpMkpContacts: res.erp_mkp_contacts,
      backUpErpMkpContacts: _.cloneDeep(res.erp_mkp_contacts),
    })
  }

  goBack = () => {
    util.navGoBack(this.props)
  }

  _showConfirmationCancel = () => {
    p.op.showConfirmation(
      '',
      'ต้องการยกเลิกการแก้ไขใช่หรือไม่',
      () => util.navGoBack(this.props),
      () => null,
      'ยกเลิกการแก้ไข',
      'แก้ไขต่อไป'
    )
  }

  _geMkpChannels = () => {
    const { selectedStore } = this.props
    // console.log('selectedStore => ', selectedStore.toJS())
    const mkpChannels = selectedStore.get('channels')
    this.setState({
      // @ts-ignore
      mkpChannels: mkpChannels.size > 0 ? mkpChannels.toJS() : null,
    })
  }

  _onChangeContactCode = (index: number, newCode: string) => {
    const { erpMkpContacts } = this.state
    const newErpMkpContacts = _.clone(erpMkpContacts)
    newErpMkpContacts[index].contact_code = newCode
    this.setState({
      erpMkpContacts: newErpMkpContacts,
    })
  }

  _onChangeContactName = (index: number, newCode: string) => {
    const { erpMkpContacts } = this.state
    const newErpMkpContacts = _.clone(erpMkpContacts)
    newErpMkpContacts[index].contact_name = newCode
    this.setState({
      erpMkpContacts: newErpMkpContacts,
    })
  }

  _setBackUpErpMkpContacts = () => {
    const { erpMkpContacts } = this.state
    // console.log('_setBackUpErpMkpContacts => ')
    this.setState({
      backUpErpMkpContacts: _.clone(erpMkpContacts),
    })
  }

  _warningToSubmitContact = (selectedContact: IErpContact, newValue: boolean) => {
    p.op.showConfirmation(
      'เปลี่ยนการตั้งค่าระบบบัญชี?',
      'การเปลี่ยนแปลงนี้จะมีผลทันทีสำหรับการสร้างเอกสารหลังจากนี้ (ไม่มีผลย้อนหลังกับเอกสารที่สร้างไปแล้ว) คุณต้องการเปลี่ยนการตั้งค่านี้ใช่หรือไม่?',
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      () => this._submitContact(selectedContact, newValue),
      () => null,
      'ยืนยัน',
      'ยกเลิก'
    )
  }

  _submitContact = async (selectedContact: IErpContact, newValue: boolean) => {
    const { selectedStore } = this.props
    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      update: [
        {
          erp_sales_channel_contact_id: selectedContact.erp_sales_channel_contact_id,
          use_shipping_cost_as_income: newValue,
        },
      ],
    }

    const apiOptions: IApiOptions = {
      showSpinner: true,
    }

    const res: { erp_mkp_contacts: IErpContact[] } = await api.postV2('erp/setting/mkp/update', reqBody, apiOptions)
    // console.log('res => ', res)
    if (res.erp_mkp_contacts) {
      p.op.showToast('บันทึกข้อมูลเรียบร้อยแล้ว', 'success')
      await util.setStatePromise(this, {
        erpMkpContacts: res.erp_mkp_contacts,
        backUpErpMkpContacts: _.cloneDeep(res.erp_mkp_contacts),
      })
    }
  }
}
