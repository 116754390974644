import React from 'react'
import { TouchableOpacity, View, Text, ScrollView, Linking, Pressable } from 'react-native'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import _ from 'lodash'
import { STYLES, COLORS } from 'x/config/styles'
import p from 'x/config/platform-specific'
import CONS from 'x/config/constants'
// import XOverlay from '../../components/XOverlay'
import BaseReportProductDailySales from 'x/modules/report/BaseReportProductDailySales'
// import * as utilN from '../../utils/util-native'
import * as util from 'x/utils/util'
import { IChannel, IMemberHelperList, IMKPChannelDetail, IVariants } from 'x/index'
import XIcon from 'xui/components/XIcon'
import Segment from 'xui/components/Segment'
import SegmentOrderPaymentStatus from 'xui/components/report/SegmentOrderPaymentStatus'
import SegmentOrderShippingStatus from 'xui/components/report/SegmentOrderShippingStatus'
import XText from 'xui/components/XText'
import XButton from 'xui/components/XButton'
import XDateRangePicker from 'xui/components/XDateRangePicker'
import XContent from 'xui/components/XContent'
import XContainer from 'xui/components/XContainer'
import XCustomHeader from 'xui/components/XCustomHeader'
import XCard from 'xui/components/XCard'
import XModal from 'xui/components/XModal'
import VStack from 'xui/components/VStack'
import XImage from 'xui/components/XImage'
import MkpLogo from 'xui/components/MkpLogo'
import XRadio from 'xui/components/XRadio'

export default abstract class BaseUIReportProductDailySales extends BaseReportProductDailySales {
  // abstract handleOnDownloadFileByPlatform(url: string, fileName: string): Promise<void>
  _renderCustomHeader = () => (
    <XCustomHeader
      title='รายงานการขายสินค้ารายวัน'
      headerLeftProps={{
        backIcon: true,
        onPressItem: () => {
          util.navGoBack(this.props)
        },
      }}
    />
  )

  _renderNoVariantsOrOneVariants = () => <View style={{ paddingTop: 8 }} />

  _renderVariants = () => {
    const { thisVariants, isShowWraningSelectedVariants } = this.state
    return (
      <Box w='full'>
        <XText variant='active' fontSize='xs'>
          กรุณาเลือกอย่างน้อย 1 ตัวเลือกสินค้า (เลือกได้หลายตัวเลือก)
        </XText>
        <Box
          w='full'
          _light={{ bg: 'white' }}
          _dark={{ bg: 'gray.800' }}
          p='2'
          borderColor='primary.500'
          borderWidth='1'
          borderRadius='xl'
          // style={{ height: p.op.isWeb() ? 128 : 72 }}
        >
          {/* <ScrollView style={{ flex: 1, zIndex: 999 }}> */}
          {thisVariants.map((data: IVariants, idx: number) => (
            // <View style={{ height: 25, paddingLeft: 4, flexDirection: 'row', paddingTop: 2 }} key={data.pp_id}>
            <TouchableOpacity key={`v_${idx.toString()}`} onPress={() => this._seleteCheckBoxBtn(data)} style={{ flexDirection: 'row' }}>
              <HStack alignItems='center'>
                <XIcon family='FontAwesome' name={_.isNil(data.value) || !data.value ? 'square-o' : 'check-square-o'} />
                <XText pl='2' variant='active' numberOfLines={1}>
                  {data.name}
                </XText>
              </HStack>
            </TouchableOpacity>
            // </View>
          ))}
          {/* </ScrollView> */}
        </Box>
        {isShowWraningSelectedVariants ? (
          <HStack w='full' bgColor={COLORS.BRAND_Warning} mt='2' py='1' alignItems='center' justifyContent='center'>
            <XIcon name='warning' color='white' family='Entypo' />
            <XText color='white' pl='2'>
              กรุณาเลือกอย่างน้อย 1 ตัวเลือกสินค้า
            </XText>
          </HStack>
        ) : null}
      </Box>
    )
  }

  // _handleOnDownloadFile = async (url: string, fileName: string): Promise<void> => {
  //   await this.handleOnDownloadFileByPlatform(url, fileName)
  // }

  _renderSellChannel = () => {
    const { showSeletedChannel } = this.state
    if (!showSeletedChannel) {
      return null
    }
    return (
      <View
        style={{
          flex: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: COLORS.WHITE,
          width: 306,
          height: p.op.isWeb() ? 455 : 355,
          zIndex: 999,
        }}>
        <View style={{ height: 30, flexDirection: 'row' }}>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              width: 30,
              height: 30,
              paddingLeft: 4,
            }}
            onPress={() => {
              this._hideSeletedChannel()
            }}>
            <XIcon
              style={{
                flex: 0,
                fontSize: STYLES.FONT_ICON_NORMAL,
                minWidth: 25,
                color: COLORS.APP_MAIN,
                width: 30,
                alignSelf: 'center',
              }}
              name='arrowleft'
              family='AntDesign'
            />
          </TouchableOpacity>
          <Text
            style={{
              fontSize: STYLES.FONT_SIZE_NORMAL,
              color: true ? COLORS.TEXT_ACTIVE : COLORS.TEXT_INACTIVE,
              textAlign: 'center',
              justifyContent: 'center',
              alignSelf: 'center',
              flex: 1,
            }}>
            เลือกช่องทางการชำระ
          </Text>
          <View style={{ width: 30 }} />
        </View>
        <View
          style={{ borderWidth: 0.7, borderColor: COLORS.TEXT_INACTIVE, height: p.op.isWeb() ? 410 : 310, marginLeft: 4, marginRight: 8 }}>
          <ScrollView>
            {CONS.ORDER_VIEW_CHANNEL_ITEMS.map((channel: IChannel, idx: number) => (
              <Pressable key={`ch_${idx.toString()}_${channel.index}`} onPress={() => this._onChangeChannel(channel)}>
                <XText
                  style={{
                    fontSize: STYLES.FONT_SIZE_NORMAL,
                    color: COLORS.TEXT_INACTIVE,
                    flex: 1,
                    textAlign: 'center',
                  }}>
                  {p.op.t(`Order.channelItems.${channel.key}`)}
                </XText>
                <XIcon
                  style={{
                    flex: 0,
                    fontSize: 18,
                    minWidth: 25,
                    color: COLORS.APP_MAIN,
                    width: 25,
                  }}
                  name='arrowright'
                  family='AntDesign'
                />
              </Pressable>
            ))}
          </ScrollView>
        </View>
      </View>
    )
  }

  _renderHeader = () => (
    <HStack alignItems='center' justifyContent='flex-end'>
      {/* <XText bold variant='active'>
        รายงาน การขายสินค้ารายวัน
      </XText> */}
      <XButton
        onPress={() => Linking.openURL('https://xsf.sgp1.cdn.digitaloceanspaces.com/x/samples/rp/ProductDailySales.xlsx')}
        h='7'
        p='0'
        variant='outline'>
        <XText color={COLORS.APP_MAIN} fontSize='xs' p='1'>
          ดูตัวอย่าง
        </XText>
      </XButton>
    </HStack>
  )

  _renderCreateAt = () => {
    const { selectedOptSegment } = this.state
    return (
      <Box>
        <HStack alignItems='center'>
          <XText variant='active' bold>
            เลือกช่วงวันที่ออเดอร์...
          </XText>
        </HStack>
        <HStack>
          <XButton
            onPress={() => {
              this._showCreateAt()
            }}
            variant='outline'
            pt='1'
            pb='1'
            size='sm'
            mt='1'
            flex={1}
            endIcon={<XIcon family='AntDesign' name='arrowright' size='xs' />}>
            {this.CHOOSE_ORDER_DAY[selectedOptSegment].label}
          </XButton>
        </HStack>
        {/* <HStack alignItems='center'>
          <Segment
            onSegmentChange={(newIndex) => this._onChangeSegmentReportProductDailySales(newIndex)}
            options={this.DATE_RANGE_REPORT_PRODUCT_DAILY_SALES}
            selectedIndex={selectedOptSegment}
          />
        </HStack> */}
      </Box>
    )
  }

  _renderDateRangePicker = () => {
    // const { allowOnlyDaysFromToday } = this.props
    const { selectedDateRange, warningDateRangeText } = this.state
    // let limitDay = allowOnlyDaysFromToday
    // if (allowOnlyDaysFromToday > 31) {
    //   limitDay = 31
    // }
    return (
      <Box mt='2'>
        <XDateRangePicker
          oneLineDateTime={false}
          allowDateRangeLimitDays={31}
          selectedDates={selectedDateRange}
          onChangeDate={this._onChangeDateRange}
          showTime
        />
        <XText variant='primary' fontSize='xs' textAlign='center' mt='2'>
          {warningDateRangeText}
        </XText>
      </Box>
    )
  }

  _renderSalesChannel = () => {
    const { selectedChannelOptionIndex, selectedChannelIds, reseller, createAtSegmentIndex } = this.state
    // const channelName = _.isNil(channelData) ? 'กรุณาระบุช่องทางการขาย' : p.op.t(`Order.channelItems.${channelData.key}`)
    if (!_.isNil(reseller) || createAtSegmentIndex === 3) {
      return null
    }
    let channelNanes = ''
    if (selectedChannelIds.length > 0) {
      selectedChannelIds.forEach((val: number, idx: number) => {
        const channel = _.find(CONS.ORDER_VIEW_CHANNEL_ITEMS, (ch) => ch.value === val)
        if (!_.isNil(channel)) {
          // channelNanes.push(p.op.t(`Order.channelItems.${channel.key}`))
          const name = p.op.t(`Order.channelItems.${channel.key}`)
          if (idx === 0) {
            channelNanes += name
          } else {
            channelNanes += `, ${name}`
          }
        }
      })
    }

    return (
      <Box mt='2'>
        <HStack alignItems='center' mt='2'>
          <Box w='20'>
            <XText variant='active' bold>
              ช่องการขาย
            </XText>
          </Box>
        </HStack>
        <Box flex={1}>
          <Segment
            onSegmentChange={(newIndex) => this._showSeletedChannel(newIndex)}
            options={this.CHANNEL_OTP}
            selectedIndex={selectedChannelOptionIndex}
          />
        </Box>
        {selectedChannelOptionIndex === 1 ? (
          <HStack>
            <XButton
              onPress={() => {
                this._showSeletedChannel(1)
              }}
              variant='outline'
              pt='1'
              pb='1'
              size='sm'
              mt='1'
              flex={1}
              endIcon={<XIcon family='AntDesign' name='arrowright' size='xs' />}>
              {channelNanes}
            </XButton>
          </HStack>
        ) : null}
      </Box>
    )
  }

  _renderPaymentStatus = () => {
    const { selectedOptSegment } = this.state
    if (selectedOptSegment === 5) {
      return null
    }
    return (
      <SegmentOrderPaymentStatus
        initialIndex={this.state.latestOrderPaymentStatusOptionIndex}
        onSegmentChange={this.onSegmentOrderPaymentStatusChange}
      />
    )
  }

  _renderShippingOrderStatus = () => {
    const { selectedOptSegment } = this.state
    if (selectedOptSegment === 5) {
      return null
    }
    return (
      <SegmentOrderShippingStatus
        initialIndex={this.state.latestOrderShippingStatusOptionIndex}
        onSegmentChange={this.onSegmentOrderShippingStatusChange}
      />
    )
  }

  _renderOtherOptions = () => {
    const { showUPC, showSKU, showPrice, showProfit, showOrderIDS, showCategory } = this.state
    return (
      <Box mt='5'>
        <XText variant='active' bold>
          เลือกติ๊กข้อมูลที่ต้องการเพิ่ม
        </XText>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showUPC', showUPC)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showUPC ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              รหัสสินค้า UPC
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showSKU', showSKU)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showSKU ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              รหัสสินค้า SKU
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showCategory', showCategory)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showCategory ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              หมวดหมู่สินค้า
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showPrice', showPrice)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showPrice ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              ยอดขายรวม
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showProfit', showProfit)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showProfit ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              ยอดกำไรรวม
            </XText>
          </HStack>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => this._seleteChooseOtp('showOrderIDS', showOrderIDS)}>
          <HStack alignItems='center' py='1'>
            <XIcon family='FontAwesome' name={showOrderIDS ? 'check-square-o' : 'square-o'} />
            <XText pl='2' variant='active'>
              เลขที่ออเดอร์
            </XText>
          </HStack>
        </TouchableOpacity>
      </Box>
    )
  }

  _renderFooter = () => {
    // const { onRequestClose } = this.props
    const { downloadingReport } = this.state
    return (
      <HStack w='full' p='1'>
        <XButton
          py='3'
          w='full'
          onPress={() => {
            this._downloadHistoryReport()
          }}
          leftIcon={<XIcon family='FontAwesome' name='file-excel-o' />}>
          ดาวน์โหลดรายงาน
        </XButton>
      </HStack>
    )
    // return (
    //   <HStack w='full' alignItems='center' mt='2' space='1'>
    //     <XButton
    //       // w='full'
    //       flex={1}
    //       // size='8'
    //       leftIcon={<XIcon family='FontAwesome' name='file-excel-o' size='xs' />}
    //       isLoading={downloadingReport}
    //       onPress={() => {
    //         this._downloadHistoryReport()
    //       }}>
    //       ดาวน์โหลดรายงาน
    //     </XButton>
    //     {/* <XButton
    //       variant='outline'
    //       // size='8'
    //       minW='10'
    //       onPress={_.isFunction(onRequestClose) ? onRequestClose() : null}>
    //       ปิด
    //     </XButton> */}
    //   </HStack>
    // )
  }

  _renderSellChannelAbsolute = () => {
    const { showSeletedChannel } = this.state
    if (!showSeletedChannel) {
      return null
    }
    const _renderHeaderLeft = () => {
      // const { mode } = this.state
      const { navigation } = this.props
      return (
        <TouchableOpacity
          style={{ width: 60, height: 50, justifyContent: 'center', paddingLeft: 10 }}
          onPress={() => {
            this.setState({
              showSeletedChannel: false,
              selectedChannelIds: this.backUpSelectedChannelIds,
            })
            if (_.isArray(this.backUpSelectedChannelIds) && this.backUpSelectedChannelIds.length < 1) {
              this.setState({ selectedChannelOptionIndex: 0 })
            }
          }}>
          <XText textAlign='center' bold color={COLORS.APP_MAIN}>
            ยกเลิก
          </XText>
        </TouchableOpacity>
      )
    }
    const _renderHeaderRight = () => {
      // const { mode } = this.state
      const { navigation } = this.props
      return (
        <TouchableOpacity
          style={{ width: 60, height: 50, justifyContent: 'center', paddingLeft: 10 }}
          onPress={() => {
            this.setState({
              showSeletedChannel: false,
            })
            this.backUpSelectedChannelIds = this.state.selectedChannelIds
            if (this.state.selectedChannelIds.length === 0) {
              this.setState({ selectedChannelOptionIndex: 0 })
            }
          }}>
          <XText textAlign='center' bold color={COLORS.APP_MAIN}>
            ตกลง
          </XText>
        </TouchableOpacity>
      )
    }
    // เก็บ code ข้างล่างนี้ไว้ก่อน รอหลังบ้านพร้อม
    // if (util.isDevMode()) {
    return (
      <XModal
        // title={shippingTypeItem.label}
        webNumColumns={1}
        title='เลือกช่องทางการชำระ'
        // contentStyle={{ backgroundColor: COLORS.WHITE }}
        onRequestClose={() => this.setState({ showSeletedChannel: false })}
        visible={showSeletedChannel}
        headerRight={_renderHeaderRight}
        headerLeft={_renderHeaderLeft}>
        {/* <VStack w='full' px='2'> */}
        <ScrollView style={{ paddingLeft: 8, paddingRight: 8 }}>
          {CONS.ORDER_VIEW_CHANNEL_ITEMS.map((channel: IChannel, idx: number) => {
            const { selectedChannelIds } = this.state
            const selected = _.includes(selectedChannelIds, channel.value)
            return (
              <TouchableOpacity onPress={() => this._selectedChannelIds(channel.value)} key={channel.key}>
                <HStack borderBottomColor='gray.400' borderBottomWidth='1' py='2' alignItems='center'>
                  <XIcon family='FontAwesome' name={selected ? 'check-square-o' : 'square-o'} />
                  <XText pl='2' variant='active'>
                    {p.op.t(`Order.channelItems.${channel.key}`)}
                  </XText>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
        {/* </VStack> */}
      </XModal>
    )
    // }

    // return (
    //   <XModal
    //     // title={shippingTypeItem.label}
    //     webNumColumns={1}
    //     title='เลือกช่องทางการชำระ'
    //     // contentStyle={{ backgroundColor: COLORS.WHITE }}
    //     onRequestClose={() => this.setState({ showSeletedChannel: false })}
    //     visible={showSeletedChannel}
    //     hasCloseButton>
    //     {/* <VStack> */}
    //     {/* <HStack alignItems='center' py='1' mt='2'>
    //         <Box flex={1} mr='2'>
    //           <XText textAlign='center'>เลือกช่องทางการชำระ</XText>
    //         </Box>
    //       </HStack> */}
    //     {/* // BOX List Item */}
    //     {/* <Box borderRadius='xl' p='2' h='380px' mt='2' m='2' borderWidth='1px' borderColor='primary.500'> */}
    //     <ScrollView>
    //       {CONS.ORDER_VIEW_CHANNEL_ITEMS.map((channel: IChannel) => (
    //         <TouchableOpacity
    //           onPress={() => this._onChangeChannel(channel)}
    //           key={channel.index}
    //           style={{
    //             borderBottomColor: COLORS.TEXT_INACTIVE,
    //             borderBottomWidth: 1,
    //             paddingBottom: 8,
    //             paddingTop: 8,
    //           }}>
    //           <HStack alignItems='center' pl='4'>
    //             <Box w='10'>
    //               <XIcon family='AntDesign' name='arrowright' />
    //             </Box>
    //             <XText variant='active'>{p.op.t(`Order.channelItems.${channel.key}`)}</XText>
    //           </HStack>
    //         </TouchableOpacity>
    //       ))}
    //     </ScrollView>
    //     {/* </Box> */}
    //     {/* </VStack> */}
    //   </XModal>
    // )
  }

  _renderCreatedAtAbsolute = () => {
    const { showCreatedAtModal } = this.state
    if (!showCreatedAtModal) {
      return null
    }
    // เก็บ code ข้างล่างนี้ไว้ก่อน รอหลังบ้านพร้อม
    // if (util.isDevMode()) {
    return (
      <XModal
        // title={shippingTypeItem.label}
        webNumColumns={1}
        title='เลือกช่วงวันที่ออเดอร์...'
        // contentStyle={{ backgroundColor: COLORS.WHITE }}
        onRequestClose={() => this.setState({ showCreatedAtModal: false })}
        visible={showCreatedAtModal}
        // headerRight={_renderHeaderRight}
        // headerLeft={_renderHeaderLeft}
      >
        <ScrollView style={{ paddingLeft: 8, paddingRight: 8 }}>
          {this.CHOOSE_ORDER_DAY.map((choose: { index: number; label: string }, idx: number) => {
            const { selectedOptSegment } = this.state
            const selected = selectedOptSegment === idx
            return (
              // eslint-disable-next-line react/no-array-index-key
              <TouchableOpacity
                onPress={() => {
                  if (choose.index === 5) {
                    // this.onSegmentOrderPaymentStatusChange({ label: 'ครบถ้วน', value: [119] }, 1)
                    // this.onSegmentOrderShippingStatusChange({ label: 'ครบถ้วน', value: [129] }, 1)
                    this.setState({
                      selectedOptSegment: idx,
                      showCreatedAtModal: false,
                      payment_states: [119],
                      latestOrderPaymentStatusOptionIndex: 1,
                      shipping_states: [129],
                      latestOrderShippingStatusOptionIndex: 1,
                    })
                  } else {
                    this.setState({ selectedOptSegment: idx, showCreatedAtModal: false })
                  }
                }}
                key={idx.toString()}>
                <HStack borderBottomColor='gray.400' borderBottomWidth='1' py='3' alignItems='center'>
                  {/* <XIcon family='FontAwesome' name={selected ? 'check-square-o' : 'square-o'} /> */}
                  <XRadio checked={selected} />
                  <XText pl='2' variant='active'>
                    {choose.label}
                  </XText>
                </HStack>
              </TouchableOpacity>
            )
          })}
        </ScrollView>
      </XModal>
    )
  }

  _renderCreateAtBy = () => {
    const { createAtSegmentIndex, visibleOnlyProfileId, profile, reseller, userGroupName } = this.state
    // @ts-ignore
    // const profile: IProfile = util.getProfile()
    // const visibleOnlyProfileId = false

    // return null
    // ถ้าไม่ใช้ dev mode ให้ซ่อน
    // if (!util.isDevMode()) {
    //   return null
    // }

    let name = null
    let lastName = null
    if (!_.isNil(profile)) {
      name = profile.first_name
      lastName = profile.last_name
    }
    if (!_.isNil(reseller)) {
      return (
        <Box mt='2'>
          <HStack alignItems='center'>
            <XText variant='active' bold>
              สร้างออเดอร์โดย...
            </XText>
          </HStack>
          <VStack w='full' bgColor={COLORS.FORM_PRIMARY_BG}>
            <HStack p='2'>
              <XImage
                // resizeMode='cover'
                style={{
                  borderRadius: 90,
                  width: 50,
                  height: 50,
                  justifyContent: 'center',
                }}
                source={{ uri: reseller.t }}
              />
              <VStack ml='8'>
                <XText>{reseller.d}</XText>
                {!_.isNil(userGroupName) ? (
                  <HStack>
                    <XText variant='inactive'>กลุ่มสมาชิก:</XText>
                    <XText ml='2'>{userGroupName}</XText>
                  </HStack>
                ) : null}
              </VStack>
            </HStack>
          </VStack>
        </Box>
      )
    }
    return (
      <Box mt='2'>
        <HStack alignItems='center'>
          <XText variant='active' bold>
            สร้างออเดอร์โดย...
          </XText>
        </HStack>
        {visibleOnlyProfileId ? (
          <VStack alignItems='center'>
            <XText variant='active' fontSize='sm' pl='2'>
              {`${name} ${lastName}`}
            </XText>
          </VStack>
        ) : (
          <HStack alignItems='center'>
            <Segment
              onSegmentChange={(newIndex) => this._onChangeCreateAtSegmentIndex(newIndex)}
              options={['ทั้งหมด', 'เจ้าของ', 'ผู้ช่วย', 'ตัวแทน']}
              selectedIndex={createAtSegmentIndex}
            />
          </HStack>
        )}
        {createAtSegmentIndex === 3 ? (
          <XText variant='inactive' fontSize='sm' pt='2' px='2'>
            {
              'ตัวเลือก "ตัวแทน" นี้ จะเป็นการโหลดข้อมูลสำหรับตัวแทนทั้งหมดของร้าน (ทุกคนในทุกกลุ่มสมาชิก) หากต้องการโหลดข้อมูลเฉพาะของตัวแทน"ทีละคน"" สามารถโหลดได้ที่ "เมนูหลัก > กลุ่มสมาชิก > สมาชิก > แตะที่ชื่อสมาชิก > เลือก รายงานการขายสินค้ารายวัน"'
            }
          </XText>
        ) : null}
      </Box>
    )
  }

  _renderHelperList = () => {
    const { hlepers, createAtSegmentIndex } = this.state
    const hasHelper = !_.isNil(hlepers) && hlepers.length > 0
    // ถ้าผู้ช่วยไม่ถูกเลือก ไม่ต้องแสดงผล
    if (createAtSegmentIndex !== 2) {
      return null
    }
    if (hasHelper) {
      return (
        <Box w='full' p='2' mt='2' h='40' borderRadius='sm' borderWidth='1px' borderColor='primary.500'>
          <ScrollView>
            {hlepers.map((data: IMemberHelperList) => {
              const select = _.isNil(data.seleted) || !data.seleted ? false : data.seleted
              // const select = true
              if (select) {
                return (
                  <HStack py='2' alignItems='center'>
                    <Box w='8'>
                      <XIcon family='AntDesign' name='caretright' />
                    </Box>
                    <XButton variant='solid' colorScheme='primary' flex={1} mr='1' onPress={() => this._onSeletedHelper(data)}>
                      {data.d}
                    </XButton>
                  </HStack>
                )
              }
              return (
                <HStack py='2' alignItems='center'>
                  <Box w='8' />
                  <XButton variant='outline' flex={1} mr='1' onPress={() => this._onSeletedHelper(data)}>
                    {data.d}
                  </XButton>
                </HStack>
              )
            })}
          </ScrollView>
        </Box>
      )
    }
    return this._renderNoHelper()
  }

  _renderNoHelper = () => (
    <VStack flex={1} pt='2' pb='2'>
      <XText variant='inactive' fontSize='xs'>
        ไม่พบผู้ช่วย
      </XText>
    </VStack>
  )

  _renderMkpChannelSelectorItem = (ch: IMKPChannelDetail, index: number) => {
    const { selectedMkpIds } = this.state
    // const checked = mkpChannelActiveMap[ch.id] || false
    const checked = _.includes(selectedMkpIds, ch.id)
    // console.log('selectedMkpIds => ', selectedMkpIds)
    // console.log('checked => ', checked)
    return (
      <HStack key={`mkp-item-${ch.id}_${index}`} pb='1'>
        <TouchableOpacity onPress={() => this._selectedMkp(ch.id)}>
          <HStack
            px='2'
            py='1'
            space='1'
            alignItems='center'
            borderWidth='1'
            borderColor={checked ? 'gray.400' : 'gray.200'}
            borderRadius='lg'>
            {checked ? <XIcon name='checkmark' /> : <XIcon name='checkmark' color='transparent' />}
            <MkpLogo mkpId={ch.mkp_id} width={24} height={24} />
            <XText variant={checked ? 'active' : 'inactive'} numberOfLines={1}>
              {ch.name}
            </XText>
          </HStack>
        </TouchableOpacity>
      </HStack>
    )
  }

  _renderMkpChannelSelector = () => {
    const mkpChannels = util.getMKPChannels()
    if (_.isNil(mkpChannels)) {
      return <XText variant='inactive'>ไม่พบช่องทางขาย</XText>
    }
    return mkpChannels.map(this._renderMkpChannelSelectorItem)
  }

  _renderFilterOrderOrigin = () => {
    const { segmentOrderOriginIndex } = this.state

    return (
      <VStack w='full' pt='2' space='1'>
        <HStack alignItems='center' space='1'>
          {/* <XIcon variant='active' name='local-shipping' family='MaterialIcons' /> */}
          <XText variant='active' bold>
            เฉพาะออเดอร์ในระบบ...
          </XText>
        </HStack>

        <Segment
          selectedIndex={segmentOrderOriginIndex}
          options={['ทั้งหมด', 'XSelly', 'ช่องทางขาย']}
          onSegmentChange={(newIndex: number) => this.setState({ segmentOrderOriginIndex: newIndex })}
        />

        {segmentOrderOriginIndex === 2 ? (
          <VStack w='full'>
            <XText variant='inactive'>แสดงเฉพาะช่องทางเหล่านี้...</XText>
            <HStack w='full' flexWrap='wrap' space='1.5'>
              {this._renderMkpChannelSelector()}
            </HStack>
          </VStack>
        ) : null}
      </VStack>
    )
  }

  _renderChildren = () => {
    const { thisVariants } = this.state
    return (
      <XContent w='full' borderRadius='xl' p='2'>
        <XCard p='2' w='full' mb='2'>
          {this._renderHeader()}
          {this._renderCreateAt()}
          {this._renderDateRangePicker()}
          {this._renderCreateAtBy()}
          {this._renderHelperList()}
          {_.isNil(thisVariants) || thisVariants.length < 2 ? null : this._renderVariants()}
          {this._renderFilterOrderOrigin()}
          {this._renderSalesChannel()}
          {p.op.isWeb() ? null : <HStack h='5' />}
          {this._renderPaymentStatus()}
          <HStack h='5' />
          {this._renderShippingOrderStatus()}
          {this._renderOtherOptions()}
          {this._renderSellChannelAbsolute()}
          {this._renderCreatedAtAbsolute()}
        </XCard>
      </XContent>
    )
  }

  render() {
    // const { visible, onRequestClose = null } = this.props
    return (
      <XContainer>
        {this._renderCustomHeader()}
        {this._renderChildren()}
        {this._renderFooter()}
      </XContainer>
    )
    // return (
    //   <XOverlay
    //     contentStyle={{ height: 'auto', width: 365 }}
    //     visible={visible}
    //     children={this._renderChildren()}
    //     onRequestClose={_.isFunction(onRequestClose) ? onRequestClose() : null}
    //   />
    // )
  }
}
