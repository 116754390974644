import React from 'react'
import _ from 'lodash'
import { TouchableOpacity, View, StyleSheet } from 'react-native'
import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import api from 'x/utils/api'
import CONS from 'x/config/constants'
import { S, STYLES, COLORS } from 'x/config/styles'
import { canDoAtSelectedStore } from 'x/utils/acl'
import { IMKPChannelDetail, ISelectedStoreMap } from 'x/index'
import XOverlay from '../XOverlay'
import XCustomHeader from '../XCustomHeader'
// import XSegment from '../XSegment'
// import XRadioList from '../XRadioList'
// import { diff } from 'deep-object-diff'
// import XIcon from '../XIcon'
import XInput from '../XInput'
import XText from '../XText'
import VStack from '../VStack'
import XButton from '../XButton'

interface IDeleteMkpChannelButtonProps {
  // after delete success
  onDeleted: () => void
  channel: IMKPChannelDetail
  selectedStore: ISelectedStoreMap
}

interface IDeleteMkpChannelButtonState {
  isOverlayVisible: boolean
  confirmDeleteText: string

  dialogStep: number // start with 1

  // use in step 3 only
  // isAccountDeleted: boolean
  // hasAppleId: boolean
  // hasFbId: boolean

  isSubmitting: boolean
}

class DeleteMkpChannelButton extends React.Component<IDeleteMkpChannelButtonProps, IDeleteMkpChannelButtonState> {
  static displayName = 'DeleteMkpChannelButton'

  inProcess?: boolean

  constructor(props) {
    super(props)
    this.state = {
      isOverlayVisible: false,
      confirmDeleteText: '',

      dialogStep: 1,

      // isAccountDeleted: false,

      isSubmitting: false,
    }

    this.inProcess = false
  }

  openOverlay = async () => {
    // console.log('openOverlay')
    await util.setStatePromise(this, { isOverlayVisible: true })
  }

  closeOverlay = async () => {
    // console.log('closeOverlay')
    // const { dialogStep = 1} = this.state

    // const { onClose, onCloseAfterDeleted } = this.props
    // if (_.isFunction(onClose)) {
    //   onClose()
    // }

    // if (_.isFunction(onCloseAfterDeleted) && dialogStep === 3) {
    //   onCloseAfterDeleted(isAccountDeleted)
    // }

    await util.setStatePromise(this, {
      isOverlayVisible: false,
      dialogStep: 1,
      // isAccountDeleted: false,
      // hasAppleId: false,
      // hasFbId: false,
      confirmDeleteText: '',
    })
  }

  _onPressDelete = async () => {
    const { selectedStore, channel, onDeleted } = this.props
    if (this.inProcess) {
      return
    }
    this.inProcess = true
    await util.setStatePromise(this, { isSubmitting: true })

    const body: { [key: string]: any } = {
      store_id: selectedStore.get('id'),
      mkp_ch_id: channel.id,
    }
    const apiOptions = {
      showSpinner: true,
    }

    const res = await api.postV2(api.POST_DELETE_MKP_CHANNEL, body, apiOptions)
    // console.log('res /// => ', res)
    if (res.channels) {
      if (_.isFunction(onDeleted)) {
        onDeleted()
      }
      p.op.showToast('ลบช่องทางขายเรียบร้อยแล้ว', 'success')
      await this.closeOverlay()
      return
    }
    p.op.showToast('ลบช่องทางขายไม่สำเร็จ', 'success')
    await util.setStatePromise(this, { isSubmitting: false })
    await this.closeOverlay()
    this.inProcess = false
  }

  // doDeleteAccount = async (): Promise<boolean> => {
  //   let isDeleteApiSuccess = false
  //   try {
  //     // const apiOptions: IApiOptions = {
  //     //   fetchOptions: {
  //     //     timeout: 180000,
  //     //   },
  //     // }

  //     const res = await api.del<{}, IDeleteAccountResponse>('privacy_policy/delete_account', {})
  //     // console.log('doDeleteAccount res => ', res)

  //     isDeleteApiSuccess = true

  //     const newState: Partial<IDeleteMkpChannelButtonState> = {}
  //     if (res && _.has(res, 'is_account_deleted')) {
  //       newState.isAccountDeleted = res.is_account_deleted
  //     }
  //     if (res && _.has(res, 'has_apple_id')) {
  //       newState.hasAppleId = res.has_apple_id
  //     }
  //     if (res && _.has(res, 'has_fb_id')) {
  //       newState.hasFbId = res.has_fb_id
  //     }

  //     if (!_.isEmpty(newState)) {
  //       await util.setStatePromise(this, newState)
  //     }
  //   } catch (err) {
  //     // console.log('doDeleteAccount err => ', err)
  //     if (util.isXSellyErrorV2(err)) {
  //       // @ts-ignore
  //       util.alertWithXSellyErrorV2(err.error)
  //     }
  //   }

  //   return isDeleteApiSuccess
  // }

  _onPressContinueToStepTwo = () => {
    this.setState({ dialogStep: 2 })
  }

  renderHeader = () => {
    const { dialogStep = 1 } = this.state
    const titleText = 'ยืนยันการลบช่องทางขาย'

    // if (dialogStep === 3) {
    //   titleText = isAccountDeleted ? 'ลบบัญชี XSelly สำเร็จ' : 'เกิดข้อผิดพลาดในการลบบัญชี'
    // }

    return <XCustomHeader title={titleText} headerRightProps={{ closeIcon: !this.state.isSubmitting, onPressItem: this.closeOverlay }} />
  }

  renderStepOneConfirmationButtons = () => (
    <View
      style={{
        flex: 1,
        width: '100%',
        minHeight: 44,
        padding: 4,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
      }}>
      <TouchableOpacity onPress={this._onPressContinueToStepTwo} style={[S.FLEX, S.BUTTON_DANGER, S.HEIGHT_34]}>
        <XText bold color='white'>
          ดำเนินการต่อ
        </XText>
      </TouchableOpacity>
      <View style={{ width: 8 }} />
      <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
        <XText bold color={COLORS.APP_MAIN}>
          {'ยกเลิก '}
        </XText>
      </TouchableOpacity>
    </View>
  )

  renderContentStepOne = () => (
    <VStack>
      <VStack w='full' bgColor='amber.100'>
        <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
          <XText variant='active'>
            หากดำเนินการลบช่องทางขาย ข้อมูลออเดอร์และสินค้าที่สร้างไว้แล้วใน XSelly จะยังคงอยู่ แต่ข้อมูลการผูกสินค้าจะถูกลบไป
            (หากเชื่อมต่อใหม่ในอนาคตและสินค้ามี SKU ตรงกันอยู่ ระบบก็จะผูกกับสินค้าตัวเดิมให้อัตโนมัติ)
            และสถานะออเดอร์ของช่องทางขายนี้ก็จะไม่อัพเดทสถานะต่อ (การเชื่อมต่อใหม่จะไม่สามารถกลับมาอัพเดทสถานะออเดอร์เหล่านี้ได้อีก)
          </XText>
        </View>

        <View style={{ height: 16 }} />
      </VStack>
      {this.renderStepOneConfirmationButtons()}
    </VStack>
  )

  isConfirmDeleteTextValid = () => {
    const { confirmDeleteText = '' } = this.state
    return confirmDeleteText === 'DELETE'
  }

  renderStepTwoConfirmationButtons = () => {
    const isDelBtnDisabled = !this.isConfirmDeleteTextValid()
    return (
      <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
        <TouchableOpacity
          disabled={isDelBtnDisabled}
          onPress={isDelBtnDisabled ? undefined : this._onPressDelete}
          style={[S.FLEX, isDelBtnDisabled ? S.BUTTON_INACTIVE : S.BUTTON_DANGER, S.HEIGHT_34]}>
          <XText bold style={[isDelBtnDisabled ? S.TEXT_ACTIVE : S.TEXT_ACTIVE_DARK, S.TEXT_LARGER, S.TEXT_ALIGN_CENTER]}>
            ลบช่องทางขาย
          </XText>
        </TouchableOpacity>
        <View style={{ width: 8 }} />
        <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
          <XText bold style={[S.TEXT_PRIMARY, S.TEXT_ALIGN_CENTER, S.TEXT_LARGER]}>
            {'ยกเลิก '}
          </XText>
        </TouchableOpacity>
      </View>
    )
  }

  _onConfirmDeleteTextChange = (newText: string) => {
    this.setState({ confirmDeleteText: newText })
  }

  renderDeleteTextInput = () => {
    const { confirmDeleteText = '' } = this.state
    return (
      <XInput
        style={{ minHeight: 34, padding: 8, fontSize: STYLES.FONT_SIZE_LARGER }}
        flex={1}
        textAlign='center'
        textAlignVertical='center'
        placeholder='พิมพ์ DELETE เพื่อลบบัญชี'
        value={confirmDeleteText}
        onChangeText={this._onConfirmDeleteTextChange}
      />
    )
  }

  renderContentStepTwo = () => (
    <>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
        <XText style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>
          เพื่อยืนยันการลบช่องทางขาย กรุณาพิมพ์คำว่า DELETE (ตัวพิมพ์ใหญ่ทุกตัว) ในช่องด้านล่างและกด
        </XText>
      </View>
      <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>{this.renderDeleteTextInput()}</View>

      <View style={{ height: 16 }} />

      {this.renderStepTwoConfirmationButtons()}
    </>
  )

  // renderStepThreeConfirmationButton = () => (
  //   <View style={{ width: '100%', minHeight: 44, padding: 4, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
  //     <TouchableOpacity onPress={this.closeOverlay} style={[S.FLEX, S.ROW_CENTER, S.BUTTON_OUTLINE_PRIMARY, S.HEIGHT_34]}>
  //       <Text style={[S.TEXT_PRIMARY, S.TEXT_BOLD, S.TEXT_ALIGN_CENTER]}>{'ปิด '}</Text>
  //     </TouchableOpacity>
  //   </View>
  // )

  // renderContentStepThree = () => (
  //   <>
  //     <View style={{ width: '100%', padding: 8, flexDirection: 'row', flexWrap: 'wrap' }}>
  //       <Text style={[S.TEXT_ACTIVE, S.TEXT_LARGER]}>{infoText}</Text>
  //     </View>

  //     <View style={{ height: 16 }} />

  //     {this.renderStepThreeConfirmationButton()}
  //   </>
  // )

  renderContent = () => {
    const { dialogStep = 1 } = this.state
    return (
      <View style={{ width: '100%' }}>
        {this.renderHeader()}

        {dialogStep === 1 ? this.renderContentStepOne() : null}
        {dialogStep === 2 ? this.renderContentStepTwo() : null}
        {/* {dialogStep === 3 ? this.renderContentStepThree() : null} */}
      </View>
    )
  }

  renderMain = () => {
    const { isOverlayVisible = false, isSubmitting = false } = this.state
    const heightDialog = 290
    return (
      <>
        {/* <TouchableOpacity
          style={[S.BUTTON_OUTLINE, S.BORDERS_DANGER, S.BG_LIGHT_GREY, { height: 34, width: 120 }]}
          onPress={this.openOverlay}>
          <XText color={COLORS.BRAND_Danger} bold>
            ลบบัญชี
          </XText>
        </TouchableOpacity> */}
        <XButton
          onPress={() => {
            if (!canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MKP_DELETE)) {
              p.op.alert('คุณไม่มีสิทธิ์ในการลบช่องทางขาย')
              return
            }
            this.openOverlay()
          }}
          flex={1}
          bgColor={COLORS.BRAND_Danger}>
          ลบช่องทางขาย
        </XButton>

        {isOverlayVisible ? (
          <XOverlay
            contentStyle={StyleSheet.flatten([
              {
                minHeight: 150,
                marginBottom: 130,
                width: 310,
                height: heightDialog,
                alignSelf: 'center',
                flexDirection: 'column',
                backgroundColor: COLORS.WHITE,
              },
              // p.op.isWeb() ? { height: dialogStep === 1 ? 290 : 260 } : {},
            ])}
            visible={isOverlayVisible}
            onRequestClose={isSubmitting ? undefined : this.closeOverlay}>
            {this.renderContent()}
          </XOverlay>
        ) : null}
      </>
    )
  }

  render() {
    return this.renderMain()
  }
}

export default DeleteMkpChannelButton
