import BaseUIReportPayment from 'xui/modules/report/BaseUIReportPayment'
import * as util from '../../utils/util-web'

export default class ReportPayment extends BaseUIReportPayment {
  _handleOnDownloadFileByPlatform = async (url: string, fileName: string): Promise<void> => {
    if (url) {
      await util.downloadFileFromUrl({ url, fileName })
    }
  }
}
