const defaultIcon = require('../img/banks/Default.png')
const baac = require('../img/banks/baac.png')
const bay = require('../img/banks/bay.png')
const bkk = require('../img/banks/bkk.png')
const cimb = require('../img/banks/cimb.png')
const citi = require('../img/banks/citi.png')
const ghb = require('../img/banks/ghb.png')
const gsb = require('../img/banks/gsb.png')
const hsbc = require('../img/banks/hsbc.png')
const ibank = require('../img/banks/ibank.png')
const icbc = require('../img/banks/icbc.png')
const kbank = require('../img/banks/kbank.png')
const kk = require('../img/banks/kk.png')
const ktb = require('../img/banks/ktb.png')
const lhbank = require('../img/banks/lhbank.png')
const mizuho = require('../img/banks/mizuho.png')
// const sbmc = require('../img/banks/sbmc.png')
const scb = require('../img/banks/scb.png')
const tisco = require('../img/banks/tisco.png')
const tmb = require('../img/banks/tmb.png')
const ttb = require('../img/banks/ttb.png')
const tnc = require('../img/banks/tnc.png')
const trcbank = require('../img/banks/trcbank.png')
const uob = require('../img/banks/uob.png')
const promptpay = require('../img/banks/promptpay.png')
const rabbit_line_pay = require('../img/banks/rabbitlinepay.png')
const truemoney = require('../img/banks/truemoney.png')
const creditcard = require('../img/banks/creditcard.png')

export function getBankIconFromBankID(bankId: number) {
  switch (bankId) {
    // case 0:
    //   return null
    // case 1:
    //   return null
    // case 2:
    //   return null
    case 3:
      return kbank
    case 4:
      return bay
    case 5:
      return ktb
    case 6:
      return scb
    case 7:
      return bkk
    case 8:
      return tmb
    case 9:
      return gsb
    case 10:
      return cimb
    case 11:
      return ibank
    case 12:
      return kk
    case 13:
      return tisco
    case 14:
      return tnc
    case 15:
      return uob
    // case 16:
    //   return scnb
    case 17:
      return trcbank
    case 18:
      return lhbank
    // case 19:
    //   return smebank
    case 20:
      return baac
    // case 21:
    //   return exim
    case 22:
      return ghb
    case 23:
      return icbc
    case 24:
      return promptpay
    case 33:
      return ttb
    case 38:
      return rabbit_line_pay
    case 39:
      return hsbc
    case 40:
      return mizuho
    case 41:
      return citi
    case 42:
      return truemoney
    case 43:
      return creditcard
    // case 25:
    //   return gbpay
    // case 26:
    //   return cod_deposit
    // case 27:
    //   return scbgateway
    // case 28:
    //   return mkp
    // case 29:
    //   return null
    // case 30:
    //   return null
    default:
      return defaultIcon
  }
}
