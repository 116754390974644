import React from 'react'
import * as util from 'x/utils/util'
import XCustomHeader from 'xui/components/XCustomHeader'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
// import SRowItem from '../../components/renderRowLeftAndRight'
import BaseStoreWebsiteListView from 'x/modules/storeWebsite/BaseStoreWebsiteListView'
import { COLORS } from 'x/config/styles'
import XButton from 'xui/components/XButton'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import HStack from 'xui/components/HStack'
import XImage from 'xui/components/XImage'
import XText from 'xui/components/XText'
import XIcon from 'xui/components/XIcon'
import _ from 'lodash'
import Box from 'xui/components/Box'
import { TouchableOpacity } from 'react-native'
import { IStoreWebsite } from 'x/index'
import XSpinner from 'xui/components/XSpinner'

// const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIStoreWebsiteListView extends BaseStoreWebsiteListView {
  _renderHeader = () => {
    const { maxLoad } = this.state
    const params = util.getNavParams(this.props)
    let title = 'รายการ'
    if (_.has(params, 'type')) {
      const { type } = params
      title = type === 'sale_page' ? 'เซลเพจ' : 'ร้านค้าออนไลน์'
    }
    if (maxLoad > 0) {
      title = `${maxLoad} ${title}`
    }
    return (
      <XCustomHeader
        title={title}
        headerLeftProps={{ backIcon: true, onPressItem: () => util.navGoBack(this.props) }}
        headerRightProps={{ addIcon: true, onPressItem: () => this._navToCreateStoreWebsiteView() }}
        // renderHeaderRight={this._renderHeaderRight}
      />
    )
  }

  _renderFooter = () => (
    // const { selectedPackages } = this.state
    // if (_.isNil(selectedPackages)) {
    //   return null
    // }
    <XButton mb='1'>ชำระเงิน</XButton>
  )

  _renderTag = (salePage: IStoreWebsite) => {
    const { type } = salePage
    const templateText = util.getStoreWebstieTagName(type)
    return (
      <Box w='20' h='6' alignItems='center' justifyContent='center' bgColor={COLORS.APP_SECONDARY} borderRadius='7'>
        <XText color={COLORS.WHITE}>{templateText}</XText>
      </Box>
    )
  }

  _renderNoWebsiteList = () => {
    const { isLoadMore } = this.state
    if (isLoadMore) {
      return null
    }
    return (
      <HStack alignItems='center' justifyContent='center'>
        <XText textAlign='center' variant='inactive'>
          {'ไม่พบรายการ\nกรุณากำปุ่ม "+" เพื่อสร้าง'}
        </XText>
      </HStack>
    )
  }

  _renderStoreWebsiteListView = () => {
    const { storeWebsiteList } = this.state
    if (_.isNil(storeWebsiteList) || storeWebsiteList.length < 1) {
      return this._renderNoWebsiteList()
    }
    // return null
    return (
      <VStack w='full'>
        {storeWebsiteList.map((storeWebsite: IStoreWebsite, idx: number) => (
          <XCard p='2' mt={idx === 0 ? '0' : '2'} w='full' key={`${storeWebsite.id}`}>
            <HStack w='full'>
              <HStack>
                <XImage w='16' h='16' borderRadius='lg' resizeMode='cover' source={{ uri: storeWebsite.img_url }} />
              </HStack>
              <HStack p='1' flex={1}>
                <XText numberOfLines={2} fontSize='lg'>
                  {storeWebsite.name}
                </XText>
              </HStack>
              <TouchableOpacity onPress={() => this._navToStoreWebsiteView(storeWebsite)} style={{ width: 32, alignItems: 'flex-end' }}>
                <XIcon name='settings' family='Ionicons' />
              </TouchableOpacity>
            </HStack>
            {/* // LINE SALE PAGE */}
            <HStack alignItems='center' space='2' mt='2'>
              {this._renderTag(storeWebsite)}
              <XText variant='inactive'>{`ID: ${storeWebsite.id}`}</XText>
            </HStack>
            <HStack mt='2' space='2'>
              <XButton h='9' bgColor={COLORS.BG_LIGHT_GREY} leftIcon={<XIcon name='eye' family='Entypo' color={COLORS.APP_MAIN} />}>
                <XText color={COLORS.APP_MAIN}>ดูเว็บไซต์</XText>
              </XButton>
              <XButton h='9' bgColor={COLORS.BG_LIGHT_GREY} leftIcon={<XIcon name='copy1' family='AntDesign' color={COLORS.APP_MAIN} />}>
                <XText color={COLORS.APP_MAIN}>คัดลอก URL</XText>
              </XButton>
            </HStack>
            <HStack>
              <XText mt='2' variant='inactive'>{`สร้างเมื่อ: ${storeWebsite.created_at}`}</XText>
            </HStack>
          </XCard>
        ))}
      </VStack>
    )
  }

  _loadmoreView = () => {
    const { isLoadMore } = this.state
    if (isLoadMore) {
      return (
        <HStack mt='8' alignItems='center' justifyContent='center'>
          <XSpinner />
        </HStack>
      )
    }
    return null
  }

  render() {
    return (
      <XContainer>
        {this._renderHeader()}
        <XContent ref={this.scrollViewRef} onScroll={this._handleScroll} style={{ backgroundColor: COLORS.BG_LIGHT_GREY }}>
          <VStack p='2' w='full' bgColor={COLORS.WHITE}>
            {this._renderStoreWebsiteListView()}
            {this._loadmoreView()}
          </VStack>
        </XContent>
        {/* {this._renderFooter()} */}
      </XContainer>
    )
  }
}
