import React from 'react'
import { View, TouchableOpacity, Image, Clipboard } from 'react-native'
import _ from 'lodash'
// import p from 'x/config/platform-specific'
import * as util from 'x/utils/util'
import BaseChannelView from 'x/modules/channels/BaseChannelView'
// import { CHANNEL_PICK } from './BaseUIChannelPickView'
import XCustomHeader from 'xui/components/XCustomHeader'
// import { Input } from 'react-native-elements'
import { STYLES, COLORS, S } from 'x/config/styles'
import XInput from 'xui/components/XInput'
import XIcon from 'xui/components/XIcon'
import XForceCreateShipping from 'xui/components/XForceCreateShipping'
import { IAddress, IMKPChannelDetail, IOverlaySetupSyncOrderCallBack } from 'x/index'
import { VIEW_WIDTH_TEN } from 'xui/utils/BaseShareUI'
import * as NavActions from 'x/utils/navigation'
import { isImmutable } from 'immutable'
import CONS from 'x/config/constants'
import HelpButton from 'xui/components/HelpButton'
import SyncBtn from 'xui/components/SyncBtn'
import OverlaySetupSyncOrder from 'xui/modules/channels/OverlaySetupSyncOrder'
import OverlaySyncMKPProduct from 'xui/modules/channels/OverlaySyncMKPProduct'
import XDropdown from 'xui/components/XDropdown'
import DotDotDotVerticalIcon from 'xui/components/DotDotDotVerticalIcon'
import p from 'x/config/platform-specific'
import XContainer from 'xui/components/XContainer'
import XContent from 'xui/components/XContent'
import XCard from 'xui/components/XCard'
import VStack from 'xui/components/VStack'
import Box from 'xui/components/Box'
import HStack from 'xui/components/HStack'
import XText from 'xui/components/XText'
import XIconButton from 'xui/components/XIconButton'
import XButton from 'xui/components/XButton'
import XRadioList from 'xui/components/XRadioList'
import XHelpModal from 'xui/components/XHelpModal'
import XFaqModal from 'xui/components/XFaqModal'
import FAQ from 'x/config/FAQ'
import FAQ_CONSTANTS from 'x/config/FAQ_CONSTANTS'
import MkpLogo from 'xui/components/MkpLogo'
import XSwitch from 'xui/components/XSwitch'
// import XSegment from 'xui/components/XSegment'
import Segment from 'xui/components/Segment'
import XFlatPicker from 'xui/components/XFlatPicker'
import * as acl from 'x/utils/acl'

const VIEW_STYLE_PENDDING = { paddingLeft: 16, paddingRight: 16 }

const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />

export default abstract class BaseUIChannelView extends BaseChannelView {
  // abstract _renderMain: () => JSX.Element
  // abstract renderImageDocumentUpload: () => JSX.Element
  abstract goBack: () => void

  // abstract navToProductGroupList: () => void
  // abstract navToToAddressViewFromStoreMyView: (isPrimary: boolean, address?: ISelectedAddresses) => void
  // abstract _renderHamburgerButton: () => JSX.Element

  renderCustomHeader = () => {
    const leftBtn = {
      backIcon: true,
      onPressItem: () => this.goBack(),
    }
    const { mode } = this.state
    const isCreateMode = mode === CONS.MODE.CREATE
    let titleHeader = 'ช่องทางขาย'
    if (isCreateMode) {
      titleHeader = `สร้างช่องทางขาย`
    }
    return <XCustomHeader title={titleHeader} headerLeftProps={leftBtn} renderHeaderRight={this._renderHamburgerButton} />
  }

  _onHamburgerWillShow = async (): Promise<void> => {
    // if (this.inProcess) {
    //   return
    // }
    // this.inProcess = true
    await util.setStatePromise(this, { isHamburgerMenuOpened: true })
    // this.inProcess = false
  }

  _onHamburgerWillHide = async (): Promise<void> => {
    await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  _renderHeaderRightDropdownButtonText = (rowData) => rowData.label

  _onHeaderRightDropdownSelected = (rowIndex, rowData) => {
    const { navigation } = this.props
    // const { editingMkpProduct, coreProduct } = this.state

    switch (rowIndex) {
      // จัดการผูกตัวเลือกใหม่
      case 0: {
        // const errorCode = util.checkMkpProductVariantsMappingError(editingMkpProduct, coreProduct)

        // ในหน้านี้ควรจะเจอแค่กรณีเดียว
        // if (errorCode || errorCode === 'ONLY_ONE_VARAINT') {
        //   p.op.alert('สินค้ามีเพียงตัวเลือกเดียว', 'กรุณาเพิ่มตัวเลือกสินค้าใหม่ เพื่อที่จะนำไปใช้สลับกับตัวเลือกเดิม')
        //   return
        // }

        // Go to Mapping View
        // navigation.dispatch(
        // NavActions.navToMkpProductDetailMapping({
        //   ...navigation.state.params,
        //   isPreventDestroyWhenNavBack: true,
        // })
        // )
        break
      }
      default: {
        // No-Op
      }
    }
    // p.op.alert(JSON.stringify(rowIndex), JSON.stringify(rowData))
  }

  _renderModalDropdownRow = (data: { label: string; isDanger?: boolean; icon?: any; onPress: () => void }): JSX.Element => {
    // log('ModalDropdown renderRow::data => ', data)
    const isDanger = _.has(data, 'isDanger') ? data.isDanger : false
    const icon = _.has(data, 'icon') ? data.icon : null
    let textColor = COLORS.TEXT_ACTIVE
    if (isDanger) {
      textColor = COLORS.FORM_ERROR
    }

    // let textPaddingLeft = -2
    // if (_.isNil(icon)) {
    //   textPaddingLeft = 10
    // }

    return (
      <Box flexDirection='row' py='2' pr='2'>
        {_.isNil(icon) ? null : (
          <XIcon
            name={icon.name}
            family={icon.family}
            style={{
              color: textColor,
            }}
          />
        )}
        <XText
          allowFontScaling={false}
          pl='1'
          style={{
            fontSize: STYLES.FONT_SIZE_LARGER,
            color: textColor,
          }}>
          {data.label}
        </XText>
      </Box>
    )
  }

  _afterHamburgerMenuSelectedSafetyClose = () => {
    setTimeout(() => {
      if (this.state.isHamburgerMenuOpened) {
        this.setState({ isHamburgerMenuOpened: false })
      }
    }, 200)
  }

  _onHamburgerMenuSelect = async (index: number, rowData: any) => {
    // if (this.inSelectHamburger) {
    //   return
    // }
    // this.inSelectHamburger = true
    // log('ModalDropdown:: onSelect index => ', index)
    // log('_onHamburgerMenuSelect ModalDropdown:: onSelect index => ', index)
    const selectedItem = this.state.hamburgerMenuOptions[index]
    // log('ModalDropdown:: onSelect selectedItem => ', selectedItem)
    if (selectedItem && _.isFunction(selectedItem.onPress)) {
      // MUST!! delay for finished render dropdown before call method
      await new Promise((delayOnPress) => {
        setTimeout(delayOnPress, 200)
      })
      // if (!_.isNil(selectedItem.perm)) {
      //   const perm = xAcl.canDoAtSelectedStore(selectedItem.perm)
      //   if (!perm) {
      //     p.op.showConfirmationOkOnly(``, `คุณไม่ได้รับสิทธิ์ในการใช้งานฟีเจอร์นี้`)
      //     // this.inSelectHamburger = false
      //     await util.setStatePromise(this, { isHamburgerMenuOpened: false })
      //     return
      //   }
      // }
      selectedItem.onPress()
      this._afterHamburgerMenuSelectedSafetyClose()
    }
    // this.inSelectHamburger = false
    await util.setStatePromise(this, { isHamburgerMenuOpened: false })
  }

  _renderHamburgerButton = () => {
    const { hamburgerMenuOptions, mode } = this.state
    const isCreateMode = mode === CONS.MODE.CREATE
    if (isCreateMode) {
      return <Box w='8' />
    }
    if (_.isNil(hamburgerMenuOptions) || hamburgerMenuOptions.length < 1) {
      return null
    }
    const isDisabled = false
    // const opts = [{ label: 'สลับการผูกตัวเลือกสินค้า' }]
    // console.log('_renderHamburgerButton => ')
    return (
      <XDropdown
        disabled={isDisabled}
        // ref={r => r && (this.hamburgerMenuRef = r)}
        // ref={this.hamburgerMenuRef}
        // dropdownStyle={{
        //   width: 220,
        //   height: 40 * hamburgerMenuOptions.length,
        // }}
        // @ts-ignore
        showsVerticalScrollIndicator={false}
        // onDropdownWillShow={this._onHamburgerWillShow}
        // onDropdownWillHide={this._onHamburgerWillHide}
        renderRow={this._renderModalDropdownRow}
        onSelect={this._onHamburgerMenuSelect}
        options={hamburgerMenuOptions}>
        <DotDotDotVerticalIcon disabled={isDisabled} />
      </XDropdown>
    )
  }

  goBackFromBase = () => {
    this.goBack()
  }

  _renderPickChannelCreateMode = () => {
    const { mkpIdSelected } = this.state
    // const isNoData = !!_.isNil(pickChannel)
    // const disableChannelBtn = !!isNoData // disable the channel pick btn if the mkp is known as we DONOT allow changing it
    const _renderTextFAQ = (onPressOpenOverlay: () => void) => (
      <TouchableOpacity style={{ paddingLeft: 4 }} onPress={() => onPressOpenOverlay()}>
        <XText color='blue.800' textDecorationLine='underline'>
          ดูคลิปแนะนำการเตรียมและเชื่อมช่องทางขาย
        </XText>
      </TouchableOpacity>
    )
    return (
      <VStack>
        <XText p='2' variant='active'>
          เลือกช่องทางการเชื่อมต่อ
        </XText>
        <XFaqModal
          key='วิธีเชื่อมช่องทางขาย'
          headerTitle='วิธีเชื่อมช่องทางขาย'
          // @ts-ignore
          FAQ={FAQ_CONSTANTS.FAQ_CONNECT_MKP_CHANNELS}
          initiateOpenIndex={[0, 1]}
          renderButton={(onPressOpenOverlay: () => void) => _renderTextFAQ(onPressOpenOverlay)}
        />
        <XCard w='full' mt='2'>
          <VStack space='2' p='2' w='full'>
            {this.MKP_IDS.map((mkpId: number) => {
              const mkpName = util.getMKPName(mkpId)
              const mkpImg = <MkpLogo mkpId={mkpId} />
              const isSelected = mkpIdSelected === mkpId
              return (
                <TouchableOpacity onPress={() => this._selectedMkp(mkpId)} style={{ flex: 1 }}>
                  <HStack
                    borderRadius='md'
                    w='full'
                    alignItems='center'
                    py='2'
                    borderWidth='1'
                    borderColor={isSelected ? COLORS.APP_MAIN : COLORS.TEXT_INACTIVE}>
                    {isSelected ? (
                      <Box w='12' alignItems='center'>
                        <XIcon name='caretright' family='AntDesign' color={COLORS.APP_MAIN} />
                      </Box>
                    ) : (
                      <Box w='12' />
                    )}
                    <Box style={{ width: 50 }}>{mkpImg}</Box>
                    <Box style={{ flex: 1 }}>
                      <XText bold fontSize='md' variant='active'>
                        {mkpName}
                      </XText>
                    </Box>
                  </HStack>
                </TouchableOpacity>
              )
            })}
          </VStack>
        </XCard>
      </VStack>
    )
  }

  _renderPickChannel = () => {
    const { channel, mode } = this.state
    if (mode === 'CREATE') {
      return this._renderPickChannelCreateMode()
    }
    if (_.isNil(channel)) {
      return null
    }
    const isViewMode = mode === 'VIEW'
    // const disableChannelBtn = !!isNoData // disable the channel pick btn if the mkp is known as we DONOT allow changing it

    // let channelLabel = isNoData ? `ระบุช่องทางขาย` : 'pickChannel.label'
    // check if the key is known. If so, resolve to use the actual name i.e. shopee => Shopee
    const mkpId = channel.mkp_id
    const mkpName = util.getMKPName(mkpId)
    // channelLabel = mkpName || channelLabel

    return (
      <Box mt='2'>
        {isViewMode ? null : <XText variant={isViewMode ? 'inactive' : 'active'}>ช่องทางการเชื่อมต่อ</XText>}
        <XCard p='2'>
          <TouchableOpacity disabled style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
            <MkpLogo mkpId={mkpId} />
            <Box pl='4'>
              <XText fontSize='md' bold variant='active'>
                {mkpName}
              </XText>
            </Box>
          </TouchableOpacity>
        </XCard>
      </Box>
    )
  }

  _renderInputChannalName = () => {
    const { channalName, mode, isEditChannelName, channalNameBeforeEdit } = this.state
    const isViewMode = mode === 'VIEW'
    // NOTE :: CREATE MODE API V2 เวลาสร้างไม่ต้องส่งชื่อไปแล้ว เดี๋ยวหลังบ้านจัดการเรื่องชื่อให้
    if (mode === 'CREATE') {
      return null
    }
    return (
      <Box mt='2'>
        {/* {VIEW_HEIGHT_TEN} */}
        <XText variant={isViewMode ? 'inactive' : 'active'}>ชื่อการเชื่อมต่อ</XText>
        {isViewMode && !isEditChannelName ? (
          <Box w='full' style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Box style={{ flex: 1, alignItems: 'flex-start' }}>
              <XText variant='active' bold fontSize='lg' numberOfLines={1}>
                {channalName}
              </XText>
            </Box>
            {VIEW_WIDTH_TEN}
            <Box
              style={{
                // width: 80,
                // height: STYLES.BTN_HEIGHT_SMALL,
                // alignItems: 'center',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}>
              <XIconButton
                variant='outline'
                name='edit'
                family='FontAwesome5'
                onPress={() => {
                  const canEditPermission = this._checkCanEditPermission()
                  if (!canEditPermission) {
                    return
                  }
                  util.setStatePromise(this, { isEditChannelName: true, channalNameBeforeEdit: channalName })
                }}
              />
            </Box>
          </Box>
        ) : (
          <Box w='full'>
            <View style={{ flex: 1, flexDirection: 'row' }}>
              <XInput
                onChangeText={(txt: string) => this._onChangeText(`channalName`, txt)}
                value={channalName}
                placeholder='ระบุชื่อร้านสำหรับช่วยให้ฉันจำได้'
                maxLength={40}
                style={{ height: 30, flex: 1 }}
              />

              {isEditChannelName ? (
                <Box style={{ flexDirection: 'row' }}>
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='closecircleo'
                    family='AntDesign'
                    onPress={() => {
                      util.setStatePromise(this, { isEditChannelName: false, channalName: channalNameBeforeEdit })
                    }}
                  />
                  <Box w='2' />
                  <XIconButton
                    variant='outline'
                    name='save'
                    family='FontAwesome5'
                    onPress={() => this._updateChannel('name', this.state.channalName.trim())}
                  />
                </Box>
              ) : null}
            </View>
          </Box>
        )}
      </Box>
    )
  }

  // _renderInputChannalEmail = () => {
  //   const { channalEmail, pickChannel } = this.state
  //   const nameChannal = _.isNil(pickChannel) ? `` : `ใน ${pickChannel.label}`
  //   const placeholderChannalName = _.isNil(pickChannel) ? `ช่องทางการขาย` : `${pickChannel.label}`
  //   return (
  //     <View style={[VIEW_STYLE_PENDDING, { height: 60 }]}>
  //       {VIEW_HEIGHT_TEN}
  //       <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{`อีเมลร้านค้าที่ใช้ ${nameChannal}`}</Text>
  //       <XInput
  //         onChangeText={(txt: string) => this._onChangeText(`channalEmail`, txt)}
  //         value={channalEmail}
  //         placeholder={`ระบุอีเมลของร้านที่ใช้ใน ${placeholderChannalName} เผื่อผูกบัญชี`}
  //         style={{ height: 30 }}
  //         // maxLength={40}
  //       />
  //     </View>
  //   )
  // }

  _renderPickChannalAndChannelName = () => (
    <Box>
      {/* {VIEW_HEIGHT_TEN} */}
      {this._renderPickChannel()}
      {/* {this._renderInputChannalName()} */}
      {/* {this._renderInputChannalEmail()} */}
    </Box>
  )

  onPressAnimatedStartLoading = async (SYNC_KEY: 'STORE' | 'PRODUCT' | 'ORDER') => {
    if (!this.state.channel.is_active) {
      this._warningChannelNotActive()
      return
    }

    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }

    // SYNC_KEY ORDER อย่าง loadng ไปทำ overlay ก่อน
    if (SYNC_KEY === 'STORE') {
      const newSyncLoadingStart = this.state.syncLoading
      newSyncLoadingStart[SYNC_KEY] = true
      await util.setStatePromise(this, {
        syncLoading: newSyncLoadingStart,
      })
    }

    // await util.delay(50000)
    if (SYNC_KEY === 'STORE') {
      await this.syncShopInfoFunction()
    } else if (SYNC_KEY === 'ORDER') {
      // this.syncOrderFunction()
      this.onOpenOverlaySetupSyncOrder()
    } else if (SYNC_KEY === 'PRODUCT') {
      this.onOpenOverlaySyncProduct()
    }
    // const newSyncLoading = this.state.syncLoading
    // newSyncLoading[SYNC_KEY] = false
    // await util.setStatePromise(this, {
    //   syncLoading: newSyncLoading,
    // })
  }

  _renderSyncbodyView = (TITLE: string, HELPER_TXT: string, SYNC_KEY: 'STORE' | 'PRODUCT' | 'ORDER') => {
    const { syncLoading, channel } = this.state
    if (_.isNil(channel)) {
      return null
    }
    let lastSync = !_.isNil(channel.last_synced_info_at) ? channel.last_synced_info_at : '-'
    if (SYNC_KEY === 'ORDER') {
      lastSync = !_.isNil(channel.last_synced_order_at) ? channel.last_synced_order_at : ''
    } else if (SYNC_KEY === 'PRODUCT') {
      lastSync = !_.isNil(channel.last_synced_product_at) ? channel.last_synced_product_at : '-'
    }
    // const isHideTimeSync = SYNC_KEY === 'PRODUCT'
    // const timeSync = isHideTimeSync ? '' : lastSync
    return (
      <Box px='2'>
        <Box style={[S.ROW_MIDDLE_BETWEEN, { paddingTop: 18 }]}>
          <Box flex={1} style={[S.ROW_MIDDLE_START]}>
            <XText variant='active'>{TITLE}</XText>

            {SYNC_KEY === 'PRODUCT' ? (
              <XHelpModal
                key='sync_sync_product'
                headerTitle='ซิงค์ข้อมูลสินค้าจากช่องทางขาย'
                FAQ={FAQ.MKP_SYNC_PRODUCT}
                initiateOpenIndex={3}
                renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
              />
            ) : (
              <HelpButton message={HELPER_TXT} style={{ width: 40, height: 20, paddingRight: 10 }} />
            )}
          </Box>
          <Box>
            <SyncBtn
              onPress={() => this.onPressAnimatedStartLoading(SYNC_KEY)}
              disabled={syncLoading[SYNC_KEY]}
              isLoading={syncLoading[SYNC_KEY]}
            />
          </Box>
        </Box>
        <Box style={{ flex: 1, flexDirection: 'row' }}>
          <Box style={{ width: 80 }}>
            {SYNC_KEY === 'ORDER' ? null : (
              <XText variant='inactive' textAlign='left'>
                อัพเดตเมื่อ
              </XText>
            )}
          </Box>
          <Box flex={1}>
            <XText textAlign='right' variant='inactive'>
              {lastSync}
            </XText>
          </Box>
        </Box>
      </Box>
    )
  }

  _renderSyncSession = () => {
    const { channel } = this.state
    if (_.isNil(channel) || !_.has(channel, 'id')) {
      return null
    }
    return (
      <Box mt='2'>
        <XText variant='inactive'>ซิงค์</XText>
        <Box pb='2' borderColor='gray.400' borderWidth='1' borderRadius='md'>
          {/* // แถว 1  */}
          {this._renderSyncbodyView('ซิงค์ข้อมูลร้าน', 'อัพเดทข้อมูลร้านและการตั้งค่าต่างๆ จากช่องทางขาย', 'STORE')}
          {/* // แถว 2  */}
          {this._renderSyncbodyView('ซิงค์ข้อมูลสินค้า', 'อัพเดทข้อมูลสินค้าจากอัพเดตครั้งล่าสุดบนช่องทางขาย', 'PRODUCT')}
          {/* // แถว 3  */}
          {this._renderSyncbodyView('ซิงค์ข้อมูลออเดอร์', 'อัพเดทข้อมูลออเดอร์จากช่องทางขาย ในช่วงเวลาที่กำหนด', 'ORDER')}
        </Box>
      </Box>
    )
  }

  _renderCommonFlatPickerItemForShipping = (data) => {
    const { item, index, disabled } = data
    // console.log('DATA => ', data)
    const shippingState = util.getShippingHasXShippingLabel(item.value)
    // console.log('shippingState => ', shippingState)
    // const UNDER_LINE = p.op.isWeb() ? {} : { borderBottomColor: COLORS.TEXT_INACTIVE, borderBottomWidth: 1 }

    return (
      <XCard w='full'>
        <XCard.Body>
          <HStack w='full' minH='10' alignItems='center' space='1'>
            <VStack flex={1}>
              <XText style={{ fontSize: STYLES.FONT_SIZE_LARGER, color: COLORS.TEXT_ACTIVE }}>{`${item.label}`}</XText>
              {_.isNil(shippingState) ? null : (
                <XText style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: shippingState.color }}>{`(${shippingState.label})`}</XText>
              )}
            </VStack>
            <XIcon name='arrowright' family='AntDesign' />
          </HStack>
        </XCard.Body>
      </XCard>
    )
  }

  _renderAutoConfirmOrder = () => {
    const {
      mode,
      channel,
      selectedAutoConfirmOrderIndex,
      isSelfDelivery,
      selectedShippingNotCodIndex,
      selectedShippingCodIndex,
      senderAddressForSelfDelivery,
      isSelfDeliveryUseXshipping,
      isSelfDeliveryAutoConfirmTrackingNumber,
      selectedAutoLoadShippingLable,
    } = this.state
    // console.log('channel => ', channel)
    if (mode !== 'VIEW' || _.isNil(selectedAutoConfirmOrderIndex)) {
      return null
    }
    if (!_.has(channel, 'auto_confirm_order')) {
      return null
    }

    const renderSelfDeliverySegment = () => {
      // const { channel } = this.state
      if (_.isNil(channel)) {
        return null
      }
      // MKP_IN_USE : MKP ที่รองรับตอนนี้
      const MKP_IN_USE = [1, 2]
      if (!_.includes(MKP_IN_USE, channel.mkp_id)) {
        return null
      }
      return (
        <VStack>
          <HStack alignItems='center'>
            <XText variant='inactive'>จัดส่งโดยใช้เลขพัสดุจากช่องทางขาย</XText>
            <XHelpModal
              key='จัดส่งโดยใช้เลขพัสดุจากช่องทางขาย'
              headerTitle='จัดส่งโดยใช้เลขพัสดุจากช่องทางขาย'
              FAQ={FAQ.MKP_IS_SELF_DELIVERY}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <Segment
            selectedIndex={isSelfDelivery ? 1 : 0}
            options={['ใช้', 'ไม่ใช้']}
            onSegmentChange={(newIndex: number) => this._onSegmentChangeSelfDelivery(newIndex)}
          />
        </VStack>
      )
    }

    const renderUseSelfDelivery = () => {
      if (!isSelfDelivery) {
        return null
      }
      const handleChangeShippingNotCodType = async (itemInfo) => {
        // console.log('itemInfo => ', itemInfo)
        const canEditPermission = this._checkCanEditPermission()
        if (!canEditPermission) {
          return
        }
        const isConfirmed = await this._confirmChangeMkpSetting()
        if (!isConfirmed) {
          return
        }
        const value = itemInfo.index === 0 ? null : itemInfo.item.value
        await util.setStatePromise(this, {
          selectedShippingNotCodIndex: itemInfo.index,
        })
        this._updateChannel('self_delivery_default_shipping_type_id', value)
      }
      const handleChangeShippingCodType = async (itemInfo) => {
        // console.log('itemInfo => ', itemInfo)
        const canEditPermission = this._checkCanEditPermission()
        if (!canEditPermission) {
          return
        }
        const isConfirmed = await this._confirmChangeMkpSetting()
        if (!isConfirmed) {
          return
        }
        const value = itemInfo.index === 0 ? null : itemInfo.item.value
        await util.setStatePromise(this, {
          selectedShippingNotCodIndex: itemInfo.index,
        })
        this._updateChannel('self_delivery_default_cod_shipping_type_id', value)
      }

      const _renderDetailShippingNotCodTypeCardButton = (data: any) => {
        const { item, index, disabled } = data
        // console.log('index // => ', index)
        let txtShippingType = null
        if (index > -1) {
          txtShippingType = this.state.shippingTypeNotCodItems[index].label
          // txtShippingType = 'PASS'
        } else if (index === -1 && item && item.value > 0) {
          txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
        } else {
          txtShippingType = 'ไม่ระบุ'
        }
        return (
          <XCard w='full'>
            <XCard.Body>
              <HStack w='full' alignItems='center' space='1'>
                <HStack alignItems='center' space='1'>
                  <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                  <XText variant='inactive'>รูปแบบจัดส่ง</XText>
                </HStack>

                <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                  <XText flex={1} textAlign='right'>
                    {txtShippingType}
                  </XText>
                  {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
                </HStack>
              </HStack>
            </XCard.Body>
          </XCard>
        )
      }

      const _renderDetailShippingCodTypeCardButton = (data: any) => {
        const { item, index, disabled } = data
        // console.log('index // => ', index)
        let txtShippingType = null
        if (index > -1) {
          txtShippingType = this.state.shippingTypeCodItems[index].label
          // txtShippingType = 'PASS'
        } else if (index === -1 && item && item.value > 0) {
          txtShippingType = 'กรุณาดาวน์โหลดแอปเวอร์ชั่นใหม่ เพื่อแสดงผลข้อมูลนี้'
        } else {
          txtShippingType = 'ไม่ระบุ'
        }
        return (
          <XCard w='full'>
            <XCard.Body>
              <HStack w='full' alignItems='center' space='1'>
                <HStack alignItems='center' space='1'>
                  <XIcon family='MaterialIcons' name='local-shipping' variant='inactive' />
                  <XText variant='inactive'>รูปแบบจัดส่ง</XText>
                </HStack>

                <HStack flex={1} alignItems='center' justifyContent='flex-end' space='1'>
                  <XText flex={1} textAlign='right'>
                    {txtShippingType}
                  </XText>
                  {disabled ? null : <XIcon variant={disabled ? 'inactive' : 'primary'} name='arrow-forward' />}
                </HStack>
              </HStack>
            </XCard.Body>
          </XCard>
        )
      }
      return (
        <VStack mt='2'>
          <HStack alignItems='center'>
            <XText variant='inactive'>รูปแบบจัดส่ง (แบบไม่เก็บเงินปลายทาง)</XText>
            <XHelpModal
              key='รูปแบบจัดส่ง (แบบไม่เก็บเงินปลายทาง)'
              headerTitle='รูปแบบจัดส่ง (แบบไม่เก็บเงินปลายทาง)'
              FAQ={FAQ.MKP_SELF_DELIVERY_DEFAULT_SHIPPING_TYPE_NON_COD}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XFlatPicker
            key='ShippingTypePicker_not_cod'
            title='เลือกรูปแบบจัดส่ง'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            placeholderStyle={{ marginLeft: 6 }}
            // ref={this._shippingTypeCreateModePickerRef}
            selectedIndex={selectedShippingNotCodIndex}
            options={this.state.shippingTypeNotCodItems}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPick={handleChangeShippingNotCodType}
            renderSelectedValue={_renderDetailShippingNotCodTypeCardButton}
            renderItem={this._renderCommonFlatPickerItemForShipping}
            // renderTitleBar={() => this._renderTotleBarForShipping()}
          />

          <HStack alignItems='center'>
            <XText variant='inactive'>รูปแบบจัดส่ง (แบบเก็บเงินปลายทาง)</XText>
            <XHelpModal
              key='รูปแบบจัดส่ง (แบบเก็บเงินปลายทาง)'
              headerTitle='รูปแบบจัดส่ง (แบบเก็บเงินปลายทาง)'
              FAQ={FAQ.MKP_SELF_DELIVERY_DEFAULT_SHIPPING_TYPE_COD}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XFlatPicker
            key='ShippingTypePicker_cod'
            title='เลือกรูปแบบจัดส่ง'
            placeholder='กรุณาเลือกรูปแบบจัดส่ง'
            placeholderStyle={{ marginLeft: 6 }}
            // ref={this._shippingTypeCreateModePickerRef}
            selectedIndex={selectedShippingCodIndex}
            options={this.state.shippingTypeCodItems}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onPick={handleChangeShippingCodType}
            renderSelectedValue={_renderDetailShippingCodTypeCardButton}
            renderItem={this._renderCommonFlatPickerItemForShipping}
            // renderTitleBar={() => this._renderTotleBarForShipping()}
          />
        </VStack>
      )
    }

    const renderSenderAddress = () => {
      if (!isSelfDelivery) {
        return null
      }
      const name = _.isNil(senderAddressForSelfDelivery) ? 'ไม่ระบุ' : senderAddressForSelfDelivery.name
      const addressLabal = _.isNil(senderAddressForSelfDelivery) ? '-' : util.getAddresFormatLable(senderAddressForSelfDelivery)
      return (
        <VStack>
          <HStack alignItems='center'>
            <XText variant='inactive'>ที่อยู่ผู้ส่ง</XText>
            <XHelpModal
              key='ที่อยู่ผู้ส่ง'
              headerTitle='ที่อยู่ผู้ส่ง'
              FAQ={FAQ.MKP_SELF_DELIVERY_SENDER_ADDRESS}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XCard w='full' borderBottomWidth='0' borderBottomLeftRadius='0' borderBottomRightRadius='0'>
            <XCard.Body>
              <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
                <XIcon name='person' variant='inactive' />
                <XText variant='inactive'>{name}</XText>
              </HStack>
            </XCard.Body>
          </XCard>
          <XCard
            w='full'
            borderTopWidth='0'
            borderTopLeftRadius='0'
            borderTopRightRadius='0'
            disabled={false}
            onPress={() => {
              this.props.navigation.dispatch(
                NavActions.navToAddressListStore({
                  headerTitle: 'เลือกที่อยู่ร้านฉัน',
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onPressAddress: (address: IAddress) => this._changeSenderAddress(address),
                  store_id: this.props.selectedStore.get('id'),
                  useSellerAddress: true,
                  useSellerAddressCustomText: 'ไม่ระบุ',
                })
              )
            }}
            // style={backgroundColor ? { backgroundColor } : undefined}
          >
            <XCard.Body>
              <HStack w='full' flexWrap='wrap' alignItems='center' space='1'>
                <HStack flex={1} space='1'>
                  <XIcon variant='inactive' family='Ionicons' name='pin' />
                  <VStack flex={1}>
                    {/* {txtLabel ? (
                  <XText variant='inactive' numberOfLines={numberOfLines} style={txtMessageStyle}>
                    {txtLabel}
                  </XText>
                ) : null} */}
                    <XText variant='inactive'>{addressLabal}</XText>
                  </VStack>
                </HStack>
                <HStack alignItems='center' justifyContent='flex-end' space='1'>
                  {/* {image != null ? <Image style={{ width: 30, height: 30 }} source={image} /> : null} */}
                  {/* {handlePress ? <XIcon name='arrow-forward' /> : null} */}
                  <XIcon name='arrow-forward' />
                </HStack>
              </HStack>
            </XCard.Body>
          </XCard>
        </VStack>
      )
    }

    const renderAutoConfirmOrder = () => {
      if (isSelfDelivery) {
        return null
      }
      return (
        <VStack mt={this._isShopee() ? '2' : '0'}>
          <HStack alignItems='center'>
            <XText variant='inactive'>ยืนยันออเดอร์อัตโนมัติ</XText>
            <XHelpModal
              key='ยืนยันออเดอร์อัตโนมัติ'
              headerTitle='ยืนยันออเดอร์อัตโนมัติ'
              FAQ={FAQ.MKP_AUTO_COMFIRM_ORDER}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XRadioList
            options={this.optionsAutoConfirmOrder}
            onRadioChange={this._autoConfirmOrderOnChange}
            selectedIndex={selectedAutoConfirmOrderIndex}
          />
          <HStack alignItems='center'>
            <XText variant='inactive'>โหลดใบปะหน้าพัสดุจากช่องทางขาย</XText>
            <XHelpModal
              key='auto_load_shipping_label'
              headerTitle='โหลดใบปะหน้าพัสดุจากช่องทางขาย'
              FAQ={FAQ.MKP_AUTO_LOAD_SHIPPING_LABEL}
              initiateOpenIndex={0}
              renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
            />
          </HStack>
          <XRadioList
            options={this.optionsAutoLoadShippingLable}
            onRadioChange={(opt: { label: string; value: number }, idx: number) => this._autoLoadShippingLableOnChange(opt, idx)}
            selectedIndex={selectedAutoLoadShippingLable}
          />
        </VStack>
      )
    }

    const renderSelfDeliveryUseXshipping = () => {
      if (!isSelfDelivery) {
        return null
      }

      const onValueChangeSelfDeliveryUseXshipping = async (newValue: boolean) => {
        const canEditPermission = this._checkCanEditPermission()
        if (!canEditPermission) {
          return
        }
        const isConfirmed = await this._confirmChangeMkpSetting()
        if (!isConfirmed) {
          return
        }
        await util.setStatePromise(this, {
          isSelfDeliveryUseXshipping: newValue,
        })
        this._updateChannel('self_delivery_use_xshipping', newValue)
      }
      const renderHelpBtn = (onPressOpenOverlay: () => void) => (
        <Box style={{ width: 30 }}>
          <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
        </Box>
      )
      return (
        <XCard mt='2'>
          <HStack
            // flex={1}
            w='full'
            style={{
              // width: '100%',
              // backgroundColor: COLORS.WHITE,
              paddingTop: 2,
              paddingRight: 4,
              paddingLeft: 8,
              borderBottomWidth: 0.5,
              borderBottomColor: COLORS.TEXT_INACTIVE,
            }}>
            <HStack flex={1}>
              <Box style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
                <XText variant='active'>ออกเลขพัสดุจาก XShipping</XText>
              </Box>
              <XHelpModal
                key='SelfDeliveryUseXshipping'
                headerTitle='ออกเลขพัสดุจาก XShipping'
                initiateOpenIndex={0}
                FAQ={FAQ.MKP_SELF_DELIVERY_USE_XSHIPPING}
                renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
              />
            </HStack>
            <Box style={{ alignItems: 'flex-end', justifyContent: 'center', paddingRight: 6 }}>
              <XSwitch
                disabled={false}
                value={isSelfDeliveryUseXshipping}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onValueChange={(newValue: boolean) => onValueChangeSelfDeliveryUseXshipping(newValue)}
              />
            </Box>
          </HStack>
        </XCard>
      )
    }

    const renderSelfDeliveryAutoConfirmTrackingNumber = () => {
      if (!isSelfDelivery) {
        return null
      }

      const onValueChangeSelfDeliveryAutoConfirmTrackingNumber = async (newValue: boolean) => {
        const canEditPermission = this._checkCanEditPermission()
        if (!canEditPermission) {
          return
        }
        const isConfirmed = await this._confirmChangeMkpSetting()
        if (!isConfirmed) {
          return
        }
        await util.setStatePromise(this, {
          selfDeliveryAutoConfirmTrackingNumber: newValue,
        })
        this._updateChannel('self_delivery_auto_confirm_tracking_number', newValue)
      }
      const renderHelpBtn = (onPressOpenOverlay: () => void) => (
        <Box style={{ width: 30 }}>
          <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
        </Box>
      )
      return (
        <XCard mt='2'>
          <HStack
            w='full'
            style={{
              // width: '100%',
              // backgroundColor: COLORS.WHITE,
              paddingTop: 2,
              paddingRight: 4,
              paddingLeft: 8,
              borderBottomWidth: 0.5,
              borderBottomColor: COLORS.TEXT_INACTIVE,
            }}>
            <HStack flex={1}>
              <Box style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
                <XText variant='active'>แจ้งเลขพัสดุไปที่ช่องทางการขาย</XText>
              </Box>
              <XHelpModal
                key='SelfDeliveryAutoConfirmTrackingNumber'
                headerTitle='แจ้งเลขพัสดุไปที่ช่องทางการขาย'
                initiateOpenIndex={0}
                FAQ={FAQ.MKP_SELF_DELIVERY_AUTO_CONFIRM_TRACKING_NUMBER}
                renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
              />
              <HStack flex={1} w='12' alignItems='center' justifyContent='flex-end'>
                <Box style={{ alignItems: 'flex-end', justifyContent: 'center', paddingRight: 6 }}>
                  <XSwitch
                    disabled={false}
                    value={isSelfDeliveryAutoConfirmTrackingNumber}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onValueChange={(newValue: boolean) => onValueChangeSelfDeliveryAutoConfirmTrackingNumber(newValue)}
                  />
                </Box>
              </HStack>
            </HStack>
          </HStack>
        </XCard>
      )
    }

    return (
      <XCard p='2' mt='2'>
        {renderSelfDeliverySegment()}
        {renderSelfDeliveryAutoConfirmTrackingNumber()}
        {renderSelfDeliveryUseXshipping()}
        {renderUseSelfDelivery()}
        {renderSenderAddress()}
        {renderAutoConfirmOrder()}
      </XCard>
    )
  }

  _renderSyncModeOrder = () => {
    const { mode, channel, selectedSyncModeOrderIndex } = this.state
    if (mode !== 'VIEW' || _.isNil(selectedSyncModeOrderIndex)) {
      return null
    }
    if (!_.has(channel, 'sync_mode_order')) {
      return null
    }
    return (
      <XCard p='2' mt='2'>
        <HStack alignItems='center'>
          <XText variant='inactive'>ซิงค์ข้อมูลออเดอร์จากช่องทางขาย</XText>
          <XHelpModal
            key='sync_mode_order'
            headerTitle='ซิงค์ข้อมูลออเดอร์จากช่องทางขาย'
            FAQ={FAQ.MKP_SYNC_MODE_ORDER}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XRadioList
          options={this.optionsSyncModeOrder}
          onRadioChange={this._syncModeOrderOnChange}
          selectedIndex={selectedSyncModeOrderIndex}
        />
      </XCard>
    )
  }

  _renderAutoLoadShippingLable = () => {
    const { mode, channel, selectedAutoLoadShippingLable } = this.state
    if (mode !== 'VIEW' || _.isNil(selectedAutoLoadShippingLable)) {
      return null
    }
    if (!_.has(channel, 'auto_load_shipping_label')) {
      return null
    }
    return (
      <XCard p='2' mt='2'>
        <HStack alignItems='center'>
          <XText variant='inactive'>โหลดใบปะหน้าพัสดุจากช่องทางขาย</XText>
          <XHelpModal
            key='auto_load_shipping_label'
            headerTitle='โหลดใบปะหน้าพัสดุจากช่องทางขาย'
            FAQ={FAQ.MKP_AUTO_LOAD_SHIPPING_LABEL}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XRadioList
          options={this.optionsAutoLoadShippingLable}
          onRadioChange={(opt: { label: string; value: number }, idx: number) => this._autoLoadShippingLableOnChange(opt, idx)}
          selectedIndex={selectedAutoLoadShippingLable}
        />
      </XCard>
    )
  }

  _renderCreateOrderAyStatus = () => {
    const { mode, channel, selectedCreateOrderAtStatus } = this.state
    if (mode !== 'VIEW' || _.isNil(selectedCreateOrderAtStatus)) {
      return null
    }
    if (!_.has(channel, 'create_order_at_status')) {
      return null
    }
    return (
      <XCard p='2' mt='2'>
        <HStack alignItems='center'>
          <XText variant='inactive'>สร้างออเดอร์จากช่องทางขายใน XSelly</XText>
          <XHelpModal
            key='create_order_at_status'
            headerTitle='สร้างออเดอร์จากช่องทางขายใน XSelly'
            FAQ={FAQ.MKP_CREATE_ORDER_AT_STATUS}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XRadioList
          options={this.optionsCreateOrderAtStatus}
          onRadioChange={(opt: { label: string; value: number }, idx: number) => this._autoCreateOrderAtStatus(opt, idx)}
          selectedIndex={selectedCreateOrderAtStatus}
        />
      </XCard>
    )
  }

  // _editProductGroups ใช้เฉพาะตอนกดแก้ product group เท่านั้น
  _editProductGroups = async () => {
    const canEditPermission = this._checkCanEditPermission()
    if (!canEditPermission) {
      return
    }
    this.props.navigation.dispatch(
      NavActions.navToPGSelectorOnly({
        callBack: (ids: number[], lineApiKey: string) => this._callBackCheckboxListModel(ids, lineApiKey),
        mkp_id: this.state.mkpIdSelected,
      })
    )

    // await util.setStatePromise(this, {
    //   isOpenProductGroupModel: true,
    // })

    // OLD VERSION
    // const { navigation, selectedStore } = this.props
    // const { channel } = this.state
    // await util.setStatePromise(this, {
    //   isGoToAuthMKP: false,
    // })
    // navigation.dispatch(
    //   NavActions.navToChannelAssignProductGroups({
    //     store_id: selectedStore.get(`id`),
    //     ug_id: channel.id,
    //     // callBackMKP: (pgs: { pg_id: number }[]) => this._updateChannelList(pgs),
    //   })
    // )
  }

  _renderProductGroups = () => {
    const { selectedStore } = this.props
    const { channel, mode } = this.state
    // แสดงเฉพาะโหมด VIEW เท่านั้น
    if (mode !== 'VIEW') {
      return null
    }
    // console.log('channel => ', channel)
    const isDisabled = false
    if (_.isNil(channel)) {
      return null
    }
    if (_.isNil(channel.pg_ids) || channel.pg_ids.length < 1) {
      return null
    }
    const productGroups = isImmutable(selectedStore.get('product_groups'))
      ? selectedStore.get('product_groups').toJS()
      : selectedStore.get('product_groups')
    if (_.isNil(productGroups)) {
      return null
    }

    const _renderAgreement = (onPressOpenOverlay: () => void) => <HelpButton onPress={() => onPressOpenOverlay()} />
    // const length = channel.pg_ids.length > 1 ? 20 * channel.pg_ids.length : 0
    // const viewHeight = 50 + length
    return (
      <Box mt='2'>
        <HStack alignItems='center'>
          <XText variant='inactive'>ราคาสำหรับสินค้าสร้างใหม่</XText>
          <XHelpModal
            key='mkp_pg'
            headerTitle='ราคาสำหรับสินค้าสร้างใหม่'
            FAQ={FAQ.PG_MKP_CHANNEL}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XCard flexDirection='row' py='2' pl='2' disabled={isDisabled} onPress={() => this._editProductGroups()}>
          <VStack flex={1} space='2'>
            {channel.pg_ids.map((id: number, index: number) => {
              // @ts-ignore
              const productGroupDetail = _.find(productGroups, (pg) => pg.id === id)
              // console.log(`productGroupDetail `, productGroupDetail)
              if (_.isNil(productGroupDetail)) {
                return null
              }
              const { name } = productGroupDetail
              return (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  flex={1}
                  borderWidth='1'
                  borderColor={COLORS.APP_SECONDARY}
                  borderRadius='md'
                  justifyContent='center'
                  h='8'>
                  <XText variant='active' bold pl='2'>
                    {name}
                  </XText>
                </Box>
              )
            })}
          </VStack>
          {isDisabled ? null : (
            <Box style={{ width: 40, alignItems: 'center', justifyContent: 'center' }}>
              <XIcon
                family='AntDesign'
                name='arrowright'
                style={{
                  fontSize: STYLES.FONT_ICON_NORMAL,
                  color: COLORS.APP_MAIN,
                  flex: 0,
                  width: 27,
                  minWidth: 44,
                  textAlign: 'center',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  // alignContent: 'center',
                  // alignSelf: 'center',
                }}
              />
            </Box>
          )}
        </XCard>
      </Box>
    )
  }

  // _renderDefaultAddressChilren = () => {
  //   const address = {
  //     id: 123,
  //     name: `กนกวรรณ สำเร็จใจหมาย`,
  //     address1: `111/23 ถ.พระราม 4`,
  //     sub_disctrict: `แขวงปทุมวัน`,
  //     disctrict: `เขตปทุมวัน`,
  //     province: `กทม`,
  //     postal_code: 10330,
  //     telephone: `099655555`,
  //     updated_at: `2020-02-24 18:23:55`,
  //     is_primary: true,
  //   }
  //   // @ts-ignore
  //   const addressLable = util.getAddresFormatLable(address)
  //   // console.log(`addressLable `, addressLable)
  //   return (
  //     <View style={{ flex: 1 }}>
  //       <View style={{ flex: 1, height: 20, flexDirection: 'row' }}>
  //         <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_INACTIVE }}>{`ที่อยู่: `}</Text>
  //         <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>ค่าเริ่มต้น</Text>
  //       </View>
  //       <View style={{ flex: 1 }}>
  //         <Text style={{ fontSize: STYLES.FONT_SIZE_NORMAL, color: COLORS.TEXT_ACTIVE }}>{addressLable}</Text>
  //       </View>
  //     </View>
  //   )
  // }

  // _renderDefaultAddress = () => (
  //   <View>
  //     <CARD children={this._renderDefaultAddressChilren()} />
  //   </View>
  // )

  _renderTimeSlots = () => (
    <Box style={{ flex: 1, height: 30 }}>
      <TouchableOpacity
        onPress={() =>
          this.props.navigation.dispatch(
            NavActions.navToTimeSlotsView({
              channel: this.state.channel,
              callBackUpdateChannel: (channelCallBack: IMKPChannelDetail) => this._updateChannelFromCallBack(channelCallBack),
            })
          )
        }
        style={{
          // borderColor: COLORS.APP_MAIN,
          // borderWidth: 0.7,
          flexDirection: 'row',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 2,
          paddingTop: 2,
          paddingLeft: 4,
          paddingRight: 4,
        }}>
        <Box style={{ flex: 1 }}>
          <XText variant='active'>ช่องทางการขนส่ง</XText>
        </Box>
        <Box style={{ width: 35, alignItems: 'center', justifyContent: 'center' }}>
          <XIcon
            family='AntDesign'
            name='arrowright'
            style={{
              fontSize: STYLES.FONT_ICON_NORMAL,
              color: COLORS.APP_MAIN,
              flex: 0,
              width: 27,
              minWidth: 44,
              textAlign: 'center',
            }}
          />
        </Box>
      </TouchableOpacity>
    </Box>
  )
  // return <XTimeSlots channel={this.state.channel} mode={`VIEW`} />

  _renderShippingTypes = () => {
    const { channel } = this.state
    if (_.isNil(channel)) {
      return null
    }
    return (
      <XCard
        mt='2'
        pl='2'
        onPress={() => {
          this.props.navigation.dispatch(
            NavActions.navToTimeSlotsView({
              channel: this.state.channel,
              callBackUpdateChannel: (channelCallBack: IMKPChannelDetail) => this._updateChannelFromCallBack(channelCallBack),
            })
          )
        }}>
        <HStack>
          <Box flex={1} p='2'>
            <XText variant='active'>ช่องทางการขนส่ง</XText>
          </Box>
          <Box style={{ width: 35, alignItems: 'center', justifyContent: 'center' }}>
            <XIcon name='arrowright' family='AntDesign' />
          </Box>
        </HStack>
      </XCard>
    )
    // return (
    //   <XCard mt='2' pl='2'>
    //     {this._renderTimeSlots()}
    //   </XCard>
    // )
    // return (
    //   <View>
    //     {/* <Text style={{ color: COLORS.TEXT_INACTIVE, fontSize: STYLES.FONT_SIZE_NORMAL }}>{`ช่องทางการขนส่ง`}</Text>
    //     <View style={{ height: 6 }} /> */}
    //     <CARD children={this._renderTimeSlots()} />
    //   </View>
    // )
  }

  _renderMkpLogoAndMkpName = () => {
    const { channel } = this.state
    if (_.isNil(channel) || !this._isActivaMKPChannel()) {
      return null
    }
    const showImg = channel.img_uri && channel.img_uri !== ''
    return (
      <Box
        style={{
          flex: 1,
          height: 80,
          flexDirection: 'row',
          // backgroundColor: COLORS.WHITE,
          marginTop: 4,
          paddingTop: 10,
        }}>
        {showImg ? (
          <Box style={{ width: 70 }}>
            <Image
              style={{ borderRadius: 7, width: 60, height: 60 }}
              source={{
                uri: channel.img_uri,
              }}
            />
          </Box>
        ) : null}
        <Box style={{ flex: 1, justifyContent: 'center' }}>
          <XText variant='active' bold>
            {channel.mkp_shop_name}
          </XText>
        </Box>
      </Box>
    )
  }

  // CHANNEL STATUS
  // More info http://localhost:8000/spec/shopee/authorization/auth_process/#status
  // 0 // Unauthorized
  // 1 // Authorized but mkp NOT activated (not yet support this mode)
  // 3 // Authorized and mkp activated

  _renderFooter = () => {
    const { mode, channel, channelWraning } = this.state
    const isViewMode = mode === 'VIEW'
    const btnTitle = mode === `CREATE` ? `ดำเนินการต่อ` : `ดำเนินการเชื่อมต่อ`
    // ถ้าไม่ใช้โหมด สร้าง แล้วไม่มี channel หรือ channel เชื่อมต่อเสร็จแล้ว ไม่ต้องแสดง footer
    if (mode !== 'CREATE') {
      return null
      // if (_.isNil(channel)) {
      //   return null
      // }
      // if (isViewMode && !_.isNil(channelWraning) && channelWraning.isShowWarning) {
      //   const MKP_NAME = util.getMKPName(channel.mkp_id)
      //   btnTitle = `ต่ออายุการเชื่อมต่อ ${MKP_NAME}`
      // } else {
      //   return null
      // }
    }

    return (
      <XButton mt='2' _text={{ fontSize: 'md', paddingTop: '1' }} onPress={() => this._footerOnPress()}>
        {btnTitle}
      </XButton>
    )
  }

  _footerOnPress = () => {
    const { mode, mkpIdSelected } = this.state
    //  console.log(`channel => `, channel)
    //  console.log(`mode => `, mode)
    // isShowLinkToProductGroup
    if (mode === 'CREATE') {
      const { subscription, selectedStore } = this.props
      const isOwner = acl.isSelectedStoreOwner()
      // console.log('isOwner => ', isOwner)
      // console.log('selectedStore => ', selectedStore.toJS())
      let mkpQuota = subscription.has('mkp_quota') ? subscription.get('mkp_quota') : null
      if (!isOwner) {
        const ownerSubscription = selectedStore.get('store_owner_subscription')
        // const storeHelperPermissions = selectedStore.get('store_helper_permissions')
        if (!acl.canDoAtSelectedStore(CONS.PERM_STORE_HELPER.MKP_ADD)) {
          p.op.showConfirmationOkOnly('', 'คุณไม่ได้รับสิทธิ์การใช้งานส่วนนี้')
          return
        }
        mkpQuota = ownerSubscription.has('mkp_quota') ? ownerSubscription.get('mkp_quota') : null
      }
      // console.log('mkpQuota => ', mkpQuota)
      const channelList = util.getMKPChannels()
      // mode CREATE ต้องเช็กแพ็กเกจก่อน
      // ถ้า Quota หาย
      if (_.isNil(mkpQuota)) {
        p.op.showConfirmationOkOnly('เกิดข้อผิดพลาด', 'กรุณาปิดหน้าต่างนี้ แล้วลองใหม่อีกครั้ง (mkp quota is null)')
        return
      }

      // ถ้าเป็น IOS และเข้าเงื่อนไขก็ปล่อยผ่าน // https://app.clickup.com/t/86cvy1v1d
      const isNoobIos = util.isIosNoobCheckByPass()
      if (!isNoobIos) {
        // ถ้าไม่มี Quota เลย
        if (mkpQuota === 0) {
          p.op.alert(p.op.t('Subscription.warning.insufficientTypeTitle'), p.op.t('Subscription.warning.insufficientTypeMsg'))
          return
        }
        // ถ้า Quota เต็ม
        const maxQuota = util.checkMaxQuotaCreateMkpChannelAndErp()
        if (maxQuota) {
          p.op.alert(p.op.t('Subscription.warning.packageFull'), p.op.t('Subscription.warning.insufficientTypeMsg'))
          return
        }
      }

      // this._openProductGroupModel()
      if (mkpIdSelected === 0) {
        p.op.showConfirmationOkOnly('', 'กรุณาเลือกช่องทางการเชื่อมต่อก่อนกดปุ่ม\n"ดำเนินการต่อ"')
        return
      }
      this._editProductGroups()
    }
    // else if (mode === `VIEW`) {
    //   // this.navToProductGroupList()
    // } else {
    //   this._getShopeeUrl()
    // }
  }

  // navToProductGroupListView = () => {
  //   this.navToProductGroupList()
  // }

  _loading = () => {
    const { isLoading, loadingWidth, loadingHeight } = this.state
    if (!isLoading) {
      return null
    }
    return (
      <Box
        style={{
          // zIndex: 999,
          position: 'absolute',
          width: loadingWidth,
          height: loadingHeight,
          backgroundColor: 'rgba(52, 52, 52, 0.7)',
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 12,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <XButton isLoading variant='ghost' />
      </Box>
    )
  }

  _renderOverlayEditSyncOrder = () => {
    const { channel, isOverlaySetupSyncOrderVisible } = this.state
    if (_.isNil(channel)) {
      return null
    }
    return (
      <OverlaySetupSyncOrder
        channel={channel}
        onClose={() => this.onCloseOverlaySetupSyncOrder()}
        isOverlayVisible={isOverlaySetupSyncOrderVisible}
        onSubmit={(callBack: IOverlaySetupSyncOrderCallBack) => this.syncOrderFunction(callBack)}
      />
    )
  }

  _renderOverlaySyncProduct = () => {
    const { channel, isOverlayOpenOverlaySyncProduct } = this.state
    if (_.isNil(channel)) {
      return null
    }
    return (
      <OverlaySyncMKPProduct
        channel={channel}
        onClose={() => this.onCloseOverlaySyncProduct()}
        isOverlayVisible={isOverlayOpenOverlaySyncProduct}
        onSubmit={(forceSync: boolean) => {
          this.onCloseOverlaySyncProduct()
          if (forceSync) {
            this._syncAllProductMKP()
          } else {
            this.syncProductFunction()
          }
        }}
      />
    )
  }

  _renderCancelShippingWhenCancelOrder = () => {
    const { channel, isCancelShippingWhenCancelOrder, mode } = this.state
    if (_.isNil(channel) || mode === 'CREATE') {
      return null
    }

    const isValueChange = (newValue: boolean) => this.setState({ isCancelShippingWhenCancelOrder: newValue })

    const renderHelpBtn = (onPressOpenOverlay: () => void) => (
      <Box style={{ width: 30 }}>
        <HelpButton style={{ minWidth: 10, width: 10 }} onPress={() => onPressOpenOverlay()} />
      </Box>
    )

    return (
      <XCard mt='2'>
        <VStack pl='2' pr='1' py='2'>
          <HStack w='full'>
            <HStack flex={1} alignItems='center'>
              <XText variant='active'>ยกเลิกการจัดส่งอัตโนมัติเมื่อออเดอร์ถูกยกเลิก</XText>
              <XHelpModal
                key='CancelShippingWhenCancelOrder'
                headerTitle='ยกเลิกการจัดส่งอัตโนมัติเมื่อออเดอร์ถูกยกเลิก'
                initiateOpenIndex={0}
                FAQ={FAQ.MKP_CANCEL_SHIPPING_WHEN_CANCEL_ORDER}
                renderButton={(onPressOpenOverlay: () => void) => renderHelpBtn(onPressOpenOverlay)}
              />
            </HStack>

            <Box style={{ alignItems: 'flex-end', justifyContent: 'center', paddingRight: 6 }}>
              <XSwitch
                disabled={false}
                value={isCancelShippingWhenCancelOrder}
                onValueChange={(newValue: boolean) => this._cancelShippingWhenCancelOrder(newValue)}
              />
            </Box>
          </HStack>
        </VStack>
      </XCard>
    )
  }

  _renderForceCreateShipping = () => {
    const { channel, isForceCreateShipping, mode } = this.state
    if (_.isNil(channel) || mode === 'CREATE') {
      return null
    }
    return (
      <XCard mt='2'>
        <XForceCreateShipping
          value={isForceCreateShipping}
          onClick={(newValue: boolean) => {
            const canEditPermission = this._checkCanEditPermission()
            if (!canEditPermission) {
              return
            }
            this.setForceCreateShipping(newValue)
          }}
          textColorActive
        />
      </XCard>
    )
  }

  _renderWarning = () => {
    const { channel, mode } = this.state
    if (mode === 'CREATE') {
      return null
    }
    const MKPChannelWraning = util.getMKPChannelWraning(channel)
    if (_.isNil(MKPChannelWraning) || !MKPChannelWraning.isShowWarning) {
      return null
    }
    const { warningText, warningBackground } = MKPChannelWraning
    return (
      <HStack bgColor={warningBackground} py='2' alignItems='center' justifyContent='center' style={{ marginLeft: -10, marginRight: -10 }}>
        <XText color='white' px='2'>
          {warningText}
        </XText>
      </HStack>
    )
  }

  // _renderProductGroupsModal = () => {
  //   const { isOpenProductGroupModal, productGroupLable, productGroupSelectedIndex } = this.state
  //   return (
  //     <XCheckboxListModel
  //       headerTitle='เลือกรายการราคา'
  //       description='กรุณาเลือกอย่างน้อย 1 ราคาด้านล่าง เพื่อกำหนดให้กับสินค้าที่ถูกสร้างใหม่ใน XSelly ระหว่างการซิงค์กับช่องทางขาย'
  //       descriptionHelp={FAQ.MKP_PRODUCT_GROUP}
  //       descriptionHelpTitle='รายการราคาสำหรับสินค้า'
  //       isOpenModel={isOpenProductGroupModal}
  //       callBack={(ids: number[]) => this._callBackCheckboxListModel(ids)}
  //       labels={productGroupLable}
  //       onRequestClose={() => this.setState({ isOpenProductGroupModal: false })}
  //       selectedLabelsIndex={productGroupSelectedIndex}
  //     />
  //   )
  // }

  _syncModeQty = () => {
    const { mode, channel, selectedSyncModeQtyIndex } = this.state
    if (mode !== 'VIEW' || _.isNil(selectedSyncModeQtyIndex)) {
      return null
    }
    if (!_.has(channel, 'sync_mode_qty')) {
      return null
    }
    return (
      <XCard p='2' mt='2'>
        <HStack alignItems='center'>
          <XText variant='inactive'>อัพเดทจำนวนคลังสินค้า</XText>
          <XHelpModal
            key='sync_mode_qty'
            headerTitle='อัพเดทจำนวนคลังสินค้า'
            FAQ={FAQ.MKP_SYNC_MODE_QTY}
            initiateOpenIndex={0}
            renderButton={(onPressOpenOverlay: () => void) => _renderAgreement(onPressOpenOverlay)}
          />
        </HStack>
        <XRadioList
          options={this.optionsRadioSyncModeQty}
          onRadioChange={this._syncModeQtyOnChange}
          selectedIndex={selectedSyncModeQtyIndex}
        />
      </XCard>
    )
  }

  _renderAuthAtTime = () => {
    const { channel } = this.state
    if (_.isNil(channel) || _.isNil(channel.auth_at) || channel.auth_at === '') {
      return null
    }
    return (
      <HStack w='full' mt='2'>
        <HStack flex={1}>
          <XText variant='inactive' fontSize='xs'>
            เชื่อมต่อเมื่อ:
          </XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText variant='inactive' fontSize='xs'>{`${channel.auth_at}`}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderExpireTime = () => {
    const { channel } = this.state
    if (_.isNil(channel) || _.isNil(channel.expire_time) || channel.expire_time === '') {
      return null
    }
    return (
      <HStack w='full' mt='2'>
        <HStack flex={1}>
          <XText variant='inactive' fontSize='xs'>
            เชื่อมต่อหมดอายุเมื่อ:
          </XText>
        </HStack>
        <HStack flex={1} justifyContent='flex-end'>
          <XText variant='inactive' fontSize='xs'>{`${channel.expire_time}`}</XText>
        </HStack>
      </HStack>
    )
  }

  _renderWebhookUrl = () => {
    const { channel } = this.state
    if (_.isNil(channel)) {
      return
    }
    const webhook = channel.webhook_url
    if (_.isNil(webhook)) {
      return null
    }

    const onPressBtn = () => {
      Clipboard.setString(webhook)
      p.op.showToast(`คัดลอก Webhook URL ${webhook} เรียบร้อยแล้ว`, 'success')
    }

    // eslint-disable-next-line consistent-return
    return (
      <HStack w='full' justifyContent='flex-end' mt='6'>
        <TouchableOpacity
          onPress={() => onPressBtn()}
          style={{
            borderTopWidth: 1,
            borderBottomWidth: 1,
            borderRightWidth: 1,
            borderLeftWidth: 1,
            borderTopColor: COLORS.APP_MAIN,
            borderBottomColor: COLORS.APP_MAIN,
            borderRightColor: COLORS.APP_MAIN,
            borderLeftColor: COLORS.APP_MAIN,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 7,
            backgroundColor: COLORS.BG_LIGHT_GREY,
          }}>
          <XText variant='primary' px='2'>
            คัดลอก Webhook URL
          </XText>
        </TouchableOpacity>
      </HStack>
    )

    //   return <HStack w='full' justifyContent={'flex-end'} mt='6'>
    //     <XButton onPress={() => onPressBtn()} variant={'outline'}>คัดลอก Webhook URL</XButton>
    //   </HStack>
  }

  render() {
    return (
      <XContainer>
        {this.renderCustomHeader()}
        <XContent
          p='2'
          onLayout={(event) => {
            const { x, y, width, height } = event.nativeEvent.layout
            util.setStatePromise(this, { loadingWidth: width, loadingHeight: height })
          }}>
          <XCard w='full' p='2' mb='2'>
            <Box w='full' style={{ flex: 1 }}>
              {/* // Artid เลิกใช้ _renderProductGroupsModel ย้ายจาก model ไปเป็น view เก็บ code สำรองไว้เผื่อมีปัญหา 21/11/2022 */}
              {/* {this._renderProductGroupsModel()} */}

              {this._renderOverlayEditSyncOrder()}
              {this._renderOverlaySyncProduct()}

              {this._renderWarning()}

              {this._renderInputChannalName()}
              {this._renderPickChannalAndChannelName()}
              {this._renderMkpLogoAndMkpName()}

              {this._renderWebhookUrl()}

              {this._renderSyncSession()}
              {this._renderAuthAtTime()}
              {this._renderExpireTime()}
              {this._renderProductGroups()}
              {/* // ไม่ใช้แแล้วตอนนี้ _renderShippingTypes เพราะ Time มีปัญหาและยังไม่จำเป็นต้องใช้ */}
              {/* {this._renderShippingTypes()} */}

              {this._renderAutoConfirmOrder()}
              {this._syncModeQty()}
              {this._renderSyncModeOrder()}
              {/* {this._renderAutoLoadShippingLable()} */}
              {this._renderCreateOrderAyStatus()}

              {this._renderCancelShippingWhenCancelOrder()}
              {this._renderForceCreateShipping()}

              {this._renderFooter()}

              {/* {this._renderDefaultAddress()} */}
            </Box>
          </XCard>
        </XContent>
        {this._loading()}
      </XContainer>
    )
  }
}
