/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/no-unused-state */
import React from 'react'
import { ISelectedStoreMap, IXScreenProps, IStoreWebsite } from 'x/index'
import * as NavActions from 'x/utils/navigation'
import p from 'x/config/platform-specific'
// import api from 'x/utils/api'
import * as api from 'x/utils/api'
import * as util from 'x/utils/util'
import _ from 'lodash'

interface ISalePageTemplate {
  template: string
  title: string
  description: string
}

interface IBaseCreateSalePageViewProps extends IXScreenProps {
  selectedStore: ISelectedStoreMap
  subscription: any
}

interface IBaseCreateSalePageViewState {
  salePage: IStoreWebsite
  salePageName: string
  selectedTemplateIndex: number
}

export default abstract class BaseCreateSalePageView extends React.Component<IBaseCreateSalePageViewProps, IBaseCreateSalePageViewState> {
  // abstract async handleSubmitForm(response: any): Promise<void>

  TEMPLATE_LIST: ISalePageTemplate[]

  constructor(props) {
    super(props)
    this.state = {
      salePage: null,
      salePageName: '',
      selectedTemplateIndex: 0,
    }
    // this.webview = React.createRef()
    this.TEMPLATE_LIST = [
      {
        template: 'sale_page',
        title: 'Sale Page',
        description: '(สำหรับสั่งซื้อสินค้า)',
      },
      {
        template: '',
        title: 'Landing Page',
        description: '(สำหรับฟอร์มเก็บข้อมูล)',
      },
      {
        template: '',
        title: 'Content Page',
        description: '(สำหรับแสดงเนื้อหาเพียงอย่างเดียว)',
      },
    ]
  }

  componentDidMount() {
    // const params = util.getNavParams(this.props)
    // const { salePage } = params
    // this.setState({ salePage })
  }

  _navToSalePageSettingWebView = () => {
    const { salePage } = this.state
    // @ts-ignore
    const { navigation } = this.props
    navigation.dispatch(
      NavActions.navToSalePageSetttingWebView({
        storeWebsite: salePage,
      })
    )
  }

  _onChangeSalePageName = (newName: string) => {
    this.setState({ salePageName: newName })
  }

  _onPressSelectedTemplate = (newIndex: number) => {
    this.setState({ selectedTemplateIndex: newIndex })
  }

  _onPressCreateSalePage = async () => {
    const { salePageName, selectedTemplateIndex } = this.state
    const { navigation, selectedStore } = this.props
    if (salePageName.length < 1) {
      p.op.showConfirmationOkOnly('', 'กรุณาระบุชื่อ Sale Page')
      return
    }
    const template = this.TEMPLATE_LIST[selectedTemplateIndex]
    if (template.template !== 'sale_page') {
      p.op.showConfirmationOkOnly('', `${template.title} ยังไม่พร้อมให้บริการ`)
      return
    }

    const store_id = selectedStore.get('id')
    const reqBody = {
      store_id,
      name: salePageName,
      type: template.template,
    }

    // const apiOptions: IApiOptions = {
    //   showSpinner: true,
    // }

    // const res = await api.postV2('store/website/create', reqBody, apiOptions)

    // // console.log('res! => ', res)
    // if (!_.isNil(res)) {
    //   util.navGoBack(this.props)
    //   const params = util.getNavParams(this.props)
    //   const { callBackCreateSucceed } = params
    //   if (_.isFunction(callBackCreateSucceed)) {
    //     callBackCreateSucceed(res)
    //   }
    // }

    try {
      const res = await api.createStoreWebsite(reqBody)

      if (res && res.id) {
        util.navGoBack(this.props)
        const params = util.getNavParams(this.props)
        const { callBackCreateSucceed } = params
        if (_.isFunction(callBackCreateSucceed)) {
          callBackCreateSucceed(res)
        }
      }
    } catch (error) {
      console.log('_onPressCreateSalePage error => ', error)
    }
  }
}
